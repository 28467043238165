import React, { ReactElement } from 'react';
import { Paper, Tabs, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import Tab from '@mui/material/Tab';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useParams } from 'react-router-dom';
import { useTabsStyles } from '../layout/tabs/dynamictabs';
import { ReportsTabsInfo } from '../../types/types';
import { mainRadius } from '../../themes/theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    marginTop: '-8px',
    padding: '4px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'none',
    borderRadius: mainRadius
  },
  title: {
    marginLeft: '12px'
  },
  tabsIndicator: {
    height: '32px',
    zIndex: 2,
    backgroundColor: 'white' || 'rgba(0, 0, 0, 0.8)',
    borderRadius: mainRadius
  },
  selectedTab: {
    color: `${theme.palette.primary.main  } !important`,
    transition: 'all 0.2s ease-in-out'
  }
}));

function ReportsTabs(props: { tabsInfo: ReportsTabsInfo }): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const classesTabs = useTabsStyles();
  const navigate = useNavigate();
  const params = useParams();
  const tabId = params['*']?.split('/')[0] || '';
  const tabNb = props.tabsInfo.findIndex(t => t.id === tabId);
  const [value, setValue] = React.useState(tabNb > 0 ? tabNb : 0);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
    navigate(`${props.tabsInfo[newValue].id}`);
  };

  if (value !== tabNb && tabNb > -1) {
    setValue(tabNb);
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        className={classesTabs.tabs}
        variant='scrollable'
        classes={{ scroller: classesTabs.scroller }}
        TabScrollButtonProps={{ className: classesTabs.scrollButtons }}
        TabIndicatorProps={{
          className: classes.tabsIndicator
        }}
      >
        { props.tabsInfo.find(item => item.id === 'pdfReports') &&
          <Tab label='PDF Reports' className={classesTabs.tab} classes={{ selected: classes.selectedTab }} disableRipple />
        }
        { props.tabsInfo.map((tabInfo) => {
          if (tabInfo.id !== 'pdfReports') {
            return (
              <Tab key={tabInfo.id} label={tabInfo.label} className={classesTabs.tab} classes={{ selected: classes.selectedTab }} disableRipple />
            );
          }

          return undefined;
        })}
      </Tabs>
    </Paper>
  );
}

export default ReportsTabs;
