import Keycloak from 'keycloak-js';
import { API } from '../../api/api';
import { KEYCLOAK_LOGOUT_REDIRECT } from '../../utils/env';
import { removeTerms } from '../../hooks/localstorage';

const Logout = (keycloak: Keycloak.KeycloakInstance): void => {
  removeTerms();
  delete API.defaults.headers.common.Authorization;
  keycloak.logout({ redirectUri: KEYCLOAK_LOGOUT_REDIRECT });
};

export default Logout;
