import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../components/params';
import { InvestMgr } from '../../../types/types';
import { InvestMgrTable } from './table';
import { EntityListDataProvider, ListRendererMap, MultiModeList } from '../../../components/entity/list';
import { InvestMgrService } from '../../../services/investmgr';
import CustomPagination from '../../../components/layout/pagination/pagination';
import InvestMgrFilters from './filters';
import TableSkeleton from '../../../components/layout/loading/tableskeleton';

class InvestMgrListDataProvider extends EntityListDataProvider<InvestMgr> {
  constructor(searchParamsAccess: SearchParamsAccess) {
    super(searchParamsAccess, new InvestMgrService());
  }
}

export function InvestMgrMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const { t } = useTranslation();
  const listDataProvider = new InvestMgrListDataProvider(searchParamsAccess);

  function renderEmpty() {
    return (
      <div style={{ marginTop: '40px' }}>No {t('investMgrs.UpperCaseSimpleLabel')}...</div>
    );
  }

  function renderLoading() {
    return (
      <TableSkeleton nbColumns={4} />
    );
  }

  function renderAsTable(eltList: Array<InvestMgr>, totalCount: number) {
    return (
      <>
        <InvestMgrTable list={eltList} paramsSort={listDataProvider.sortParamsManager} />
        <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
      </>
    );
  }

  function renderAsCards(eltList: Array<InvestMgr>, totalCount: number) {  // eslint-disable-line @typescript-eslint/no-unused-vars
    return (
      <>
        <div>Cards List here</div>
        <div>Pagination goes here...</div>
      </>
    );
  }

  const renderers: ListRendererMap<InvestMgr> = {
    'table': renderAsTable,
    'cards': renderAsCards
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table' /* 'cards' */]}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={() => renderEmpty()}
      renderLoading={() => renderLoading()}
      filtersComponent={InvestMgrFilters}
    />
  );
}

function InvestMgrListingPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <InvestMgrMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default InvestMgrListingPage;
