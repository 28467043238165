import React, { ReactElement, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Modal, Theme, Typography } from '@mui/material';
import { Nullable } from '../../types/types';
import { API } from '../../api/api';
import CustomButton from '../../components/button/button';

SyntaxHighlighter.registerLanguage('json', json);

export function DisplayDataLink(props: {link: string, label?: string}) : ReactElement {
  const href=`/dev/displayData?url=${props.link}`;
  const label = props.label ? props.label : props.link;

  return (
    <CustomButton href={href} variant='contained' small style={{ marginLeft: '16px' }}>
      {label}
    </CustomButton>
  );
}

export function DisplayData(props: {url: string}): ReactElement {
  const { url } = props;

  const [data, setData] = React.useState<Nullable<string>>(null);

  useEffect(() => {
    if (url) {
      API.get(url)
        .then((resp) => {
          setData(JSON.stringify(resp.data, null, 4));
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
  }, []);

  return (
    <>
      {data &&
            <SyntaxHighlighter language='json' style={docco} wrapLongLines >
              {data}
            </SyntaxHighlighter>
      }
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '20px' // theme.spacing.unit * 4,
  }
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "scroll"
  };
}

export function DisplayDataModal(props: {url: string, text: string}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CustomButton variant='outlined' color='primary' onClick={handleOpen} style={{ marginRight: '16px' }} small>
        {props.text}
      </CustomButton>
      <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <>
          <div style={getModalStyle()} className={classes.paper}>
            <div><Button onClick={handleClose}>Close</Button></div>
            <DisplayData url={props.url} />
          </div>
        </>
      </Modal>
    </>
  );
}

function DisplayDataPage(): ReactElement {
  const [searchParams /* setSearchParams */] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  const url = searchParams.get('url');

  return (
    <Typography variant='h1' component='h1'>
      { url &&
                <DisplayData url={url} />
      }
    </Typography>
  );
}

export default DisplayDataPage;
