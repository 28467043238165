import React, { Dispatch, ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Checkbox } from '@mui/material';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../params';
import {
  EntityListDataProvider,
  FilterParamsManager,
  ListRendererMap,
  MultiModeList
} from '../../entity/list';
import { DdTemplatesService } from '../../../services/import';
import { DdTemplate, ApiFilter, Nullable, ParamFilter } from '../../../types/types';
import GenericFilters from '../../filters/filters';
import CustomPagination from '../../layout/pagination/pagination';
import CustomTable, { Column } from '../../table/table';
import AllCheckbox, { AllCheckboxProps } from '../../checkBoxes/allcheckbox';

interface DdTemplatesTableProps {
  list: DdTemplate[],
  checked: Nullable<DdTemplate[]>,
  setChecked: Dispatch<React.SetStateAction<Nullable<DdTemplate[]>>>,
  paramsSort?: FilterParamsManager
}

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export function DdTemplatesTable(props: DdTemplatesTableProps) : ReactElement {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.list.forEach((template) => {
      if (template._id === event.target.value) {
        if (props.checked && props.checked.some(value => value._id === template._id)) {
          props.setChecked(props.checked.filter(value => value._id !== template._id));
        } else {
          props.setChecked(prevState => (prevState ? [...prevState, template] : [template]));
        }
      }
    });
  };

  let columns: Array<Column> = [];

  if (props.checked) {
    columns.push({ id: 'checkbox', label: <AllCheckbox {...props as AllCheckboxProps<unknown>} />, minWidth: 'min-content', align: 'left' });
  }

  columns = [
    ...columns,
    { id: 'fullRef', label: 'Full Ref', minWidth: 'min-content' },
    { id: 'simpleId', label: 'Simple Id', minWidth: 'min-content' },
    { id: 'version', label: 'Version', minWidth: 'min-content', align: 'center' },
    { id: 'active', label: 'Active', minWidth: 'min-content', align: 'center' },
    { id: 'type', label: 'Type', minWidth: 'min-content', align: 'center' },
    { id: 'label', label: 'Label', minWidth: 'min-content', align: 'center' },
    { id: 'auditedEntity.supportedTypes', label: 'Audited Entity Supported Types', minWidth: 'min-content', align: 'center' }
  ];

  const rows = props.list.map((importObj: DdTemplate) => {
    const active = `${importObj.versionManagement.active}`;

    return ({
      id: importObj._id,
      value: columns.map(column => (
        <>
          {column.id === 'checkbox' && props.checked &&
            <Checkbox
              color='primary'
              value={importObj._id}
              checked={props.checked.some(value => value._id === importObj._id)}
              onChange={handleChange}
            />
          }
          {column.id === 'fullRef' && <Link className={classes.link} to={`/admin/ddtemplates/${importObj._id}`}>{importObj.fullRef}</Link>}
          {column.id === 'simpleId' && importObj.versionManagement.simpleId}
          {column.id === 'version' && importObj.versionManagement.version.toString()}
          {column.id === 'active' && active.charAt(0).toUpperCase() + active.slice(1)}
          {column.id === 'type' && importObj.type}
          {column.id === 'label' && importObj.label}
          {column.id === 'auditedEntity.supportedTypes' && importObj.auditedEntity.supportedTypes.toString()}
        </>
      ))
    });
  });

  return (
    <>
      <CustomTable columns={columns} rows={rows} size='small' paramsSort={props.paramsSort} />
    </>
  );
}

export class DdTemplatesDataProvider extends EntityListDataProvider<DdTemplate> {
  constructor(searchParamsAccess: SearchParamsAccess, itemsPerPageMin?: number) {
    super(searchParamsAccess, new DdTemplatesService(), itemsPerPageMin);
  }
}

export function DdTemplatesFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const filters: Array<ParamFilter> = props.apiFilters ?
    props.apiFilters.map(filter => (
      { id: filter.value, type: 'multiSelect', label: filter.label, values: filter.elements }
    ))
    :
    [
      { id: 'type', type: 'multiSelect', label: 'Type', values: [{ label: 'mdd-review' }, { label: 'imported-mdd' }] },
      { id: 'auditedEntity.supportedTypes', type: 'multiSelect', label: 'Audited Entity Supported Types', values: [{ label: 'strategy' }, { label: 'investMgr' }] }
    ];

  filters.push({ id: 'active', type: 'boolean', label: 'Active' });
  filters.push({ id: 'simpleId', type: 'search', label: 'Simple Id', autoComplete: query => new DdTemplatesService().getSearchList(query) });

  return (<GenericFilters filters={filters} filterParamsManager={props.filterParamsManager} />);
}

export function DdTemplatesMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new DdTemplatesDataProvider(searchParamsAccess);

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '40px' }}>No imports...</div>
    </>
  );

  const renderLoading = () => (
    <div style={{ marginTop: '40px' }}>Loading...</div>
  );

  const renderAsTable = (eltList: DdTemplate[], totalCount: number, checked?: Nullable<DdTemplate[]>, setChecked?: Dispatch<React.SetStateAction<Nullable<DdTemplate[]>>>): ReactElement => (
    <>
      {checked !== undefined && setChecked && <DdTemplatesTable list={eltList} checked={checked} setChecked={setChecked} />}
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
    </>
  );

  const renderers: ListRendererMap<DdTemplate> = {
    'table': (esList, totalCount, setUpdateList, checked, setChecked) => renderAsTable(esList, totalCount, checked, setChecked)
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table' ]}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      filtersComponent={DdTemplatesFilters}
      actionsService={new DdTemplatesService()}
    />
  );
}

function DdTemplates(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <DdTemplatesMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default DdTemplates;
