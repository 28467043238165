import { Typography, Grid } from '@mui/material';
import React, { ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ChipVersion from './ChipVersion';
import { BaseEntityService } from '../../services/data';
import { VersionProps } from '../../types/types';
import EntityLink from '../modularcomponents/entitylink/entitylink';

interface VersionsSubHeaderProps<T> {
	id: string,
	service: BaseEntityService<T>
}

export default function VersionsSubHeader<T>(props: VersionsSubHeaderProps<T>): ReactElement {
  const location = useLocation();

  const linkFactory = () => {
    if (location.pathname.includes('ddtemplates')) {
      return "ddtemplates";
    }
    if (location.pathname.includes('ddelementgroups')) {
      return "ddelementgroups";
    }
    if (location.pathname.includes('ddreportblocks')) {
      return "ddreportblocks";
    }

    return '';
  };

  const [allVersions, setAllVersions] = useState<VersionProps[]>([]);

  useEffect(() => {
    props.service.getAllVersions(props.id)
      .then((apiResp) => {
        apiResp.data && setAllVersions(apiResp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Grid container style={{ padding: '16px' }}>
      <Grid item style={{ width: '160px' }}>
        <Typography variant='h2' component='h2'>Available versions</Typography>
      </Grid>
      <Grid item style={{ width: 'calc(100% - 160px)' }}>
        { allVersions.map( (version) => {
          const entityId = linkFactory();

          return (
            <EntityLink key={version._id} type='admin' id={entityId} params={`/${  version._id}`}>
              <ChipVersion
                id={version._id}
                version={version.versionManagement.version}
                active={!!version.versionManagement.active}
                inUse={!!version.versionManagement.inUse}
              />
            </EntityLink>
          );
        })}
      </Grid>
    </Grid>
  );
}
