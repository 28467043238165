import React, { ReactElement } from "react";
import Typography from '@mui/material/Typography';
import { QuestionSpec } from '../../../types/types';

function NumberFieldViewer(props: {value?: string, specs?: QuestionSpec}) : ReactElement {
  return (
    <Typography variant='body1' component='p'>
      {props.value}{props.specs?.unit}
    </Typography>
  );
}
export default NumberFieldViewer;
