import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Grid, List, ListItem, Modal, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { mainRadius } from '../themes/theme';
import CustomButton from '../components/button/button';
import Logout from '../components/authentification/logout';
import { useTermsStatus } from '../hooks/localstorage';
import checkRoles from '../components/authentification/checkRoles';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  backgroundColor: 'background.paper',
  borderRadius: mainRadius,
  border: 'none',
  boxShadow: 24,
  p: 4
};

const termsStyle = {
  border: 'solid 1px lightgrey',
  borderRadius: mainRadius
};

function AuditorModal(): ReactElement {
  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12}>
        <Typography variant='h1'>
          Terms of Service
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '12px' }}>
        <Typography variant='h2'>
          You will now access to the Deeligenz platform, please find below the usage, interpretation, disclosure of the reports and data stemming from the reports (the &quot;Usage of Reports&quot;) :
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={termsStyle} style={{ backgroundColor: '#f7f7f7' }}>
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                Broadly the report and embedded data are for internal use only (meaning for the professional usage of individuals that have personal access to the platform).
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                The ratings set in the report are deemed confidential and cannot be communicated externally except by the ODD and/or the MDD team and under particular legal and/or business circumstances.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                The content of the reports is deemed confidential and cannot be communicated externally except by the ODD and/or MDD team and under particular legal and/or business circumstances.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                The content of the reports and embedded data shall not be open to any interpretation or alteration. Should any of the user needs an explanation on the content of a report, the ODD and MDD team will remain their sole contact.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                Please refer to the ODD and/or MDD team should there be any confusion or question.
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          For any avoidance of doubt, should a user breaches the &quot;Usage of Reports&quot; as defined above, she/he will be held reliable of any potential pertaining consequences and resulting damage.
        </Typography>
      </Grid>
    </Grid>
  );
}

function AuditeeModal(): ReactElement {
  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12}>
        <Typography variant='h1'>
          Dear all,
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '12px' }}>
        <Typography variant='h2'>
          You have been granted access to the Deeligenz platform (the “Platform”) to facilitate the questions/answers process between you as Investment Manager Company and ABN AMRO Investment Solutions; please find below the usage, interpretation and disclosure of the questionnaire (the “Usage of the Platform”) :
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={termsStyle} style={{ backgroundColor: '#f7f7f7' }}>
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                The questionnaire is for internal use only (meaning for the professional usage by the platform’s users)
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                The questionnaire is confidential and must not be communicated externally except with ABN AMRO Investment Solutions’ prior approval.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                The content of the questionnaire shall not be open to any interpretation or alteration. Should any of the user needs an explanation on the content of a question, AAIS’ ODD and MDD team will remain the sole contact.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant='body1'>
                Please refer to AAIS’ ODD and MDD team if there is any confusion or question
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          For any avoidance of doubt, should a user breaches the Usage of the Platform, she/he will be held liable of any potential pertaining consequences and resulting damage.
        </Typography>
      </Grid>
    </Grid>
  );
}

function PostAuthModal(): ReactElement {
  const [openAuditee, setOpenAuditee] = useState(false);
  const [openAuditor, setOpenAuditor] = useState(false);
  const { keycloak } = useKeycloak();
  const [accepted, setAccepted] = useTermsStatus();

  useEffect(() => {
    if (checkRoles(['admin', 'dd-editor', 'dd-viewer', 'developer', 'fund-center-user', 'manager', 'report-viewer-all', 'report-viewer-simple', 'super-admin'], keycloak)) {
      setOpenAuditor(!accepted);
    } else if (checkRoles(['auditee'], keycloak)) {
      setOpenAuditee(!accepted);
    }
  }, [accepted]);

  const handleLogout = () => {
    Logout(keycloak);
  };

  const handleAccept = () => {
    setAccepted(true);
    setOpenAuditor(false);
    setOpenAuditee(false);
  };

  return (
    <Modal
      open={openAuditor || openAuditee}
      style={{
        backdropFilter: 'blur(8px)'
      }}
      disableAutoFocus
    >
      <Box sx={style}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12}>
            {openAuditor &&
              <AuditorModal />
            }
            {openAuditee &&
              <AuditeeModal />
            }
          </Grid>
          <Grid item xs={12} style={{ marginTop: '24px' }}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={6}>
                <CustomButton
                  style={{
                    textTransform: 'none'
                  }}
                  variant='outlined'
                  onClick={handleLogout}
                  fullWidth
                >
                  Decline
                </CustomButton>
              </Grid>
              <Grid item xs={6}>
                <CustomButton
                  style={{
                    textTransform: 'none'
                  }}
                  variant='contained'
                  fullWidth
                  onClick={handleAccept}
                >
                  Accept
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default PostAuthModal;
