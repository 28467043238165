import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnalysisInfo } from '../../types/types';
import { InputStatCard } from './InputStatCard';

interface AnalysisPhaseStatsProps {
	analysisInfo: AnalysisInfo
}

export default function AnalysisPhaseStats(props: AnalysisPhaseStatsProps): ReactElement {
  const { analysisInfo } = props;
  const { t } = useTranslation();

  return (
    <Grid
      container
      style={{ backgroundColor: 'white', padding: '16px', borderRadius: '24px', marginBottom: '16px' }}
    >
      <Grid item xs={12} style={{ marginBottom: '16px' }}>
        <Typography variant='h1' component='h2'>{t('dds.phases.analysisPhase')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container item spacing={2} style={{ marginBottom: '16px', maxWidth: '800px' }}>
          <InputStatCard count={analysisInfo.rating.ok} title={t('ddElements.rating.ok')} link='analysis?f_rating=ok' color='lightGreen'/>
          <InputStatCard count={analysisInfo.rating.concern} title={t('ddElements.rating.concern')} link='analysis?f_rating=concern' color='lightBlue' />
          <InputStatCard count={analysisInfo.rating.redFlag} title={t('ddElements.rating.redFlag')} link='analysis?f_rating=redFlag' color='lightRed' />
          <InputStatCard count={analysisInfo.flags.meeting} title={t('ddElements.rating.meeting')} link='analysis?f_flags=meeting' color='lightGrey' />
          <InputStatCard count={analysisInfo.flags.keyFinding} title={t('ddElements.rating.keyFinding')} link='analysis?f_flags=keyFinding' color='lightGrey'/>
          <InputStatCard count={analysisInfo.flags.nextReview} title={t('ddElements.rating.nextReview')} link='analysis?f_flags=nextReview' color='lightGrey'/>
        </Grid>
      </Grid>
    </Grid>
  );
}
