import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Autocomplete, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ServiceError from '../../services/errors';
import { SearchType } from './search';
import SearchBar  from './searchbar';

const useStyles = makeStyles(() => ({
  popper: {
    marginLeft: '64px',
    minWidth: 'max-content'
  }
}));

function useDebounce(value: string, delay: number, initialValue: string) {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    const timer = setTimeout(() => setState(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return state;
}

function SearchAutoComplete({ filter, filterParamsManager, update }: SearchType): ReactElement {
  const classes = useStyles();
  const [options, setOptions] = React.useState<string[]>([]);
  const [myValue, setMyValue] = useState(filterParamsManager.getFilter(filter.id)?.value || '');
  const [inputValue, setInputValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const debouncedValue = useDebounce(myValue, 500, '');
  
  useEffect(() => {
    update && setMyValue('');
  }, [update]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      if (!filter.autoComplete) {
        setErrorMsg(`missing autocomplete in search filter ${filter.id}`);
      } else if (myValue.length > 2) {
        setLoading(true);
        filter.autoComplete(myValue)
          .then((apiResp) => {
            apiResp.data && setOptions(apiResp.data);
            setLoading(false);
          })
          .catch((err) => {
            setErrorMsg(ServiceError.getErrorMsg(err));
            setLoading(false);
          });
      } else {
        setOpen(false);
      }
    }
  }, [debouncedValue]
  // [myValue, open]
  );

  const handleSubmit = (query: string) => {
    setInputValue(query);
    setMyValue(query);
    setOpen(false);
    if (query.length > 0) {
      filterParamsManager.onFilterChanged(filter.id, query);
    } else {
      filterParamsManager.onFilterRemoved(filter.id);
    }
  };

  return <>
    <Autocomplete
      id='searchAutoComplete'
      classes={{
        popper: classes.popper
      }}
      style={{ minWidth: 300 }}
      open={open}
      onOpen={() => {
        setOpen(!!filter.autoComplete);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={myValue}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={option => option}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubmit(myValue);
        }
      }}
      onChange={(event, value) => {
        handleSubmit(value || '');
      }}
      options={options}
      loading={loading}
      renderInput={params => (
        <SearchBar focused={focused} setFocused={setFocused} handleSubmit={handleSubmit} value={myValue} setValue={setMyValue} params={params} />
      )}
    />
    <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
        {errorMsg}
      </Alert>
    </Snackbar>
  </>;
}

export default SearchAutoComplete;
