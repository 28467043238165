import React, { ReactElement } from 'react';
import { Grid, Paper, Theme } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mainRadius } from '../../../themes/theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: '12px',
    width: '100%',
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    border: 'none',
    borderRadius: mainRadius,
    height: '100%',
    overflow: 'hidden'
  }
}));

function HeaderItem(props: {children: Array<ReactElement> | ReactElement}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid item xs>
      <Paper className={classes.paper} elevation={0} variant='outlined'>
        <Grid container spacing={1} style={{ padding: '12px' }}>
          {props.children}
        </Grid>
      </Paper>
    </Grid>
  );
}

export default HeaderItem;
