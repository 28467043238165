interface DeeligenzEnv {
	REACT_APP_DEELIGENZ_API_BASE_URL: string,
	REACT_APP_DEELIGENZ_PLATFORM: string,
	REACT_APP_DEELIGENZ_KEYCLOAK_LOGOUT_REDIRECT: string
}

declare global {
  interface Window {
    __DEELIGENZ_ENV__: DeeligenzEnv
  }
}

export const API_BASE_URL = process.env.REACT_APP_DEELIGENZ_API_BASE_URL
  ? process.env.REACT_APP_DEELIGENZ_API_BASE_URL
  : window.__DEELIGENZ_ENV__.REACT_APP_DEELIGENZ_API_BASE_URL;
  
export const PLATFORM = process.env.REACT_APP_DEELIGENZ_PLATFORM
  ? process.env.REACT_APP_DEELIGENZ_PLATFORM
  : window.__DEELIGENZ_ENV__.REACT_APP_DEELIGENZ_PLATFORM;
  
export const KEYCLOAK_LOGOUT_REDIRECT = process.env.REACT_APP_DEELIGENZ_KEYCLOAK_LOGOUT_REDIRECT
  ? process.env.REACT_APP_DEELIGENZ_KEYCLOAK_LOGOUT_REDIRECT
  : window.__DEELIGENZ_ENV__.REACT_APP_DEELIGENZ_KEYCLOAK_LOGOUT_REDIRECT;
  
