import React, { ChangeEvent, ReactElement, useEffect, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import dayjs, { Dayjs } from 'dayjs';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Switch,
  Typography
} from '@mui/material';
import { LocalizationProvider, TimePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LabelIcon from '@mui/icons-material/Label';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { EventRounded, RemoveCircleRounded } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import AddParticipantMenu from './addparticipantmenu';
import { BasePerson } from '../../../types/types';
import { MeetingUpdateProps } from '../../../types/meetingstypes';
import EditionTools from './editiontools';
import CustomButton from '../../button/button';
import { parseISOString } from '../../../utils/parse';

const useStyles = makeStyles({
  container: {
    padding: '14px',
    opacity: '0.8',
    '&:hover': {
      opacity: '1',
      zIndex: '9',
      boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.2s ease-in-out'
    }
  },
  textField: {
    margin: '4px 0 8px 0'
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  'validate-button': {
    textTransform: 'capitalize',
    backgroundColor: green[500],
    height: '50px',
    '&:hover': {
      backgroundColor: green[400]
    }
  }
});

function AuditeeField(props: { label: string, value: string | Date, children?: ReactNode }): ReactElement {
  return (
    <Box>
      <Typography style={{ fontSize: '11px', opacity: .8, marginBottom: '4px' }}>{ props.label}</Typography>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid lightGrey', paddingBottom: '4px', marginBottom: '8px' }}>
        <Typography>{ props.value && props.value !== '' ? `${props.value}` : '' }</Typography>
        { props.children }
      </Box>
    </Box>
  );
}

function SetUpMeeting(props: MeetingUpdateProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [meeting, setMeeting] = useState(props.meeting);
  const [ddParticipants, setDdParticipants] = useState(props.ddIdParticipants || []);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setMeeting(props.meeting);
  }, [props.meeting]);

  useEffect(() => {
    setDdParticipants(props.ddIdParticipants || []);
  }, [props.ddIdParticipants]);

  const handleTitleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMeeting(old => ({ ...old, title: event.target.value }));
  };

  const handleLocationChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMeeting(old => ({ ...old, location: event.target.value }));
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      setMeeting(old => ({ ...old, date: date.toDate() }));
    }
  };

  const handleParticipant = (id: string) => {
    if (!props.isAuditor){
      return;
    }
    if (meeting.participantIds.includes(id)) {
      if (meeting.participantIds.length === 1) {
        setMeeting(old => ({ ...old, participantIds: [] }));
      } else {
        setMeeting(old => ({ ...old, participantIds: old.participantIds.filter(value => value !== id) }));
      }
    } else {
      setMeeting(old => ({ ...old, participantIds: [...old.participantIds, id] }));
    }
  };

  const removeExtraParticipant = (index: number) => {
    if (!props.isAuditor){
      return;
    }
    if (meeting.extraParticipants.length === 1) {
      setMeeting(old => ({ ...old, extraParticipants: [] }));
    } else {
      setMeeting(old => ({ ...old, extraParticipants: old.extraParticipants.filter(value => old.extraParticipants.indexOf(value) !== index) }));
    }
  };

  const addMeetingExtraParticipants = (addedExtraParticipant: BasePerson) => {
    if (!props.isAuditor){
      return;
    }
    setMeeting(old => ({ ...old, extraParticipants: [...old.extraParticipants, addedExtraParticipant] }));
  };

  const editMeetingExtraParticipants = (editedExtraParticipant: BasePerson, index: number) => {
    if (!props.isAuditor){
      return;
    }
    const customExtraParticipants = meeting.extraParticipants.filter((participant, i) => (
      i !== index
    ));
    setMeeting(old => ({ ...old, extraParticipants: [...customExtraParticipants, editedExtraParticipant] }));
  };

  const handleVisible = () => {
    setMeeting(old => ({ ...old, visible: !old.visible }));
  };

  const handleMeetingUpdate = () => {
    if (!props.isAuditor){
      return;
    }
    props.updateMeeting(meeting);
    setIsEditing(!isEditing);
  };

  const handleEdition = () => {
    if (!props.isAuditor){
      return;
    }
    setIsEditing(!isEditing);
  };

  const handleCancel = () => {
    if (!props.isAuditor){
      return;
    }
    setMeeting(props.meeting);
  };

  return (
    <Paper elevation={0} className={classes.container} style={{ opacity: !props.isAuditor ? 1 : .8 }}>
      <Grid container>
        <Grid item xs>
          <Typography variant='h2' style={{ marginTop: '8px' }} gutterBottom>
            {t('dds.tabs.meetings.setup.meetingInformation')}
          </Typography>
        </Grid>
        { props.isAuditor &&
					<Grid item>
					  <EditionTools
					    edit={handleEdition}
					    cancel={handleCancel}
					    isEditing={isEditing}
					    isSaveNeeded={false}
					    isEditable
					    readonly={props.readonly}
					  />
					</Grid>
        }
      </Grid>
      { props.isAuditor &&
				<FormControlLabel
				  control={
				    <Switch
				      checked={meeting.visible}
				      onChange={handleVisible}
				      color='primary'
				      name={t('dds.tabs.meetings.setup.isVisible') || undefined}
				      inputProps={{ 'aria-label': 'primary checkbox' }}
				      disabled={!isEditing}
				    />
				  }
				  label={meeting.visible ? t('utils.visible') : t('utils.notVisible')}
				/>
      }
      { !props.isAuditor ?
        <AuditeeField label='Title' value={meeting.title}>
          <LabelIcon style={{ marginRight: '12px' }}/>
        </AuditeeField>
        :
        <FormControl style={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}>
          <InputLabel htmlFor='meeting-title'>{t('utils.title')}</InputLabel>
          <Input
            id='meeting-title'
            name='title'
            fullWidth
            value={meeting.title}
            onChange={handleTitleChange}
            endAdornment={<InputAdornment position='end'><LabelIcon style={{ marginRight: '12px' }}/></InputAdornment>}
            inputProps={{
              'aria-label': t('dds.tabs.meetings.meetingTitle') || undefined
            }}
            error={meeting.title.length < 1}
            required
            disabled={!isEditing}
          />
          {meeting.title.length < 1 && <FormHelperText error>{t('inputs.required')}</FormHelperText>}
        </FormControl>
      }
      { !props.isAuditor ?
        <AuditeeField label='Location' value={meeting.location}>
          <LocationOnIcon style={{ marginRight: '12px' }}/>
        </AuditeeField>
        :
        <FormControl style={{ width: '100%', marginTop: '8px', marginBottom: '16px' }}>
          <InputLabel htmlFor='meeting-address'>{t('utils.location')}</InputLabel>
          <Input
            id='meeting-address'
            name={t('utils.location') || undefined}
            fullWidth
            value={meeting.location}
            onChange={handleLocationChange}
            endAdornment={<InputAdornment position='end'>
              <LocationOnIcon style={{ marginRight: '12px' }}/>
            </InputAdornment>}
            inputProps={{
              'aria-label': t('dds.tabs.meetings.meetingAddress') || undefined
            }}
            disabled={!isEditing}
          />
        </FormControl>
      }
      { !props.isAuditor ?
        <AuditeeField label={t('utils.dateAndTime')} value={meeting.date ? parseISOString(meeting.date.toString()) : ''}>
          <EventRounded style={{ marginRight: '12px' }}/>
        </AuditeeField>
        :
        <Grid container spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={6}>
              <DesktopDatePicker
                label={t('utils.date')}
                value={dayjs(meeting.date) || null}
                onChange={handleDateChange}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePicker
                label={t('utils.time')}
                value={dayjs(meeting.date) || null}
                onChange={handleDateChange}
                disabled={!isEditing}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      }
      <Box>
        <Typography variant='h3' component='h3' style={{ margin: '14px auto 14px auto' }} color='primary'>
          {t('contactsAndParticipants.participants.label')}
        </Typography>
        <Typography variant='h3'>
          {t('dds.ddMembers')}
        </Typography>
        { !props.isAuditor ?
          <Box style={{ paddingTop: '18px', display: 'flex' }}>
            { ddParticipants.length > 0 ?
              <>
                { ddParticipants.map(participant =>
                  <Typography key={`participant-${  participant.id}`} style={{ marginRight: '24px' }}>
                    {`${participant.firstName} ${participant.lastName}`}
                  </Typography>
                )}
              </>
              :
              <Typography>{t('contactsAndParticipants.participants.noParticipant')}</Typography>
            }
          </Box>
          :
          <FormControl style={{ marginTop: '14px' }}>
            { ddParticipants.length > 0 ?
              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                { ddParticipants.map(participant =>
                  <FormControlLabel
                    key={`participant-${  participant.id}`}
                    control={
                      <Checkbox
                        checked={meeting.participantIds.includes(participant.id)}
                        name={`${participant.firstName} ${participant.lastName}`}
                        onChange={() => handleParticipant(participant.id)}
                        color='primary'
                        disabled={!isEditing && props.isAuditor}
                        readOnly={!props.isAuditor}
                      />
                    }
                    label={`${participant.firstName} ${participant.lastName}`}
                  />
                )}
              </FormGroup> :
              <Typography>{t('contactsAndParticipants.participants.noParticipant')}</Typography>
            }
          </FormControl>
        }

        {(isEditing || meeting.extraParticipants.length > 0) &&
					<>
					  <Typography variant='h3' style={{ marginTop: '14px' }}>
					    {t('contactsAndParticipants.participants.otherParticipants')}
					  </Typography>
					  <FormControl style={{ marginTop: '14px' }}>
					    {meeting.extraParticipants.length > 0 &&
								<Grid container spacing={1} alignItems='center'>
								  {meeting.extraParticipants.map((extraParticipant, index) =>
								    <Grid key={JSON.stringify(extraParticipant)} item xs={12}>
								      <Grid container spacing={1}>
								        <Grid item>
								          <Typography style={{ marginTop: '4px' }}>
								            {`${extraParticipant.firstName} ${extraParticipant.lastName}`}
								            {extraParticipant.title ? ` - ${extraParticipant.title}` : ''}
								          </Typography>
								        </Grid>
								        {isEditing && props.isAuditor &&
													<>
													  <Grid item style={{ marginLeft: '8px' }}>
													    <AddParticipantMenu
													      addMeetingExtraParticipants={
													        (participant) => {
													          editMeetingExtraParticipants(participant, index);
													        }}
													      participantToEdit={extraParticipant}
													    />
													  </Grid>
													  <Grid item>
													    <IconButton onClick={() => removeExtraParticipant(index)} size='small'>
													      <RemoveCircleRounded />
													    </IconButton>
													  </Grid>
													</>
								        }
								      </Grid>
								    </Grid>
								  )}
								</Grid>
					    }
					  </FormControl>
					</>
        }
        { isEditing && props.isAuditor &&
					<AddParticipantMenu addMeetingExtraParticipants={addMeetingExtraParticipants}/>
        }
      </Box>
      { isEditing && props.isAuditor &&
				<Box style={{ marginTop: '24px' }} >
				  <CustomButton
				    onClick={handleMeetingUpdate} variant='contained'
				    fullWidth className={classes['validate-button']}
				  >
				    {t('btns.save')}
				  </CustomButton>
				</Box>
      }
    </Paper>
  );
}

export default SetUpMeeting;
