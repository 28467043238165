import React, { ReactElement } from 'react';
import FundAndMandateList from './fundandmandatelist';
import { strategyContext } from '../../../../hooks/datatabs';
import { StrategyService } from '../../../../services/strategy';

function StrategyFundAndMandateList(): ReactElement {
  const strategyData = strategyContext();
  const dataProvider = new StrategyService();

  return (
    <>
      {strategyData && strategyData._id &&
        <FundAndMandateList dataProvider={dataProvider} entityId={strategyData._id} entityTypeText='strategy' />
      }
    </>
  );
}

export default StrategyFundAndMandateList;