import React, { ReactElement } from 'react';
import { Chip, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

interface ChipVersionProps {
	version: number,
	active?: boolean,
	inUse?: boolean,
	id: string
}

export default function ChipVersion( props: ChipVersionProps ): ReactElement {
  const isSelected = () => window.location.href.includes(props.id);

  return (
    <Tooltip
      title={` ${props.active ? 'Active' : 'NOT active'} & ${props.inUse ? 'in use' : 'NOT in use'}`}
      arrow
    >
      <Chip
        style={{ padding: '4px', marginRight: '8px' }}
        icon={props.inUse ? <FileCopyIcon /> : undefined }
        label={`V${props.version} ${props.active ? 'Active' : '' }`}
        color={isSelected() ? 'primary' : 'default'}
        size='small'
      />
    </Tooltip>
  );
}

// check id and color in primary if chip id === id in url