import React, { ReactElement, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDrag } from 'react-dnd';
import { DdElement, SurveyElement } from '../../../types/types';
import { UpdateMeetingProps } from '../../../types/meetingstypes';

const useStyles = makeStyles(() => ({
  item: {
    transition: 'all 0.2s ease-in-out',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      boxShadow: '0 4px 16px -2px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.2s ease-in-out'
    }
  }
}));

function indentation(path: string) {
  const level = path.split('/').length;

  return (8 * level) - 8;
}

interface QuestionItemProps {
	item: DdElement | SurveyElement,
	children: ReactElement,
	isCategory: boolean,
	questionId: string,
	meetingId: string
}

function QuestionItem(props: QuestionItemProps): ReactElement {
  const classes = useStyles();
  const [mouseOver, setMouseOver] = useState(false);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'question',
      item: (): UpdateMeetingProps => ({
        itemId: props.questionId,
        oldMeetingId: props.meetingId
      }),
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    }),
    []);

  return (
    <Paper
      ref={drag as React.Ref<HTMLDivElement>}
      elevation={0}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: '12px 12px',
        marginLeft: `${indentation(props.item.category)  }px`,
        marginBottom: '8px',
        verticalAlign: 'center',
        backgroundColor: 'RGB(231, 233, 238)',
        borderRadius: '16px',
        cursor: isDragging ? 'grabbing' : 'grab'
      }}
      className={classes.item}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' style={{ marginTop: '2px' }}>{props.item.question.spec.label}</Typography>
        </Grid>
        <Grid item>
          <Box
            style={{
              opacity: mouseOver ? 1 : 0,
              transition: 'all 0.4s ease-in-out'
            }}
          >
            {props.children}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default QuestionItem;
