import React  from 'react';
import SurveysQuestions from '../containers/auditee/surveys/pages/questions';

// import investmgrGeneral from '../containers/investmentmanagers/pages/general';
// import investmgrMorningstar from '../containers/investmentmanagers/pages/morningstar';
import InvestMgrDdList from '../containers/investmentmanagers/pages/investmgrddlist';
import InvestMgrDeveloper from '../containers/investmentmanagers/pages/developer';
import InvestMgrFundAndMandateList from '../containers/investmentmanagers/pages/investmgrfundandmandatelist';
import InvestMgrContacts from '../containers/investmentmanagers/pages/contacts/contacts';
import InvestMgrParameters from '../containers/investmentmanagers/pages/parameters/parameters';

import AssetMgrDdList from '../containers/assetmanagers/pages/assetmgrddlist';
import AssetmgrDeveloper from '../containers/assetmanagers/pages/developer';
import AssetMgrFundAndMandateList from '../containers/assetmanagers/pages/assetmgrfundandmandatelist';
import AssetMgrContacts from '../containers/assetmanagers/pages/contacts/contacts';
import AssetMgrParameters from '../containers/assetmanagers/pages/parameters/parameters';

// import fundGeneral from '../containers/fundsandmandates/pages/general';
// import fundMorningStar from '../containers/fundsandmandates/pages/morningstar';
import FundDdList from '../containers/funds/pages/fundddlist';
import FundParameters from '../containers/funds/pages/parameters/parameters';
import FundDeveloper from '../containers/funds/pages/developer';

import MandateDdList from '../containers/mandates/pages/mandateddlist';
import MandateParameters from '../containers/mandates/pages/parameters/parameters';
import MandateDeveloperPage from '../containers/mandates/pages/developer';

import StrategyFundAndMandateList from '../containers/strategies/pages/fundsandmandates/strategyfundandmandatelist';
import StrategyDdList from '../containers/strategies/pages/strategyddlist';
import StrategyContacts from '../containers/strategies/pages/contacts/contacts';
import StrategyParameters from '../containers/strategies/pages/parameters/parameters';
import StrategyDeveloperPage from '../containers/strategies/pages/developer/index';

import DdElementGroupElements from '../components/admin/ddelementgroup/ddelementgroupelements';
import DdElementGroupJson from '../components/admin/ddelementgroup/ddelementgroupjson';
import DdTemplateJson from '../components/admin/ddtemplates/ddtemplatejson';
import DdTemplateCategories from '../components/admin/ddtemplates/ddtemplatecategories';
import DdReportBlockJson from '../components/admin/ddtreportblocks/ddreportblockjson';

import SurveysMeetings from '../containers/auditee/surveys/pages/meetings/surveysmeetings';
import SurveysSummary from '../containers/auditee/surveys/pages/summary/summary';
import FundsContacts from '../containers/funds/pages/contacts/contacts';
/* import {
  FundReportsList,
  InvestMgrReportsList,
  MandatesReportsList, StrategiesReportsList
} from '../components/reports/reportsdynamiclist'; */

// import Messages from '../containers/duediligences/pages/messages';

// Note : Tabs for Due Diligences are now directly in the DueDiligenceRouter
// =========================================================================

export const surveysTabs = [
  { path: 'summary', label: 'Summary', component: <SurveysSummary /> },
  { path: 'questions', label: 'Questions', component: <SurveysQuestions /> },
  { path: 'meetings/list', label: 'Meetings', component: <SurveysMeetings /> }
  // { path: 'participants', label: 'Participants', component: <undefined}
];

export const fundsTabs = [
  // { path: 'reports', label: 'Reports', component: <FundReportsList /> },
  { path: 'dds', label: 'Due Diligences', component: <FundDdList /> },
  { path: 'contacts', label: 'Contacts', component: <FundsContacts /> },
  { path: 'parameters', label: 'Parameters', component: <FundParameters /> },
  // {path: 'general', label: 'General', component: <fundGeneral},
  // {path: 'morningstar', label: 'Morning Star', component: <fundMorningStar},
  { path: 'developer', label: 'Developer', component: <FundDeveloper /> }
];

export const assetmgrsTabs = [
  // { path: 'reports', label: 'Reports', component: <InvestMgrReportsList /> },
  { path: 'fundsandmandates', label: 'Funds & Mandates', component: <AssetMgrFundAndMandateList /> },
  { path: 'dds', label: 'Due Diligences', component: <AssetMgrDdList /> },
  { path: 'contacts', label: 'Contacts', component: <AssetMgrContacts /> },
  { path: 'parameters', label: 'Parameters', component: <AssetMgrParameters /> },
  //  {path: 'general', label: 'General', component: <investmgrGeneral},
  //  {path: 'morningstar', label: 'Morning Star', component: <investmgrMorningstar},
  { path: 'developer', label: 'Developer', component: <AssetmgrDeveloper /> }
];

export const investmgrsTabs = [
  // { path: 'reports', label: 'Reports', component: <InvestMgrReportsList /> },
  { path: 'fundsandmandates', label: 'Funds & Mandates', component: <InvestMgrFundAndMandateList /> },
  { path: 'dds', label: 'Due Diligences', component: <InvestMgrDdList /> },
  { path: 'contacts', label: 'Contacts', component: <InvestMgrContacts /> },
  { path: 'parameters', label: 'Parameters', component: <InvestMgrParameters /> },
  //  {path: 'general', label: 'General', component: <investmgrGeneral},
  //  {path: 'morningstar', label: 'Morning Star', component: <investmgrMorningstar},
  { path: 'developer', label: 'Developer', component: <InvestMgrDeveloper /> }
];

export const mandatesTabs = [
  // { path: 'reports', label: 'Reports', component: <MandatesReportsList /> },
  { path: 'dds', label: 'Due Diligences', component: <MandateDdList /> },
  { path: 'parameters', label: 'Parameters', component: <MandateParameters /> },
  { path: 'developer', label: 'Developers', component: <MandateDeveloperPage /> }
];

export const strategiesTabs = [
  // { path: 'reports', label: 'Reports', component: <StrategiesReportsList /> },
  { path: 'dds', label: 'Due Diligences', component: <StrategyDdList /> },
  { path: 'fundsandmandates', label: 'Funds & Mandates', component: <StrategyFundAndMandateList /> },
  { path: 'contacts', label: 'Contacts', component: <StrategyContacts /> },
  { path: 'parameters', label: 'Parameters', component: <StrategyParameters /> },
  { path: 'developer', label: 'Developers', component: <StrategyDeveloperPage /> }
];

export const ddElementGroupTabs = [
  { path: 'json', label: 'JSON', component: <DdElementGroupJson /> },
  { path: 'elements', label: 'Elements', component: <DdElementGroupElements /> }
];

export const ddTemplateTabs = [
  { path: 'json', label: 'JSON', component: <DdTemplateJson /> },
  { path: 'categories', label: 'Categories', component: <DdTemplateCategories /> }
];

export const ddReportBlockTabs = [
  { path: 'json', label: 'JSON', component: <DdReportBlockJson /> }
];

export const meetingsTabs = [
  { path: 'list', label: 'Meetings' },
  { path: 'allocation', label: 'Allocation' }
];
