import { API } from '../api/api';
import { ApiResponse, Contact, Nullable, Participant, UsernameComputed } from '../types/types';
import { BaseService } from './data';

export abstract class AbstractContactsListService<T>  extends BaseService {
  basePath: string;

  _id: string;

  protected constructor() {
    super();
    this.basePath = '';
    this._id = '';
  }

  abstract getBaseUrl(): string;

  getAll(): Promise<ApiResponse<Nullable<Array<T>>>> {
    return API.get(this.getBaseUrl())
      .then(res => this.handleResponse<Array<T>>(res));
  }
}

export abstract class AbstractContactsService<T> extends AbstractContactsListService<T> {
  protected constructor() {
    super();
  }

  get(id: string): Promise<ApiResponse<Nullable<T>>> {
    return API.get(`${this.getBaseUrl()}/${id}`)
      .then(res => this.handleResponse<T>(res));
  }

  search(query: string): Promise<ApiResponse<Nullable<Array<T>>>> {
    return API.get(`${this.getBaseUrl()}/search/?q=${query}`)
      .then(res => this.handleResponse<Array<T>>(res));
  }

  add(contact: Contact): Promise<ApiResponse<Nullable<{id: string}>>> {
    return API.post(this.getBaseUrl(), contact)
      .then(res => this.handleResponse<{id: string}>(res));
  }

  edit(id: string, contact: Contact): Promise<ApiResponse<Nullable<T>>> {
    return API.put(`${this.getBaseUrl()}/${id}`, contact)
      .then(res => this.handleResponse<T>(res));
  }

  delete(id: string): Promise<ApiResponse<Nullable<T>>> {
    return API.delete(`${this.getBaseUrl()}/${id}`)
      .then(res => this.handleResponse<T>(res));
  }
}

export abstract class AbstractContactsAssignmentService<T> extends AbstractContactsListService<T> {
  protected constructor() {
    super();
  }

  get(id: string): Promise<ApiResponse<Nullable<T>>> {
    return API.get(`${this.getBaseUrl()}/${id}`)
      .then(res => this.handleResponse<T>(res));
  }

  assign(ids: Array<string>): Promise<ApiResponse<Nullable<Array<T>>>> {
    return API.get(`${this.getBaseUrl()}/add?contactId=${ids.toString()}`)
      .then(res => this.handleResponse<Array<T>>(res));
  }

  remove(id: string): Promise<ApiResponse<Nullable<T>>> {
    return API.get(`${this.getBaseUrl()}/remove?contactId=${id}`)
      .then(res => this.handleResponse<T>(res));
  }
}

export abstract class AbstractParticipantsAssignmentsService<T> extends AbstractContactsListService<Participant> {
  protected constructor() {
    super();
  }

  get(id: string): Promise<ApiResponse<Nullable<T>>> {
    return API.get(`${this.getBaseUrl()}/${id}`)
      .then(res => this.handleResponse<T>(res));
  }

  assign(id: string, external: boolean, roles: Array<string>): Promise<ApiResponse<Nullable<T>>> {
    return API.get(`${this.getBaseUrl()}/add`, { params: {
      contactId: id,
      external,
      roles: roles.toString()
    } })
      .then(res => this.handleResponse<T>(res));
  }

  remove(id: string): Promise<ApiResponse<Nullable<T>>> {
    return API.get(`${this.getBaseUrl()}/remove?contactId=${id}`)
      .then(res => this.handleResponse<T>(res));
  }

  editRoles(id: string, roles: Array<string>): Promise<ApiResponse<Nullable<T>>> {
    return API.get(`${this.getBaseUrl()}/setRoles?participantId=${id}&roles=${roles.toString()}`)
      .then(res => this.handleResponse<T>(res));
  }
}

export class StrategyContactsService extends AbstractContactsAssignmentService<Contact> {
  constructor(id: string) {
    super();
    this._id = id;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/strategies/${this._id}/contacts`;

    return this.basePath;
  }
}

export class AssetMgrsContactsService extends AbstractContactsAssignmentService<Contact> {
  constructor(id: string) {
    super();
    this._id = id;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/assetmgrs/${this._id}/contacts`;

    return this.basePath;
  }
}

export class InvestMgrsContactsService extends AbstractContactsAssignmentService<Contact> {
  constructor(id: string) {
    super();
    this._id = id;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/investmgrs/${this._id}/contacts`;

    return this.basePath;
  }
}

export class FundsContactsService extends AbstractContactsAssignmentService<Contact> {
  constructor(id: string) {
    super();
    this._id = id;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/funds/${this._id}/contacts`;

    return this.basePath;
  }
}

export class InternalContactsService extends AbstractContactsListService<Contact> {
  constructor() {
    super();
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/global/contacts/allInternal`;

    return this.basePath;
  }
}

export class GlobalContactsService extends AbstractContactsService<Contact> {
  constructor() {
    super();
  }

  getAllInternal(): Promise<ApiResponse<Nullable<Array<Contact>>>> {
    return API.get(`${this.getBaseUrl()}/allInternal`)
      .then(res => this.handleResponse<Array<Contact>>(res));
  }

  findByEmail(email: string): Promise<ApiResponse<Nullable<Array<Contact>>>> {
    return API.get(`${this.getBaseUrl()}/findByEmail?email=${email}`)
      .then(res => this.handleResponse<Array<Contact>>(res));
  }

  computeUsername(firstName: string, lastName: string): Promise<ApiResponse<Nullable<UsernameComputed>>> {
    return API.get(`${this.getBaseUrl()}/computeUsername?firstName=${firstName}&lastName=${lastName}`)
      .then(res => this.handleResponse<UsernameComputed>(res));
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/global/contacts`;

    return this.basePath;
  }
}

export class DdParticipantsService extends AbstractParticipantsAssignmentsService<Participant> {
  constructor(id: string) {
    super();
    this._id = id;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/dds/${this._id}/participants`;

    return this.basePath;
  }
}
