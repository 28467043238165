import React, { ReactElement, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { useTranslation } from 'react-i18next';
import { InvestMgr } from '../../../types/types';
import CustomTable, { Column } from '../../../components/table/table';
import EntityLink from '../../../components/modularcomponents/entitylink/entitylink';
import { Rating2, Rating1 } from '../../duediligences/listing/datadisplay';
import { FilterParamsManager } from '../../../components/entity/list';
import SecureDisplay from '../../../components/authentification/securedisplay';
import { CustomIconButton } from '../../../components/button/button';
import { StyledMenu, useMenuStyle } from '../../../components/layout/navbar/navbar';
import { ReportHelper } from '../../../components/reports/pdfreports';
import checkRoles from '../../../components/authentification/checkRoles';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';
import TagIcon from '../../../components/aum/tagicon';

// const useStyles = makeStyles({
//   link: {
//     textDecoration: 'none',
//     color: 'inherit',
//     '&:hover': {
//       textDecoration: 'underline',
//     }
//   }
// });

const useStyles = makeStyles({
  rating: {
    display: 'inline-block'
  }
});

export function InvestMgrTable(props: {list: Array<InvestMgr>, paramsSort?: FilterParamsManager}) : ReactElement {
  const theme = useTheme();
  const classes = useStyles();
  const menuClasses = useMenuStyle(theme);
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [dlOpen, setDlOpen] = useState<null | string>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setDlOpen(id);
  };

  const handleClose = () => {
    setDlOpen(null);
    setAnchorEl(null);
  };

  const columns: Array<Column> = [
    { id: 'investMgrName', label: t('investMgrs.columns.investMgrName'), minWidth: 'min-content' },
    // { id:'country', label: 'Country', minWidth: 'min-content', align: 'center' },
    { id: 'rating1', label: 'AAIS Global Rating', minWidth: 'min-content', align: 'center' },
    { id: 'rating2', label: 'AAIS ESG Rating', minWidth: 'min-content', align: 'center' },
    // { id: 'gpsl', label: 'GPSL', minWidth: 'min-content', align: 'center' },
    { id: 'validationDate', label: 'Validation Date', minWidth: 'min-content', align: 'center' },
    { id: 'nextReviewDate', label: 'Next Review Date', minWidth: 'min-content', align: 'center' },
    { id: 'latestDdType', label: 'Latest Dd type', minWidth: 'min-content', align: 'center' }
    // { id: 'totalFund', label: 'Total Funds', minWidth: 'min-content', align: 'center' }
  ];

  const columnsOnlyFundCenter: Array<Column> = [
    { id: 'investMgrName', label: t('investMgrs.columns.investMgrName'), minWidth: 'min-content' },
    // { id:'country', label: 'Country', minWidth: 'min-content', align: 'center' },
    { id: 'rating1', label: 'AAIS Global Rating', minWidth: 'min-content', align: 'center' },
    { id: 'rating2', label: 'AAIS ESG Rating', minWidth: 'min-content', align: 'center' },
    // { id: 'gpsl', label: 'GPSL', minWidth: 'min-content', align: 'center' },
    { id: 'validationDate', label: 'Validation Date', minWidth: 'min-content', align: 'center' },
    { id: 'nextReviewDate', label: 'Next Review Date', minWidth: 'min-content', align: 'center' },
    { id: 'latestDdType', label: 'Latest Dd type', minWidth: 'min-content', align: 'center' },
    { id: 'reports', label: 'Reports', minWidth: 'min-content', align: 'right' }
  ];

  const rows = props.list.map((investMgr: InvestMgr) => ({
    id: investMgr._id,
    value: (checkRoles(['dd-viewer'], keycloak) ? columns :
      checkRoles(['fund-center-user', 'report-viewer-all'], keycloak) ? columnsOnlyFundCenter :
        columns).map(column => (
      <>
        {column.id === 'investMgrName' &&
              <Typography>
                <SecureDisplay
                  permissions='details'
                  optionalChild={<>{investMgr.name}</>}
                >
                  <EntityLink type='investMgr' id={investMgr._id} name={investMgr.name} center={column.align === 'center'} openInNewTabIcon />
                </SecureDisplay>
              </Typography>
        }
        {/* {column.id === 'country' && investmgr.country} */}
        {column.id === 'rating1' &&
              <div className={classes.rating} >
                {/* <AAISGlobalRating ratings={{aais: {final: 2}}} /> // for display test only */}
                <Rating1 ratings={investMgr.ratings} />
              </div>
        }
        {column.id === 'rating2' &&
              <div className={classes.rating} >
                {/* <AAISESGRating ratings={{aaisEsg: {final: "A"}}} /> // // for display test only */}
                <Rating2 ratings={investMgr.ratings} />
              </div>
        }
        {column.id === 'totalFund' && investMgr.funds.length}
        {column.id === 'gpsl' &&
          <TagIcon val={!!investMgr.tags?.find(tag => tag.value === 'gpsl')} />
        }
        {column.id === 'latestDdType' && investMgr.latestDdInfo &&
          <Typography>
            <SecureDisplay
              permissions='details'
              optionalChild={<>{investMgr.latestDdInfo.type}</>}
            >
              <EntityLink type='dueDiligence' id={investMgr.latestDdInfo._id} name={investMgr.latestDdInfo.type} center={column.align === 'center'} openInNewTabIcon />
            </SecureDisplay>
          </Typography>
        }
        {column.id === 'validationDate' && investMgr.latestDdInfo &&
          <Typography>
            {investMgr.latestDdInfo.validationDate}
          </Typography>
        }
        {column.id === 'nextReviewDate' &&
          <Typography>
            {investMgr.nextReviewDate}
          </Typography>
        }
        {column.id === 'reports' && investMgr.reportDocuments &&
          <>
            <CustomIconButton variant='contained' onClick={e => handleClick(e, investMgr._id)} small>
              <GetAppOutlinedIcon />
            </CustomIconButton>
            <StyledMenu
              disableScrollLock
              anchorEl={anchorEl}
              id='reportMenu'
              keepMounted
              open={investMgr._id === dlOpen}
              onClose={handleClose}
            >
              <Grid container spacing={1}>
                {investMgr.reportDocuments.map(report => (
                  report.tags.find(tag => tag.value === 'summary' || tag.value === 'mdd-review' || tag.value === 'mdd-analysis') &&
                  <Grid item xs={12} key={report._id}>
                    <MenuItem
                      className={menuClasses.menuItem}
                      onClick={() => {
                        ReportHelper.downloadPDFReportByDocId(report._id);
                        handleClose;
                      }}
                    >
                      {capitalizeFirstLetter(report.tags.find(tag => tag.family === 'ddReportType')?.value)}
                    </MenuItem>
                  </Grid>
                ))}
              </Grid>
            </StyledMenu>
          </>
        }
      </>
    ))
  }));

  return (
    <SecureDisplay
      permissions='details'
      optionalChild={
        <SecureDisplay
          permissions='reports'
          optionalChild={
            <CustomTable columns={columns} rows={rows} size='medium' paramsSort={props.paramsSort} />
          }
        >
          <CustomTable columns={columnsOnlyFundCenter} rows={rows} size='medium' paramsSort={props.paramsSort} />
        </SecureDisplay>
      }
    >
      <CustomTable columns={columns} rows={rows} size='medium' paramsSort={props.paramsSort} />
    </SecureDisplay>
  );
}

export default InvestMgrTable;
