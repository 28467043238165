import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { EvalComponentsProps } from './evaluations';
import Rating from '../../ratings/rating';

function Scores(props: EvalComponentsProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item style={{ marginLeft: '8px', marginTop: '8px' }}>
        <Typography variant='h4'>{t('ddElements.rating.scores')} :</Typography>
      </Grid>
      {props.ddEval.scores.map(score => (
        <Grid key={score.category} item style={{ marginTop: '-2px' }}>
          <Rating rank={score.score.toString()} tooltip={score.category} isComputed withIcon />
        </Grid>
      ))}
    </Grid>
  );
}

export default Scores;
