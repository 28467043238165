import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Snackbar, Typography, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import { dataSettings } from '../../../hooks/datatabs';
import UploadFiles from '../../upload/uploadfiles';
import { PlatformLogoUploadService } from '../../../services/platform';
import { API_BASE_URL } from '../../../utils/env';
import { useSettingsStyles } from './settingspage';

interface ApplicationImageFieldProps {
  title: string,
  dimensions?: string[],
  margin?: string,
  logoType: string,
  authorizedTypes: string[],
  globalRefresh?: boolean,
  maxSize?: string,
  recommendedAspectRatio?: string
}

function ApplicationImageField(props: ApplicationImageFieldProps): ReactElement {
  const theme = useTheme();
  const classes = useSettingsStyles(theme);
  const settings = dataSettings();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [logo, setLogo] = useState(settings?.settings?.logo.find(myLogo => myLogo.type === props.logoType));

  useEffect(() => {
    setLogo(settings?.settings?.logo.find(myLogo => myLogo.type === props.logoType));
  }, [settings]);

  return (
    <Grid
      container className={classes.paper}
      style={{ margin: props.margin ? props.margin : 0 }}
    >
      <Grid item xs={6} style={{ position: 'relative' }}>
        <Typography variant='h2' component='h2' color='primary' gutterBottom>
          {props.title}
        </Typography>
        {props.maxSize &&
          <Typography style={{ fontSize: '12px' }}>
            Maximum width and height :  <b>{props.maxSize}px  </b>
          </Typography>
        }
        {props.recommendedAspectRatio &&
          <Typography style={{ fontSize: '12px' }}>
            An aspect ratio of <b>{props.recommendedAspectRatio}</b> is recommended
          </Typography>
        }
        <Box style={{ width: 'fit-content', position: 'absolute', bottom: 0 }}>
          <UploadFiles
            service={new PlatformLogoUploadService(props.logoType)}
            update={() => {
              if (props.globalRefresh && settings) {
                settings.setUpdateSettings(true);
              }
            }}
            authorizedTypes={props.authorizedTypes}
            uploadButtonLabel='Upload Image'
            small
            hideAfterFinish
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        className={classes.imageDisplay}
        style={{
          position: 'relative',
          backgroundColor: 'white',
          border: logo ? '1px solid RGB(76, 175, 80)' : '1px solid grey',
          minHeight: '200px'
        }}
      >
        { logo ?
          <img
            alt=''
            src={`${API_BASE_URL}/api/v1/admin/platform${logo.filePath}`}
            height='28'
            style={{ display: 'block' }}
          /> :
          <Typography>No {props.title} uploaded</Typography>
        }
      </Grid>
      <Snackbar
        onClose={() => setSnackbarOpen(false)}
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert style={{ marginTop: '20px' }} severity='info' action={
          <>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={() => window.location.reload()}
            >
              <RefreshIcon fontSize='small' />
            </IconButton>
          </>
        }>
          To see your logo, it is necessary to refresh the page.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

function Branding(): ReactElement {
  const theme = useTheme();
  const classes = useSettingsStyles(theme);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h2' component='h2'>
          Branding
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Accordion
          className={classes.hideBorder}
          elevation={0}
          style={{ margin: 0, borderBottom: '1px solid lightGrey' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='branding1'
            id='branding1-header'
          >
            <Typography variant='h2' component='h2'>
              Application Visuals
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ margin: 0 }}>
            <Grid container>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                  <InfoIcon color='primary' />
                </Box>
                <Box>
                  <Typography style={{ fontSize: '12px' }}>
                    <strong>Accepted file : </strong>.png, .svg<br />
                    <strong>Recommended : </strong>Logo background should be transparent.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} style={{ marginTop: '16px' }}>
                <ApplicationImageField
                  title='Logo'
                  dimensions={['300px x 75px']}
                  margin='0 12px 0 0'
                  logoType='appMain'
                  authorizedTypes={['.png', '.svg']}
                  globalRefresh
                  maxSize='1024'
                  recommendedAspectRatio='5'
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: '16px' }}>
                <ApplicationImageField
                  title='Icon'
                  dimensions={['16px x 16px', '32px x 32px', '48px x 48px']}
                  margin='0 0 0 12px'
                  logoType='appIcon'
                  authorizedTypes={['.png', '.svg']}
                  globalRefresh
                  maxSize='1024'
                  recommendedAspectRatio='1'
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.hideBorder} elevation={0}
          style={{ margin: 0 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='branding2'
            id='branding2-header'
          >
            <Typography variant='h2' component='h2'>
              Reports Visuals
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                  <InfoIcon color='primary' />
                </Box>
                <Box>
                  <Typography style={{ fontSize: '12px' }} gutterBottom>
                    <strong>Accepted files : </strong>.png, .svg<br />
                    <strong>Recommended : </strong>Logo background should be transparent.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} style={{ marginTop: '16px' }}>
                <ApplicationImageField
                  title='Header Logo'
                  margin='0 12px 0 0'
                  dimensions={['300px x 150px']}
                  logoType='reportMain'
                  authorizedTypes={['.png', '.svg']}
                  globalRefresh
                  maxSize='2048'
                  recommendedAspectRatio='5'
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: '16px' }}>
                <ApplicationImageField
                  title='Footer Logo'
                  margin='0 0 0 12px'
                  dimensions={['200px x 50px']}
                  logoType='reportFooter'
                  authorizedTypes={['.png', '.svg']}
                  globalRefresh
                  maxSize='2048'
                  recommendedAspectRatio='5'
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
}

export default Branding;
