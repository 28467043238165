import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';

export default function Currencyfieldviewer(props: {value?: string}): ReactElement {
  const currency = props.value;

  return (
    <Typography variant='body1' component='p'>
      {currency}
    </Typography>
  );
}
