import React, { ReactElement, useEffect, useState } from 'react';
import { MandateTable } from '../../../mandates/listing/table';
import { FundTable } from '../../../funds/listing/table';
import { ApiResponse, Fund, Mandate, Nullable } from '../../../../types/types';
import { FundAndMandateProvider } from '../../../../services/data';
import ServiceError from '../../../../services/errors';
import ErrorPageManager from '../../../../components/Error/errorpagemanager';

function FundAndMandateList(props: {dataProvider: FundAndMandateProvider, entityId: string, entityTypeText: string}): ReactElement {
  const [fundListResp, setFundListResp] = React.useState<Nullable<ApiResponse<Nullable<Array<Fund>>>>>(null);
  const [mandatesListResp, setMandatesListResp] = React.useState<Nullable<ApiResponse<Nullable<Array<Mandate>>>>>(null);
  const [fundsError, setFundsError] = useState<Nullable<ServiceError>>(null);
  const [mandatesError, setMandatesError] = useState<Nullable<ServiceError>>(null);

  useEffect(() => {
    props.dataProvider.getFunds(props.entityId)
      .then((apiResp) => {
        setFundListResp(apiResp);
      })
      .catch((err) => {
        setFundsError(ServiceError.ensureServiceError(err));
      });
    props.dataProvider.getMandates(props.entityId)
      .then((apiResp) => {
        setMandatesListResp(apiResp);
      })
      .catch((err) => {
        setMandatesError(ServiceError.ensureServiceError(err));
      });
  }, []);

  return (
    <>
      <h3>Mandates on this {props.entityTypeText}</h3>
      {mandatesError ? <ErrorPageManager error={mandatesError} /> :
        <div style={{ marginBottom: '60px' }}>
          { (mandatesListResp != null) && (mandatesListResp.data != null) &&
            <>
              { (mandatesListResp.data.length === 0) &&
                <div>No Mandates found...</div>
              }
              { (mandatesListResp.data.length > 0) &&
                <MandateTable list={mandatesListResp.data} />
              }
            </>
          }
        </div>
      }
      <h3>Funds on this {props.entityTypeText}</h3>
      {fundsError ? <ErrorPageManager error={fundsError} /> :
        <>
          { (fundListResp != null) && (fundListResp.data != null) &&
            <>
              { (fundListResp.data.length === 0) &&
                <div>No Funds found...</div>
              }
              { (fundListResp.data.length > 0) &&
                <FundTable list={fundListResp.data} />
              }
            </>
          }
        </>
      }
    </>
  );
}

export default FundAndMandateList;
