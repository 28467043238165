import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import {
  Snackbar,
  Typography
  , Alert } from '@mui/material';
import { AbstractContactsAssignmentService, GlobalContactsService } from '../../services/contacts';
import CustomButton from '../button/button';
import ServiceError from '../../services/errors';
import { Contact, Nullable } from '../../types/types';
import { ErrorStringManager } from '../Error/errorpagemanager';
import SelectList from './selectlist';
import SearchContact from './searchcontact';
import { useContactSidePanelLayoutStyle } from './contactsTable';
import CreateContact from './createcontact';

interface AddContactProps {
  contacts: Array<Contact>,
  contactsService: AbstractContactsAssignmentService<Contact>,
  setUpdate: Dispatch<SetStateAction<boolean>>,
  onClose: () => void
}

function AddContact(props: AddContactProps ): ReactElement {
  const contactClasses = useContactSidePanelLayoutStyle();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [contactList, setContactList] = useState<Array<Contact>>([]);
  const [contactSelected, setContactSelected] = useState<Array<Contact>>([]);
  const [createMode, setCreateMode] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAdd = () => {
    props.contactsService.assign(contactSelected.map(contact => contact.id))
      .then(() => {
        props.onClose();
        props.setUpdate(true);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <Typography variant='subtitle1' className={contactClasses.title}>Add Contact</Typography>
      { !createMode ?
        <>
          <SearchContact contactList={props.contacts} setContactList={setContactList} error={error} setError={setError} contactsService={new GlobalContactsService} />
          <div style={{ marginBottom: '24px' }}>
            {error ? <ErrorStringManager error={error} /> :
              <>
                {contactList.length > 0 ?
                  <SelectList contactList={contactList} contactSelected={contactSelected} setContactSelected={setContactSelected} /> :
                  <Typography variant='h3' style={{ width: '288px', marginTop: '19px', marginBottom: '19px' }}>Please search a contact above</Typography>
                }
              </>
            }
          </div>
          <div className={contactClasses.buttons} style={{ marginBottom: '12px' }}>
            <div className={contactClasses.btn}>
              <CustomButton
                disabled={contactSelected.length < 1}
                variant='contained'
                style={{ width: '100%' }}
                onClick={handleAdd}
              >
                Add
              </CustomButton>
            </div>
          </div>
          <div className={contactClasses.buttons}>
            <div className={contactClasses.btn}>
              <CustomButton
                disabled={contactSelected.length > 0}
                variant='contained'
                style={{ width: '100%' }}
                onClick={() => setCreateMode(true)}
              >
                Create New
              </CustomButton>
            </div>
          </div>
        </> :
        <CreateContact handleAdd={handleAdd} contactList={contactSelected} setContactList={setContactSelected} contactsService={new GlobalContactsService} />
      }
      <Snackbar onClose={handleCloseSnackbar} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddContact;