import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputPhaseStats from '../../../../components/summary/InputPhaseStats';
import AnalysisPhaseStats from '../../../../components/summary/AnalysisPhaseStats';
import { DdService } from '../../../../services/dds';
import { Nullable, SummaryInfo } from '../../../../types/types';
import { dataContext } from '../../../../hooks/datatabs';
import ServiceError from '../../../../services/errors';
import ManagerValidationPhase from '../../../../components/summary/ManagerValidationPhase';
import { rotateLeft } from '../../../../utils/arraycomputations';
import ReactKeyUtils from '../../../../utils/reactkeyutils';
import { mainRadius } from '../../../../themes/theme';

function Summary(): ReactElement {
  const ddMenu = dataContext();
  const { t } = useTranslation();
  const dd = ddMenu?.dueDiligence;
  const ddId = dd?._id;
  const currentPhase = dd?.lifecycle?.currentPhaseId?.split('-')[0] || 'validation';
  const ddService = new DdService();
  const [loading, setLoading] = useState(true);
  const [summaryInfo, setSummaryInfo] = useState<Nullable<SummaryInfo>>(null);
  let phases: (string | null | undefined)[] = [t('dds.phases.validation'), t('dds.phases.report'), t('dds.phases.analysis'), t('dds.phases.input')];

  if (phases.some(phase => typeof phase === 'string' && (currentPhase.toLowerCase().includes(phase as string) || currentPhase === phase))) {
    Array(phases.indexOf(currentPhase.toLowerCase().includes('validation') ? 'validation' : currentPhase)).forEach(() => {
      phases = rotateLeft(phases);
    });
  }

  if (phases[0] === 'validation') {
    phases.shift();
  } else if (phases[0] === 'report') {
    phases.pop();
  }

  useEffect(() => {
    if (ddId) {
      ddService.getSummaryInfo(ddId)
        .then((apiResp) => {
          setSummaryInfo(apiResp.data);
        })
        .catch((err) => {
          console.log(ServiceError.ensureServiceError(err));
        });
    }
  }, [ddMenu]);

  useEffect(() => {
    setLoading(false);
  }, [summaryInfo]);

  return <>
    {!loading ?
      summaryInfo && phases.map((phase) => {
        if (phase === 'input' && summaryInfo.input) {
          return <InputPhaseStats key={ReactKeyUtils.generateRandomKey()} phaseInfo={summaryInfo.input} />;
        }
        if (phase === 'analysis' && summaryInfo.input) {
          return <AnalysisPhaseStats key={ReactKeyUtils.generateRandomKey()} analysisInfo={summaryInfo.analysis} />;
        }
        if ((phase === 'report' || phase === 'validation') && summaryInfo.input) {
          return <ManagerValidationPhase key={ReactKeyUtils.generateRandomKey()} managerInfo={summaryInfo.managerValidation} />;
        }

        return undefined;
      }) :
      <Grid container spacing={2}>
        {[...Array(3)].map(() => (
          <Grid key={ReactKeyUtils.generateRandomKey()} item xs={12}>
            <Grid container style={{ opacity: 0.7, backgroundColor: 'rgba(200, 200, 200, 0.2)', padding: '16px', borderRadius: '24px', marginBottom: '16px' }}>
              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Typography variant='h1' component='h2'><Skeleton animation='wave' variant='rectangular' width='200px' /></Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {[...Array(3)].map(() => (
                    <Grid key={ReactKeyUtils.generateRandomKey()} item>
                      <Skeleton animation='wave' variant='rectangular' width='224px' height='72px' style={{ borderRadius: mainRadius }} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    }
  </>;
}

export default Summary;
