import React, { ReactElement } from 'react';
import { Grid, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ddElementGroupContext } from '../../../hooks/datatabs';
import { DdElementListItemHeader, useStyles as questionStyles } from '../../ddelement/listitem';
import { ElementTemplate } from '../../../types/importtypes';
import DispatchQuestionEditor from '../../question/editors/dispatchquestioneditor';
import { ButtonsSettings } from '../../../types/types';

function DdElementGroupElement(props: {element: ElementTemplate}): ReactElement {
  const theme = useTheme();
  const classes = questionStyles(theme);

  const buttonsSettings: ButtonsSettings = {
    onSaveAsDraft: () => undefined,
    onSubmit: () => undefined,
    id: '',
    role: 'admin',
    disabled: true,
    notSubmittable: true,
    hideBtns: true
  };

  return (
    <Paper className={classes.paper} style={{ backgroundColor: 'rgba(157, 157, 157, 0.175)' }} elevation={0} variant='outlined'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DdElementListItemHeader _id={props.element.question.dataPath} type={props.element.kind} title={props.element.title} breadcrumbs={props.element.question.dataPath} draft={false} />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0} variant='outlined' style={{ position: 'relative' }}>
            <Grid container spacing={2}>
              <Grid item xs>
                <DispatchQuestionEditor spec={props.element.question.spec} answer={null} buttonsSettings={buttonsSettings} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

function DdElementGroupElements(): ReactElement {
  const ddElementGroup = ddElementGroupContext();

  return (
    <Grid container spacing={2}>
      { ddElementGroup && ddElementGroup.elementTemplates.map(element => (
        <Grid key={element.question.spec.label} item xs={12}>
          <DdElementGroupElement element={element} />
        </Grid>
      ))
      }
    </Grid>
  );
}

export default DdElementGroupElements;
