import React, { ReactElement } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MultiTypeFieldViewer from './multitypefieldviewer';
import { useStyles as tableHeadStyles } from '../../table/head';
import { Answer, BaseValueTypes, Nullable, QuestionSpec, TableQuestionSpec } from '../../../types/types';

function TableFieldViewer(props: { spec: QuestionSpec, answer: Nullable<Answer> }): ReactElement {
  const tableQuestionSpec = props.spec as TableQuestionSpec;
  const value = props.answer?.value as Array<Array<string>>;
  const theme = useTheme();
  const classesTableHead = tableHeadStyles(theme);

  return (
    <Table aria-label='tableQuestion' style={{ width: 'fit-content' }}>
      <TableHead>
        <TableRow>
          {tableQuestionSpec.columns.map(column => (
            <TableCell
              key={column.id}
              className={classesTableHead.tableHead}
              align='left'
            >
              <Typography variant='h3'>
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {value && value.map(rowValue => (
          <TableRow key={JSON.stringify(rowValue)}>
            {rowValue && tableQuestionSpec.columns.map(column => (
              <TableCell key={column.id} style={{ border: 'none' }}>
                <MultiTypeFieldViewer
                  type={column.type}
                  value={rowValue[column.id as keyof typeof rowValue] as BaseValueTypes}
                  specs={column}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TableFieldViewer;
