import React, { ReactElement } from 'react';
import { FilterParamsManager } from '../../../components/entity/list';
import GenericFilters from '../../../components/filters/filters';
import { ApiFilter, ParamFilter } from '../../../types/types';
import { InvestMgrService } from '../../../services/investmgr';

function InvestMgrFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const filters: Array<ParamFilter> = props.apiFilters ?
    props.apiFilters.map(filter => (
      { id: filter.value, type: 'multiSelect', label: filter.label, values: filter.elements }
    ))
    :
    [];

  if (!filters.find(filter => filter.id === 'name')) {
    filters.push({
      id: 'name',
      type: 'search',
      label: 'Search',
      autoComplete: query => new InvestMgrService().getSearchList(query)
    });
  }

  return (<GenericFilters filters={filters} filterParamsManager={props.filterParamsManager} />);
}

export default InvestMgrFilters;
