import React, { ReactElement, useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import { PercentQuestionSpec, ValueSetter } from '../../../types/types';

function PercentFieldEditor(props: { value: number, setValue: ValueSetter, specs?: PercentQuestionSpec, customKey?: string }): ReactElement {
  const [value, setValue] = useState(props.value ? (props.value * 100).toFixed(2).toString() : '');

  useEffect(() => {
    const valueToSet = props.value || props.value === 0 ? (props.value * 100) : '';

    valueToSet.toString().split('.')[1]?.length > 2 && valueToSet !== '' ? setValue(parseFloat(valueToSet.toFixed(2)).toString()) : setValue(valueToSet.toString());
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value.length < 1) {
      props.setValue('');
      setValue('');
    } else {
      const isValidValue = event.target.value.substring(event.target.value.length - 1) !== '.' && event.target.value !== '-' && !Number.isNaN(parseFloat(event.target.value));
      const isValidInput = event.target.value !== '.' && ((event.target.value.substring(event.target.value.length - 1) === '.' && !event.target.value.slice(0, -1).includes('.')) || event.target.value === '-');

      if (isValidValue) {
        props.setValue(parseFloat(event.target.value) / 100);
        setValue(parseFloat(event.target.value).toString());
      } else if (isValidInput) {
        setValue(event.target.value);
      }
    }
  };

  return (
    <TextField
      key={props.customKey}
      variant='standard'
      type='text'
      value={value}
      onChange={event => handleChange(event)}
      InputProps={{
        inputProps: { type: 'text', min: props.specs?.min, max: props.specs?.max, step: 'any' },
        endAdornment: <InputAdornment position='end'>%</InputAdornment>
      }}
      required
      error={props.specs?.required ? props.value !== 0 && !props.value : false}
    />
  );
}

export default PercentFieldEditor;
