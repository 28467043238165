import React, { ReactElement } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { Paper } from '@mui/material';
import { useStyles } from '../../ddelement/listitem';

const breadcrumbsUseStyles = makeStyles(() => createStyles({
  opacity: {
    opacity: 0.75,
    '&:hover': {
      opacity: '1'
    }
  }
}));

function BreadcrumbsNav(props: {pathId: string[]}): ReactElement {
  const classes = breadcrumbsUseStyles();
  const theme = useTheme();
  const paperClasses = useStyles(theme);
  let location = useLocation()?.pathname.split(/(?=[A-Z])/).join(' ');
  if (props.pathId[0] !== '') {
    location = location?.replace(props.pathId[0], props.pathId[1]);
  }
  location = location?.replace('dds', 'Due Diligences')
    .replace('assetmgrs', 'Asset Managers')
    .replace('mancos', 'Management Companies');
  const pathNames = location?.split('/').filter(item => item);
  /* if (pathNames.length > 1 && pathId[0] === '') {
    pathNames = [pathNames[0], `${pathNames[1] === 'surveys' && pathNames[1]}`];
  } */

  return (
    <Paper
      elevation={0}
      className={paperClasses.paper}
      style={{
        padding: '6px 18px',
        width: 'fit-content',
        marginBottom: '14px'
      }}
    >
      <Breadcrumbs separator={<NavigateNextRoundedIcon style={{ opacity: 0.7 }} fontSize='small' />} aria-label='breadcrumb'>
        <Link className={classes.opacity} style={{ textDecoration: 'none' }} color='inherit' to='/' component={RouterLink}>
          <HomeRoundedIcon className={classes.opacity} style={{ marginBottom: '-6px' }} fontSize='small' />
        </Link>
        {pathNames.map((value, index) => {
          const last = index === pathNames.length - 1;
          const to = `/${pathNames?.slice(0, index + 1).join('/')}`;

          // uppercase on first letter
          let text = `${to.split('/').pop()?.charAt(0).toUpperCase()}${to.split('/').pop()?.slice(1)}`;

          text = text === 'False' ? '': text;

          return last ? (
            <Typography color='textPrimary' key={to}>
              {text}
            </Typography>
          ) : (
            <Link
              className={classes.opacity}
              style={{ textDecoration: 'none' }}
              color='inherit'
              to={
                to
                  .replace('Due Diligences', 'dds')
                  .replace('Asset Managers', 'assetmgrs')
                  .replace('Funds', 'funds')
                  .replace('Management Companies', 'mancos')
                  .replace(props.pathId[1], props.pathId[0])
              }
              key={to}
              component={RouterLink}
            >
              {text}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Paper>
  );
}

export default BreadcrumbsNav;
