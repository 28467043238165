import { Grid, IconButton, Typography } from '@mui/material';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MeetingDropWrapper from '../../../containers/duediligences/pages/meetings/meetingdropwrapper';
import { UpdateMeetingInfo, UpdateMeetingProps } from '../../../types/meetingstypes';

type MeetingComponentProps = {
	id: string,
	title: string,
	type: string,
	actions: UpdateMeetingInfo,
	updateMeetings: ( updateItems: UpdateMeetingProps ) => void,
	children?: ReactNode
}

function Meeting(props: MeetingComponentProps) : ReactElement {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const meetingCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
		 // necessary for meetings display update
  }, [props]);

  return (
    <MeetingDropWrapper meetingId={props.id} updateMeetings={props.updateMeetings}>
      <Grid container spacing={2} style={{ padding: '8px' }}>
        <Grid item xs onClick={meetingCollapse} style={{ cursor: 'pointer' }}>
          <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item onClick={meetingCollapse} style={{ cursor: 'pointer' }}>
          <IconButton aria-label='collapse' size='small' style={{ padding: 0 }}>
            <KeyboardArrowRightIcon style={{ transform: isCollapsed ? 'rotate(90deg)' : 'rotate(0deg)' }}/>
          </IconButton>
        </Grid>
        { isCollapsed &&
					<Grid item xs={12}>
					  {props.children}
					</Grid>
        }
      </Grid>
    </MeetingDropWrapper>
  );
}

export default Meeting;