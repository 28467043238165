import * as _ from 'lodash';
import {
  Nullable,
  DataDueDiligence,
  DueDiligenceParsed,
  CategoriesMenu,
  Categories
} from '../types/types';

function put(obj: Array<CategoriesMenu>, path: string, val: CategoriesMenu) {
  const stringToPath = (myPath: string) => {
    const output: Array<string> = [];
    myPath.split('.').forEach((item) => {
      item.split(/\[([^}]+)/g).forEach((key) => {
        if (key.length > 0) {
          output.push(key);
        }
      });
    });

    return output;
  };
  const pathArray = stringToPath(path);

  const { length } = pathArray;
  let current = obj;

  pathArray.forEach((key: string, index: number) => {
    if (index === length - 1) {
      current.push(val);
    } else if (key !== 'children' && !(_.isEqual(current, []))) {
      current.forEach((child: CategoriesMenu) => {
        if (key === child.path.split('.').pop()) {
          current = child.children;
        }
      });
    }
  });
}

export function getTreesFromCategories(categories: Array<Categories>): {tree: Array<CategoriesMenu>, treeList: Array<CategoriesMenu>} {
  const tree: Array<CategoriesMenu> = [];
  const treeList: Array<CategoriesMenu> = [];

  categories?.forEach((cat) => {
    const path = cat.path.split('/');
    if (path.length === 1) {
      const obj = { path: path[0], label: cat.label, children: [] };
      tree.push(obj);
      treeList.push(obj);
    } else {
      const finalPath = path.join('.children.');
      const obj = { path: path.join('.'), label: cat.label, children: [] };
      put(tree, finalPath, obj);
      treeList.push(obj);
    }
  });

  return { tree, treeList };
}

export function getTreesFromData(data: Nullable<DataDueDiligence>): Nullable<DueDiligenceParsed> {
  if (data) {
    const trees = getTreesFromCategories(data.categories);

    return { dueDiligence: data, tree: trees.tree, treeList: trees.treeList };
  }

  return null;
}

export default getTreesFromData;
