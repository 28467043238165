import React, { ReactElement, useEffect } from 'react';
import { Typography } from '@mui/material';
import { ValueSetter } from '../../../types/types';

function YearFieldEditor(props: { index: number, value?: number, setValue: ValueSetter, required?: boolean, customKey?: string, lastValue?: number }): ReactElement {
  const year = new Date().getFullYear() - (props.index + 1);

  useEffect(() => {
    if (!props.value) {
      if (props.lastValue) {
        props.setValue(props.lastValue - 1);
      } else {
        props.setValue(year);
      }
    }
  }, []);

  return (
    <Typography key={year} variant='body2' style={{ whiteSpace: 'pre-line' }}>{props.value}</Typography>
  );
}

export default YearFieldEditor;
