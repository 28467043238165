import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Snackbar, Alert } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { red } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { SurveyElement } from '../../../types/types';
import QuestionService from '../../../services/questions';
import CustomButton from '../../button/button';
import CustomValidationModal from '../../layout/modals/customvalidationmodal';
import { mainColor } from '../../../themes/theme';
import ServiceError from '../../../services/errors';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      right: '0px',
      padding: '0px',
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
);

function QuestionCancelButton(props: { update: () => void, surveyElement: SurveyElement, questionService: QuestionService, setUpdateList: Dispatch<React.SetStateAction<boolean>>, readonly?: boolean}): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [cancelDisabled, setCancelDisabled] = useState(props.surveyElement.answerStatus === 'notAnswered' || props.readonly);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setCancelDisabled(props.surveyElement.answerStatus === 'notAnswered' || props.readonly);
  }, [props.surveyElement]);

  const handleChangeSubmit = () => {
    setButtonDisabled(true);
    props.questionService.revertAsAuditee(props.surveyElement.ddInfo._id, props.surveyElement._id)
      .then(() => {
        props.update();
        setModalOpen(false);
        setButtonDisabled(true);
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
        setButtonDisabled(false);
      });
  };

  const handleDeleteSubmit = () => {
    setButtonDisabled(true);
    props.questionService.deleteAsAuditee(props.surveyElement.ddInfo._id, props.surveyElement._id)
      .then(() => {
        props.update();
        setModalOpen(false);
        setButtonDisabled(false);
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
        setButtonDisabled(false);
      });
  };

  const handleCancel = () => {
    setCancelDisabled(false);
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setCancelDisabled(true);
    setModalOpen(true);
  };

  return (
    <>
      <div key={`${props.surveyElement._id  } validation`} className={classes.container}>
        <CustomButton
          variant='contained'
          style={{
            backgroundColor: cancelDisabled ? red[50] : red[500],
            width: '180px',
            marginLeft: 'auto'
          }}
          startIcon={<ClearRoundedIcon />}
          onClick={handleOpenModal}
          disabled={cancelDisabled}
        >
          {t('btns.cancelSubmit')}
        </CustomButton>
      </div>
      <CustomValidationModal
        action1={handleChangeSubmit}
        action1Title={t('btns.editSubmit')}
        action1Color={mainColor}
        action2={handleDeleteSubmit}
        action2Title={t('btns.deleteSubmit')}
        action2Color={red[500]}
        cancel={handleCancel}
        open={modalOpen}
        msg={t('ddElements.modals.cancelSubmit.title')}
        disabled={buttonDisabled}
      />
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default QuestionCancelButton;
