import React, { ReactElement, useState } from 'react';
import CardActions from "@mui/material/CardActions";
import MultiSelectFieldEditor from '../../field/editors/mulltiselectfieldeditor';
import QuestionBox from "../box";
import QuestionValue from "../value";
import { QuestionEditorProps, SelectQuestionSpec, Value } from '../../../types/types';
import QuestionEditorButton from '../questioneditorbutton';
import QuestionLabel from '../label';

export default function MultiSelectQuestionEditor(props: QuestionEditorProps<SelectQuestionSpec>) : ReactElement{
  const [value, setValue] = useState<Value>(props.answer?.value);

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <MultiSelectFieldEditor specs={props.spec as SelectQuestionSpec} value={value as string[]} setValue={setValue}/>
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} />
      </CardActions>
    </QuestionBox>
  );
}
