import React, { Dispatch, ReactElement, useState } from 'react';
import SearchBar from '../search/searchbar';
import { Contact, Nullable } from '../../types/types';
import { AbstractContactsService } from '../../services/contacts';
import ServiceError from '../../services/errors';

interface SearchContactProps {
  contactList: Array<Contact>,
  setContactList: Dispatch<React.SetStateAction<Array<Contact>>>,
  error: Nullable<ServiceError>,
  setError: Dispatch<React.SetStateAction<Nullable<ServiceError>>>,
  contactsService: AbstractContactsService<Contact>
}

function SearchContact(props: SearchContactProps): ReactElement {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState('');

  const removeContactsAlreadyAdded = (contacts: Array<Contact>) => contacts.filter(item => (
    props.contactList.every(contact => contact.id !== item.id))
  );

  const handleSubmit = (query: string) => {
    props.contactsService.search(query)
      .then((apiResp) => {
        apiResp.data && props.setContactList(removeContactsAlreadyAdded(apiResp.data));
      })
      .catch((err) => {
        props.setError(ServiceError.ensureServiceError(err));
      });
  };

  return (
    <div style={{ height: '50px', marginTop: '24px' }}>
      <SearchBar focused={focused} setFocused={setFocused} handleSubmit={handleSubmit} value={value} setValue={setValue} />
    </div>
  );
}

export default SearchContact;
