import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { lighten, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Snackbar, Alert, Theme, Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { TabTypeMap } from '@mui/material/Tab/Tab';
import { SurveyElement } from '@deecision/deeligenz-interfaces';
import { ArrowDownwardRounded, WarningRounded } from '@mui/icons-material';
import { Answer, ButtonsSettings, Nullable, Value } from '../../../types/types';
import DispatchQuestionEditor from '../../question/editors/dispatchquestioneditor';
import QuestionService from '../../../services/questions';
import { surveyContext } from '../../../hooks/datatabs';
import { a11yProps, DdElementListItemHeader, TabPanel } from '../../ddelement/listitem';
import DispatchQuestionViewer from '../../question/viewers/dispatchquestionviewer';
import { mainRadius } from '../../../themes/theme';
import { getTreesFromCategories } from '../../../utils/dataforcontext';
import { useTabsStyles } from '../../layout/tabs/dynamictabs';
import Comments from '../../ddelement/comments/comments';
import { SurveysElementsCommentService } from '../../../services/comments';
import QuestionCancelButton from './questioncancelbutton';
import QuestionDisplayButtons from '../../ddelement/questiondisplaybuttons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: ' 12px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '0.9',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out'
      }
    },
    tabsIndicator: {
      height: '32px',
      zIndex: 2,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      borderRadius: mainRadius
    },
    customChipIndicator: {
      width: '24px',
      height: '24px',
      borderRadius: '24px',
      lineHeight: '24px',
      backgroundColor: '#457ac9',
      color: 'white',
      position: 'absolute',
      right: '4px',
      fontSize: '12px'
    }
  })
);

function CustomChipIndicatorAndLabel( props: { label: string, count: number }): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <span>
        {props.label}
        { props.count > 0 &&
          <strong className={ classes.customChipIndicator }>
            { props.count }
          </strong>
        }
      </span>
    </>
  );
}

export interface SurveyElementProps {
  surveyElement: SurveyElement,
  setUpdateList: Dispatch<React.SetStateAction<boolean>>,
  readonly?: boolean,
  setReadonly?: (value: boolean) => void
}

/**
 * This represents a DD Element in the list of elements. It shows the title + various tabs
 * @returns
 */
export default function SurveysDdElementListItem(props: SurveyElementProps): ReactElement{
  const { surveyElement } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const tabsClasses = useTabsStyles();
  const survey = surveyContext();
  // For the moment, we choose this answer
  const [answer, setAnswer] = useState(surveyElement.answer);
  const paths = props.surveyElement.category.split('/');
  const [view, setView] = useState('auditeeAnswer');
  const [disableInput, setDisableInput] = useState(answer?.answerInfo?.[0]);
  const [disabled, setDisabled] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setAnswer(surveyElement.answer);
  }, [surveyElement.answer]);

  if (!survey) {
    return (<></>);
  }

  const { treeList } = getTreesFromCategories(survey.categories);

  let location = '';
  let locationLabel = '';
  paths.forEach((path, index) => {
    location += index > 0 ? `.${  path}` : path;
    treeList?.forEach((treePath) => {
      if (treePath.path === location) {
        locationLabel += index > 0 ? ` > ${  treePath.label}` : treePath.label;
      }
    });
  });

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const getTabsForElement = (mySurveyElement: SurveyElement /* initialTabId?: string */) => {
    const tabs = [];
    if (mySurveyElement.kind === 'question') {
      tabs.push('question');
    } else {
      tabs.push('question');
    }
    // tabs.push('contradictionRoom');
    tabs.push('messages');

    return tabs;
  };

  const tabIds = getTabsForElement(props.surveyElement);

  const getTabForId = (mySurveyElement: SurveyElement, tabId: string, index: number) => {
    const messagesCount = mySurveyElement.comments.length;
    switch (tabId) {
    case 'question':
      return (<Tab key={tabId} label='Question' {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple />);
    case 'messages':
      return (
        <Tab
          style={{ paddingRight: messagesCount > 0 ? '36px' : '24px', marginRight: messagesCount > 0 ? '16px' : '0' }}
          key={tabId} label={<CustomChipIndicatorAndLabel label='Messages' count={messagesCount} />}
          {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple
        />);
    default:
      return (<Tab key={tabId} label={tabId} {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple />);
    }
  };

  const surveysQuestionService = new QuestionService();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getTabContentForId = (mySurveyElement: SurveyElement, tabId: string, index: number) => {
    const onSaveAsDraft = (value: Value, answerInfo?: string[]) => {
      setDisabled(true);
      surveysQuestionService.saveAsAuditee(mySurveyElement._id, value, true, mySurveyElement.ddInfo.auditedEntity.id, answerInfo)
        .then(() => {
          props.setUpdateList(true);
          setDisabled(false);
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${err.message}`);
          setSnackbarOpen(true);
          setDisabled(false);
        });
    };

    const onSubmit = (value: Value, answerInfo?: string[]) => {
      surveysQuestionService.saveAsAuditee(surveyElement._id, value, false, surveyElement.ddInfo.auditedEntity.id, answerInfo)
        .then(() => {
          setView('auditeeAnswer');
          props.setUpdateList(true);
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${err.message}`);
          setSnackbarOpen(true);
          setDisabled(false);
        });
    };

    const handleSetAnswer = (value: Nullable<Answer>) => {
      setAnswer(value);
      props.setReadonly ? props.setReadonly(false) : null;
    };

    const handleSetAnswerFromPreviousAnswer = (value: Nullable<Answer>) => {
      setAnswer(value);
      setView('auditeeAnswer');
    };

    const buttonsSettings: ButtonsSettings = {
      onSaveAsDraft,
      onSubmit,
      role: 'auditee',
      answerInfo: surveyElement.answer?.answerInfo,
      id: surveyElement._id,
      disabled,
      draft: surveyElement.answer?.draft,
      update: () => props.setUpdateList(true),
      ddId: surveyElement.ddInfo._id,
      disableInput,
      setDisableInput: (val: string) => setDisableInput(val)
    };

    switch (tabId) {
    case 'question':
      return (
        <>
          {surveyElement.answerStatus !== 'answered' &&
            <>
              {surveyElement.previousAnswer && view !== 'previousAnswer' &&
                <Paper
                  elevation={0}
                  sx={{
                    p: '4px',
                    pl: '8px',
                    pr: '8px',
                    width: '100%',
                    mb: 1,
                    cursor: 'pointer',
                    backgroundColor: lighten(theme.palette.warning.light, 0.7),
                    '&:hover': {
                      textDecoration: `underline ${theme.palette.warning.main}`
                    }
                  }}
                  onClick={() => {
                    setView('previousAnswer');
                    surveyElement.previousAnswer && setAnswer(surveyElement.previousAnswer);
                  }}
                >
                  <Stack direction='row'>
                    <WarningRounded fontSize='small' color='warning' />
                    <Stack direction='row' spacing={2} sx={{ ml: 'auto', width: 'fit-content' }}>
                      <Typography variant='body1' fontWeight={500} color={theme.palette.warning.main}>
                        Previous answer available
                      </Typography>
                      <ArrowDownwardRounded fontSize='small' color='warning' />
                    </Stack>
                  </Stack>
                </Paper>
              }
              <QuestionDisplayButtons
                ddElement={{
                  ...surveyElement,
                  answers: {
                    auditee: surveyElement.answer,
                    auditor: null,
                    previous: surveyElement.previousAnswer
                  },
                  events: [],
                  ddInfo: {
                    ...surveyElement.ddInfo,
                    templateId: ''
                  }
                }}
                view={view}
                setView={setView}
                setAnswer={setAnswer}
                readonly={!!props.readonly}
              />
            </>
          }
          {view === 'previousAnswer' || props.readonly || surveyElement.answerStatus === 'validated' || (answer && !answer.draft && surveyElement.answerStatus === 'answered') ?
            <>
              <DispatchQuestionViewer
                spec={surveyElement.question.spec}
                answer={answer}
                setAnswer={props.readonly ? handleSetAnswer : view === 'previousAnswer' ? handleSetAnswerFromPreviousAnswer : undefined}
                id={surveyElement._id}
                buttonsSettings={buttonsSettings}
              />
              {surveyElement.answerStatus === 'answered' &&
                <QuestionCancelButton update={() => props.setUpdateList(true)} surveyElement={props.surveyElement} questionService={surveysQuestionService} setUpdateList={props.setUpdateList} readonly={!!props.readonly} />
              }
            </>:
            <DispatchQuestionEditor
              spec={surveyElement.question.spec}
              answer={answer}
              buttonsSettings={buttonsSettings}
              disableInput={disableInput}
            />
          }
        </>
      );
    case 'messages':
      return (
        <Comments
          events={surveyElement.comments}
          commentsService={new SurveysElementsCommentService(survey._id, surveyElement._id)}
          setUpdate={props.setUpdateList}
          readonly={props.readonly}
        />
      );
    default:
      return undefined;
    }
  };

  const getTabs = (mySurveyElement: SurveyElement) => (
    tabIds.map((tabId, i) => getTabForId(mySurveyElement, tabId, i))
  );

  let backgroundColor;

  switch (surveyElement.answerStatus) {
  case 'validated' :
    backgroundColor = 'rgba(124, 179, 66, 0.175)';
    break;
  case 'rejected' :
    backgroundColor = 'rgba(229, 57, 53, 0.175)';
    break;
  case 'answered' :
    backgroundColor = !surveyElement.answer?.draft ? 'rgba(121, 134, 203, 0.175)' : 'rgba(157, 157, 157, 0.175)';
    break;
  default :
    backgroundColor = 'rgba(157, 157, 157, 0.175)';
  }

  return (
    <>
      <Paper key={surveyElement._id} className={classes.paper} style={{ backgroundColor }} elevation={0} variant='outlined'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DdElementListItemHeader
              _id={surveyElement._id}
              type={surveyElement.kind}
              title={surveyElement.title}
              breadcrumbs={locationLabel}
              draft={answer?.draft || false}
              survey
            />
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={selectedTabIndex}
              onChange={handleTabChange}
              indicatorColor='primary'
              className={tabsClasses.tabs}
              variant='scrollable'
              classes={{ scroller: tabsClasses.scroller }}
              TabScrollButtonProps={{ className: tabsClasses.scrollButtons }}
              style={{ marginRight: 'auto', marginTop: '0px' }}
              TabIndicatorProps={{
                className: classes.tabsIndicator
              }}
              // centered
            >
              {getTabs(props.surveyElement)}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {tabIds.map((tabId, i) => {
              if (i === selectedTabIndex) {
                return (
                  <TabPanel key={`tab-${  tabId}`} value={selectedTabIndex} index={i}>
                    <Paper className={classes.paper} elevation={0} variant='outlined' style={{ position: 'relative' }}>
                      {getTabContentForId(props.surveyElement, tabId, i)}
                    </Paper>
                  </TabPanel>
                );
              }

              return undefined;
            })}
          </Grid>
        </Grid>
      </Paper>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
