import React from 'react';

export function createContext<T>(): readonly [typeof Context, typeof provider.Provider] {
  const provider = React.createContext<T | undefined>(undefined);
  function Context(): T {
    const c = React.useContext(provider);
    if (c === undefined) {
      throw new Error("useContext must be inside a Provider with a value");
    }

    return c;
  }

  return [Context, provider.Provider] as const;
}

export default createContext;
