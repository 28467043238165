import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { Navigate, Routes, Route, useLocation, useParams } from 'react-router-dom';
import IdRouter from './idrouter';
import MeetingsTabs from '../components/meetings/meetingstabs';
import ListOfMeetings from '../containers/duediligences/pages/meetings/list';
import MeetingDetails from '../containers/duediligences/pages/meetings/details';
import { meetingsTabs } from '../utils/tabs';
import Allocation from '../containers/duediligences/pages/meetings/allocation';

function MeetingsRouter(props: {readonly?: boolean}): ReactElement {
  const tabsInfo = meetingsTabs;
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  return (
    <Grid container spacing={2}>
      {tabsInfo && id &&
        <>
          <Grid item xs={12}>
            <MeetingsTabs tabsInfo={tabsInfo} readonly={props.readonly} />
          </Grid>
          <Grid item xs={12}>
            <Routes>
              <Route path='/' element={<Navigate to={`${pathname}/${tabsInfo ? tabsInfo[0].path : ''}`} />} />
              <Route path='/list/*' element={
                <IdRouter index={<ListOfMeetings ddId={id} isAuditor readonly={props.readonly} />}>
                  <MeetingDetails ddId={id} isAuditor readonly={props.readonly} />
                </IdRouter>
              }
              />
              <Route path='/allocation/*' element={
                <Allocation ddId={id} readonly={props.readonly} />
              }
              />
            </Routes>
          </Grid>
        </>
      }
    </Grid>
  );
}

export default MeetingsRouter;
