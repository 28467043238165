import React, { useState } from 'react';
import Navbar from './navbar';
import ScrollTopBtn from '../tools/scrolltop';
import ThemeWrapper from '../../../themes/themewrapper';

interface NavProps {
    children: React.ReactNode
}

function Layout( props : NavProps):React.ReactElement {
  const [darkTheme, setDarkTheme] = useState(false);

  return (
    <ThemeWrapper darkTheme={darkTheme}>
      <ScrollTopBtn />
      <Navbar
        appTitle='Deeligenz'
        darkTheme={darkTheme}
        onThemeChanged={() => setDarkTheme(prevState => !prevState)}
      >
        {props.children}
      </Navbar>
    </ThemeWrapper>
  );
}

export default Layout;
