import React, { ReactElement } from 'react';
import {
  Navigate,
  Routes,
  Route
} from 'react-router-dom';
import IdRouter from './idrouter';
import SurveyRouter from './surveyrouter';
import AuditeeIndex from '../containers/auditee/surveys/surveys';

function AuditeeRouter(): ReactElement {

  return (
    <Routes>
      <Route path='/' element={<Navigate to='surveys' />} />
      <Route path='/surveys/*' element={
        <IdRouter index={<AuditeeIndex />}>
          <SurveyRouter />
        </IdRouter>
      }
      />
    </Routes>
  );
}

export default AuditeeRouter;
