import React, { ReactElement, useState } from "react";
import CardActions from '@mui/material/CardActions';
import NumberFieldEditor from '../../field/editors/numberfieldeditor';
import QuestionBox from "../box";
import QuestionValue from "../value";
import { NumberQuestionSpec, QuestionEditorProps, Value } from '../../../types/types';
import QuestionEditorButton from "../questioneditorbutton";
import QuestionLabel from '../label';

function NumberQuestionEditor(props: QuestionEditorProps<NumberQuestionSpec>) : ReactElement {
  const [value, setValue] = useState<Value>(props.answer?.value);

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <NumberFieldEditor specs={props.spec as NumberQuestionSpec} value= {value as number} setValue={setValue}/>
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} />
      </CardActions>
    </QuestionBox>
  );
}
export default NumberQuestionEditor;
