import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CategoriesTree from '../../../../components/ddelement/categoriestree';
import { FilterParamsManager } from '../../../../components/entity/list';
import GenericFilters, { defaultFilterComponentFactory, FilterProps } from '../../../../components/filters/filters';
import { dataContext } from '../../../../hooks/datatabs';
import { ApiFilter, ParamFilter } from '../../../../types/types';

function AnalysisFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const { t } = useTranslation();
  const filters: Array<ParamFilter> = props.apiFilters ?
    [{ id: 'category', type: 'tree', label: t('dds.filters.category') },
      ...props.apiFilters.map(filter => (
        { id: filter.value, type: 'multiSelect' as ParamFilter['type'], label: filter.label, values: filter.elements }
      ))]
    :
    [
      { id: 'category', type: 'tree', label: t('dds.filters.category') },
      { id: 'answerStatus', type: 'multiSelect', label: t('dds.filters.answerStatus'), values: [{ label: 'Answered', value: 'answeredAll' }, { label: 'Answered to analyze', value: 'answered' }, { label: 'Validated', value: 'validated' }, { label: 'Rejected', value: 'rejected' }, { label: 'Not Answered', value: 'notAnswered' }] },
      { id: 'rating', type: 'multiSelect', label: t('dds.filters.rating'), values: [{ label: 'Ok', value: 'ok' }, { label: 'Concern', value: 'concern' }, { label: 'Red Flag', value: 'redFlag' }] },
      { id: 'flags', type: 'multiSelect', label: t('dds.filters.flags'), values: [{ label: 'Meeting', value: 'meeting' }, { label: 'Key Finding', value: 'keyFinding' }, { label: 'Next Review', value: 'nextReview' }] }
    ];
  filters.push({
    id: 'text',
    type: 'search',
    label: 'Search'
  });
  
  const categories = dataContext()?.dueDiligence?.categories;

  const ddElementFilterComponentFactory = (props2: FilterProps): ReactElement | undefined => {
    const { filter } = props2;
    switch (filter.type) {
    case 'tree':
      if (categories) {
        return (<CategoriesTree key={filter.id} categories={categories} {...props2} />);
      }

      return undefined;

    default:
      return defaultFilterComponentFactory(props2);
    }
  };

  return (<GenericFilters
    filters={filters}
    filterParamsManager={props.filterParamsManager}
    filterComponentFactory={ddElementFilterComponentFactory}
    fixed
  />);
}

export default AnalysisFilters;
