import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import CustomTable, { Column } from '../../../../components/table/table';
import { ResolvedVersions } from '../../../../types/types';

function DdElementGroupsList(props: {ddElementGroups: ResolvedVersions['groups'] }): ReactElement {
  const columns: Array<Column> = [
    { id: 'name', label: 'Dd Element Groups', minWidth: 'min-content' }
  ];

  const rows = Object.keys(props.ddElementGroups).map(key => ({
    id: key,
    value: columns.map(column => (
      <>
        {column.id === 'name' &&
            <Typography>
              {props.ddElementGroups[key]}
            </Typography>
        }
      </>
    ))
  }));

  return (
    <CustomTable columns={columns} rows={rows} size='small' />
  );
}

export default DdElementGroupsList;
