import React, { ReactElement, useEffect, useState } from 'react';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { DdElement, Nullable } from '../../../../../types/types';
import Comments from '../../../../../components/ddelement/comments/comments';
import { ElementsCommentService } from '../../../../../services/comments';
import { DdElementService } from '../../../../../services/ddelements';
import QuestionView from '../../../../../components/ddelement/questionview';
import QuestionHistory from '../../../../../components/ddelement/questionhistory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    }
  })
);

// TODO: ADD To props Previous url for the backurl
export default function DdElementDetails(): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const params = useLocation();
  const id = params.pathname.split('/').pop();
  const [ddElement, setDdElement] = useState<Nullable<DdElement>>(null);
  const [update, setUpdate] = useState(true);
  if (!params.search) {
    params.search = '';
  }
  const getDdElement = new DdElementService<DdElement>();

  useEffect(() => {
    update && getDdElement.get(`${id}`)
      .then((res) => {
        res.data && setDdElement(res.data);
        setUpdate(false);
      })
      .catch(() => {
        setUpdate(false);
      });
  }, [update]);
  // params.search = parameters for backbutton

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // const backUrl = url.replace(id, '');

  if (ddElement) {
    return <>
      <Button variant='outlined'>
        <HashLink scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'center' })} to={`../analysis${params.search}#${id}`}>
          <KeyboardBackspaceIcon /></HashLink></Button>
      <div className={classes.root}>
        <h2>{ddElement.question.spec.label}</h2>
        <div>
          <div>{(ddElement.kind === 'question') && <QuestionView question={ddElement}/>}</div>
          <h3>Evaluation</h3>
          {
            /* <CriteriaView ddElement={ddElement} questionService={questionService}
                         setUpdateList={props.setUpdateList} /> */
          }
          <h3>Message</h3>
          <div style={{ height: '600px' }}>
            <Comments
              events={ddElement.comments}
              commentsService={new ElementsCommentService(ddElement._id)}
              setUpdate={setUpdate}
            />
          </div>
          <div><h3>Audit</h3>
            <QuestionHistory ddElement={ddElement} />
          </div>
        </div>
      </div>
    </>;
  }

  return (<div>No Data</div>);
}
