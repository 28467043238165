import React, { ReactElement } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Ratings } from '../../types/types';
import HeaderProperty from '../modularcomponents/header/headerproperty';
import HeaderPropertyName from '../modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../modularcomponents/header/headerpropertyvalue';
import { Rating2, Rating1 } from '../../containers/duediligences/listing/datadisplay';

function HeaderRating(props: { rating?: Ratings, nextReviewDate?: string, entityType?: string }): ReactElement {

  return (
    <>
      <HeaderProperty>
        <Grid item xs={12} style={{ paddingTop: 0, marginBottom: '12px' }}>
          <Stack spacing={2} direction='row' width='128px' height='38px' alignItems='center' justifyContent='space-between'>
            <Typography variant='body2'>
              Global Rating
            </Typography>
            <Rating1 ratings={props.rating} />
          </Stack>
        </Grid>
      </HeaderProperty>
      <HeaderProperty>
        <Grid item xs={12} style={{ paddingTop: 0, marginBottom: '12px' }}>
          <Stack spacing={2} direction='row' width='128px' height='38px' alignItems='center' justifyContent='space-between'>
            <Typography variant='body2'>
              ESG Rating
            </Typography>
            <Rating2 ratings={props.rating} />
          </Stack>
        </Grid>
      </HeaderProperty>
      <HeaderProperty>
        <HeaderPropertyName name='Next Review Date ' />
        <HeaderPropertyValue after={props.entityType?.includes('odd') ? 'temporary' : undefined}>
          {props.nextReviewDate || ''}
        </HeaderPropertyValue>
      </HeaderProperty>
    </>
  );
}

export default HeaderRating;
