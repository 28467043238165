import React, { Dispatch, ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DdElement,
  DataDueDiligence
} from '../../../../types/types';
import { dataContext } from '../../../../hooks/datatabs';
import AnalysisDdElementListItem from '../../../../components/ddelement/listitem';
import { DdElementsService } from '../../../../services/ddelements';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../../components/params';
import CustomPagination from '../../../../components/layout/pagination/pagination';
import { ListRendererMap, MultiModeList, QuestionListDataProvider } from '../../../../components/entity/list';
import AnalysisFilters from './filters';
import QuestionSkeleton from '../../../../components/layout/loading/questionskeleton';
import { checkAccessToDd } from '../../../../components/authentification/checkRoles';

class AnalysisDataProvider extends QuestionListDataProvider<DdElement> {
  constructor(ddId: string, searchParamsAccess: SearchParamsAccess, itemsPerPageMin?: number) {
    super(ddId, searchParamsAccess, new DdElementsService(), itemsPerPageMin);
  }
}

interface DdElementListProps {
  ddElements: Array<DdElement>,
  setUpdateList: Dispatch<React.SetStateAction<boolean>>,
  readonly?: 'full' | 'partial'
}

function AnalysisDdElementList(props: DdElementListProps): ReactElement {
  // We order the elements by displayIndex ascending before rendering them
  // =====================================================================
  if (props.ddElements) {
    props.ddElements.sort((a: DdElement, b: DdElement) => (a.displayIndex - b.displayIndex));
  }

  return (
    <Grid container spacing={3}>
      {props.ddElements &&
      props.ddElements.map((ddElement: DdElement) => (
        <Grid key={ddElement._id} item xs={12}>
          <AnalysisDdElementListItem
            ddElement={ddElement}
            setUpdateList={props.setUpdateList}
            readonly={props.readonly}
          />
        </Grid>
      ))
      }
    </Grid>
  );
}

function AnalysisMultiModeList(props: {dd: DataDueDiligence, readonly?: 'full' | 'partial'}): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const { t } = useTranslation();
  const listDataProvider = new AnalysisDataProvider(props.dd._id, searchParamsAccess);

  const renderEmpty = (totalCount?: number) => (
    <>
      <div style={{ marginTop: '42px' }}>{t('dds.tabs.analysis.noQuestion')}</div>
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount || 0} />
    </>
  );

  const renderLoading = () => (
    <QuestionSkeleton />
  );

  const renderAsList = (eltList: Array<DdElement>, totalCount: number, setUpdateList?: Dispatch<React.SetStateAction<boolean>>) => (
    <>
      {setUpdateList && <AnalysisDdElementList ddElements={eltList} setUpdateList={setUpdateList} readonly={props.readonly} />}
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
    </>
  );

  const renderers: ListRendererMap<DdElement> = {
    'list': renderAsList
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['list' ]}
      defaultMode='list'
      renderers={renderers}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      filtersComponent={AnalysisFilters}
    />
  );
}

function Analysis(): ReactElement {
  const dd = dataContext();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));
  const [readonly, setReadonly] = React.useState<'full' | 'partial' | undefined>(undefined);

  useEffect(() => {
    if (dd && dd.dueDiligence) {
      let input = '';
      let analysis = '';

      dd.dueDiligence.lifecycle?.activities.forEach((activity) => {
        if (activity.id === 'input') {
          input = activity.status;
        }
        if (activity.id === 'analysis') {
          analysis = activity.status;
        }
      });
      if  (analysis !== 'started' && analysis !== 'reopened') {
        setReadonly('full');
      } else if (input !== 'started' && input !== 'reopened') {
        setReadonly('partial');
      } else {
        setReadonly(undefined);
      }
      if (!checkAccessToDd(['owner', 'validator', 'contributor', 'manager'], dd.dueDiligence.participants, keycloak.subject || '')) {
        setReadonly('full');
      }
    }
  }, [dd]);

  return (
    <>
      <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
        { dd && dd.dueDiligence &&
          <AnalysisMultiModeList dd={dd.dueDiligence} readonly={readonly} />
        }
      </SearchParamsContextProvider>
    </>
  );
}

export default Analysis;
