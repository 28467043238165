import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

function Index(): ReactElement {
  const { keycloak } = useKeycloak();

  switch (true) {
  case keycloak.hasResourceRole('dd-editor'):
    return (<Navigate to='/dds'/>);
  case keycloak.hasResourceRole('auditee'):
    return (<Navigate to='/auditee'/>);
  case keycloak.hasResourceRole('dd-viewer'):
    return (<Navigate to='/strategies'/>);
  case keycloak.hasResourceRole('fund-center-user'):
    return (<Navigate to='/assetmgrs'/>);
  default:
    return (<Navigate to='/dds'/>);
  }
}

export default Index;
