import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper, Theme } from '@mui/material';
import { DGPSLImportService } from '../../../../services/import';
import CustomButton from '../../../button/button';
import { mainRadius } from '../../../../themes/theme';
import UploadFiles from '../../../upload/uploadfiles';

/* export interface FileToImport {
  accepted: boolean,
  file: File,
  errors?: FileError[],
  uploadStatus?: 'uploading' | 'failed' | 'pending' | 'noUpload' | 'success',
  importGroupId?: string,
  progress?: number,
  setProgress?: (progress: number) => void,
  //TODO: show progress ?
  errMsg?: string,
} */

export interface FilesState {
  accepted: boolean,
  file: File
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '24px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '0.9',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out'
      }
    }
  }));

function GPSLImport() : ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          Import GPSL
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/admin/importGroups'>
          <CustomButton variant='contained'>Show all imported files</CustomButton>
        </Link>
      </Grid>
      {/* <Grid item xs={6} style={{ textAlign: 'left' }}>
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/admin/gpsl'>
          <CustomButton variant='contained' disabled>Show all imported GPSL</CustomButton>
        </Link>
      </Grid> */}
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant='h2'>Or</Typography>
      </Grid>
      {/* <DdImportUploader /> */}
      <Grid item xs={12}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UploadFiles
                service={new DGPSLImportService()}
                update={() => undefined}
                /* fileTypes={authorizedTypes} */
                uploadButtonLabel='Click here to upload GPSL'
                baseUrl='/admin/importgroups/'
                multiple
                dispUploaded
                childBeforeBtn
              >
                <Typography variant='h2'>Drag and drop here the file to import or</Typography>
              </UploadFiles>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

/* export function DdImportUploader() : ReactElement {
  const [files, setFiles] = useState<Array<FileToImport>>([]);
  const [currentUpload, setCurrentUpload] = useState<FileToImport | undefined>(undefined);
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>(undefined);

  useEffect(() => {
    if (currentUpload) {
      console.log("need to start")
      // setTimeout(() => startUpload(), 1000);
      doUpload();
    } else {
      if (checkIfStartUpload()) {
          console.log("need to start")
          // setTimeout(() => startUpload(), 1000);
          startUpload();
        }
      }
    console.log("after start")
  }, [currentUpload]);

  useEffect(() => {
    if (!currentUpload) {
      if (checkIfStartUpload()) {
        console.log("need to start")
        // setTimeout(() => startUpload(), 1000);
        startUpload();
      }
      console.log("after start")
      }
  }, [files]);

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const accepted: FileToImport[] = acceptedFiles.map(f => ({ accepted: true, file: f, uploadStatus: 'pending'}));
    const rejected: FileToImport[] = fileRejections.map(rejection => ({ accepted: false, file: rejection.file, uploadStatus: 'noUpload', errors: rejection.errors }));

    const allFilesToImport = [...files, ...accepted, ...rejected];
    console.log('allFilesToImport', allFilesToImport);
    setFiles(allFilesToImport);

  }

  function onCancelUpload(fileInfo: FileToImport) {
    console.log('Canceling ', fileInfo);
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Upload canceled by user');
    }
    return ;
  }

  function onRemoveUpload(fileInfo: FileToImport) {
    console.log('Removing ', fileInfo);
    setFiles(files.filter((elt) => !(elt === fileInfo)));
    return ;
  }

  function checkIfStartUpload() {
    if (currentUpload) {
      console.log('checkIfStartUpload - already uploading');
      return false; // already uploading so it will continue
    }

    console.log('files before for', files);
    for (const f of files) {
      if (f.uploadStatus == 'pending') {
        console.log('checkIfStartUpload - found pending', f);
        return true;
      }
    }

    console.log('checkIfStartUpload - none found');
    return false;
  }

  function startUpload() {
    console.log("startUpload...")
    if (currentUpload) {
      throw new Error('Already uploading');
    }

    startNextUpload();
  }

  function nextForUpload() { //filelist: Array<FileToImport>
    console.log("nextForUpload...")
    for (const f of files) {
      if (f.uploadStatus == 'pending') {
        console.log("found file to upload...", f);
        return f;
      }
    }
    console.log("no file found to upload...");
    return undefined;
  }

  function startNextUpload() {
    console.log("startNextUpload...")
    const nextUpload = nextForUpload();

    if (nextUpload) {
      nextUpload.uploadStatus = 'uploading';
      setCurrentUpload(nextUpload);
      setFiles(files);
    } else {
      setCurrentUpload(undefined);
    }
  }

  function uploadSuccessful(fileInfo: FileToImport) { //TODO : add here the api resp
    console.log("upload successful");
    fileInfo.uploadStatus = 'success';
    //fileInfo.progress = 1;

    // TODO: update with response from upload (importId)
    setFiles(files);

    //setTimeout(() => startNextUpload(), 100);
    //startNextUpload();
    setCurrentUpload(undefined);
    setCancelTokenSource(undefined);
  }

  function uploadFailed(fileInfo: FileToImport, err: unknown) {
    console.log("upload failed");
    fileInfo.uploadStatus = 'failed';
    fileInfo.errMsg = ''+err;
    setFiles(files);
    setCurrentUpload(undefined);
    setCancelTokenSource(undefined);
  }

  function doUpload() {
    console.log("doing upload");

    const file = currentUpload;

    function onUploadProgress(event: {loaded: number, total: number}) {
      const loaded = event.loaded;
      const total = event.total;

      // if (loaded == total) {
      //   // We are done
      //   // if (file) {
      //   //   uploadSuccessful(file);
      //   // }
      //   console.log('progress total !')
      // }
      console.log('progress - event', event);
      if (file && file.setProgress) {
        file.setProgress(loaded / total);
      }
    }

    if (file) {
      console.log('Calling service to upload the files');
      new ImportGroupService().uploadDD(file.file, onUploadProgress, setCancelTokenSource)
        .then((resp) => uploadSuccessful(file))
        .catch((err) => uploadFailed(file, err));
    }
  }

  // const onDelete = (index:number) => {
  //   setFiles(files.filter((_, i) => i !== index));
  // }

  return (
    <>
    {maxFiles={10}}
      <DdImportDropzone onDrop={onDrop} accept={".zip"} multiple={true}/>
      <DdImportUploadQueue files={files} onCancelUpload={onCancelUpload} onRemoveUpload={onRemoveUpload} />
      {<div><Button onClick={(ev) => startUpload()} >go</Button></div>}
    </>
  );
} */

/* export function DdImportDropzone(props: DropzoneOptions) : ReactElement {

  const { maxFiles } = props;

  const { getRootProps, getInputProps, open, isDragActive} = useDropzone(props);

  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign="center"
      border="3px dashed"
      borderColor={isDragActive ? "primary.light" : "divider"}
      borderRadius={16}
      bgcolor="background.paper"
      my={2}
      color={isDragActive ? "primary.light" : "divider"}
    >
      <Box {...getRootProps()} pt={2} style={{ outline: "none" }}>
        <input {...getInputProps()} type="file" />
        <Typography variant="h6" color="textPrimary">
          Drag and drop here the Zip files containing the Archived MDD you want to import {maxFiles && <> (maximum {maxFiles} files) </> }
        </Typography>
      </Box>
      <Box
        display="flex"
        flexWrap="1"
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        mx={12}
      >
        <Typography variant="subtitle2" color="textSecondary">
          OR
        </Typography>
      </Box>
      <Box p={2} mx={8} pb={2}>
        <CustomButton
          variant="contained"
          onClick={open}
        >
          Browse files
        </CustomButton>
      </Box>
    </Box>
  );
}

export function DdImportUploadQueue(props: {files: Array<FileToImport>, onCancelUpload: (f: FileToImport) => void, onRemoveUpload: (f: FileToImport) => void}) : ReactElement {

  console.log('props.files : ', props.files);

  return (
    <div>
      { (props.files.length > 0) &&
      <h3>Files to upload: <strong style={{color: 'red'}}>(DO NOT CLOSE THIS WINDOW UNTIL ALL UPLOADS ARE COMPLETED OR CANCELED)</strong></h3>
      }
      {
        props.files.map((fileToImport: FileToImport) => {
          return (
            <DdImportFileItem key={fileToImport.file.name} fileInfo={fileToImport} progress={fileToImport.progress} onCancelUpload={props.onCancelUpload} onRemoveUpload={props.onRemoveUpload} />
          );
        })
      }
    </div>
  );
}

export function DdImportFileItem(props: {fileInfo: FileToImport, progress: number |undefined, onCancelUpload: (f: FileToImport) => void, onRemoveUpload: (f: FileToImport) => void}) : ReactElement {
  const { fileInfo } = props;
  const [progress, setProgress] = useState<number | undefined>(undefined);

  function bytesToSize(bytes: number) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i === 0) return bytes + " " + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  }

  let severity: Color;
  if ( (!fileInfo.accepted) || (fileInfo.uploadStatus == 'failed') || (fileInfo.uploadStatus == 'noUpload')) {
    severity = 'error';
  } else if (fileInfo.uploadStatus == 'success') {
    severity = 'success';
  } else {
    severity = 'info';
  }

  let onClose;
  switch(fileInfo.uploadStatus) {
    case 'success':
      onClose = undefined;
      break;
    case 'uploading':
      onClose = () => {props.onCancelUpload(fileInfo)};
      break;
    default:
      onClose = () => {props.onRemoveUpload(fileInfo)};
      break;
  }

  fileInfo.setProgress = setProgress;

  return (
    <Alert severity={severity} onClose={onClose} style={{marginBottom: '3px'}}>
      <AlertTitle><strong>{fileInfo.file.name}</strong> <span>({bytesToSize(fileInfo.file.size)})</span></AlertTitle>
      <div style={{width: "600px"}} >
        <div>
          <span>{fileInfo.uploadStatus}</span>
          { fileInfo.errMsg &&
          <span> ({ fileInfo.errMsg }) </span>
          }
        </div>
        { (fileInfo.uploadStatus == 'uploading'  || fileInfo.uploadStatus == 'success')  && progress && <>
          <div>
          <div style={{width: "90%", paddingRight: '10px', display: 'inline-block'}}>
              {<div className={classes.bar} style={{'backgroundColor': colors[key] || colors['unknown'], 'width': ''+(stats[key] * 100 / totalImports) + '%'}}  key={key} />}
              <div style={{'backgroundColor': '#00000020', 'width': ''+(progress * 100) + '%', float: 'left', height: '10px'}}  />
          </div>
          <span>{'' + Math.floor(progress * 100) + '%'}</span>
          </div>
          </>
        }
      </div>

      {<div>
        <span><b>Total number of included imports:</b>&nbsp;{totalImports} </span>
        <span>( { renderStatsAsText(importGroup) })</span>
      </div>
      <div><ImportGroupStatsBar importGroup={importGroup} /></div>}
    </Alert>
  );
} */

export default GPSLImport;
