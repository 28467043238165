import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { useStyles } from '../ddelement/listitem';
import ScrollTopBtn from '../layout/tools/scrolltop';

export interface Header {
  id: string,
  value: ReactElement
}

export interface Elements {
  id: string,
  values: Array<ReactElement>
}

interface CustomCardsProps {
  headers: Array<Header> | undefined,
  elements: Array<Elements> | undefined
}

function CustomCards(props: CustomCardsProps ): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const scrollHandler = () => {
    if (ref.current && ref.current?.getBoundingClientRect().top < 0 && !isVisible) {
      setIsVisible(true);
    } else if (ref.current && ref.current?.getBoundingClientRect().top >= 0) {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    ref.current?.scrollIntoView({ block: 'start', inline: 'nearest' });
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);

    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 56px - 33px - 54px - 38px - 26px)',
          marginTop: '-8px',
          marginBottom: '0px'
        }}
      >
        { props.headers?.map((header, index) => (
          <Grid
            ref={index === 0 ? ref : undefined}
            key={header.id}
            item
            xs
            style={{
              minWidth: '370px',
              maxWidth: '750px'
            }}
          >
            <Paper elevation={0} className={classes.paper}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  {header.value}
                </Grid>
                {props.elements && props.elements[index].values.map((element, i) => (
                  <Grid item key={props.elements && props.elements[index].id + i}>
                    {element}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        ))
        }
      </Grid>
      <ScrollTopBtn isVisible={isVisible} scrollToTop={scrollToTop}/>
    </>
  );
}

export default CustomCards;
