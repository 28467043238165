import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../../components/params';
import { EntityListDataProvider, ListRendererMap, MultiModeList } from '../../../../components/entity/list';
import SurveyTable from './table';
import SurveyCard from './surveycards';
import { DataSurvey } from '../../../../types/types';
import CustomPagination from '../../../../components/layout/pagination/pagination';
import { SurveyService } from '../../../../services/survey';
import SurveysFilters from './filters';
import TableSkeleton from '../../../../components/layout/loading/tableskeleton';

class SurveyListDataProvider extends EntityListDataProvider<DataSurvey> {
  constructor(searchParamsAccess: SearchParamsAccess) {
    super(searchParamsAccess, new SurveyService());
  }
}

export function SurveyMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new SurveyListDataProvider(searchParamsAccess);

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '40px' }}>No Survey...</div>
    </>
  );

  const renderLoading = () => (
    <TableSkeleton nbColumns={4} />
  );

  const renderAsTable = (eltList: Array<DataSurvey>, totalCount: number) => (
    <>
      <SurveyTable list={eltList} paramsSort={listDataProvider.sortParamsManager} />
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
    </>
  );

  const renderAsCards = (eltList: Array<DataSurvey>, totalCount: number) =>   // eslint-disable-line @typescript-eslint/no-unused-vars
    (
      <>
        <SurveyCard list={eltList} />
        <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
      </>
    );

  const renderers: ListRendererMap<DataSurvey> = {
    'table': renderAsTable,
    'cards': renderAsCards
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['cards', 'table']}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      filtersComponent={SurveysFilters}
    />
  );
}

function SurveyListingPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <SurveyMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default SurveyListingPage;
