import React, { ReactElement, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Snackbar,
  Typography,
  Alert,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { green, yellow } from '@mui/material/colors';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { useTranslation } from 'react-i18next';
import { StyledMenu, useMenuStyle } from '../../layout/navbar/navbar';
import { Nullable, PossibleAction } from '../../../types/types';
import { setUpdateHook } from '../../../hooks/datatabs';
import { BaseEntityService } from '../../../services/data';
import ServiceError from '../../../services/errors';
import CustomButton from '../../button/button';
import { API_BASE_URL } from '../../../utils/env';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';
import ValidationModal from '../../layout/modals/validationmodal';

function HeaderButtons<T>(props: {id: string, service: BaseEntityService<T>, update?: boolean, actionList?: Nullable<PossibleAction[]>}): ReactElement {
  const theme = useTheme();
  const menuClasses = useMenuStyle(theme);
  const { t } = useTranslation();
  const [categories, setCategories] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [possibleActions, setPossibleActions] = useState<Nullable<Array<PossibleAction>>>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [disable, setDisable] = useState(false);
  const [update, setUpdate] = useState(true);
  const [modalOpen, setModalOpen] = useState('');
  const updateHook = setUpdateHook();

  useEffect(() => {
    if (props.actionList) {
      props.actionList.length > 0 && update ? setPossibleActions(props.actionList) : update && props.service.getPossibleActions(props.id)
        .then((apiResp) => {
          apiResp.data && setPossibleActions(apiResp.data.filter(item => item.enabled));
          setUpdate(false);
        })
        .catch((err) => {
          console.log(ServiceError.ensureServiceError(err));
          setSnackbarOpen(true);
          setUpdate(false);
        });
    }
  }, [props.id, update]);

  useEffect(() => {
    setUpdate(true);
  }, [props.update]);

  useEffect(() => {
    const myCategories: string[] = [];

    possibleActions?.forEach((action) => {
      if (action.scope && !myCategories.includes(action.scope)) {
        myCategories.push(action.scope);
      }
    });
    setCategories(myCategories);
  }, [possibleActions]);

  const doAction = (id: string, action: PossibleAction) => {
    setModalOpen('');
    setAnchorEl(null);
    setDisable(true);
    if (action.call) {
      props.service.makeActions(id, action.call)
        .then(() => {
          setTimeout(() => {
            setUpdate(true);
            updateHook(true);
            setDisable(false);
          }, 3000);
        })
        .catch((err) => {
          console.log(ServiceError.ensureServiceError(err));
          setSnackbarOpen(true);
          setDisable(false);
        });
    } else if (action.download) {
      window.open(API_BASE_URL + action.download.url, '_blank');
      setDisable(false);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenu = (id: string, possibleDdActions?: Array<PossibleAction>) => (
    <StyledMenu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Grid container spacing={1}>
        { possibleDdActions && possibleDdActions.map((action) => {
          if (!action.scope) {
            return (
              <Grid key={action.id} item xs={12}>
                <MenuItem className={menuClasses.menuItem} onClick={() => setModalOpen(action.id)}>
                  {action.label}
                </MenuItem>
                <ValidationModal
                  action={() => doAction(id, action)}
                  cancel={() => setModalOpen('')}
                  open={modalOpen === action.id}
                  msg={action.confirmText || `${t('dds.header.actionBtns.validationModalMsg')} ${action.label} ?`}
                />
              </Grid>
            );
          }

          return undefined;
        })}
        {categories.map(category => (
          <Grid key={category} item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ padding: '6px 16px' }}>{`${capitalizeFirstLetter(category)} :`}</Typography>
              </Grid>
              { possibleDdActions && possibleDdActions.map((action) => {
                if (action.scope === category) {
                  return (
                    <Grid item xs={12} key={action.id}>
                      <MenuItem className={menuClasses.menuItem} onClick={() => setModalOpen(action.id)}>
                        {action.label}
                      </MenuItem>
                      <ValidationModal
                        action={() => doAction(id, action)}
                        cancel={() => setModalOpen('')}
                        open={modalOpen === action.id}
                        msg={action.confirmText || `${t('dds.header.actionBtns.validationModalMsg')} ${action.label} ?`}
                      />
                    </Grid>
                  );
                }

                return undefined;
              })}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </StyledMenu>
  );

  return <>
    { possibleActions && possibleActions.length > 0 &&
    <Grid item style={{ textAlign: 'end', marginRight: '8px' }}>
      <Grid container spacing={2} style={{ overflow: 'auto', marginLeft: 'auto' }}>
        {disable &&
          <Grid item>
            <CircularProgress size={24} style={{ marginTop: '8px' }} />
          </Grid>
        }
        <Grid item xs>
          <CustomButton
            onClick={() => setModalOpen(possibleActions[0].id)}
            style={{
              backgroundColor: !disable && (possibleActions[0].type === 'success' && green[500] || possibleActions[0].type === 'warning' && yellow[500]) || undefined,
              marginLeft: 'auto',
              whiteSpace: 'nowrap'
            }}
            variant={possibleActions[0].type === 'neutral' ? 'outlined' : 'contained'}
            color={possibleActions[0].type === 'error' && 'secondary' || 'primary'}
            startIcon={possibleActions[0].id === 'validate' && <CheckRoundedIcon />}
            disabled={disable}
            small
          >
            {possibleActions[0].scope ? `${capitalizeFirstLetter(possibleActions[0].scope)} : ${possibleActions[0].label}` : possibleActions[0].label}
          </CustomButton>
          <ValidationModal
            action={() => doAction(props.id, possibleActions[0])}
            cancel={() => setModalOpen('')}
            open={modalOpen === possibleActions[0].id}
            msg={`A${t('dds.header.actionBtns.validationModalMsg')} ${possibleActions[0].label} ?`}
          />
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <IconButton
            style={{ width: '38px', height: '38px', color: 'grey', marginRight: '8px' }}
            onClick={handleMenuOpen}
            size='large'
            disabled={disable}
          >
            <MoreVertRoundedIcon/>
          </IconButton>
          {renderMenu(props.id, possibleActions)}
        </Grid>
      </Grid>
    </Grid>
    }
    <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
        {t('dds.header.actionBtns.errorMsg')}
      </Alert>
    </Snackbar>
  </>;
}

export default HeaderButtons;
