import React, { ReactElement, useState } from 'react';
import HeaderTitle from '../../../components/modularcomponents/header/headertitle';
import { InvestMgr, Nullable, PossibleAction } from '../../../types/types';
import HeaderContainer from '../../../components/modularcomponents/header/headercontainer';
import HeaderSummary from '../../../components/modularcomponents/header/headersummary';
import HeaderItems from '../../../components/modularcomponents/header/headeritems';
import HeaderItem from '../../../components/modularcomponents/header/headeritem';
import HeaderProperty from '../../../components/modularcomponents/header/headerproperty';
import HeaderPropertyName from '../../../components/modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../../../components/modularcomponents/header/headerpropertyvalue';
import HeaderAUM from '../../../components/modularcomponents/header/headeraum';
import HeaderRating from '../../../components/headerdata/headerrating';
import HeaderButtons from '../../../components/modularcomponents/header/headerbuttons';
import { AssetMgrService } from '../../../services/assetmgr';

function InvestMgrHeader(props: {entity: Nullable<InvestMgr>}): ReactElement {
  const [expand, setExpand] = useState(false);
  const actionList: PossibleAction[] | undefined = undefined; // [] // replace undefined by [] to enable action buttons;

  const toggleExpand = () => {
    setExpand(val => !val);
  };

  return (
    <>
      { props.entity &&
        <HeaderContainer expanded={expand} setExpanded={setExpand}>
          <HeaderSummary toggleExpand={toggleExpand}>
            <HeaderTitle toggleExpand={toggleExpand} type='assetMgr' name={props.entity.name} tags={props.entity.tags} />
            <HeaderButtons id={props.entity._id} service={new AssetMgrService()} actionList={actionList} />
          </HeaderSummary>
          <HeaderItems>
            <HeaderItem>
              <HeaderProperty>
                <HeaderAUM aum={props.entity.aum} />
              </HeaderProperty>
              <HeaderProperty>
                <HeaderPropertyName name='Country ' />
                <HeaderPropertyValue>{props.entity.country}</HeaderPropertyValue>
              </HeaderProperty>
            </HeaderItem>
            <HeaderItem>
              <HeaderProperty>
                <HeaderPropertyName name='Number of Funds ' />
                <HeaderPropertyValue>{props.entity.funds.length.toString()}</HeaderPropertyValue>
              </HeaderProperty>
              <HeaderProperty>
                <HeaderPropertyName name='Number of Mandates ' />
                <HeaderPropertyValue>{props.entity.mandates.length.toString()}</HeaderPropertyValue>
              </HeaderProperty>
              <HeaderProperty>
                <HeaderPropertyName name='Number of Strategies ' />
                <HeaderPropertyValue>{props.entity.strategies.length.toString()}</HeaderPropertyValue>
              </HeaderProperty>
            </HeaderItem>
            <HeaderItem>
              <HeaderRating rating={props.entity.ratings} nextReviewDate={props.entity.nextReviewDate} entityType={props.entity.latestDdInfo && !props.entity.latestDdInfo.validationDate ? props.entity.latestDdInfo.type : undefined} />
            </HeaderItem>
          </HeaderItems>
        </HeaderContainer>
      }
    </>
  );
}

export default InvestMgrHeader;
