import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { SurveyContextProvider, PathHook, SetUpdateHookContextProvider } from '../hooks/datatabs';
import { Nullable, DataSurvey } from '../types/types';
import { surveysTabs } from '../utils/tabs';
import SurveysHeader from '../containers/auditee/surveys/pageheader';
import DisplayTabs from '../components/layout/tabs/tabs';
import ServiceError from '../services/errors';
import ErrorPageManager from '../components/Error/errorpagemanager';
import { SurveyService } from '../services/survey';
import TabsRouter from './tabsrouter';

function SurveyRouter(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Nullable<DataSurvey>>(null);
  const surveyService = new SurveyService();
  const [update, setUpdate] = useState(true);
  const [error, setError] = useState<Nullable<ServiceError>>(null);

  useEffect(() => {
    if (update && id) {
      surveyService.getEntity(id)
        .then((apiResp) => {
          setData(apiResp.data);
          setUpdate(false);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
          setUpdate(false);
        });
    }
  }, [id, update]);

  const pathName = `${data?.auditedEntity?.name  } - ${  data?.type  } - ${  data?.startDate}`;
  PathHook(pathName);

  return (
    <SetUpdateHookContextProvider value={setUpdate}>
      <Grid container spacing={2}>
        {error ? <ErrorPageManager error={error} /> :
          <SurveyContextProvider value={data}>
            <Grid item xs={12}>
              <SurveysHeader entity={data} />
            </Grid>
            <Grid item xs={12}>
              <DisplayTabs tabs={surveysTabs} />
            </Grid>
            <Grid item xs={12}>
              <TabsRouter tabs={surveysTabs} />
            </Grid>
          </SurveyContextProvider>
        }
      </Grid>
    </SetUpdateHookContextProvider>
  );
}

export default SurveyRouter;
