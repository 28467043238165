import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { AmountType } from '../../../types/types';

export default function AmountFieldViewer(props: {value?: AmountType, trunc?: boolean, fontWeight?: number}): ReactElement {
  const addCommas = (num: { toString: () => string }): string => {
    if (num.toString().split('.').length > 1) {
      return `${num.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')  }.${
        num.toString().split('.')[1]}`;
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };
  const removeNonNumeric = (num: { toString: () => string }): string => (num ? num.toString().replace(/[^0-9-.]/g, "") : '');
  let defaultValue = props.value && props.value.amount ? parseFloat(removeNonNumeric((props.value as AmountType).amount)) : undefined;
  defaultValue = props.trunc && defaultValue ? Math.trunc(defaultValue) : defaultValue;
  const getUnit = (value: number) => {
    if (value % 1e12 === 0) {
      return ('T');
    }
    if (value % 1e9 === 0) {
      return ('B');
    }
    if (value % 1e6 === 0) {
      return ('M');
    }
    if (value % 1e3 === 0) {
      return ('K');
    }

    return undefined;
  };
  const getNumber = (value: number) => {
    let result = value;
    switch (getUnit(value)) {
    case 'T':
      result = (value / 1e12);
      break;
    case 'B':
      result = (value / 1e9);
      break;
    case 'M':
      result = (value / 1e6);
      break;
    case 'K':
      result = (value / 1e3);
      break;
    default:
      result = value;
      break;
    }

    return result;
  };

  return (
    <div>
      <Typography variant='body1' component='p' fontWeight={props.fontWeight}>
        { defaultValue &&
          [
            addCommas(getNumber(defaultValue).toString()),
            `${getUnit(defaultValue) || ''  } `,
            props.value?.currency || ''
          ]
        }
      </Typography>
    </div>
  );
}
