import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Avatar, Paper, Snackbar, Tooltip, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CustomButton, { CustomIconButton } from '../../button/button';
import { Participant, AuditedEntity, Nullable } from '../../../types/types';
import { AbstractParticipantsAssignmentsService } from '../../../services/contacts';
import CustomTable, { Column } from '../../table/table';
import AddParticipant from './addparticipant';
import { useContactListStyles } from '../contactsTable';
import { ParticipantsRolesInList } from './participantsroles';
import { setUpdateHook } from '../../../hooks/datatabs';
import checkRoles from '../../authentification/checkRoles';

function ParticipantsTable(props: {participantsList: Array<Participant>, setUpdate: Dispatch<SetStateAction<boolean>>, service: AbstractParticipantsAssignmentsService<Participant>, auditedEntity: AuditedEntity}): ReactElement {
  const theme = useTheme();
  const classes = useContactListStyles(theme);
  const setUpdateDd = setUpdateHook();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [addParticipantOpen, setAddParticipantOpen] = useState(false);
  const [editionMode, setEditionMode] = useState<Nullable<Participant>>(null);
  const [rolesSelected, setRolesSelected] = useState<Array<string>>([]);
  const participantRoles = props.participantsList.filter(participant => participant.id === keycloak.subject).flatMap(participant => participant.roles);
  const canAddParticipant = checkRoles(['owner', 'manager', 'admin', 'developer'], keycloak)
    || participantRoles.some(role => role === 'owner' || role === 'manager');

  const columns: Array<Column> = [
    { id: 'avatar', label: '', minWidth: 'min-content' },
    { id: 'name', label: t('utils.name'), minWidth: 'min-content' },
    { id: 'email', label: t('utils.email'), minWidth: 'min-content' },
    { id: 'phoneNumber', label: t('utils.phone'), minWidth: 'min-content' },
    { id: 'title', label: t('utils.title'), minWidth: 'min-content' },
    { id: 'roles', label: t('contactsAndParticipants.participants.roles'), minWidth: 'min-content' },
    { id: 'actions', label: '', minWidth: 'max-content', align: 'right' }
  ];

  const handleCancelEdit = (participant: Participant) => {
    setRolesSelected(participant.roles);
    setEditionMode(null);
  };

  const handleValidateEdit = (participant: Participant) => {
    if (participant.roles !== rolesSelected) {
      props.service.editRoles(participant.id, rolesSelected)
        .then((apiResp) => {
          if (apiResp.data?.id === participant.id) {
            setUpdateDd(true);
            props.setUpdate(true);
          }
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${err.message}`);
          setSnackbarOpen(true);
        });
    }
    setEditionMode(null);
  };

  const handleEdit = (participant: Participant) => {
    if (canAddParticipant) {
      setRolesSelected(participant.roles);
      setEditionMode(participant);
    }
  };

  const handleRemove = (participantToRemove: Participant) => {
    props.service.remove(participantToRemove.id)
      .then((apiResp) => {
        setEditionMode(null);
        apiResp.data?.id === participantToRemove.id && props.setUpdate(true);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  const rows = props.participantsList.map(participant => ({
    id: participant.id,
    value: columns.map(column => (
      <>
        {column.id === 'avatar' &&
          <Avatar className={classes.avatar} alt={`${participant.firstName}.${participant.lastName}`} src=''>
            {participant.firstName && participant.firstName.charAt(0) + participant.lastName && participant.lastName.charAt(0) || ''}
          </Avatar>
        }
        {column.id === 'name' && `${participant.firstName} ${participant.lastName}`}
        {column.id === 'title' && participant[column.id] }
        {column.id === 'email' && participant[column.id] }
        {column.id === 'phoneNumber' && participant[column.id] }
        {column.id === 'roles' &&
          <ParticipantsRolesInList
            participant={participant}
            editionMode={editionMode === participant}
            rolesSelected={rolesSelected}
            setRolesSelected={setRolesSelected}
          />
        }
        { column.id === 'actions' &&
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {editionMode === participant ?
              <>
                <Tooltip title={t('btns.apply')} arrow>
                  <div style={{ marginRight: '12px' }}>
                    <CustomIconButton
                      variant='text'
                      color='primary'
                      sx={ { color: 'white', backgroundColor: 'success.light', "&:hover": { color: 'white', backgroundColor: 'success.light' } } }
                      onClick={() => handleValidateEdit(participant)}
                      small
                    >
                      <DoneRoundedIcon />
                    </CustomIconButton>
                  </div>
                </Tooltip>
                <Tooltip title={t('btns.cancel')} arrow>
                  <div style={{ marginRight: '12px' }}>
                    <CustomIconButton
                      variant='text'
                      color='default'
                      onClick={() => handleCancelEdit(participant)}
                      small
                    >
                      <CloseRoundedIcon />
                    </CustomIconButton>
                  </div>
                </Tooltip>
              </> :
              <>
                {canAddParticipant &&
                  <Tooltip title={t('btns.edit')} arrow>
                    <div style={{ marginRight: '12px' }}>
                      <CustomIconButton
                        variant='contained'
                        onClick={() => handleEdit(participant)}
                        disabled={addParticipantOpen}
                        small
                      >
                        <EditRoundedIcon />
                      </CustomIconButton>
                    </div>
                  </Tooltip>
                }
              </>
            }
            {canAddParticipant &&
              <Tooltip title='Remove' arrow>
                <div style={{ marginRight: '12px' }}>
                  <CustomIconButton
                    variant='contained'
                    color='secondary'
                    small
                    onClick={() => handleRemove(participant)}
                  >
                    <RemoveRoundedIcon />
                  </CustomIconButton>
                </div>
              </Tooltip>
            }
          </div>
        }
      </>
    ))
  }));

  const handleAdd = () => {
    setAddParticipantOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={addParticipantOpen ? classes.tableWithEditContact : classes.table}>
          { props.participantsList.length > 0 ?
            <CustomTable columns={columns} rows={rows} size='medium' /> :
            <>{t('contactsAndParticipants.participants.noParticipant')}</>
          }
          {canAddParticipant &&
            <div style={{ textAlign: 'right' }}>
              <CustomButton
                disabled={addParticipantOpen || !!editionMode}
                key='add'
                variant='contained'
                onClick={handleAdd}
              >
                {t('contactsAndParticipants.participants.addParticipant')}
              </CustomButton>
            </div>
          }
        </div>
        <div className={ addParticipantOpen ? classes.edit : classes.editEmpty}>
          {addParticipantOpen &&
            <Paper elevation={0} className={classes.sideContainer}>
              <AddParticipant
                participants={props.participantsList}
                participantsService={props.service}
                auditedEntity={props.auditedEntity}
                setUpdate={props.setUpdate}
                onClose={() => setAddParticipantOpen(false)}
              />
            </Paper>}
        </div>
        <Snackbar onClose={handleCloseSnackbar} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleCloseSnackbar} style={{ marginTop: '20px' }} severity='error'>
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default ParticipantsTable;
