import React, { ReactElement } from "react";
import TextField from '@mui/material/TextField';

function Datefieldviewer(props: {value?: string}) : ReactElement {
  return (
    <TextField
      variant='standard'
      disabled
      id='date'
      type='date'
      value={props.value}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
}
export default Datefieldviewer;
