import React, { ReactElement, useState } from "react";
import CardActions from '@mui/material/CardActions';
import BooleanFieldEditor from '../../field/editors/booleanfieldeditor';
import QuestionBox from "../box";
import QuestionValue from "../value";
import { QuestionEditorProps, Value, BooleanQuestionSpec } from '../../../types/types';
import QuestionEditorButton from "../questioneditorbutton";
import QuestionLabel from '../label';

function BooleanQuestionEditor(props: QuestionEditorProps<BooleanQuestionSpec>) : ReactElement {
  const [value, setValue] = useState<Value>(props.answer?.value);

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <BooleanFieldEditor value={value as boolean} setValue={setValue}/>
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} />
      </CardActions>
    </QuestionBox>
  );
}
export default BooleanQuestionEditor;
