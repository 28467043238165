import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function MandatesIcon(props: SvgIconProps): ReactElement {
  return (
    <div style={{ height: '24px', width: '24px' }}>
      <SvgIcon {...props} version='1.0' xmlns='http://www.w3.org/2000/svg' width='341.3' height='341.3' viewBox='0 0 256 256'>
        <path d='M168 1l-7 13 7 1 9 1c2 1 2 1 2-6 0-6 0-7-3-8-3-2-5-2-8-1zM210 2c-3 2-3 3-3 9s0 6 2 5l9-1 7-1-6-13c-2-2-7-1-9 1zM158 23c-6 2-11 5-12 8l-3 9-3 7 7-3c13-4 26-1 36 8l5 4v-3c0-3 0-3 5-3s5 0 5 2l1 3 4-4c10-8 23-11 36-7l7 3-3-7-3-9c-3-7-20-11-29-8-4 2-12 10-12 12 0 1-1 2-5 2-5-1-6-1-8-4l-7-7c-5-3-6-4-12-4l-9 1z'/>
        <path d='M1 136c0 118 0 111 7 117l3 3h172l-2-4-2-4-1-70v-69l-6 3c-6 2-8 2-15 2s-10-1-14-3c-12-6-20-18-21-31 0-7 0-10 10-34l6-16-69-1H1v107zm106-89c2 1 3 5 1 7H15c-1-2-1-5 1-6 1-2 7-2 45-2l46 1zM87 71c2 1 3 4 1 6H15c-2-2-1-5 1-6 2-2 7-2 36-2 28 0 33 0 35 2zm12 22c2 1 3 5 1 7H15c-2-2-1-5 1-6s9-2 41-2l42 1zm-19 25v4c-1 2-2 2-32 2-32 0-34 0-34-4s2-4 34-4c30 0 31 0 32 2zm42 14l1 11c-1 8-2 11-5 11-2 0-5-4-5-6l1-1-13 11-12 11H61l-14 15c-13 14-16 16-19 13s-1-6 15-21l16-16h28l11-10c8-7 10-10 8-10l-3-1c-2-2-1-6 1-7 2-2 16-1 19 0zm14 37l1 32v30h3l4 3c1 1 1 2-1 4l-1 2H77l-67-1c-2-1-3-5-1-7l5-1h4v-11c0-15 0-15 13-15l10 1 1 13v12h8v-19l1-20 11-1 10 1 1 20v19h8v-26l2-27h21l1 27v26h8v-31l1-33 11-1c10 0 10 0 11 3z'/>
        <path d='M121 203v29h8v-58h-8v29zM89 209v23h8v-47h-8v24zM58 216v16h8v-33h-8v17zM26 223v9h8v-19h-8v10zM148 52c-28 13-19 54 11 53 8 0 13-2 19-7 6-6 8-12 8-20 1-20-20-34-38-26zm19 8c7 3 11 9 11 18 0 11-8 19-19 19-9 0-15-4-18-12-8-16 9-33 26-25z'/>
        <path d='M155 66l-5 4c-5 6-3 16 4 19 4 1 7 1 11-1 12-7 4-25-10-22zM217 52c-14 5-22 23-15 37 3 6 9 12 15 15 6 2 16 2 22-1 22-11 21-41 0-51-6-2-15-3-22 0zm18 7c10 5 15 16 10 26-2 5-6 9-10 11-5 2-13 1-17-1-11-5-13-21-5-30 6-6 14-9 22-6z'/>
        <path d='M221 68c-3 1-6 7-6 10 0 4 4 9 7 11 5 2 11 1 14-3 3-3 3-4 3-8 0-5 0-6-3-9-3-2-4-3-8-3l-7 2zM187 215c0 33 0 33 6 38s13 3 17-4c2-3 2-4 3-34v-31h-26v31z'/>
      </SvgIcon>
    </div>
  );
}
