import React, { ReactElement, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid, Paper, Snackbar, Typography, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CustomButton from '../button/button';
import { AumContributor } from '../../types/types';
import AumService from '../../services/aum';
import TagIcon from './tagicon';
import { roundValue } from '../../utils/computations';
import ServiceError from '../../services/errors';
import { mainRadius } from '../../themes/theme';

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell": {
      outline: "none"
    }
  }
});

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    disableColumnMenu: true,
    maxWidth: 0
  },
  {
    field: 'label',
    headerName: 'Label',
    flex: 3,
    filterable: true,
    editable: false
  },
  {
    field: "identifier",
    headerName: "Identifier",
    flex: 1,
    minWidth: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    minWidth: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: "tags",
    headerName: "GPSL",
    flex: 1,
    type: 'boolean',
    editable: false,
    description: "Golden Pride Savings and Loans Banking",
    renderCell: cellValues => (
      <TagIcon val={cellValues.row.tags.GPSL} />
    ),
    valueGetter: cellValues => cellValues.row.tags.GPSL
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 2,
    type: 'number',
    renderCell: cellValues => (
      <div
        style={{
          fontWeight: 'bold'
        }}
      >
        {roundValue(cellValues.row.amount.amount)} {cellValues.row.amount.currency}
      </div>
    ),
    valueGetter: cellValues => cellValues.row.amount.amount
  }
];

function AumDataGrid(props: { list: AumContributor[], aumService: AumService, handleClose: () => void, update: () => void}): ReactElement {
  const classes = useStyles();
  const [data, setData] = useState<AumContributor[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedIds, setSelectedIds] = useState<string[]>(props.list.map(contributor => contributor.identifier));
  const [loading, setLoading] = useState(true);

  const getData = () => {
    props.aumService.getAllAUM()
      .then((apiResp) => {
        if (apiResp.data) {
          setData(apiResp.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const handleSave = () => {
    props.aumService.setAUM([...data.filter(myData => selectedIds.find(identifier => identifier === myData.identifier))])
      .then(() => {
        props.update();
        props.handleClose();
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
      });
  };

  const getAUMFromId = (aumId: string) => {
    const getListIndex = data.findIndex(aum => aum.identifier === aumId);

    return data[getListIndex];
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ height: '600px' }}>
          <DataGrid
            style={{ overflowY: 'auto', borderRadius: mainRadius }}
            getRowId={row => row.identifier}
            className={classes.root}
            rows={data}
            rowSelectionModel={selectedIds}
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              loading ? props.list.map(contributor => contributor.identifier) : setSelectedIds(newSelection as string[]);
            }}
            loading={loading}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              },
              columns: {
                ...data,
                columnVisibilityModel: {
                  id: false
                }
              }
            }}
            pageSizeOptions={[10, 20, 50, 100]}
          />
        </Grid>
        <Grid item xs={12}>
          { selectedIds.length > 0 ?
            selectedIds.map(myId => (
              <Paper
                key={myId as string}
                elevation={0}
                style={{
                  marginRight: '8px',
                  marginBottom: '8px',
                  padding: '8px',
                  backgroundColor: '#d9e4f4',
                  borderRadius: '16px',
                  display: 'inline-block'
                }}
              >
                <Typography variant='h3'>{getAUMFromId(myId as string)?.label || ''}</Typography>
                <Typography style={{ fontSize: '12px' }}>{getAUMFromId(myId as string)?.identifier || ''}</Typography>
              </Paper>
            ))
            :
            <Typography variant='h3'>
              No AUM selected
            </Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <CustomButton style={{ marginBottom: '14px' }} onClick={handleSave} variant ='contained' color='primary' disabled={!selectedIds.length} fullWidth>Add Selection</CustomButton>
        </Grid>
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AumDataGrid;
