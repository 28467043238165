import React, { ReactElement } from 'react';
import AmountFieldViewer from '../../field/viewers/amountfieldviewer';
import { AmountType, Answer, QuestionSpec, Nullable, ButtonsSettings } from '../../../types/types';
import QuestionBox from "../box";
import QuestionValue from "../value";
import QuestionLabel from '../label';
import QuestionEditorButton from '../questioneditorbutton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AmountQuestionViewer(props: {spec: QuestionSpec, answer: Nullable<Answer>, setAnswer?: (answer: Nullable<Answer>) => void, buttonsSettings?: ButtonsSettings, disableInput?: string}): ReactElement {
  const setValue = (value: Nullable<Answer['value']>) => {
    if (props.answer && props.setAnswer) {
      props.setAnswer({
        ...props.answer,
        value
      });
    }
  };
  
  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <AmountFieldViewer value={props.answer?.value as AmountType}/>
      </QuestionValue>
      {props.setAnswer && props.buttonsSettings &&
        <QuestionEditorButton response={props.answer?.value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} previousAnswer />
      }
    </QuestionBox>
  );
}

export default AmountQuestionViewer;
