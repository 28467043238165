import React, { ReactElement, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Theme } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import secureTab from '../../authentification/securetab';
import { mainRadius } from '../../../themes/theme';
import { CustomIconButton } from '../../button/button';
import { TabsInfo, TabSpecs } from '../../../types/types';

export const useTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      width: 'max-content',
      maxWidth: '100%',
      minHeight: '32px'
    },
    scrollButtons: {
      borderRadius: mainRadius,
      width: '32px'
    },
    scroller: {
      borderRadius: mainRadius
    },
    tabsIndicator: {
      height: '32px',
      zIndex: 2,
      backgroundColor: theme.palette.primary.main || 'rgba(0, 0, 0, 0.8)',
      borderRadius: mainRadius
    },
    tab: {
      position: 'relative',
      zIndex: 3,
      minWidth: '0px',
      paddingRight: '24px',
      paddingLeft: '24px',
      borderRadius: mainRadius,
      textTransform: 'none',
      minHeight: '32px',
      height: '32px',
      color: 'inherit',
      transition: 'all 0.2s ease-in-out'
    },
    selectedTab: {
      color: `${theme.palette.primary.contrastText  } !important`,
      transition: 'all 0.2s ease-in-out'
    }
  })
);

function DynamicTabs({ tabsInfo, tabSpecs }: {tabsInfo: TabsInfo, tabSpecs: TabSpecs}): ReactElement {
  const { pathname, search } = useLocation();
  const params = useParams();
  const { keycloak } = useKeycloak();
  const theme = useTheme();
  let tabId = params['*']?.split('/')[0] || '';
  let tabNb = tabsInfo.tabs.findIndex(t => (t ? t.id === tabId : 'summary'));
  const [value, setValue] = React.useState(tabNb > 0 ? tabNb : 0);
  const navigate = useNavigate();
  const classes = useTabsStyles(theme);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
    navigate(`${tabsInfo.tabs[newValue]?.id || 'summary'}`);
  };

  useEffect(() => {
    tabId = params['*']?.split('/')[0] || '';
    tabNb = tabsInfo.tabs.findIndex(t => (t ? t.id === tabId : 'summary'));

    if (value !== tabNb && tabNb > -1) {
      setValue(tabNb);
    }
  }, [params]);

  return (
    <Grid container spacing={4} style={{ flexWrap: 'nowrap' }}>
      <Grid item xs={11}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          className={classes.tabs}
          variant='scrollable'
          classes={{ scroller: classes.scroller }}
          TabScrollButtonProps={{ className: classes.scrollButtons }}
          TabIndicatorProps={{
            className: classes.tabsIndicator
          }}
        >
          {tabsInfo.tabs.map((tabInfo) => {
            const tabSpec = tabSpecs.find(e => e.id === tabInfo?.id);

            return (
              (tabSpec && secureTab(tabSpec.label, keycloak)) ?
                <Tab key={tabSpec.id} label={tabSpec.label} className={classes.tab} classes={{ selected: classes.selectedTab }} disableRipple /> :
                undefined
            );
          }).filter(x => !!x)}
        </Tabs>
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'end' }}>
        <CustomIconButton
          variant='text'
          onClick={() => window.open(`/deeligenz/${pathname}${search}`, '_blank', '')}
          small
        >
          <OpenInNewRoundedIcon />
        </CustomIconButton>
      </Grid>
    </Grid>
  );
}

export default DynamicTabs;
