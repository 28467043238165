import React, { ReactElement } from 'react';
import { investmgrContext } from '../../../hooks/datatabs';
import { AssetMgrService } from '../../../services/assetmgr';
import FundAndMandateList from '../../strategies/pages/fundsandmandates/fundandmandatelist';

function AssetMgrFundAndMandateList(): ReactElement {
  const investMgrData = investmgrContext();
  const dataProvider = new AssetMgrService();

  return (
    <>
      {investMgrData && investMgrData._id &&
        <FundAndMandateList dataProvider={dataProvider} entityId={investMgrData._id} entityTypeText='asset manager' />
      }
    </>
  );
}

export default AssetMgrFundAndMandateList;
