import 'date-fns';
import React, { ReactElement } from "react";
import TextField from "@mui/material/TextField";
import { ValueSetter } from '../../../types/types';

function DateFieldEditor(props: {value?: string, setValue: ValueSetter, required?: boolean, customKey?: string}) : ReactElement {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.setValue(event.target.value);
  };
  let response;
  if (props.value) {
    response = props.value;
  } else {
    response = "";
  }

  return (
    <TextField
      error={props.required ? !props.value : false}
      key={props.customKey}
      variant='standard'
      id='date'
      type='date'
      value={response}
      InputLabelProps={{
        shrink: true
      }}
      onChange={event => handleChange(event)}
    />
  );
}
export default DateFieldEditor;
