import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Toolbar from '../toolbar/Toolbar';
import Rating from '../../ratings/rating';
import RatingScoreTendancy from './RatingScoreTendancy';

interface RatingProps{
	previousValue?: number | string,
	currentValue?: number | string,
	computedValue?: number | string,
	type: string,
	displayAsLetter?: boolean
}

interface ReportBlockRatingProps extends RatingProps {
	isEditable?: boolean,
	onSave: (value: number | undefined) => void
}

function ReportBlockRating(props: ReportBlockRatingProps): ReactElement {
  const { t } = useTranslation();
  const { isEditable } = props;
  const { onSave } = props;
  const initialValue = (props.currentValue !== undefined) ? `${  props.currentValue}` : (props.previousValue ? `${  props.previousValue}` : undefined);
  const [rank, setRank ] = useState(initialValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaveNeeded, setIsSaveNeeded] = useState<boolean>(false);

  const editAction = ( ) => {
    setIsEditing(true);
  };
  const cancelAction = () => {
    setIsEditing(false);
    setRank(initialValue);
  };

  const saveAction = () => {
    if (rank) {
      onSave( parseInt(rank) );
    } else {
      onSave( undefined );
    }
    setIsEditing(false);
  };

  const scoreUpdate = (action: string): void => {
    const scoreN = ['', '1', '2', '3', '4', '5'];
    const scoreL= ['', 'A', 'B', 'C', 'D', 'E'];
    let value = 0;
    if (rank !== undefined){
      if ( scoreN.indexOf(rank) > -1 ){
        value = scoreN.indexOf(rank);
      }
      if ( scoreL.indexOf(rank) > -1 ){
        value = scoreL.indexOf(rank);
      }
    }

    switch (action){
    case '+' :
      if (value >= 5) {
        return;
      }
      setRank((value + 1).toString());
      break;
    case '-' :
      if (value <= 0) {
        return;
      }
      setRank((value - 1).toString());
      break;
    default:
      break;
    }
  };

  useEffect(() => {
    if (initialValue === undefined && rank === '0'){
      setIsSaveNeeded(false);
    } else {
      setIsSaveNeeded( rank !== initialValue);
    }
  }, [rank, initialValue]);

  return (
    <>
      <Grid container>
        <Grid
          style={{ maxWidth: 'calc(100% - 54px)', flexBasis: 'calc(100% - 54px)', justifyContent: 'center' /* borderRight: '1px solid grey' */ }}
        >
          <Grid container direction='column' alignItems='flex-start' style={{ width: '80px' }} spacing={2}>
            { props.previousValue &&
              <Grid item style={{ width: '100%' }}>
                <Grid container direction='column' alignItems='center'>
                  <Grid item>
                    <Rating rank={props.previousValue.toString()} displayAsLetter={props.displayAsLetter} isComputed/>
                  </Grid>
                  <Grid item>
                    <Typography variant='caption'>
                      {t('dds.tabs.reports.blocks.previous')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
            { props.computedValue &&
              <Grid item style={{ width: '100%' }}>
                <Grid container direction='column' alignItems='center'>
                  <Grid item>
                    <Rating rank={props.computedValue.toString()} displayAsLetter={props.displayAsLetter} isComputed withIcon/>
                  </Grid>
                  <Grid item>
                    <Typography variant='caption'>
                      {t('dds.tabs.reports.blocks.automatic')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
            { isEditing && props.computedValue &&
              <Grid item style={{ width: '100%', paddingLeft: '24px', marginTop: '-12px', marginBottom: '-8px' }}>
                <Tooltip
                  title={t('dds.tabs.reports.blocks.applyAutomaticToFinal')}
                  onClick={() => setRank(props.computedValue?.toString() || initialValue)}
                  arrow
                  placement='top'
                >
                  <IconButton aria-label='edit mode' size='small'>
                    <SyncRoundedIcon fontSize='medium' />
                  </IconButton>
                </Tooltip>
              </Grid>
            }
            <Grid item style={{ width: '100%' }}>
              <Grid container direction='column' justifyContent='center' alignItems='center'>
                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1} justifyContent='center' style={isEditing ? { width: '110px' } : undefined}>
                    <Grid item>
                      <Grid container direction='column' alignItems='center'>
                        <Grid item>
                          <Rating rank={rank} displayAsLetter={props.displayAsLetter}/>
                        </Grid>
                        <Grid item>
                          <Typography variant='caption'>
                            {t('dds.tabs.reports.blocks.final')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    { isEditing &&
                      <Grid item style={{ marginTop: '-12px' }}>
                        <Grid container direction='column'>
                          <Grid item>
                            <Tooltip title={t('dds.tabs.reports.blocks.incrementScore')} onClick={() => scoreUpdate('+')} arrow>
                              <IconButton aria-label='edit mode' size='small'>
                                <AddCircleIcon fontSize='medium' />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title={t('dds.tabs.reports.blocks.decrementScore')} onClick={() => scoreUpdate('-')} arrow>
                              <IconButton aria-label='edit mode' size='small'>
                                <RemoveCircleIcon fontSize='medium' />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Grid container justifyContent='center'>
                <Grid item>
                  <RatingScoreTendancy previousValue={props.previousValue} currentValue={props.currentValue} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ maxWidth: '54px', flexBasis: '54px' }}>
          <Toolbar
            isEditable={ isEditable }
            isEditing={ isEditing }
            editAction={ editAction }
            cancelAction={ cancelAction }
            saveAction={ saveAction}
            isSaveNeeded={isSaveNeeded}
            isNoSplitVisible
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ReportBlockRating;
