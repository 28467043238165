import React, { ReactElement, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Checkbox, Input, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SelectQuestionSpec, ValueSetter } from '../../../types/types';

export default function MultiSelectFieldEditor(props: {specs: SelectQuestionSpec, value?: string[], setValue: ValueSetter, customKey?: string}) : ReactElement {
  const [value, setValue] = useState<string[]>(props.value || []);

  useEffect(() => {
    setValue(props.value || []);
  }, [props.value]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setValue(event.target.value as string[]);
    props.setValue((event.target.value as string[]).length > 0 ? event.target.value as string[] : undefined);
  };

  return (
    <FormControl component='fieldset' style={{ minWidth: '80px' }}>
      <Select
        variant='standard'
        key={props.customKey}
        aria-label='gender'
        name='gender1'
        value={value}
        onChange={handleChange}
        error={props.specs.required ? !props.value : false}
        input={<Input />}
        renderValue={selected => (selected as string[]).join(', ')}
        multiple
      >
        {props.specs.choices && props.specs.choices.map(rowValue => (
          <MenuItem key={rowValue.label} value={rowValue.value}>
            <Checkbox color='primary' checked={value && value.indexOf(rowValue.value) > -1} />
            <ListItemText primary={rowValue.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
