import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';

function HeaderProperty(props: {children: Array<ReactElement> | ReactElement}): ReactElement {

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        {props.children}
      </Grid>
    </Grid>
  );
}

export default HeaderProperty;
