import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Snackbar, Typography, Alert } from '@mui/material';
import { ReportBlockData, ReportBlockValues } from '../../../containers/duediligences/pages/rating/ratingtable';
import { ReportBlockDesc } from '../../../types/reporttype';
import { ApiResponse, DataDueDiligence, Nullable } from '../../../types/types';
import { dataContext } from '../../../hooks/datatabs';
import { DdGraphUploadService, DdService } from '../../../services/dds';
import ReportBlockDispatch from './reportblockdispatch';
import VerticalButtons from '../verticalbuttons';
import ServiceError from '../../../services/errors';

export type PopulateFieldMethod = (fieldId: string) => Promise<ApiResponse<Nullable<string>>>;

interface ReportBlockProps {
  id: string,
  desc: ReportBlockDesc,
  previousValues: ReportBlockValues,
  currentValues: ReportBlockValues,
  hasEditRights?: boolean, // should be is participant ?
  populateField?: PopulateFieldMethod,
  saveAction: (valuePath: string, value: unknown | undefined) => void,
  update: () => void,
  uploadServiceFactory: (fieldId: string) => DdGraphUploadService | undefined
}

function ReportBlock(props: ReportBlockProps): ReactElement {
  const [isEachFieldsSplit, setIsEachFieldsSplit] = useState(false);
  const [isDiffView, setIsDiffView] = useState(false);
  const totalCurrentValues =  Object.keys(props.currentValues.fields ? props.currentValues.fields : {});
  const { hash } = useLocation();

  return (
    <>
      <Grid container alignItems='center'>
        <Grid item xs>
          <Typography id={props.id} variant='h1' component='h2'>
            {props.desc.label}
          </Typography>
        </Grid>
        <Grid item>
          <VerticalButtons
            orientation='horizontal'
            isVerticalSplitOpened={isEachFieldsSplit}
            isDiffView={isDiffView}
            setSplitAction={setIsEachFieldsSplit}
            setDiffView={setIsDiffView}
            disabled={totalCurrentValues.length <= 0}
          />
        </Grid>
      </Grid>

      { props.desc.fields.map( (fieldDesc) => {
        const fieldId = fieldDesc.id;

        const previousValue = props.previousValues?.fields && props.previousValues?.fields[fieldId];
        const currentValue = props.currentValues?.fields && props.currentValues?.fields[fieldId];

        return (
          <ReportBlockDispatch
            blockId={props.id}
            hash={hash}
            key={fieldDesc.id}
            desc={fieldDesc}
            previousValue={previousValue}
            currentValue={currentValue}
            hasEditRights={props.hasEditRights} // should be id participant
            saveAction={props.saveAction}
            update={props.update}
            populateField={props.populateField}
            uploadServiceFactory={props.uploadServiceFactory}
            isEachFieldsSplitted={isEachFieldsSplit}
            isDiffView={isDiffView}
          />
        );
      })
      }
    </>
  );
}

function ReportsBlockFactory(props: { blockId: string, isParticipant: boolean, readonly?: boolean}): ReactElement {
  const ddMenu = dataContext();
  const dd: Nullable<DataDueDiligence> = ddMenu ? ddMenu.dueDiligence : null;
  const ddService = new DdService();
  const [reportBlockData, setReportBlockData] = useState<Nullable<ReportBlockData>>(null);
  const ddId = dd?._id;
  const [isParticipant, setIsParticipant] = useState(props.isParticipant);
  const [update, setUpdate] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('success');
  const [snackbarSeverity, setSnackbarSeverity] = useState<string>('success');
  const getSnackColor = () => (snackbarSeverity === 'success' ? 'success' : 'error');

  const saveAction = (valuePath: string, value: unknown): void => {
    if (ddId) {
      ddService.updateReportBlockField(ddId, props.blockId, valuePath, value)
        .then(() => {
          setUpdate(true);
        })
        .catch((err) => {
          setSnackbarOpen(true);
          setSnackbarMessage(ServiceError.getErrorMsg(err));
          setSnackbarSeverity('error');
          setUpdate(false);
        });
    }
  };

  const uploadServiceFactory = (fieldId: string) => {
    if (ddId) {
      return new DdGraphUploadService(ddId, props.blockId, fieldId);
    }

    return undefined;
  };

  useEffect(() => {
    setIsParticipant(props.isParticipant);
  }, [props.isParticipant]);

  useEffect(() => {
    if (update && ddId) {
      setUpdate(false);

      ddService.getReportBlockData(ddId, props.blockId)
        .then((res) => {
          res.data && setReportBlockData(res.data);
        })
        .catch((err) => {
          setSnackbarMessage(ServiceError.getErrorMsg(err));
        });
    }
  }, [ddId, update]);

  let populateField: PopulateFieldMethod | undefined;

  if (ddId) {
    populateField = (fieldId: string) => ddService.getPopulateText(ddId, props.blockId, fieldId);
  }

  return (
    <>
      {reportBlockData &&
        <ReportBlock
          id={props.blockId}
          desc={reportBlockData.reportBlockDesc}
          previousValues={reportBlockData.previousValues}
          currentValues={reportBlockData.currentValues}
          hasEditRights={props.readonly ? false : isParticipant} // should be called isEditableByDefault with isParticipant
          saveAction={saveAction}
          update={() => setUpdate(true)}
          populateField={populateField}
          uploadServiceFactory={uploadServiceFactory}
        />
      }
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity={getSnackColor()}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ReportsBlockFactory;
