import { AxiosResponse } from 'axios';
import { API } from '../api/api';
import { ApiResponse, DdComments } from '../types/types';

type CommentServiceReturnType = Promise<AxiosResponse<ApiResponse<DdComments>>>;
type CommentsServiceReturnType = Promise<AxiosResponse<ApiResponse<DdComments[]>>>;

export abstract class AbstractCommentService {
  basePath: string;

  _id: string;

  protected constructor() {
    this.basePath = '';
    this._id = '';
  }

  abstract getBaseUrl(): string;

  getAll(): CommentsServiceReturnType {
    return API.get(this.getBaseUrl());
  }

  getComment(id: string): CommentServiceReturnType {
    return API.get(`${this.getBaseUrl()}/${id}`);
  }

  add(type: string, value: string): CommentServiceReturnType {
    return API.post(this.getBaseUrl(), { type, content: value });
  }

  edit(id: string, value: string): CommentServiceReturnType {
    return API.put(`${this.getBaseUrl()}/${id}`, { content: value });
  }

  reply(id: string, value: string): CommentServiceReturnType {
    return API.post(`${this.getBaseUrl()}/${id}`, { content: value });
  }

  delete(id: string): CommentServiceReturnType {
    return API.delete(`${this.getBaseUrl()}/${id}`);
  }
}

export class DdsCommentService extends AbstractCommentService {
  constructor(id: string) {
    super();
    this._id = id;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/dds/${this._id}/comments`;

    return this.basePath;
  }
}

export class ElementsCommentService extends AbstractCommentService {
  constructor(id: string) {
    super();
    this._id = id;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/ddElements/${this._id}/comments`;

    return this.basePath;
  }
}

export class SurveysElementsCommentService extends AbstractCommentService {
  surveyId: string;

  constructor(surveyId: string, questionId: string) {
    super();
    this._id = questionId;
    this.surveyId = surveyId;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/surveys/${this.surveyId}/questions/${this._id}/comments`;

    return this.basePath;
  }
}