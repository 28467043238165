import React, { ReactElement, useState } from 'react';
import Menu from '@mui/material/Menu';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CustomButton, { useButtonsStyle } from '../../button/button';
import { BasePerson } from '../../../types/types';

const extraParticipantModel: BasePerson = {
  firstName: '',
  lastName: '',
  title: '',
  email: ''
};

interface AddParticipantsMenuProps {
  addMeetingExtraParticipants: (addedExtraParticipant: BasePerson) => void,
  participantToEdit?: BasePerson,
  disabled?: boolean
}

function AddParticipantMenu(props: AddParticipantsMenuProps): ReactElement {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [newParticipant, setNewParticipant] = useState<BasePerson>(props.participantToEdit || extraParticipantModel);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const textFieldHandler = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const temporaryNewParticipant = { ...newParticipant, [event.currentTarget.name]: event.currentTarget.value };
    setNewParticipant(temporaryNewParticipant);
  };

  const validateEmail = (email: string | undefined) => {
    if (email === undefined){
      return false;
    }

    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validateNewParticipant = () => {
    props.addMeetingExtraParticipants(newParticipant);
    setNewParticipant(extraParticipantModel);
    handleClose();
  };

  const isParticipantValid = () => !(newParticipant.firstName !== '' && newParticipant.lastName !== '' && newParticipant.title !== '');

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      {props.participantToEdit ?
        <IconButton onClick={handleClick} size='small'>
          <EditRoundedIcon />
        </IconButton>
        :
        <Button
          onClick={handleClick}
          variant='contained'
          color='primary'
          className={classes.SmallContainedBtn}
          style={{ marginTop: '14px' }}
          size='small'
          fullWidth
          disabled={props.disabled}
          disableElevation
        >
          <GroupAddIcon style={{ marginRight: '6px' }} />Add Other Participant
        </Button>
      }
      <Menu
        id='meeting-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ padding: '8px', margin: '8px 8px 0 8px' }}
      >
        <Box style={{ width: '400px', padding: '16px' }}>
          <Typography variant='h2' component='h2' style={{ marginBottom: '16px' }}>
            {props.participantToEdit ? 'Edit participant' : 'Add other participant'}
          </Typography>
          <TextField
            variant='standard'
            name='firstName'
            label='First Name'
            value={newParticipant.firstName}
            onKeyDown={onKeyDown}
            onChange={textFieldHandler}
            helperText={newParticipant.firstName === '' ? 'required' : ''}
            fullWidth
            style={{ marginTop: '8px' }}
            error={newParticipant.firstName === ''}
          />
          <TextField
            variant='standard'
            name='lastName'
            label='Last Name'
            value={newParticipant.lastName}
            onKeyDown={onKeyDown}
            onChange={textFieldHandler}
            helperText={newParticipant.lastName === '' ? 'required' : ''}
            fullWidth
            style={{ marginTop: '8px' }}
            error={newParticipant.lastName === ''}
          />
          <TextField
            variant='standard'
            name='title'
            label='Title'
            type='title'
            value={newParticipant.title}
            onKeyDown={onKeyDown}
            onChange={textFieldHandler}
            helperText={newParticipant.title === '' ? 'required' : ''}
            fullWidth
            style={{ marginTop: '8px' }}
            error={newParticipant.title === ''}
          />
          <TextField
            variant='standard'
            name='email'
            label='Email'
            type='email'
            value={newParticipant.email}
            onKeyDown={onKeyDown}
            onChange={textFieldHandler}
            helperText={validateEmail(newParticipant.email) ? '' : 'Not required'}
            fullWidth
            style={{ marginTop: '8px' }}
          />
          <CustomButton
            onClick={validateNewParticipant}
            small variant='contained' style={{ marginTop: '16px' }}
            disabled={isParticipantValid()}
            fullWidth
          >
            {props.participantToEdit ? 'Save participant' : 'Validate new participant'}
          </CustomButton>
        </Box>
      </Menu>
    </>
  );
}

export default AddParticipantMenu;
