import React, { ReactElement } from 'react';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CustomCards from '../../../../components/cards/cards';
import CustomButton from '../../../../components/button/button';
import EntityLink from '../../../../components/modularcomponents/entitylink/entitylink';
import { DataSurvey } from '../../../../types/types';

const useStyles = makeStyles({
  header: {
    width: '100%'
  },
  headerTitle: {
    display: 'flex',
    paddingLeft: '0',
    paddingRight: '0'
  },
  headerSubElements: {
    display: 'flex',
    marginLeft: '12px'
  },
  viewLink: {
    textDecoration: 'none'
  }
});

function SurveyCard(props: {list: Array<DataSurvey>}): ReactElement {
  const classes = useStyles();

  const headers = props.list.map(survey => ({
    id: survey._id,
    value:
        <>
          <ListItem className={classes.headerTitle}>
            <Typography variant='h2'>Due Dilligence</Typography>
            <div className={classes.headerSubElements}>
              <Chip size='small' label={<Typography variant='h4'>{survey.type}</Typography>} />
              {/* <Badge badgeContent={12} color='primary'>
                              <MailOutlineIcon />
                            </Badge> */}
            </div>
          </ListItem>
          <Typography variant='subtitle1'>{survey.auditedEntity.name}</Typography>
        </>
  }));

  const elements = props.list.map(survey =>
  // const startDate = new Date(survey.startDate);
  // const dueDate = survey.finalDate ? new Date(survey.finalDate) : undefined;
  // const totalDays = dueDate ? (dueDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) : 0;
  // const todayDate = new Date();
  // const remainingDays = dueDate ? ((dueDate.getTime() - todayDate.getTime()) / (1000 * 3600 * 24) | 0) : 0;
  // const daysPassed = (todayDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

    ({
      id: survey._id,
      values:
        [
          <>
            {/*
            <>
              <ProgressBar key={'dateProgress'} label={'days passed'} value={parseInt(daysPassed.toFixed(0))} endValue={totalDays} secondaryLabel={dueDate && `${remainingDays}  days remaining`} />
              <ProgressBar key={'questionsProgress'} label={'questions answered'} startValue={0} value={800} endValue={900}/>
            </>
          */}
          </>,
          <EntityLink key='surveyLink' type='survey' id={survey._id} className={classes.viewLink}>
            <CustomButton style={{ width: '100%' }}>
              <VisibilityOutlinedIcon />
              <Typography style={{ paddingLeft: '6px' }}>Open</Typography>
            </CustomButton>
          </EntityLink>
        ]
    })
  );

  return (
    <CustomCards headers={headers} elements={elements} />
  );
}

export default SurveyCard;
