import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { useButtonsStyle } from '../button/button';
import { DropZoneProps } from './uploadfiles';

const useStyle = makeStyles({
  container: {
    width: 'fit-content'
  }
});

interface UploadButtonProps extends DropZoneProps {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void,
  small?: boolean,
  uploadButtonLabel?: string,
  disabled?: boolean
}

function UploadButton(props: UploadButtonProps): ReactElement {
  const theme = useTheme();
  const classes = useStyle();
  const classesButtons = useButtonsStyle(theme);
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({ onDrop: props.onDrop, multiple: props.multiple, noDrag: true, accept: props.fileTypes, maxFiles: props.maxFiles, disabled: props.disabled, useFsAccessApi: false, onError: e => console.log(e) });

  return (
    <div {...getRootProps()} className={classes.container}>
      <input {...getInputProps()} />
      <Button
        size={props.small ? 'small' : 'medium'}
        startIcon={<CloudUploadRoundedIcon />}
        variant='contained'
        color='primary'
        component='span'
        className={props.small ? classesButtons.SmallContainedBtn : classesButtons.ContainedBtn}
        style={props.small ? undefined : { paddingLeft: '20px' }}
        disabled={props.disabled}
        disableElevation
      >
        {props.uploadButtonLabel ? props.uploadButtonLabel : (props.multiple ? t('files.uploadFiles') : t('files.uploadFile'))}
      </Button>
    </div>
  );
}

export default UploadButton;
