import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Grid, Snackbar, Alert } from '@mui/material';
import mime from 'mime';
import { Accept, FileError, FileRejection } from 'react-dropzone';
import { AxiosProgressEvent, CancelTokenSource } from 'axios';
import DropzoneWrapper from './dropzonewrapper';
import UploadButton from './uploadbutton';
import UploadTable from './uploadtable';
import { PostDocumentReturn, UploadReturn } from '../../types/types';
import ServiceError from '../../services/errors';
import { BaseUploadServiceInterface } from '../../services/data';

export interface FileActions {
  actions?: {
    download?: (id: string) => void,
    delete?: (id: string) => void
  }
}

export interface FileInfo {
  accepted: boolean,
  file: File,
  errors?: FileError[],
  uploadStatus?: 'uploading' | 'failed' | 'pending' | 'noUpload' | 'success' | 'aborted',
  id: string
}

export interface DropZoneProps {
  clickable?: boolean,
  fileTypes?: Accept,
  multiple?: boolean,
  maxFiles?: number,
  noDrag?: boolean
}

interface UploadFilesProps extends DropZoneProps {
  service: BaseUploadServiceInterface,
  update?: () => void,
  children?: ReactElement,
  small?: boolean,
  uploadButtonLabel?: string,
  noBtn?: boolean,
  childBeforeBtn?: boolean,
  dispUploaded?: boolean,
  baseUrl?: string,
  actions?: FileActions['actions'],
  disableAfterUpload?: boolean,
  disabled?: boolean,
  callback?: () => void,
  hideAfterFinish?: boolean,
  authorizedTypes?: string[]
}

function UploadFiles(props: UploadFilesProps): ReactElement {
  const [filesToUpload, setFilesToUpload] = useState<FileInfo[]>([]);
  const [fileInProgress, setFileInProgress] = useState<FileInfo | undefined>(undefined);
  const [fileUploaded, setFileUploaded] = useState<FileInfo[]>([]);
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>();
  const [hasCallbackActive, setHasCallbackActive] = useState(!!props.callback);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const fileTypes: Accept = props.authorizedTypes ? Object.fromEntries(
    props.authorizedTypes?.map(type => [mime.getType(type) || 'null', []])
  ) : {};
  Object.keys(fileTypes).forEach((key) => {
    props.authorizedTypes?.forEach((type) => {
      if ((mime.getType(type) || 'null') === key) {
        fileTypes[key] = [...fileTypes[key], type];
      }
    });
  });
  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const accepted: FileInfo[] = acceptedFiles.map(f => ({ accepted: true, file: f, uploadStatus: 'pending', id: '' }));
    const rejected: FileInfo[] = fileRejections.map(rejection => ({ accepted: false, file: rejection.file, uploadStatus: 'noUpload', id: '', errors: rejection.errors }));

    setFilesToUpload(oldFilesToUpload => [...oldFilesToUpload, ...accepted, ...rejected ]);
  }, []);

  const upload = () => {
    if (fileInProgress) {
      const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
        setProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1)));
      };

      const setCancelUpload= (source: CancelTokenSource) => {
        setCancelTokenSource(source);
      };

      props.service.uploadFile(fileInProgress.file, onUploadProgress, setCancelUpload)
        .then((apiResp) => {
          setFileInProgress({ ...fileInProgress, uploadStatus: 'success', id: (apiResp.data as UploadReturn)?.importId || (apiResp.data as PostDocumentReturn)?.addedFile?._id || '' });
          props.update && props.update();
        })
        .catch((err) => {
          setErrorMsg(ServiceError.getErrorMsg(err));
          setSnackbarOpen(true);
          setFileInProgress({ ...fileInProgress, uploadStatus: 'failed' });
        });
    }
  };

  const prepareUpload = () => {
    const nextFile = filesToUpload.shift();
    setProgress(0);

    if (nextFile?.accepted) {
      setFileInProgress(nextFile);
    }
    setFilesToUpload(filesToUpload);
  };

  const removeUpload = (fileToRemove: FileInfo) => {
    setFilesToUpload(filesToUpload.filter(elt => !(elt === fileToRemove)));
  };

  const deleteUploaded = (id: string) => {
    props.actions?.delete?.(id);
    setFileUploaded(fileUploaded.filter(file => file.id !== id));
  };

  useEffect(() => {
    if (filesToUpload.length > 0 && !fileInProgress) {
      prepareUpload();
    }
  }, [filesToUpload]);

  useEffect(() => {
    if (fileInProgress) {
      if (fileInProgress.uploadStatus === 'pending') {
        fileInProgress.uploadStatus = 'uploading';
        setFileInProgress(fileInProgress);
        upload();
      } else if (fileInProgress.uploadStatus === 'success' || fileInProgress.uploadStatus === 'failed') {
        setHasCallbackActive(true);
        setFileUploaded(oldUploaded => [fileInProgress, ...oldUploaded]);
        setFileInProgress(undefined);
      }
    } else if (filesToUpload.length > 0) {
      prepareUpload();
    }
  }, [fileInProgress]);

  useEffect(() => {
    if (hasCallbackActive && filesToUpload.length < 1 && !fileInProgress && fileUploaded.length > 0) {
      props.callback && props.callback();
      props.hideAfterFinish && setFileUploaded([]);
      setHasCallbackActive(false);
    }
  });

  return (
    <>
      <Grid container spacing={2}>
        { !props.noBtn &&
          <Grid item xs={12}>
            <DropzoneWrapper
              onDrop={onDrop}
              clickable={false}
              multiple={props.multiple}
              fileTypes={fileTypes}
              maxFiles={props.maxFiles}
              noDrag={props.noDrag || props.disableAfterUpload && fileUploaded.length > 0}
            >
              <Grid container spacing={2} alignItems='center' justifyContent='center'>
                { props.childBeforeBtn &&
                  <Grid item>
                    {props.children}
                  </Grid>
                }
                <Grid item>
                  <UploadButton
                    onDrop={onDrop}
                    multiple={props.multiple}
                    small={props.small}
                    uploadButtonLabel={props.uploadButtonLabel}
                    fileTypes={fileTypes}
                    maxFiles={props.maxFiles}
                    disabled={props.disableAfterUpload && fileUploaded.length > 0 || props.disabled}
                  />
                </Grid>
                { (fileInProgress || filesToUpload.length > 0 || fileUploaded.length > 0) &&
                  <Grid item xs={12}>
                    <UploadTable
                      files={fileInProgress ?
                        props.dispUploaded ? [...fileUploaded, fileInProgress, ...filesToUpload] : [fileInProgress, ...filesToUpload] :
                        props.dispUploaded ? [...fileUploaded, ...filesToUpload] : [...filesToUpload]
                      }
                      removeUpload={removeUpload}
                      progress={progress}
                      cancelTokenSource={cancelTokenSource}
                      baseUrl={props.baseUrl}
                      actions={props.actions && { ...props.actions, delete: deleteUploaded }}
                    />
                  </Grid>
                }
              </Grid>
            </DropzoneWrapper>
          </Grid>
        }
        { props.children && (props.noBtn || !props.childBeforeBtn) &&
          <Grid item xs={12}>
            <DropzoneWrapper
              onDrop={onDrop}
              clickable={props.clickable}
              multiple={props.multiple}
              fileTypes={fileTypes}
              maxFiles={props.maxFiles}
              noDrag={props.noDrag || props.disableAfterUpload && fileUploaded.length > 0}
            >
              <Grid container spacing={2}>
                { !props.childBeforeBtn &&
                <Grid item xs={12}>
                  {props.children}
                </Grid>
                }
                { props.noBtn &&
                  <Grid item xs={12}>
                    <UploadTable
                      files={fileInProgress ?
                        props.dispUploaded ? [...fileUploaded, fileInProgress, ...filesToUpload] : [fileInProgress, ...filesToUpload] :
                        props.dispUploaded ? [...fileUploaded, ...filesToUpload] : [...filesToUpload]
                      }
                      removeUpload={removeUpload}
                      progress={progress}
                      cancelTokenSource={cancelTokenSource}
                      baseUrl={props.baseUrl}
                      actions={props.actions && { ...props.actions, delete: deleteUploaded }}
                    />
                  </Grid>
                }
              </Grid>
            </DropzoneWrapper>
          </Grid>
        }
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default UploadFiles;
