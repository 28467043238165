import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { darken, Grid, LinearProgress, Theme, Typography } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { lighten, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { CancelTokenSource } from 'axios';
import { NavLink } from 'react-router-dom';
import DownloadRoundedIcon from '@mui/icons-material/GetAppRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { mainRadius } from '../../themes/theme';
import { CustomIconButton } from '../button/button';
import CustomTable, { Column } from '../table/table';
import { FileActions, FileInfo } from './uploadfiles';
import { bytesToSize } from '../../utils/computations';

interface UploadTableProps extends FileActions {
  files: FileInfo[],
  removeUpload: (fileToRemove: FileInfo) => void,
  progress: number,
  cancelTokenSource?: CancelTokenSource,
  baseUrl?: string
}

const makeColoredRow = (baseColor: string) => ({
  backgroundColor: lighten(baseColor, 0.8),
  '& td': {
    color: darken(baseColor, 0.5)
  }
});

const useStyle = makeStyles((theme: Theme) => ({
  failedRow: makeColoredRow(theme.palette.error.light),
  warningRow: makeColoredRow(theme.palette.warning.light),
  infoRow: makeColoredRow(theme.palette.info.light),
  standardRow: makeColoredRow('rgb(255, 255, 255)'),
  completedRow: makeColoredRow(theme.palette.success.light),
  successRow: makeColoredRow(theme.palette.success.light),
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

function UploadTable(props: UploadTableProps): ReactElement {
  const theme = useTheme();
  const classes = useStyle(theme);
  const { t } = useTranslation();

  const columns: Array<Column> = [
    { id: 'filename', label: t('files.filename'), minWidth: 'min-content', align: 'left' },
    { id: 'status', label: t('files.status'), minWidth: 'min-content', align: 'left' },
    { id: 'progress', label: t('files.progress'), minWidth: 'max-content', align: 'left' },
    { id: 'size', label: t('files.size'), minWidth: 'min-content', align: 'center' },
    { id: 'actions', label: '', minWidth: 'min-content', align: 'right' }
  ];

  const getStatusLabel = (status?: string) => {
    switch (status) {
    case 'failed':
      return t('files.uploadStatus.failed');
    case 'aborted':
      return t('files.uploadStatus.aborted');
    case 'noUpload':
      return t('files.uploadStatus.noUpload');
    case 'success' :
      return t('files.uploadStatus.success');
    case 'pending' :
      return t('files.uploadStatus.pending');
    default:
      return t('files.uploadStatus.noUpload');
    }
  };

  const rows = props.files.map((fileObject: FileInfo) => {
    let className : string | undefined;

    switch (fileObject.uploadStatus) {
    case 'failed' || 'aborted':
      className = classes.failedRow;
      break;
    case 'noUpload':
      className = classes.warningRow;
      break;
    case 'success' :
      className = classes.successRow;
      break;
    case 'pending' :
      className = classes.standardRow;
      break;
    default:
      className = classes.infoRow;
      break;
    }

    return ({
      id: fileObject.file.name,
      className,
      value: columns.map(column => (
        <>
          {column.id === 'filename' &&
            <div>
              {props.baseUrl ?
                <NavLink to={props.baseUrl + fileObject.id} className={classes.link}>
                  <Typography>
                    {fileObject.file.name}
                  </Typography>
                </NavLink>
                :
                <Typography>
                  {fileObject.file.name}
                </Typography>
              }
            </div>
          }
          {column.id === 'status' &&
            <div>
              <Typography>{getStatusLabel(fileObject.uploadStatus)}</Typography>
            </div>
          }
          {column.id === 'progress' &&
            <div>
              { fileObject.uploadStatus === 'uploading' && <LinearProgress variant='determinate' style={{ borderRadius: mainRadius }} value={props.progress} />}
            </div>
          }
          {column.id === 'size' &&
            <div>
              <Typography>{bytesToSize(fileObject.file.size)}</Typography>
            </div>
          }
          {column.id === 'actions' &&
            <div>
              { (fileObject.uploadStatus === 'noUpload' || fileObject.uploadStatus === 'pending') &&
                <CustomIconButton
                  small
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    props.removeUpload(fileObject);
                  }}
                >
                  <RemoveRoundedIcon />
                </CustomIconButton>
              }
              { fileObject.uploadStatus === 'uploading' && props.cancelTokenSource &&
                <CustomIconButton
                  small
                  color='secondary'
                  variant='contained'
                  onClick={() => (props.cancelTokenSource?.cancel(t('files.uploadCancel') || undefined))}
                >
                  <ClearRoundedIcon />
                </CustomIconButton>
              }
              { fileObject.uploadStatus === 'success' && (props.actions?.delete || props.actions?.download) &&
                <Grid container spacing={1} justifyContent='flex-end' style={{ flexWrap: 'nowrap' }}>
                  {props.actions?.download &&
                    <Grid item>
                      <CustomIconButton
                        variant='contained'
                        color='primary'
                        onClick={() => (props.actions?.download ? props.actions?.download(fileObject.id) : undefined)}
                        small
                      >
                        <DownloadRoundedIcon />
                      </CustomIconButton>
                    </Grid>
                  }
                  { props.actions?.delete &&
                    <Grid item>
                      <CustomIconButton
                        variant='contained'
                        color='secondary'
                        onClick={() => (props.actions?.delete ? props.actions?.delete(fileObject.id) : undefined)}
                        small
                      >
                        <DeleteRoundedIcon />
                      </CustomIconButton>
                    </Grid>
                  }
                </Grid>
              }
            </div>
          }
        </>
      ))
    });
  });

  return (
    <>
      { rows.length > 0 &&
        <CustomTable columns={columns} rows={rows} />
      }
    </>
  );
}

export default UploadTable;
