import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { DdParticipantsService } from '../../../../../services/contacts';
import { dataContext } from '../../../../../hooks/datatabs';
import Participants from '../../../../../components/contacts/participants/participants';

function DdParticipants(): ReactElement {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const service = new DdParticipantsService(id || '');
  const dd = dataContext()?.dueDiligence;

  return (
    <>
      <Typography variant='subtitle1'>{t('contactsAndParticipants.participants.label')}</Typography>
      {dd && <Participants service={service} list={dd.participants} auditedEntity={dd.auditedEntity} />}
    </>
  );
}

export default DdParticipants;
