// parse ISO date into a right and readable format for the user
import { Filters } from '../types/types';

export function parseISOString(s: string): string {
  const b: Array<string> = s.split(/\D+/);
  const date = new Date(
    Date.UTC(
      parseInt(b[0]),
      parseInt(b[1]) - 1,
      parseInt(b[2]),
      parseInt(b[3]),
      parseInt(b[4]),
      parseInt(b[5]),
      parseInt(b[6])
    )
  );
  const monthDateYear = date.toLocaleDateString('en-US',
    { year: 'numeric', month: 'long', day: '2-digit' }
  ).toString();
  const hourMinute = date.toLocaleTimeString('en-US',
    { hour: '2-digit', minute: '2-digit', hour12: false }
  ).toString();

  return (
    `${monthDateYear  } - ${  hourMinute}`
  );
}

export function parseUrlToFilters(url: string): Filters {
  let urlParam = {
    category: '/*',
    active: true
  };
  if (url.length > 0) {
    url.substring(1).split('&').forEach((param) => {
      const key = param.split('=')[0];
      const value = param.split('=')[1];
      if (!(key === 'category' && value === '/*')) {
        urlParam = {
          ...urlParam,
          [key]: value.split(',').length < 2 ?
            (key === 'category' ? value : [value]) : value.split(',').map(subValue => subValue)
        };
      }
    });
  }

  return urlParam;
}

export function parseFiltersToUrl(filters: Filters): string {
  let params = '?';
  Object.keys(filters).forEach((key) => {
    if (key !== 'active' && !((key === 'category' && filters[key as keyof typeof filters] === '/*'))) {
      params += `${key}=${filters[key as keyof typeof filters]}&`;
    }
  });

  return params.slice(0, -1);
}
