import React, { ReactElement } from 'react';
import { Grid, Paper, Typography, Skeleton, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mainRadius } from '../../../themes/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '12px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '0.9',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out'
      }
    }
  }));

function QuestionSkeleton(): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const numberOfRaws = 3;
  const numberOfTabs = 5;

  return (
    <Grid container spacing={2} justifyContent='space-around' style={{ marginTop: '-8px' }}>
      {[...Array(numberOfRaws)].map((x, i) => (
        <Grid key={`${new Date().getTime()}-${Math.random()}`} item xs={12} style={{ opacity: (1 - (i / numberOfTabs)), marginTop: i > 0 ? '8px' : undefined }}>
          <Paper className={classes.paper} style={{ backgroundColor: 'rgba(157, 157, 157, 0.175)' }} elevation={0} variant='outlined'>
            <Grid container spacing={2} direction='column'>
              <Grid item>
                <Grid container direction='column' style={{ marginLeft: '8px' }}>
                  <Grid item>
                    <Typography variant='h1' style={{ width: '30%' }}>
                      <Skeleton animation='wave' variant='text' height='28px' style={{ borderRadius: '10px' }} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body1' style={{ width: '20%' }}>
                      <Skeleton animation='wave' variant='text' style={{ borderRadius: '8px' }} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  {[...Array(numberOfTabs)].map((y, j) => (
                    <Grid key={`${x}-${new Date().getTime()}-${Math.random()}`} item>
                      {j === 0 ?
                        <Skeleton animation='wave' variant='rectangular' width='110px' height='32px' style={{ borderRadius: mainRadius }} /> :
                        <Typography variant='h1' style={{ height: '32px' }}>
                          <Skeleton animation='wave' variant='text' width='90px' height='28px' style={{ borderRadius: '12px' }} />
                        </Typography>
                      }
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Skeleton animation='wave' variant='rectangular' width='100%' height='146px' style={{ borderRadius: mainRadius }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default QuestionSkeleton;
