import React, { ReactElement, useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { darken, Grid, lighten, Theme } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ImportGroupService, ImportService } from '../../../services/import';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../params';
import { Import, ImportEvent } from '../../../types/importtypes';
import { ApiFilter, ApiResponse, Nullable, ParamFilter } from '../../../types/types';
import CustomTable, { Column } from '../../table/table';
import HeaderProperty from '../../modularcomponents/header/headerproperty';
import HeaderPropertyName from '../../modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../../modularcomponents/header/headerpropertyvalue';
import EntityLink from '../../modularcomponents/entitylink/entitylink';
import ServiceError from '../../../services/errors';
import ErrorPageManager from '../../Error/errorpagemanager';
import { EntityListDataProvider, FilterParamsManager, ListRendererMap, MultiModeList } from '../../entity/list';
import GenericFilters from '../../filters/filters';
import CustomPagination from '../../layout/pagination/pagination';
import { DisplayDataModal } from '../../../containers/dev/displaydata';
import HeaderContainer from '../../modularcomponents/header/headercontainer';
import HeaderTitle from '../../modularcomponents/header/headertitle';
import HeaderItems from '../../modularcomponents/header/headeritems';
import HeaderItem from '../../modularcomponents/header/headeritem';
import JsonWrapper from '../../dev/jsonwrapper';
import { API_BASE_URL } from '../../../utils/env';
import HeaderSummary from '../../modularcomponents/header/headersummary';

function makeColoredRow(baseColor: string) {
  return {
    backgroundColor: lighten(baseColor, 0.8),
    '& td': {
      color: darken(baseColor, 0.5)
    }
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  import: {
    marginBottom: '3px'
  },
  bars: {

  },
  bar: {
    float: 'left',
    height: '10px'
  },
  importedInfo: {
    marginLeft: '50px',
    fontSize: 'small'
  },
  chip: {
    marginLeft: '30px'
  },
  failedRow: makeColoredRow(theme.palette.error.light),
  warningRow: makeColoredRow(theme.palette.warning.light),
  infoRow: makeColoredRow(theme.palette.info.light),
  completedRow: makeColoredRow(theme.palette.success.light),
  successRow: makeColoredRow(theme.palette.success.light)
}));

/* export function ImportRow(props: {importObj: Import}) : ReactElement {
  const { importObj } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const { status } = importObj;

  let severity: AlertColor = 'info';
  switch (status) {
  case 'created':
    severity = 'info';
    break;
  case 'started':
    severity = 'info';
    break;
  case 'completed':
    severity = 'success';
    break;
  case 'failed':
    severity = 'error';
    break;
  default:
    break;
  }

  return (
    <>
      <Alert key={importObj._id} className={classes.import} severity={severity}>
        <AlertTitle><Link to={`/admin/imports/${importObj._id}`}>{importObj.type !== 'ddImport' ? importObj.name : importObj.ddImportInfo?.ddDirName}</Link>
          <Chip className={classes.chip} color='primary' label={importObj.status} size='small'/>
        </AlertTitle>

      </Alert>
    </>
  );
} */

/* export function ImportTable(props: {list: Array<Import>}) : ReactElement {

  return (
    <div>
     {props.list.map((importObj: Import) => {
        return (
          <>
            <div key={importObj._id}>
              <ImportRow importObj={importObj} />
            </div>
          </>
        );
      } )}
    </div>
  );
} */

export function ImportTable2(props: {list: Array<Import>}) : ReactElement {
  const classes = useStyles({});

  const columns: Array<Column> = [
    { id: 'type', label: 'Type', minWidth: 'min-content' },
    { id: 'ddDirName', label: 'Name', minWidth: 'min-content' },
    { id: 'status', label: 'Status', minWidth: 'min-content', align: 'center' },
    { id: 'currentStep', label: 'Current Step', minWidth: 'min-content', align: 'center' },
    { id: 'errors', label: 'Errors', minWidth: 'min-content', align: 'center' },
    { id: 'warnings', label: 'Warnings', minWidth: 'min-content', align: 'center' },
    { id: 'filename', label: 'Filename', minWidth: 'min-content' }
  ];

  const rows = props.list.map((importObj: Import) => {
    let nbErrors = 0;
    let nbWarnings = 0;
    const { status } = importObj;

    importObj.events.forEach((event) => {
      if (event.level === 'error') {
        nbErrors += 1;
      } else if (event.level === 'warning') {
        nbWarnings += 1;
      }
    });

    let className : string | undefined;
    if ((nbErrors > 0) || (status === 'failed') || (status === 'aborted')) {
      className = classes.failedRow;
    } else if (nbWarnings > 0) {
      className = classes.warningRow;
    } else if (status === 'completed') {
      className = classes.completedRow;
    } else {
      className = classes.infoRow;
    }

    return ({
      id: importObj._id,
      className,
      value: columns.map(column => (
        <>
          {column.id === 'type' && importObj.type}
          {column.id === 'ddDirName' &&
              <Link className={classes.link} to={`/admin/imports/${importObj._id}`}>{importObj.name || importObj.ddImportInfo?.ddDirName}</Link>
          }
          {column.id === 'filename' && importObj.importGroup.filename}
          {column.id === 'currentStep' && importObj.currentStep && importObj.currentStep.id}
          {column.id === 'status' && importObj.status.toUpperCase()}
          {column.id === 'errors' && nbErrors}
          {column.id === 'warnings' && nbWarnings}
        </>
      ))
    });
  });

  return (
    <CustomTable columns={columns} rows={rows} size='small' />
  );
}

export function ImportGroupImportList(props: {importGroupId: string}): ReactElement {
  const [listResp, setListResp] = React.useState<Nullable<ApiResponse<Nullable<Array<Import>>>>>(null);
  const importGroupService = new ImportGroupService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);

  function updateData() {
    importGroupService.getImportGroupImports(props.importGroupId)
      .then((apiResp) => {
        setListResp(apiResp);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
      });
  }

  useEffect(() => {
    updateData();

    const interval = setInterval(() => updateData(), 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {error ? <ErrorPageManager error={error} /> :
        <>
          { (listResp != null) && (listResp.data != null) &&
            <>
              { (listResp.data.length === 0) &&
                <div>No imports...</div>
              }
              { (listResp.data.length > 0) &&
                <ImportTable2 list={listResp.data} />
              }
            </>
          }
        </>
      }
    </>
  );
}

export function ImportEventTable(props: {list: Array<ImportEvent>}) : ReactElement {
  const classes = useStyles({});

  const columns: Array<Column> = [
    { id: 'level', label: 'Level', minWidth: 'min-content' },
    { id: 'type', label: 'Type', minWidth: 'min-content' },
    { id: 'message', label: 'Message', minWidth: 'min-content' },
    { id: 'location', label: 'Location', minWidth: 'min-content' },
    { id: 'timestamp', label: 'Timestamp', minWidth: 'min-content' }
  ];

  const rows = props.list.map((event: ImportEvent) => {
    let className : string | undefined;
    if (event.level === 'error') {
      className = classes.failedRow;
    } else if (event.level === 'warning') {
      className = classes.warningRow;
    } else if (event.level === 'info') {
      className = classes.infoRow;
    }

    return ({
      id: `${event.level}-${event.type}`,
      className,
      value: columns.map(column => (
        <div key={column.id}>
          {column.id === 'level' && event.level}
          {column.id === 'type' && event.type}
          {column.id === 'message' && event.message}
          {column.id === 'location' && event.location &&
            <Grid container spacing={1}>
              <Grid item xs={12}>
                file: {event.location.file.split('/').pop()}
              </Grid>
              <Grid item xs={12}>
                worksheet: {event.location.worksheet}
              </Grid>
              <Grid item xs={12}>
                cell: {event.location.cell}
              </Grid>
            </Grid>
          }
          {column.id === 'timestamp' && event.timestamp}
        </div>
      ))
    });
  });

  return (
    <CustomTable columns={columns} rows={rows} size='small' />
  );
}

export function ImportGroupAllErrorsList(props: {importGroupId: string}): ReactElement {
  const [listResp, setListResp] = React.useState<Nullable<ApiResponse<Nullable<Array<Import>>>>>(null);
  const importGroupService = new ImportGroupService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);

  function updateData() {
    importGroupService.getImportGroupImports(props.importGroupId)
      .then((apiResp) => {
        setListResp(apiResp);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
      });
  }

  useEffect(() => {
    updateData();

    const interval = setInterval(() => updateData(), 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {error ? <ErrorPageManager error={error} /> :
        <>
          { (listResp != null) && (listResp.data != null) &&
            <>
              { (listResp.data.length === 0) &&
                <div>No import in this import group...</div>
              }
              { (listResp.data.length > 0) &&
                listResp.data.map((importObj: Import) => (
                  <div key={importObj._id} style={{ marginBottom: '40px' }}>
                    { importObj.events.length > 0 &&
                        <>
                          <h4><EntityLink type='imports' id={importObj._id}>{importObj.name || importObj.ddImportInfo?.ddDirName}</EntityLink>
                          </h4>
                          <ImportEventTable list={importObj.events} />
                        </>
                    }
                  </div>
                ))
              }
            </>
          }
        </>
      }
    </>
  );
}

const useStyles2 = makeStyles((theme: Theme) => ({
  parsedInfo: {
    overflowX: 'auto',
    overflowY: 'auto',
    maxWidth: '1000px',
    maxHeight: '500px',
    border: '1 solid',
    fontSize: 'smallest',
    backgroundColor: darken(theme.palette.background.paper, 0.03)
  }
}));

export function ImportPage(props: {importObj: Import}): ReactElement {
  const { importObj } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles2(theme);
  const [expand, setExpand] = useState(false);

  const toggleExpand = () => {
    setExpand(val => !val);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <HeaderContainer expanded={expand} setExpanded={setExpand}>
          <HeaderSummary toggleExpand={toggleExpand}>
            <HeaderTitle toggleExpand={toggleExpand} type='' name={`${t('admin.import.label')} - ${importObj.type !== 'ddImport' ? importObj.name : importObj.ddImportInfo?.ddDirName} - (from file ${importObj.importGroup.filename})`} wide />
          </HeaderSummary>
          <HeaderItems>
            <HeaderItem>
              <>
                <HeaderProperty>
                  <HeaderPropertyName name='Status'/>
                  <HeaderPropertyValue><strong>{importObj.status}</strong></HeaderPropertyValue>
                </HeaderProperty>
                <>
                  { importObj.currentStep && importObj.currentStep.text &&
                  <HeaderProperty>
                    <HeaderPropertyName name='Current Step'/>
                    <HeaderPropertyValue><>{importObj.currentStep.text} ({importObj.currentStep.id})</></HeaderPropertyValue>
                  </HeaderProperty>
                  }

                  { importObj.type === 'ddImport' && importObj.ddImportInfo && importObj.ddImportInfo.ddId &&
                    <HeaderProperty>
                      <HeaderPropertyName name='Associated Due Diligence'/>
                      <HeaderPropertyValue><EntityLink type='dueDiligence' id={importObj.ddImportInfo.ddId} name={importObj.name || importObj.ddImportInfo.ddDirName}/></HeaderPropertyValue>
                    </HeaderProperty>
                  }
                </>
              </>
            </HeaderItem>
          </HeaderItems>
        </HeaderContainer>
      </Grid>
      <Grid item xs={12}>
        <h3>Errors / Warnings</h3>
        { importObj.events && importObj.events.length > 0 &&
        <ImportEventTable list={importObj.events} />
        }
        { importObj.events?.length === 0 && <>No message...</>
        }
      </Grid>
      <>
        {importObj.type === 'elementGroupImport' &&
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h3>Template Import Info</h3>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.parsedInfo}>
                  <JsonWrapper>
                    {JSON.stringify(importObj.templateImportInfo, null, ' ')}
                  </JsonWrapper>
                </div>
              </Grid>
            </Grid>
          </Grid>
        }
        { importObj.parsedInfo &&
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h3>Raw parsed info</h3>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.parsedInfo}>
                  <JsonWrapper>
                    {JSON.stringify(importObj.parsedInfo, null, ' ')}
                  </JsonWrapper>
                </div>
              </Grid>
            </Grid>
          </Grid>
        }
      </>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h3>JSON</h3>
          </Grid>
          <Grid item xs={12}>
            <DisplayDataModal url={`${API_BASE_URL}/api/v1/admin/imports/${importObj._id}`} text='Import JSON'/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
}

export class ImportListDataProvider extends EntityListDataProvider<Import> {
  constructor(searchParamsAccess: SearchParamsAccess, itemsPerPageMin?: number) {
    super(searchParamsAccess, new ImportService(), itemsPerPageMin);
  }
}

export function ImportFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const filters: Array<ParamFilter> = props.apiFilters ?
    props.apiFilters.map(filter => (
      { id: filter.value, type: 'multiSelect', label: filter.label, values: filter.elements }
    ))
    :
    [
      { id: 'status', type: 'multiSelect', label: 'Status', values: [{ label: 'completed' }, { label: 'failed' }, { label: 'started' }, { label: 'created' }] }
    ];

  filters.push({ id: 'latest', type: 'boolean', label: 'Show Latest' });

  return (<GenericFilters filters={filters} filterParamsManager={props.filterParamsManager} />);
}

export function ImportMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new ImportListDataProvider(searchParamsAccess);

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '40px' }}>No imports...</div>
    </>
  );

  const renderLoading = () => (
    <div style={{ marginTop: '40px' }}>Loading...</div>
  );

  const renderAsTable = (eltList: Array<Import>, totalCount: number) => (
    <>
      <ImportTable2 list={eltList} />
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
    </>
  );

  const renderers: ListRendererMap<Import> = {
    'table': renderAsTable
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table' ]}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      filtersComponent={ImportFilters}
      autoRefreshDelay={10}
    />
  );
}

export function ImportListingPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <ImportMultiModeList />
    </SearchParamsContextProvider>
  );
}
