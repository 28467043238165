import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';

export default function QuestionBox(props: {children?: React.ReactNode}): ReactElement {
  const { children } = props;

  return (
    <Box style={{ paddingLeft: '12px', paddingRight: '12px', paddingBottom: '12px' }}>
      {children}
    </Box>
  );
}
