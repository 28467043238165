import React, { Dispatch, SetStateAction, ReactElement } from 'react';
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Theme, Badge
} from '@mui/material';
import { darken, lighten, useTheme, styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ToggleButtonProps } from '@mui/lab';
import { mainRadius } from '../../themes/theme';

interface CustomButtonProps extends ButtonProps {
  variant?: 'text' | 'outlined' | 'contained',
  small?: true
}

interface CustomIconButtonProps extends Omit<IconButtonProps, 'small'> {
  variant?: 'text' | 'outlined' | 'contained',
  small?: true
}

interface CustomSwitchButtonProps extends ButtonProps {
  label1: string,
  label2: string,
  value: string,
  setValue: Dispatch<SetStateAction<string>>
}

interface CustomSwitchIconButtonProps extends ButtonProps {
  label1: string,
  label2: string,
  label1Icon: ReactElement,
  label2Icon: ReactElement,
  setValue: Dispatch<SetStateAction<string>>,
  small?: true
}

interface CustomToggleButtonProps extends ToggleButtonProps {
  label: string,
  icon: ReactElement,
  value: string,
  disabled?: boolean,
  orientation?: 'horizontal' | 'vertical',
  badge?: string
}
interface CustomToggleTextButtonProps extends ToggleButtonProps {
  label: string,
  value: string,
  text: string,
  disabled?: boolean
}

export const useButtonsStyle = makeStyles((theme: Theme) => ({
  ContainedBtn: {
    height: '50px',
    borderRadius: mainRadius,
    textTransform: 'none',
    textDecoration: 'none !important',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2)
    }
  },
  SmallContainedBtn: {
    height: '38px',
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2)
    }
  },
  ContainedIconBtn: {
    height: '50px',
    width: '50px',
    padding: '0px',
    borderRadius: mainRadius,
    textTransform: 'none',
    backgroundColor: lighten(theme.palette.primary.main, 0),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2)
    }
  },
  SmallContainedIconBtn: {
    height: '38px',
    width: '38px',
    padding: '0px',
    borderRadius: mainRadius,
    textTransform: 'none',
    backgroundColor: lighten(theme.palette.primary.main, 0),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2)
    }
  },
  ContainedBtnSecondary: {
    height: '50px',
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.2)
    }
  },
  SmallContainedBtnSecondary: {
    height: '38px',
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.2)
    }
  },
  ContainedIconBtnSecondary: {
    height: '50px',
    width: '50px',
    padding: '0px',
    borderRadius: mainRadius,
    textTransform: 'none',
    backgroundColor: lighten(theme.palette.secondary.main, 0),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.2)
    }
  },
  SmallContainedIconBtnSecondary: {
    height: '38px',
    width: '38px',
    padding: '0px',
    borderRadius: mainRadius,
    textTransform: 'none',
    backgroundColor: lighten(theme.palette.secondary.main, 0),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.2)
    }
  },
  TextBtn: {
    border: 'none',
    height: '50px',
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
    }
  },
  SmallTextBtn: {
    border: 'none',
    height: '38px',
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
    }
  },
  TextIconBtn: {
    border: 'none',
    height: '50px',
    width: '50px',
    padding: 0,
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
    }
  },
  SmallTextIconBtn: {
    border: 'none',
    height: '38px',
    width: '38px',
    padding: 0,
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
    }
  },
  SmallOutlinedBtn: {
    height: '38px',
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
    }
  },
  OutlinedBtn: {
    height: '50px',
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
    }
  },
  OutlinedIconBtn: {
    height: '50px',
    width: '50px',
    border: 'solid 1px rgb(147, 147, 147)',
    padding: 0,
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
    }
  },
  SmallOutlinedIconBtn: {
    height: '38px',
    width: '38px',
    border: 'solid 1px rgb(147, 147, 147)',
    padding: 0,
    borderRadius: mainRadius,
    textTransform: 'none',
    '&:hover': {
    }
  },
  SwitchButton: {
    height: '50px',
    borderRadius: mainRadius,
    textTransform: 'none',
    padding: '4px',
    backgroundColor: darken(theme.palette.background.default, 0.07),
    color: theme.palette.text.primary
  },
  SmallSwitchButton: {
    height: '38px',
    borderRadius: mainRadius,
    textTransform: 'none',
    minWidth: '72px',
    padding: '4px',
    backgroundColor: darken(theme.palette.background.default, 0.07),
    color: theme.palette.text.primary
  },
  InnerSwitchBtn: {
    position: 'relative',
    height: '42px',
    display: 'flex'
  },
  SmallInnerSwitchBtn: {
    position: 'relative',
    height: '32px',
    display: 'flex'
  },
  SwitchBtnLabel: {
    zIndex: 99,
    margin: 'auto',
    padding: '0px 12px',
    transition: 'all 0.2s ease-out'
  },
  SwitchBtnLabelSelected: {
    zIndex: 99,
    margin: 'auto',
    padding: '0px 12px',
    transition: 'all 0.2s ease-out',
    color: theme.palette.primary.contrastText
  },
  SwitchBtnSelected1: {
    zIndex: 98,
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    width: '50%',
    height: '42px',
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-out'
  },
  SwitchBtnSelected2: {
    zIndex: 98,
    position: 'absolute',
    marginLeft: '50%',
    backgroundColor: theme.palette.primary.main,
    width: '50%',
    height: '42px',
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-out'
  },
  SmallSwitchBtnSelected1: {
    zIndex: 98,
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    width: '50%',
    height: '32px',
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-out'
  },
  SmallSwitchBtnSelected2: {
    zIndex: 98,
    position: 'absolute',
    marginLeft: '50%',
    backgroundColor: theme.palette.primary.main,
    width: '50%',
    height: '32px',
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-out'
  }
}));

function ContainedBtn(props: ButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <Button disableElevation color='primary' variant='contained' className={props.color !== 'secondary' ? classes.ContainedBtn : classes.ContainedBtnSecondary} {...props} />;
}

function SmallContainedBtn(props: ButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <Button disableElevation color='primary' variant='contained' className={props.color !== 'secondary' ? classes.SmallContainedBtn : classes.SmallContainedBtnSecondary} {...props} />;
}

function TextBtn(props: ButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <Button disableElevation color='primary' variant='text' className={classes.TextBtn} {...props} />;
}

function SmallTextBtn(props: ButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <Button disableElevation color='primary' variant='text' className={classes.SmallTextBtn} {...props} />;
}

function OutlinedBtn(props: ButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <Button variant='outlined' className={classes.OutlinedBtn} {...props} />;
}

function SmallOutlinedBtn(props: ButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <Button variant='outlined' className={classes.SmallOutlinedBtn} {...props} />;
}

function CustomButton(props : CustomButtonProps): ReactElement {
  const { variant, small, ...others } = props;

  if (variant === 'contained') {
    if (small) {
      return (<SmallContainedBtn {...others} />);
    }

    return (<ContainedBtn {...others} />);
  }
  if (variant === 'text') {
    if (small) {
      return (<SmallTextBtn {...others} />);
    }

    return (<TextBtn {...others} />);
  }
  if (small) {
    return (<SmallOutlinedBtn {...others} />);
  }

  return (<OutlinedBtn {...others} />);
}

function ContainedIconBtn(props: IconButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return (
    <IconButton
      color='primary'
      className={props.color !== 'secondary' ? classes.ContainedIconBtn : classes.ContainedIconBtnSecondary}
      {...props}
      size='large' />
  );
}

function SmallContainedIconBtn(props: IconButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return (
    <IconButton
      color='primary'
      className={props.color !== 'secondary' ? classes.SmallContainedIconBtn : classes.SmallContainedIconBtnSecondary}
      {...props}
      size='large' />
  );
}

function TextIconBtn(props: IconButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <IconButton color='primary' className={classes.TextIconBtn} {...props} size='large' />;
}

function SmallTextIconBtn(props: IconButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return (
    <IconButton
      color='primary'
      className={classes.SmallTextIconBtn}
      {...props}
      size='large' />
  );
}

function OutlinedIconBtn(props: IconButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <IconButton className={classes.OutlinedIconBtn} {...props} size='large' />;
}

function SmallOutlinedIconBtn(props: IconButtonProps) {
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  return <IconButton className={classes.SmallOutlinedIconBtn} {...props} size='large' />;
}

export function CustomIconButton(props : CustomIconButtonProps): ReactElement {
  const { variant, small, ...others } = props;

  if (variant === 'contained') {
    if (small) {
      return (<SmallContainedIconBtn {...others} />);
    }

    return (<ContainedIconBtn {...others} />);
  }
  if (variant === 'text') {
    if (small) {
      return (<SmallTextIconBtn {...others} />);
    }

    return (<TextIconBtn {...others} />);
  }
  if (small) {
    return (<SmallOutlinedIconBtn {...others} />);
  }

  return (<OutlinedIconBtn {...others} />);
}

export function CustomSwitchButton(props: CustomSwitchButtonProps): ReactElement {
  const { label1, label2, value, setValue, ...others } = props;
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  const handleClick = () => {
    value === label1 ? setValue(label2) : setValue(label1);
  };

  return (
    <Button
      disableElevation
      variant='contained'
      className={classes.SwitchButton}
      onClick={handleClick}
      disableRipple
      {...others}
    >
      <div className={classes.InnerSwitchBtn}>
        <div className={value === label1 ? classes.SwitchBtnSelected1 : classes.SwitchBtnSelected2} />
        <div className={value === label1 ? classes.SwitchBtnLabelSelected : classes.SwitchBtnLabel}>
          {label1.charAt(0).toUpperCase() + label1.slice(1)}
        </div>
        <div className={value === label2 ? classes.SwitchBtnLabelSelected : classes.SwitchBtnLabel}>
          {label2.charAt(0).toUpperCase() + label2.slice(1)}
        </div>
      </div>
    </Button>
  );
}

export function CustomSwitchIconButton(props: CustomSwitchIconButtonProps): ReactElement {
  const { label1, label2, label1Icon, label2Icon, value, setValue, small, ...others } = props;
  const theme = useTheme();
  const classes = useButtonsStyle(theme);

  const handleClick = () => {
    value === label1 ? setValue(label2) : setValue(label1);
  };

  return (
    <Button
      disableElevation
      variant='contained'
      className={small ? classes.SmallSwitchButton : classes.SwitchButton}
      onClick={handleClick}
      disableRipple
      size='small'
      {...others}
    >
      <div className={small ? classes.SmallInnerSwitchBtn : classes.InnerSwitchBtn}>
        <div
          className={
            value === label1 ? (small ? classes.SmallSwitchBtnSelected1 : classes.SwitchBtnSelected1) :
              (small ? classes.SmallSwitchBtnSelected2 : classes.SwitchBtnSelected2)
          }
          style={{ width: small ? '32px' : '42px', padding: '0px' }}
        />
        <div className={value === label1 ? classes.SwitchBtnLabelSelected : classes.SwitchBtnLabel} style={{ width: small ? '32px' : '42px', padding: '0px' }}>
          <div style={{ marginTop: '5.5px' }}>{label1Icon}</div>
        </div>
        <div className={value === label2 ? classes.SwitchBtnLabelSelected : classes.SwitchBtnLabel} style={{ width: small ? '32px' : '42px', padding: '0px' }}>
          <div style={{ marginTop: '5.5px' }}>{label2Icon}</div>
        </div>
      </div>
    </Button>
  );
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 'none !important',
    marginBottom: '6px',
    '&:not(:first-of-type)': {
      border: 'none !important',
      borderRadius: `${useTheme().shape.borderRadius}px !important`
    },
    '&:first-of-type': {
      border: 'none !important',
      borderRadius: `${useTheme().shape.borderRadius}px !important`
    }
  },
  groupedHorizontal: {
    '&:not(:first-of-type)': {
      marginLeft: '6px !important'
    }
  },
  groupedVertical: {
    '&:not(:first-of-type)': {
      marginTop: '6px !important'
    }
  }
}));

export function CustomTooltipToggleButton(props: CustomToggleButtonProps): ReactElement {
  const { label, icon, value, disabled, size, orientation, ...others } = props;

  if (disabled) {
    return (
      <Tooltip title={label} arrow placement={orientation === 'vertical' ? 'left' : 'top'}>
        <span style={{ marginLeft: '6px' }}>
          {props.badge ?
            <Badge color='primary' overlap='circular' badgeContent={props.badge}>
              <ToggleButton style={{ border: 'none !important' }} value={value} size={size} {...others} disabled>{icon}</ToggleButton>
            </Badge> :
            <ToggleButton style={{ border: 'none !important' }} value={value} size={size} {...others} disabled>{icon}</ToggleButton>
          }
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={label} arrow placement={orientation === 'vertical' ? 'left' : 'top'}>
      <span style={{ marginLeft: '6px' }}>
        {props.badge ?
          <Badge color='primary' overlap='circular' badgeContent={props.badge}>
            <ToggleButton style={{ border: 'none !important' }} value={value} size={size} {...others}>{icon}</ToggleButton>
          </Badge> :
          <ToggleButton style={{ border: 'none !important' }} value={value} size={size} {...others}>{icon}</ToggleButton>
        }
      </span>
    </Tooltip>
  );
}

export function CustomToggleTextButton(props: CustomToggleTextButtonProps): ReactElement {
  const { label, value, text, disabled, size, ...others } = props;

  if (disabled) {
    return (
      <ToggleButton style={{ border: 'none', textTransform: 'none' }} value={value} size={size} {...others} disabled>{text}</ToggleButton>
    );
  }

  return (
    <ToggleButton style={{ border: 'none', textTransform: 'none' }} value={value} size={size} {...others}>{text}</ToggleButton>
  );
}

export default CustomButton;
