import React, { ReactElement, useState } from 'react';
import DateFieldEditor from '../../field/editors/datefieldeditor';
import QuestionBox from '../box';
import QuestionValue from '../value';
import { QuestionEditorProps, Value, DateQuestionSpec } from '../../../types/types';
import QuestionEditorButton from '../questioneditorbutton';
import QuestionLabel from '../label';

function DateQuestionEditor(props: QuestionEditorProps<DateQuestionSpec>) : ReactElement {
  const [value, setValue] = useState<Value>(props.answer?.value);

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <DateFieldEditor value={value as string || undefined} setValue={setValue}/>
      </QuestionValue>
      <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} />
    </QuestionBox>

  );
}
export default DateQuestionEditor;
