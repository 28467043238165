import React, { ReactElement } from 'react';
import Dialog  from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Grid, Typography } from '@mui/material';
import AumDataGrid from './aumdata';
import CustomButton, { CustomIconButton } from '../button/button';
import AumService from '../../services/aum';
import { AumContributor } from '../../types/types';

function AumDataModal(props: { list: AumContributor[], aumService: AumService, aumType: string, update: () => void }): ReactElement {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CustomButton variant='contained' color='primary' onClick={handleClickOpen} style={{ marginLeft: '16px' }}>
          Add AUM
        </CustomButton>
      </div>
      <Dialog
        disableEnforceFocus
        fullWidth
        maxWidth={props.aumType !== 'manualAUM' ? 'lg' : 'xs'}
        open={open}
        onClose={handleClose}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id='max-width-dialog-title'>
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item>
              <Typography style={{ marginTop: '8px' }} variant='h1'>AUM Selection</Typography>
            </Grid>
            <Grid item>
              <CustomIconButton
                variant='contained'
                color='secondary'
                onClick={handleClose}
                small
              >
                <CloseRoundedIcon />
              </CustomIconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ minHeight: '140px' }}>
          { props.aumType === 'filterAUM' &&
            <AumDataGrid list={props.list} aumService={props.aumService} handleClose={handleClose} update={props.update} />
          }
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AumDataModal;
