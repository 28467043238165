import { Typography, Divider } from '@mui/material';
import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { dataContext } from '../../../../hooks/datatabs';
import { DataDueDiligence, Nullable } from '../../../../types/types';
import { DisplayDataLink, DisplayDataModal } from '../../../dev/displaydata';
import { DeleteDdSection } from '../components/deleteddsection';
import { API_BASE_URL } from '../../../../utils/env';

const useStyles = makeStyles(() => ({
  button: {
    margin: '8px 16px 8px 0'
  },
  titleSpacer: {
    margin: '16px 0'
  },
  fillAuditee: {
    width: '50%',
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

function DeveloperPage(): ReactElement {
  const classes = useStyles();
  const ddMenu = dataContext();
  const dd: Nullable<DataDueDiligence> = ddMenu ? ddMenu.dueDiligence : null;

  if (dd) {
    return (
      <>
        <Typography variant='h1' component='h2' className={classes.titleSpacer}>
          API links
        </Typography>
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/dds/${dd._id}`} text='Entity JSON'/>
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/dds/${dd._id}/elements?limit=1000`} text='Elements JSON' />
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/dds/${dd._id}/possibleDdActions`} text='Possible Actions JSON' />

        <Typography variant='h1' component='h2' className={classes.titleSpacer}>Data Trees</Typography>
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/dddatatrees/dd/${dd._id}/previous`} text='Previous' />
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/dddatatrees/dd/${dd._id}/current`} text='Current' />
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/dddatatrees/dd/${dd._id}/all`} text='All' />
        <Typography variant='h1' component='h2'className={classes.titleSpacer}>
          Testing
        </Typography>
        <Typography className={classes.fillAuditee}>
          Fill auditee answers (50%)
          <DisplayDataLink link={`${API_BASE_URL}/api/v1/dds/${dd._id}/dev/auditeeAnswers?percent=50`} label='50%'/>
        </Typography>
        <Divider style={{ width: '50%' }}/>
        <Typography className={classes.fillAuditee}>
          Fill auditee answers (70%)
          <DisplayDataLink link={`${API_BASE_URL}/api/v1/dds/${dd._id}/dev/auditeeAnswers?percent=70`} label='70%'/>
        </Typography>
        <Divider style={{ width: '50%' }}/>
        <Typography className={classes.fillAuditee}>
          Fill auditee answers (100%)
          <DisplayDataLink link={`${API_BASE_URL}/api/v1/dds/${dd._id}/dev/auditeeAnswers?percent=100`} label='100%'/>
        </Typography>
        <Divider style={{ width: '50%' }}/>
        <Typography variant='h1' component='h2'className={classes.titleSpacer}>
          Validation Items
        </Typography>
        <div>
          <DisplayDataModal url={`${API_BASE_URL}/api/v1/dds/${dd._id}/validationItems/devAddTestItems`} text='Add Validation Item' />
        </div>

        <Typography variant='h1' component='h2' className={classes.titleSpacer}>
          !!! Dangerous Section !!!
        </Typography>
        <DeleteDdSection ddId={dd._id}/>
      </>
    );
  }

  return (
    <></>
  );
}

export default DeveloperPage;
