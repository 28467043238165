import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { TableRow, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Column, Row } from './table';
import { mainRadius } from '../../themes/theme';

interface PropsType {
  rows: Array<Row> | undefined,
  columns: Array<Column>
}

const listRadius = mainRadius;

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& tr': {
      borderRadius: listRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '0.8',
      position: 'relative',
      border: 'none',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out',
        overflow: 'visible'
      }
    }
  },
  row: {
    backgroundColor: theme.palette.background.paper
  },
  innerRow: {
    padding: '12px 12px',
    height: '64px',
    border: 'none',
    "&:first-of-type": {
      borderTopLeftRadius: listRadius,
      borderBottomLeftRadius: listRadius
    },
    "&:last-of-type": {
      borderTopRightRadius: listRadius,
      borderBottomRightRadius: listRadius
    }
  }
}));

function CustomBody(props: PropsType): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { rows, columns } = props;

  return (
    <TableBody className={classes.table}>
      {rows?.map(row => (
        <TableRow role='checkbox' tabIndex={-1} key={row.id} className={ row.className || classes.row}>
          {columns.map((column, i) => (
            <TableCell
              key={column.id} align={column.align || 'left'}
              className={!row.style ? classes.innerRow : undefined}
              style={{ ...row.style, minWidth: column.minWidth }}
            >
              {row.value[i]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export default CustomBody;
