import React, { ReactElement } from 'react';
import CategoriesTree from '../../../../../components/ddelement/categoriestree';
import { FilterParamsManager } from '../../../../../components/entity/list';
import GenericFilters, { defaultFilterComponentFactory, FilterProps } from '../../../../../components/filters/filters';
import { surveyContext } from '../../../../../hooks/datatabs';
import { ApiFilter, ParamFilter } from '../../../../../types/types';

function SurveyFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const filters: Array<ParamFilter> = props.apiFilters ?
    [{ id: 'category', type: 'tree', label: 'Categories' },
      ...props.apiFilters.map(filter => (
        { id: filter.value, type: 'multiSelect' as ParamFilter['type'], label: filter.label, values: filter.elements }
      ))]
    :
    [
      { id: 'category', type: 'tree', label: 'Categories' },
      { id: 'answerStatus', type: 'multiSelect', label: 'Answer Status', values: [{ label: 'answered' }, { label: 'validated' }, { label: 'rejected' }, { label: 'notAnswered' }] }
    ];

  const categories = surveyContext()?.categories;

  const surveyFilterComponentFactory = (props2: FilterProps): ReactElement | undefined => {
    const { filter } = props2;
    switch (filter.type) {
    case 'tree':
      if (categories) {
        return (<CategoriesTree key={filter.id} categories={categories} {...props2} />);
      }

      return undefined;

    default:
      return defaultFilterComponentFactory(props2);
    }
  };

  return (
    <GenericFilters
      fixed
      filters={filters}
      filterParamsManager={props.filterParamsManager}
      filterComponentFactory={surveyFilterComponentFactory}
    />
  );
}

export default SurveyFilters;
