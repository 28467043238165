import React, { ReactElement } from 'react';
import SelectFieldViewer from '../../field/viewers/selectfieldviewer';
import { Answer, QuestionSpec, SelectQuestionSpec, Nullable, ButtonsSettings } from '../../../types/types';
import QuestionBox from "../box";
import QuestionValue from "../value";
import QuestionLabel from '../label';
import QuestionEditorButton from '../questioneditorbutton';

export default function SelectQuestionViewer(props: {spec: QuestionSpec, answer: Nullable<Answer>, setAnswer?: (answer: Nullable<Answer>) => void, buttonsSettings?: ButtonsSettings, disableInput?: string}): ReactElement {
  const spec = props.spec as SelectQuestionSpec;
  const setValue = (value: Nullable<Answer['value']>) => {
    if (props.answer && props.setAnswer) {
      props.setAnswer({
        ...props.answer,
        value
      });
    }
  };
  
  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <SelectFieldViewer spec={spec || undefined} value={props.answer?.value as string}/>
      </QuestionValue>
      {props.setAnswer && props.buttonsSettings &&
        <QuestionEditorButton response={props.answer?.value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} previousAnswer />
      }
    </QuestionBox>
  );
}
