import { BaseEntityService, FundAndMandateProvider } from './data';
import { ApiResponse, Fund, Mandate, Nullable, Strategy } from '../types/types';
import { API } from '../api/api';

export class StrategyService extends BaseEntityService<Strategy> implements FundAndMandateProvider {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/strategies';
  }

  getFunds(strategyId: string): Promise<ApiResponse<Nullable<Array<Fund>>>> {
    return API.get(`${this.getBaseUrl()}/${strategyId}/funds`, { params: { limit: 1000 } })
      .then(res => this.handleResponse<Array<Fund>>(res));
  }

  getMandates(strategyId: string): Promise<ApiResponse<Nullable<Array<Mandate>>>> {
    return API.get(`${this.getBaseUrl()}/${strategyId}/mandates`, { params: { limit: 1000 } })
      .then(res => this.handleResponse<Array<Mandate>>(res));
  }

  setName(strategyId: string, name: string): Promise<ApiResponse<Nullable<unknown>>> {
    return API.put(`${this.getBaseUrl()}/${strategyId}/name`, { name })
      .then(res => this.handleResponse<Nullable<unknown>>(res));
  }
}

export default StrategyService;
