import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, Snackbar, Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import { useTranslation } from 'react-i18next';
import { mainColor } from '../../../themes/theme';
import { CustomTooltipToggleButton, StyledToggleButtonGroup } from '../../button/button';
import { EvalComponentsProps } from './evaluations';
import { Nullable } from '../../../types/types';

function EvalRatings(props: EvalComponentsProps): ReactElement {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [disableAll, setDisableAll] = useState(false);

  const handleRatingChange = (nextView: Nullable<string>, id: string) => {
    setDisableAll(true);
    props.service.setRating(props.ddElement._id, nextView, id)
      .then(() => {
        props.setUpdateList(true);
        setDisableAll(false);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
        setDisableAll(false);
      });
  };

  useEffect(() => {
    setDisableAll(false);
  }, [props.ddEval]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item style={{ marginLeft: '8px', marginTop: '8px', whiteSpace: 'nowrap' }}>
          <Typography variant='h4'>{t('ddElements.rating.label')} :</Typography>
        </Grid>
        <Grid item style={{ display: 'flex', justifyContent: 'center', marginTop: '-8px' }}>
          <StyledToggleButtonGroup
            orientation='horizontal'
            value={props.ddEval.rating}
            exclusive
            onChange={(event, value) => handleRatingChange(value, props.ddEval._id)}
          >
            {!(props.readonly && props.ddEval.rating !== 'ok') &&
              <CustomTooltipToggleButton
                label={t('ddElements.rating.ok')}
                value='ok'
                style={{ color: green[500], opacity: disableAll ? 0.5 : 1 }}
                icon={<ThumbUpAltRoundedIcon />}
                disabled={props.readonly || disableAll}
              />
            }
            {!(props.readonly && props.ddEval.rating !== 'concern') &&
              <CustomTooltipToggleButton
                label={t('ddElements.rating.concern')}
                value='concern'
                style={{ color: mainColor, opacity: disableAll ? 0.5 : 1 }}
                icon={<ErrorRoundedIcon />}
                disabled={props.readonly || disableAll}
              />
            }
            {!(props.readonly && props.ddEval.rating !== 'redFlag') &&
              <CustomTooltipToggleButton
                label={t('ddElements.rating.redFlag')}
                value='redFlag'
                style={{ color: red[500], opacity: disableAll ? 0.5 : 1 }}
                icon={<FlagRoundedIcon />}
                disabled={props.readonly || disableAll}
              />
            }
          </StyledToggleButtonGroup>
        </Grid>
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default EvalRatings;
