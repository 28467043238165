import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import CustomButton, { useButtonsStyle } from '../../button/button';

interface MeetingCreationProps {
	createMeeting: (title: string) => void
}

function MeetingCreationModal(props: MeetingCreationProps): ReactElement{
  const theme = useTheme();
  const classes = useButtonsStyle(theme);
  const { t } = useTranslation();
  const [meetingTitle, setMeetingTitle] = useState<string>('');
  // const [selectedType, setSelectedType] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('test');
    setAnchorEl(event.currentTarget);
  };
  // to use with selectList later on
  /*
	const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedType(event.target.value as string);
	}
	*/

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validateMeeting = () => {
    if (meetingTitle !== '') {
      props.createMeeting(meetingTitle);
      handleClose();
      // setSelectedType('');
      setMeetingTitle('');
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      validateMeeting();
    }
  };

  const editTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setMeetingTitle(e.currentTarget.value);
  };

  // to use with handleTypeChange later on
  /*
		interface SelectListProps {
		options: string[],
		label: string,
		action: (event: React.ChangeEvent<{ value: unknown }>) => void
	}

	const SelectList = (props: SelectListProps): ReactElement => {
		const {options, label, action} = {...props};
		return (
			<FormControl style={{width: '100%', padding: '0 16px'}}>
				<InputLabel style={{width: '100%', padding: '0 20px'}}
					id={`${label}-select-label`}
				>
					{label}
				</InputLabel>
				<Select
          variant='standard'
					labelId={`${label}-select-label`}
					id={`${label}-select`}
					value={selectedType !== '' ? selectedType : ''}
					displayEmpty
					onChange={action}
					fullWidth
				>
					{ options.map( (option, i) =>
						<MenuItem key={option + '-' + i} value={option}>{option}</MenuItem>
					)}
				</Select>
			</FormControl>
		)
	}
	*/

  return (
    <>
      <Button
        disableElevation
        variant='contained'
        color='primary'
        aria-label='actions'
        size='small'
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        startIcon={<AddIcon/>}
        className={classes.ContainedBtn}
        style={{ paddingLeft: '16px', paddingRight: '24px', zIndex: 10 }}
      >
        {t('dds.tabs.meetings.creationModal.createMeeting')}
      </Button>
      <Menu
        id='meeting-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ padding: '16px', marginBottom: '16px' }}
      >
        <Box style={{ width: '300px' }}>
          <Typography style={{ margin: '16px' }} variant='h2' gutterBottom>
            &#8288;{t('dds.tabs.meetings.creationModal.createAMeeting')}
          </Typography>
          <TextField
            variant='standard'
            name='newTitleInput'
            label={t('utils.title')}
            value={meetingTitle}
            onChange={editTitle}
            onKeyDown={onKeyDown}
            helperText={meetingTitle === '' ? 'required' : ''}
            fullWidth
            style={{ width: 'calc(100% - 32px)', marginLeft: '16px' }}
            error={meetingTitle === ''}
          />
          {/* to use when types will be ready
					<SelectList options={['corporate', 'other', '...']} label='Types' action={handleTypeChange}/>
				*/}
          <CustomButton
            variant='contained' color='primary' onClick={validateMeeting} small
            startIcon={<SaveIcon />}
            fullWidth
            style={{ width: 'calc(100% - 32px)', margin: '16px 0 8px 16px' }}
            disabled={meetingTitle === ''}
          >
            {t('btns.create')}
          </CustomButton>
        </Box>
      </Menu>
    </>
  );
}

export default MeetingCreationModal;
