import React, { ReactElement, useEffect, useState } from 'react';
import { Box, CircularProgress, CircularProgressProps, Grid, Tooltip, Typography } from '@mui/material';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import { green, grey, orange } from '@mui/material/colors';
import { LifeCycle } from '../../../types/types';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';
import { mainColor } from '../../../themes/theme';

function CircularProgressWithLabel(props: CircularProgressProps & { value?: number, colorsetter: string, status: string }): ReactElement {
  const inner = <Box
    top={0}
    left={0}
    bottom={0}
    right={0}
    position='absolute'
    display='flex'
    alignItems='center'
    justifyContent='center'
  >
    {(props.status === 'started' || props.status === 'reopened') && (props.value && props.value > 0 ?
      <Typography variant='caption' component='div' color='textSecondary'>{`${Math.round(props.value)}%`}</Typography> :
      <PlayArrowRoundedIcon style={{ color: mainColor }} />
    )}
    {props.status === 'completed' &&
      <DoneRoundedIcon style={{ color: green[500] }} />
    }
    {props.status === 'paused' &&
      <PauseRoundedIcon style={{ color: orange[500] }} />
    }
    {props.status === 'created' &&
      <NewReleasesRoundedIcon style={{ color: grey[400] }} />
    }
  </Box>;

  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress variant='determinate' style={{ color: props.colorsetter, opacity: 0.25 }} value={100}/>
      <CircularProgress variant='determinate' style={{ marginLeft: '-40px', color: props.colorsetter }}  {...props} />
      {props.value ?
        <Tooltip arrow placement='top' title={`${props.value}%`}>
          {inner}
        </Tooltip> :
        inner
      }
    </Box>
  );
}

function HeaderActivities(props: { lifeCycle: LifeCycle }): ReactElement {
  const [largerWidth, setLargerWidth] = useState(0);

  useEffect(() => {
    props.lifeCycle.activities.forEach((activity) => {
      if (activity.label && activity.label.length * 8.5 > largerWidth) {
        setLargerWidth(activity.label.length * 8.5);
      }
    });
  }, [props.lifeCycle.activities]);

  const getColor = (status: string) => {
    switch (status) {
    case 'started' || 'reopened':
      return mainColor;
    case 'paused':
      return orange[500];
    case 'completed':
      return green[500];
    case 'created':
      return grey[500];
    default:
      return mainColor;
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={0}>
        {props.lifeCycle.activities.map(activity => (
          <Grid item xs key={activity.id}>
            <Grid container spacing={0} direction='column'>
              <Grid item style={{ textAlign: 'center' }}>
                <CircularProgressWithLabel value={activity.completion ? Math.round( activity.completion * 100) : undefined} colorsetter={getColor(activity.status)} status={activity.status} />
              </Grid>
              <Grid item style={{ textAlign: 'center', marginTop: '-4px' }}>
                <Typography variant='caption' style={{ textAlign: 'center', padding: '0px 4px' }}>{capitalizeFirstLetter(activity.label?.split(/(?=[A-Z])/)[0])}</Typography>
              </Grid>
              <Grid item style={{ textAlign: 'center', marginTop: '-4px' }}>
                <Typography variant='caption' style={{ textAlign: 'center', padding: '0px 4px' }}>{capitalizeFirstLetter(activity.label?.split(/(?=[A-Z])/)[1])}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default HeaderActivities;
