import { ApiResponse, Nullable } from '../types/types';
import { API } from '../api/api';
import { BaseService } from './data';

interface AccessTokenRes {
  nbUses: number,
  userName: string,
  accessInfo: {
    type: string,
    resourceId: string
  },
  creationTime: Date,
  expirationTime: Date,
  token: string
}

class AccessTokenService extends BaseService {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/accessTokens';
  }

  get(ressourceId: string, type?: string): Promise<ApiResponse<Nullable<AccessTokenRes>>> {
    return API.get(`${this.getBaseUrl()}?resourceId=${ressourceId}&type=${type || 'dd'}`)
      .then(res => this.handleResponse<AccessTokenRes>(res));
  }
}

export default AccessTokenService;
