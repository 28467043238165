import React, { ReactElement } from 'react';
import { AccordionDetails, Grid } from '@mui/material';

function HeaderItems(props: {children: Array<ReactElement> | ReactElement}): ReactElement {
  return (
    <Grid item xs={12}>
      <AccordionDetails>
        <Grid container spacing={2}>
          {props.children}
        </Grid>
      </AccordionDetails>
    </Grid>
  );
}

export default HeaderItems;
