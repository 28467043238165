import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import FolderIcon from '@mui/icons-material/Folder';
import EntityLink from '../modularcomponents/entitylink/entitylink';

const useStyles = makeStyles(() => ({
  button: {
    margin: '8px 16px 8px 0'
  },
  mb16: {
    marginBottom: '16px'
  },
  liBb: {
    maxWidth: '33.33%',
    paddingLeft: 0
  },
  liIcon: {
    minWidth: '40px'
  },
  fillAuditee: {
    width: '50%',
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

function AdminPage(): ReactElement {
  const classes = useStyles();

  return (
    <>
      <Typography variant='h1' component='h2' gutterBottom>Admin</Typography>
      <Typography className={classes.mb16}>This is the admin section of the application</Typography>
      <Typography variant='h3' component='h3' gutterBottom>View All</Typography>
      <List style={{ marginBottom: '24px' }}>
        <ListItem className={classes.liBb}>
          <ListItemIcon className={classes.liIcon}>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText>
            <EntityLink type='admin' id='importgroups'>
                All Imported Files
            </EntityLink>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.liBb}>
          <ListItemIcon className={classes.liIcon}>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText>
            <EntityLink type='admin' id='imports'>
                Archived Due Diligences
            </EntityLink>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.liBb}>
          <ListItemIcon className={classes.liIcon}>
            <ChromeReaderModeIcon />
          </ListItemIcon>
          <ListItemText>
            <EntityLink type='admin' id='ddtemplates'>
                Due Diligences Templates
            </EntityLink>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.liBb}>
          <ListItemIcon className={classes.liIcon}>
            <ChromeReaderModeIcon />
          </ListItemIcon>
          <ListItemText>
            <EntityLink type='admin' id='ddelementgroups'>
                Element Groups Templates
            </EntityLink>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.liBb}>
          <ListItemIcon className={classes.liIcon}>
            <ChromeReaderModeIcon />
          </ListItemIcon>
          <ListItemText>
            <EntityLink type='admin' id='ddreportblocks'>
                Report Blocks Templates
            </EntityLink>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
}

export default AdminPage;
