import React, { ReactElement } from 'react';
import Countryfieldviewer from './countryfieldviewer';
import StringFieldViewer from './stringfieldviewer';
import Currencyfieldviewer from './currencyfieldviewer';
import TextFieldViewer from './textfieldviewer';
import BooleanFieldViewer from './booleanfieldviewer';
import NumberFieldViewer from './numberfieldviewer';
import Datefieldviewer from './datefieldviewer';
import { AmountType, BaseValueTypes, QuestionSpec, SelectQuestionSpec } from '../../../types/types';
import AmountFieldViewer from './amountfieldviewer';
import PercentFieldViewer from './percentfieldviewer';
import SelectFieldViewer from './selectfieldviewer';
import MultiSelectFieldViewer from './multiselectfieldviewer';
import YearFieldViewer from './yearfieldviewer';

export default function MultiTypeFieldViewer(props: { type: string, value?: BaseValueTypes, specs?: QuestionSpec }): ReactElement {
  switch (props.type) {
  case 'amount':
    return (
      <AmountFieldViewer value={props.value as AmountType}/>
    );
  case 'string':
    return (
      <StringFieldViewer value={props.value as string}/>
    );
  case 'number':
    return (
      <NumberFieldViewer value={props.value as string} specs={props.specs}/>
    );
  case 'percent':
    return (
      <PercentFieldViewer value={props.value as string}/>
    );
  case 'currency':
    return (
      <Currencyfieldviewer value={props.value as string}/>
    );
  case 'date':
    return (
      <Datefieldviewer value={props.value as string}/>
    );
  case 'text':
    return (
      <TextFieldViewer value={props.value as string}/>
    );
  case 'country':
    return (
      <Countryfieldviewer value={props.value as string}/>
    );
  case 'boolean':
    return (
      <BooleanFieldViewer value={props.value as string}/>
    );
  case 'location':
    return (
      <StringFieldViewer value={props.value as string}/>
    );
  case 'yearCountDown':
    return (
      <YearFieldViewer value={props.value as number}/>
    );
  case 'select':
    return (
      <>
        {props.specs && <SelectFieldViewer spec={props.specs as SelectQuestionSpec} value={props.value as string} />}
      </>
    );
  case 'multiselect':
    return (
      <>
        {props.specs && <MultiSelectFieldViewer spec={props.specs as SelectQuestionSpec} value={props.value as string[]} />}
      </>
    );
  default:
    return (<></>);
  }
}
