import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ManagerValidationInfo } from '../../types/types';
import { InputStatCard } from './InputStatCard';

interface ManagerValidationPhaseProps {
	managerInfo?: ManagerValidationInfo
}

export default function ManagerValidationPhase(props: ManagerValidationPhaseProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      {props.managerInfo &&
        <Grid
          container
          style={{ backgroundColor: 'white', padding: '16px', borderRadius: '24px', marginBottom: '16px' }}
        >
          <Grid item xs={12} style={{ marginBottom: '16px' }}>
            <Typography variant='h1' component='h2'>{t('dds.phases.managerValidationPhase')}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2} style={{ maxWidth: '800px' }}>
              <InputStatCard
                count={props.managerInfo?.requiresValidation}
                title={t('dds.reports.scoring.status.requiresValidation')}
                link='managerView' color='lightGrey'
              />
              <InputStatCard
                count={props.managerInfo?.validated}
                title={t('dds.reports.scoring.status.validated')}
                link='managerView'
                color='lightGreen'
              />
              <InputStatCard
                count={props.managerInfo?.rejected}
                title={t('dds.reports.scoring.status.rejected')}
                link='managerView'
                color='lightRed'
              />
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
}
