import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Grid, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import * as _ from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import CustomButton, { CustomIconButton } from '../button/button';
import { Answer, ButtonsSettings, Nullable } from '../../types/types';
import { StyledMenu } from '../layout/navbar/navbar';

interface QuestionEditorButtonProps {
  response: Answer['value'],
  setResponse: (answer: Nullable<Answer['value']>) => void,
  defaultValue: Answer['value'],
  buttonsSettings: ButtonsSettings,
  previousAnswer?: true,
  hideDraft?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '16px',
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'right'
    },
    button: {
      marginLeft: '12px'
    }
  })
);

function QuestionEditorButton(props: QuestionEditorButtonProps) : ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(props.buttonsSettings.disabled);
  const [checkedFlag, setCheckedFlag] = useState(props.buttonsSettings.disableInput);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    setDisabled(false);
  }, [props.response, props.buttonsSettings.disabled]);

  const handleOnClickSubmit = () => {
    setDisabled(true);
    if ((props.response !== undefined && props.response !== null) || checkedFlag) {
      const answerInfo: string[] = props.buttonsSettings.answerInfo ? props.buttonsSettings.answerInfo : [];

      answerInfo[0] = checkedFlag || '';
      props.buttonsSettings.onSubmit(checkedFlag ? null : props.response, answerInfo.length > 0 ? answerInfo : undefined);
    } else if (props.buttonsSettings.answerInfo) {
      props.buttonsSettings.onSubmit(null, props.buttonsSettings.answerInfo);
    }
  };

  const handleOnClickEditPreviousAnswer = () => {
    if (props.buttonsSettings.setDisableInput && props.buttonsSettings.answerInfo && props.buttonsSettings.answerInfo[0]) {
      props.buttonsSettings.setDisableInput(props.buttonsSettings.answerInfo[0]);
    }
    props.setResponse(props.response);
  };

  const handleOnClickSaveAsDraft = () => {
    setDisabled(true);
    if ((props.response !== undefined && props.response !== null) || props.buttonsSettings.answerInfo?.[0] !== checkedFlag) {
      const answerInfo: string[] = props.buttonsSettings.answerInfo ? props.buttonsSettings.answerInfo : [];

      answerInfo[0] = checkedFlag || '';
      props.buttonsSettings.onSaveAsDraft(checkedFlag ? null : props.response, answerInfo.length > 0 ? answerInfo : undefined);
    }
  };

  const handleOnClickCancel = () => {
    setDisabled(true);
    props.setResponse(props.defaultValue);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleCheckFlag = (checkedValue: string) => {
    if (!checkedValue) {
      setCheckedFlag(undefined);
      props.buttonsSettings.setDisableInput && props.buttonsSettings.setDisableInput(checkedValue);
    } else if (checkedValue !== 'notDisclosed') {
      setCheckedFlag(checkedValue);
      props.buttonsSettings.setDisableInput && props.buttonsSettings.setDisableInput(checkedValue);
      props.setResponse(null);
    }
  };

  const isDisabled = (value: Answer['value']) => {
    if (checkedFlag && (props.buttonsSettings.answerInfo?.[props.buttonsSettings.answerInfo.length - 1] !== checkedFlag || props.buttonsSettings.draft)) {
      return false;
    }
    if (props.buttonsSettings.draft && !props.buttonsSettings.disabled) {
      return props.response === undefined || props.response === null;
    }

    return (
      (value == null) ||
      (Object.prototype.hasOwnProperty.call(value, 'length') && (value as Array<unknown>).length === 0) ||
      (value.constructor === Object && Object.keys(value).length === 0) ||
      value === props.defaultValue ||
      _.isEqual(props.response, props.defaultValue) ||
      props.buttonsSettings.disabled ||
      disabled ||
      _.isEqual(value, {}) ||
      _.isEqual(value, [])
    );
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (checkedFlag?.includes('notDisclosed')) {
      setCheckedFlag(undefined);
      props.buttonsSettings.setDisableInput && props.buttonsSettings.setDisableInput('');
    }
    setAnchorEl(event.currentTarget);
  };

  const handleToReview = (toReview: boolean) => {
    if (toReview) {
      setCheckedFlag('notDisclosedToReview');
      props.buttonsSettings.setDisableInput && props.buttonsSettings.setDisableInput('notDisclosedToReview');
      props.setResponse(null);
    } else {
      setCheckedFlag('notDisclosedNotToReview');
      props.buttonsSettings.setDisableInput && props.buttonsSettings.setDisableInput('notDisclosedNotToReview');
      props.setResponse(null);
    }
    setAnchorEl(null);
  };

  const renderMenu = (
    <StyledMenu
      disableScrollLock
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Grid container spacing={1} style={{ padding: '8px 16px', width: '260px' }} justifyContent='space-around'>
        <Grid item xs={12}>
          <Typography>
            {t('ddElements.tabs.question.submit.toBeReviewed')} ?
          </Typography>
        </Grid>
        <Grid item>
          <MenuItem style={{ width: 'min-content', borderRadius: '25px' }} onClick={() => handleToReview(true)} >
            {t('btns.yes')}
          </MenuItem>
        </Grid>
        <Grid item>
          <MenuItem style={{ width: 'min-content', borderRadius: '25px' }} onClick={() => handleToReview(false)} >
            {t('btns.no')}
          </MenuItem>
        </Grid>
      </Grid>
    </StyledMenu>
  );

  return (
    <>
      { !props.buttonsSettings.hideBtns &&
        <form onSubmit={onSubmit} style={{ width: '100%', display: 'flex' }}>
          {!props.previousAnswer &&
            <ToggleButtonGroup
              orientation='horizontal'
              value={checkedFlag?.includes('notDisclosed') ? 'notDisclosed' : checkedFlag}
              style={{ marginTop: 'auto' }}
              exclusive
              onChange={(event, value) => handleCheckFlag(value)}
            >
              <ToggleButton
                color='primary'
                value='notDisclosed'
                style={{ height: '32px', textTransform: 'none' }}
                onClick={handleProfileMenuOpen}
                disabled={props.previousAnswer}
              >
                <Typography color={props.previousAnswer ? 'grey' : 'primary'}>
                  {checkedFlag?.includes('Review') ?
                    checkedFlag?.includes('NotTo') ?
                      t('ddElements.status.notDisclosed.noReview') : t('ddElements.status.notDisclosed.toReview')
                    :
                    t('ddElements.status.notDisclosed.label')
                  }
                </Typography>
              </ToggleButton>
              <ToggleButton
                color='primary'
                value='notApplicable'
                style={{ height: '32px', textTransform: 'none' }}
                disabled={props.previousAnswer}
              >
                <Typography color={props.previousAnswer ? 'grey' : 'primary'}>{t('ddElements.status.notApplicable')}</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          }
          <div className={classes.root}>
            { !props.hideDraft &&
            <div className={classes.button}>
              {props.previousAnswer ?
                <CustomButton
                  variant='outlined'
                  color='primary'
                  style={{ width: '150px' }}
                  onClick={handleOnClickEditPreviousAnswer}
                >
                  {t('btns.edit')}
                </CustomButton>
                :
                <CustomButton
                  type='submit'
                  variant='outlined'
                  color='primary'
                  onClick={handleOnClickSaveAsDraft}
                  disabled={
                    (props.response && _.isEqual(props.response, props.defaultValue) && !checkedFlag ||
                      isDisabled(props.response)) ||
                    (props.buttonsSettings.answerInfo?.[props.buttonsSettings.answerInfo.length - 1] === checkedFlag
                    && !!checkedFlag)
                  }
                  style={{ width: '150px' }}
                >
                  {t('btns.saveDraft')}
                </CustomButton>
              }
            </div>}
            <div className={classes.button}>
              <CustomButton
                type='submit'
                variant='contained'
                color='primary'
                onClick={handleOnClickSubmit}
                disabled={props.previousAnswer ? false : ((isDisabled(props.response) || props.buttonsSettings.notSubmittable))}
                style={{ width: '150px' }}
              >
                {t('btns.submit')}
              </CustomButton>
            </div>
            { !props.hideDraft &&
              <div className={classes.button}>
                <CustomIconButton
                  type='submit'
                  variant='contained'
                  color='secondary'
                  onClick={handleOnClickCancel}
                  disabled={ _.isEqual(props.response, props.defaultValue) || (!!props.response && isDisabled(props.response))}
                >
                  <CloseRoundedIcon />
                </CustomIconButton>
              </div>
            }
          </div>
          {renderMenu}
        </form>
      }
    </>
  );
}
export default QuestionEditorButton;
