import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
  Divider,
  Snackbar
  , Alert } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  AbstractContactsAssignmentService,
  AbstractParticipantsAssignmentsService,
  GlobalContactsService,
  StrategyContactsService,
  InvestMgrsContactsService,
  AssetMgrsContactsService, FundsContactsService
} from '../../../services/contacts';
import CustomButton, { CustomIconButton, CustomSwitchButton } from '../../button/button';
import ServiceError from '../../../services/errors';
import { ApiResponse, AuditedEntity, Contact, Nullable, Participant } from '../../../types/types';
import { ErrorStringManager } from '../../Error/errorpagemanager';
import ParticipantsRoles from './participantsroles';
import SelectList from '../selectlist';
import { useContactSidePanelLayoutStyle } from '../contactsTable';

interface AddParticipantProps {
  participants: Array<Participant>,
  participantsService: AbstractParticipantsAssignmentsService<Participant>,
  auditedEntity: AuditedEntity,
  setUpdate: Dispatch<SetStateAction<boolean>>,
  onClose: () => void
}

const useStyles = makeStyles(() => ({
  buttonsTop: {
    marginBottom: '24px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  roles: {
    width: '100%',
    marginTop: '24px',
    marginBottom: '12px'
  },
  rolesTitle: {
    margin: '12px 0px'
  },
  rolesLabels: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  rolesLabel: {
    marginRight: '12px'
  }
}));

function AddParticipant(props: AddParticipantProps ): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const contactClasses = useContactSidePanelLayoutStyle();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [contactList, setContactList] = useState<Nullable<Array<Contact>>>(null);
  const [participantType, setParticipantType] = useState('internal');
  const [contactSelected, setContactSelected] = useState<Array<Contact>>([]);
  const [rolesSelected, setRolesSelected] = useState<Array<string>>([]);
  const [switchDisabled, setSwitchDisabled] = useState(true);
  let externalContactService: Nullable<AbstractContactsAssignmentService<Contact>> = null;
  const globalContactService = new GlobalContactsService();

  const removeParticipantsFromContacts = (contacts: Array<Contact>) => contacts.filter(item => (
    props.participants.every(participant => participant.id !== item.id))
  );

  switch (props.auditedEntity.type) {
  case 'strategy':
    externalContactService = new StrategyContactsService(props.auditedEntity.id);
    break;
  case 'investMgr':
    externalContactService = new InvestMgrsContactsService(props.auditedEntity.id);
    break;
  case 'assetMgr':
    externalContactService = new AssetMgrsContactsService(props.auditedEntity.id);
    break;
  case 'fund':
    externalContactService = new FundsContactsService(props.auditedEntity.id);
    break;
  default:
    break;
  }

  useEffect(() => {
    setSwitchDisabled(true);
    setContactList([]);
    setContactSelected([]);
    setError(null);
    if (participantType === 'external' && externalContactService) {
      externalContactService.getAll()
        .then((apiResp) => {
          apiResp.data && setContactList(removeParticipantsFromContacts(apiResp.data));
          setSwitchDisabled(false);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
          setSwitchDisabled(false);
        });
    } else {
      globalContactService.getAllInternal()
        .then((apiResp) => {
          apiResp.data && setContactList(removeParticipantsFromContacts(apiResp.data));
          setSwitchDisabled(false);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
          setSwitchDisabled(false);
        });
    }
  }, [participantType]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAdd = () => {
    const participantsToAdd: Array<Participant> = contactSelected.map(contact => (
      {
        ...contact,
        external: participantType === 'external',
        roles: rolesSelected
      }
    ));
    let promise: Promise<ApiResponse<Nullable<Participant>>> | undefined;

    participantsToAdd.forEach((participant) => {
      if (!promise) {
        promise = props.participantsService.assign(participant.id, participant.external, participant.roles);
      } else {
        promise = promise
          .then(() => props.participantsService.assign(participant.id, participant.external, participant.roles));
      }
    });

    promise && promise
      .then(() => {
        props.onClose();
        props.setUpdate(true);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <div className={classes.buttonsTop}>
        <CustomSwitchButton label1={t('utils.internal')} label2={t('utils.external')} value={participantType} setValue={setParticipantType} disabled={!externalContactService || switchDisabled}/>
        <div>
          <CustomIconButton
            variant='outlined'
            onClick={props.onClose}
          >
            <CloseRoundedIcon />
          </CustomIconButton>
        </div>
      </div>
      {error ? <ErrorStringManager error={error} /> :
        contactList ? <SelectList contactList={contactList} contactSelected={contactSelected} setContactSelected={setContactSelected} enableSelectAll /> : <>Loading...</>
      }
      <Divider />
      <ParticipantsRoles participantType={participantType} external={participantType === 'external'} rolesSelected={rolesSelected} setRolesSelected={setRolesSelected} />
      <div className={contactClasses.buttons}>
        <div className={contactClasses.btn}>
          <CustomButton
            disabled={contactSelected.length < 1 || rolesSelected.length < 1}
            variant='contained'
            style={{ width: '100%' }}
            onClick={handleAdd}
          >
            {t('btns.add')}
          </CustomButton>
        </div>
      </div>
      <Snackbar onClose={handleCloseSnackbar} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddParticipant;
