import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Paper,
  Grid,
  Typography,
  Snackbar,
  SelectChangeEvent,
  Alert
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { CustomIconButton } from '../button/button';
import { currencies } from '../field/editors/currencyfieldeditor';
import { useStyles } from '../ddelement/listitem';
import AumService from '../../services/aum';
import ServiceError from '../../services/errors';
import { Aum, BaseAumContributor } from '../../types/types';

function ManualAum(props: {name: string, type: string, aum?: Aum, aumService: AumService, update: () => void, readonly?: boolean }): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [label, setLabel] = useState(props.type === 'manual' ? props.name : '');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [aumAmount, setAumAmount] = useState<number>(0);
  const [currency, setCurrency] = useState('EUR');
  const [editionMode, setEditionMode] = useState(false);

  useEffect(() => {
    if (props.aum) {
      // here set if aum is manual in dd
      setAumAmount(props.type === 'manual' ? parseFloat(props.aum.amount?.amount || '0') : 0);
      setCurrency(props.type === 'manual' ? props.aum.amount?.currency || 'EUR' : 'EUR');
    }
    setLabel(props.type === 'manual' ? props.name : '');
  }, [props]);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value as string);
  };

  const validAuM = () => {
    const myManualAum: BaseAumContributor = {
      label,
      amount: {
        amount: aumAmount, currency
      },
      type: 'manual'
    };

    props.aumService.setAUM([myManualAum])
      .then(() => {
        props.update();
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
      });
    setEditionMode(false);
  };

  const handleManualAumAmount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAumAmount(event.target.value as number);
  };

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Grid container spacing={1} style={{ height: '100%', marginTop: '-2px' }}>
              <Grid item style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '12px' }}>
                {editionMode ?
                  <TextField
                    variant='standard'
                    id='standard-basic'
                    type='text'
                    label={t('utils.name')}
                    value={label}
                    style={{ marginTop: '-8px', marginLeft: '12px' }}
                    onChange={event => setLabel(event.target.value)}
                    disabled={!editionMode}
                  /> :
                  <Typography>{props.type === 'manual' ? label : t('dds.tabs.parameters.aums.noManualAum')}</Typography>
                }
              </Grid>
              <Grid item style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '24px' }}>
                {editionMode ?
                  <TextField
                    variant='standard'
                    id='standard-basic'
                    type='number'
                    label={t('inputs.types.amount')}
                    value={aumAmount}
                    style={{ marginTop: '-8px', marginLeft: '12px' }}
                    onChange={handleManualAumAmount}
                    disabled={!editionMode}
                  /> :
                  <Typography variant='h3'>{aumAmount}</Typography>
                }
              </Grid>
              <Grid item style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {editionMode ?
                  <FormControl
                    style={{ marginTop: '-8px', minWidth: '80px' }}
                  >
                    <InputLabel>{t('utils.currency')}</InputLabel>
                    <Select
                      variant='standard'
                      value={currency}
                      onChange={handleChange}
                      disabled={!editionMode}
                    >
                      { currencies.map(curr => (
                        <MenuItem key={curr.value} value={curr.label} selected>{curr.value}</MenuItem>
                      ))}
                      {/* <MenuItem value='EUR' selected>EUR</MenuItem>
                    <MenuItem value='USD'>USD</MenuItem>
                    <MenuItem value='GBP'>GBP</MenuItem>
                    <MenuItem value='JPY'>GPY</MenuItem> */}
                    </Select>
                  </FormControl> :
                  <Typography variant='h3'>{currency}</Typography>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {!props.readonly && (editionMode ?
                <>
                  <Grid item>
                    <Tooltip title={t('btns.apply')} arrow>
                      <div style={{ marginRight: '4px' }}>
                        <CustomIconButton
                          variant='contained'
                          onClick={validAuM}
                          small
                        >
                          <DoneRoundedIcon />
                        </CustomIconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title={t('btns.cancel')} arrow>
                      <div style={{ marginRight: '12px' }}>
                        <CustomIconButton
                          variant='contained'
                          color='secondary'
                          onClick={() => setEditionMode(false)}
                          small
                        >
                          <CloseRoundedIcon />
                        </CustomIconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                </> :
                <Grid item>
                  <Tooltip title={t('btns.edit')} arrow>
                    <div style={{ marginRight: '12px' }}>
                      <CustomIconButton
                        variant='contained'
                        onClick={() => setEditionMode(true)}
                        small
                      >
                        <EditRoundedIcon />
                      </CustomIconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ManualAum;
