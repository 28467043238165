import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Fade, Grid, Modal, Typography } from '@mui/material';
import { mainRadius } from '../../../themes/theme';
import CustomButton from '../../button/button';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      borderRadius: mainRadius,
      width: '600px'
    },
    title: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '12px',
      width: 'fit-content',
      textAlign: 'center'
    }
  })
);

function CustomValidationModal(props: {action1: () => void, action1Title: string, action1Color: string, action2: () => void, action2Title: string, action2Color: string, cancel: () => void, open: boolean, msg: string, disabled?: boolean}): ReactElement {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={props.open}
        onClose={props.cancel}
        closeAfterTransition
      >
        <Fade in={props.open}>
          <div
            className={classes.paper}
            style={{
              backgroundColor: theme.palette.background.paper,
              padding: theme.spacing(2, 4, 3)
            }}
          >
            <Grid container spacing={4} justifyContent='space-between'>
              <Grid item xs={12}>
                <Typography className={classes.title}  variant='h2' id='transition-modal-title'>{props.msg}</Typography>
              </Grid>
              <Grid item xs={6}>
                <CustomButton onClick={props.action1} variant='contained' style={{ backgroundColor: props.action1Color, opacity: props.disabled ? 0.5 : 1 }} disabled={props.disabled} fullWidth>
                  {props.action1Title}
                </CustomButton>
              </Grid>
              <Grid item xs={6}>
                <CustomButton onClick={props.action2} variant='contained' style={{ backgroundColor: props.action2Color, opacity: props.disabled ? 0.5 : 1 }} disabled={props.disabled} fullWidth>
                  {props.action2Title}
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default CustomValidationModal;
