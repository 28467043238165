import React, { ReactElement } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EntityIcon from '../entityicon/entityicon';
import EntityLink from '../entitylink/entitylink';

interface EntityLinkWithIconProps {
  type: string,
  id: string,
  name:  string,
  params?: string,
  className?: string,
  survey?: boolean
}

function MyListItem({ icon, value, className }: {icon?: ReactElement, value: string, className?: string}): ReactElement {
  return (
    <ListItem style={{ paddingLeft: '0px' }}>
      {icon ?
        <ListItemIcon style={{ minWidth: '40px' }}>
          {icon}
        </ListItemIcon> : <></>
      }
      <ListItemText primary={value} className={className} style={{ width: 'min-content', whiteSpace: 'nowrap' }} />
    </ListItem>
  );
}

function EntityLinkWithIcon(props: EntityLinkWithIconProps): ReactElement {
  return (
    <EntityLink type={props.type} id={props.id} params={props.params} disabled={props.survey} iconMargin='11px 0 0 -11px' openInNewTabIcon>
      <MyListItem icon={EntityIcon({ type: props.type })} value={props.name} className={props.className} />
    </EntityLink>
  );
}

export default EntityLinkWithIcon;
