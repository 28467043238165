import React, { ReactElement } from 'react';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import { DdQuestionElement } from '../../types/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    }
  })
);

export default function QuestionView(props: { question: DdQuestionElement }) : ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (props.question.answers.auditee?.value) {
    return (
      <div className={classes.root}>
        <h3>Question</h3>
        <Typography>{`Answer  : ${props.question.answers.auditee?.value}`}</Typography>

      </div>
    );
  }

  return (
    <div>
      <h3>Question</h3>
      <Typography>No answer yet</Typography>
    </div>
  );
}
