import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Grid } from '@mui/material';

import EditActions from './EditActions';
import CancelOrSaveActions from './CancelOrSaveActions';

export interface ToolbarProps {
	isEditable?: boolean,
	isEditing: boolean,
	isPopulate?: boolean | string | undefined,
	isSplitOptionAvailable?: boolean,
	setSplitAction?: Dispatch<SetStateAction<boolean>>,
	setDiffView?: Dispatch<SetStateAction<boolean>>,
	editAction: () => void,
	cancelAction: () => void,
	deleteAction?: () => void,
	saveAction: (value: unknown) => void,
	populateAction?: () => void,
	isNoSplitVisible?: boolean,
	isHorizontal?: boolean,
	isVerticalSplit?: boolean,
	isVerticalSplitOpened?: boolean,
	isDiffView?: boolean,
	isSaveNeeded?: boolean,
	noTags?: boolean,
  dispOnlyChanges?: boolean,
  setDispOnlyChanges?: Dispatch<SetStateAction<boolean>>
}

export default function Toolbar(props: ToolbarProps): ReactElement {
  const { isEditable, isEditing, isPopulate, editAction, cancelAction, deleteAction, saveAction, populateAction, isSplitOptionAvailable, setSplitAction, setDiffView, isNoSplitVisible, isVerticalSplitOpened, isDiffView, isHorizontal, isSaveNeeded, dispOnlyChanges, setDispOnlyChanges } = props;

  const shouldDisplayEditBlock = () => {
    let verdict;
    if (!isEditable && !isEditing) verdict = true;
    if (isEditable && isEditing) verdict = false;
    if (isEditable && !isEditing) verdict = true;

    return verdict;
  };

  return (
    <Grid container style={{ paddingLeft: '16px' }}>
      { shouldDisplayEditBlock() ?
        <EditActions
          editAction={editAction}
          isEditable={isEditable}
          isPopulate={isPopulate}
          populateAction={isPopulate ? populateAction : undefined}
          isSplitOptionAvailable={isSplitOptionAvailable}
          setSplitAction={setSplitAction}
          setDiffView={setDiffView}
          isNoSplitVisible={isNoSplitVisible}
          isHorizontal={isHorizontal}
          isVerticalSplitOpened={isVerticalSplitOpened}
          isDiffView={isDiffView}
          dispOnlyChanges={dispOnlyChanges}
          setDispOnlyChanges={setDispOnlyChanges}
        /> :
        <CancelOrSaveActions
          cancelAction={cancelAction}
          deleteAction={deleteAction}
          saveAction={saveAction}
          isHorizontal={isHorizontal}
          isSaveNeeded={isSaveNeeded}
          noTags={props.noTags}
        />
      }
    </Grid>
  );
}
