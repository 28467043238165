import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import UploadFiles from '../../../upload/uploadfiles';
import { TemplateImportService } from '../../../../services/import';
import { mainRadius } from '../../../../themes/theme';
import CustomButton from '../../../button/button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '24px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '0.9',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out'
      }
    }
  }));

const authorizedTypes = ['.xlsx', '.xlsm', '.xlsb', '.xltx', '.xltm', '.xls', '.xlt', '.xml', '.xlam', '.xla', '.xlw', '.xlr'];

function TemplateImport(): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          Import Templates
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/admin/importGroups'>
          <CustomButton variant='contained'>Show all imported files</CustomButton>
        </Link>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'left' }}>
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/admin/ddtemplates'>
          <CustomButton variant='contained'>Show all imported templates</CustomButton>
        </Link>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant='h2'>Or</Typography>
      </Grid>
      {/* <DdImportUploader /> */}
      <Grid item xs={12}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UploadFiles
                service={new TemplateImportService()}
                update={() => undefined}
                authorizedTypes={authorizedTypes}
                uploadButtonLabel='Click here to upload Templates'
                baseUrl='/admin/importgroups/'
                multiple
                dispUploaded
                childBeforeBtn
              >
                <Typography variant='h2'>Drag and drop here the file to import or</Typography>
              </UploadFiles>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default TemplateImport;
