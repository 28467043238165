import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { Paper } from '@mui/material';
import Typography from "@mui/material/Typography";
import CustomButton from '../../button/button';
import { useStyles } from '../../ddelement/listitem';

export default function ErrorPage( props : {errTitle: string, errMessage: string}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();

  return (
    <Paper elevation={0} className={classes.paper} style={{ height: 'calc(100vh - 56px - 33px - 56px)', margin: '12px' }}>
      <Grid container spacing={0} justifyContent='center' style={{ height: '100%' }}>
        <Grid item>
          <Grid container direction='column' spacing={2} justifyContent='center' style={{ width: 'max-content', maxWidth: '320px', height: '100%' }}>
            <Grid item>
              <Typography style={{ fontSize: '80px', fontWeight: '700', lineHeight: '60px' }}>
                {props.errTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: '24px', fontWeight: '500', lineHeight: '28px' }}>
                Something went
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: '-12px' }}>
              <Typography style={{ fontSize: '28px', fontWeight: '700', lineHeight: '28px' }}>
                WRONG!
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>
                {props.errMessage}
              </Typography>
            </Grid>
            <Grid item style={{ width: '200px' }}>
              <CustomButton variant='contained' onClick={() => navigate('/')} fullWidth>
                Back to Home
              </CustomButton>
            </Grid>
            <Grid item style={{ width: '200px', textAlign: 'center', marginTop: '-12px' }}>
              <Typography variant='caption'>
                or contact your webmaster
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ height: 'min-content', marginTop: 'auto', marginBottom: 'auto' }}>
          <img
            src={`${process.env.PUBLIC_URL  }/asset/gif/giphy.gif`}
            alt=''
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
