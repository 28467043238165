import countries from '../data/countries.json';

export interface Country {
    code2: string,
    code3: string,
    label: string
}

export class CountryManager {
  countries: Array<Country>;

  countriesByCode2: Map<string, Country>;

  countriesByCode3: Map<string, Country>;

  private static instance: CountryManager;

  /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
  public static getInstance(): CountryManager {
    if (!CountryManager.instance) {
      CountryManager.instance = new CountryManager(countries);
    }

    return CountryManager.instance;
  }

  private constructor(myCountries: Array<Country>) {
    this.countries = myCountries;
    this.countriesByCode2 = new Map();
    this.countriesByCode3 = new Map();

    myCountries.forEach((country) => {
      this.countriesByCode2.set(country.code2, country);
      this.countriesByCode3.set(country.code3, country);
    });
  }

  getByCode2(code: string): Country | undefined {
    return this.countriesByCode2.get(code);
  }

  getByCode3(code: string): Country | undefined {
    return this.countriesByCode3.get(code);
  }
}
