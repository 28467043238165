import { useTheme } from '@mui/material/styles';
import React, { ReactElement, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import TablePagination from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { Box, Grid, Table, TableContainer, TableFooter, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ParamsPager } from '../../paging';

interface CustomTablePagination2Props {
  paramsPager: ParamsPager,
  totalItems: number
}

const useStyles = makeStyles(() => ({
  actions: {
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '38px',
    minHeight: '38px'
  }
}));

export function PaginationActions(props: TablePaginationActionsProps): ReactElement {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  return (
    <div style={{ marginLeft: '24px', display: 'table-cell', whiteSpace: 'nowrap' }}>
      <Grid container spacing={1} style={{ width: 'max-content' }}>
        <Grid item>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label='first page'
            size='small'
          >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label='previous page'
            size='small'
          >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='next page'
            size='small'
          >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='last page'
            size='small'
          >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

function CustomPagination({ paramsPager, totalItems }: CustomTablePagination2Props): ReactElement {
  const classes = useStyles();
  const onPageChanged = (event: unknown, newPage: number) => {
    paramsPager.onPageChanged(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  const [currentPage, setCurrentPage] = useState(0);

  const onRowsPerPageChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = parseInt(event.target.value, 10);
    paramsPager.onPageSizeChanged(newValue);
  };

  useEffect(() => {
    const page = paramsPager.getPage();

    setCurrentPage(page);
  }, [paramsPager]);

  const labelDisplayedRows = ({ from, to, count, page } : {from: number, to: number, count: number, page: number}) => <Box>{`Page ${page + 1} : ${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}</Box>;

  return (
    <Grid container justifyContent='center' style={{ padding: '4px 0px', marginBottom: '-8px' }}>
      { (totalItems > 0 || currentPage > 0) &&
        <Grid item>
          <TableContainer>
            <Table stickyHeader >
              <TableFooter>
                <TableRow>
                  <TablePagination
                    classes={{ toolbar: classes.actions }}
                    rowsPerPageOptions={paramsPager.getPageSizeOptions()}
                    colSpan={3}
                    count={totalItems}
                    rowsPerPage={paramsPager.getPageSize()}
                    page={currentPage}
                    labelDisplayedRows={labelDisplayedRows}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true
                    }}
                    onPageChange={onPageChanged}
                    onRowsPerPageChange={ onRowsPerPageChanged }
                    ActionsComponent={PaginationActions}
                    style={{ borderBottom: 'none' }}
                    // component={TableCell}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      }
    </Grid>
  );
}

export default CustomPagination;
