import React, { ReactElement, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Permissions from './permissions.json';

function SecureDisplay(props: {permissions?: string, children: Array<ReactElement> | ReactElement, optionalChild?: Array<ReactElement> | ReactElement}): ReactElement {
  const { keycloak } = useKeycloak();
  const [access, setAccess] = useState(false);

  !access && Permissions.forEach((permission) => {
    if (permission.label === props.permissions) {
      permission.roles.map(role => keycloak.hasResourceRole(role)).some(item => item) && setAccess(true);
    }
  });

  if (!props.permissions) {
    return <>{props.children}</>;
  }

  if (props.optionalChild) {
    return (
      <>
        {(access) ?
          props.children
          :
          props.optionalChild
        }
      </>
    );
  }

  return (
    <>
      {(access) && props.children}
    </>
  );
}

export default SecureDisplay;
