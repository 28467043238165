import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputInfo } from '../../types/types';
import { InputStatCard } from './InputStatCard';

interface InputPhaseStatsProps {
	phaseInfo: InputInfo
}

export default function InputPhaseStats(props: InputPhaseStatsProps): ReactElement {
  const { phaseInfo } = props;
  const { t } = useTranslation();

  return (
    <Grid container style={{ backgroundColor: 'white', padding: '16px', borderRadius: '24px', marginBottom: '16px' }}>
      <Grid item xs={12} style={{ marginBottom: '16px' }}>
        <Typography variant='h1' component='h2'>{t('dds.phases.inputPhase')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} style={{ marginBottom: '16px' }}>
          <InputStatCard count={phaseInfo.total} total={phaseInfo.total} title={t('utils.total')} color='white' />
          <InputStatCard count={phaseInfo.answered} total={phaseInfo.total} showPercent title={t('ddElements.status.answered')} color='blue' link='analysis?f_answerStatus=answered' />
          <InputStatCard count={phaseInfo.notAnswered} total={phaseInfo.total} showPercent title={t('ddElements.status.notAnswered')} color='lightGrey' link='analysis?f_answerStatus=notAnswered' />
          <InputStatCard count={phaseInfo.validated} total={phaseInfo.total} showPercent title={t('ddElements.status.validated')} color='lightGreen' link='analysis?f_answerStatus=validated' />
          <InputStatCard count={phaseInfo.rejected} total={phaseInfo.total} showPercent title={t('ddElements.status.rejected')} color='lightRed' link='analysis?f_answerStatus=rejected' />
          <InputStatCard count={phaseInfo.auditeeDraft} lessThan title={t('ddElements.status.draft.answerAsDraftAuditee')} color='lightBlue' />
        </Grid>
      </Grid>
    </Grid>
  );
}
