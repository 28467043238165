import React, { ReactElement } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import secureTab from '../../authentification/securetab';
import { useTabsStyles } from './dynamictabs';
import { CustomIconButton } from '../../button/button';
import { TabsType } from '../../../types/types';

function DisplayTabs({ tabs }: {tabs: TabsType}): ReactElement {
  const [value, setValue] = React.useState(0);
  const { pathname, search } = useLocation();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const classes = useTabsStyles();

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
    navigate(`${tabs[newValue].path}`);
  };

  const tabName = pathname.split('/').pop() || '';
  const tabNb = tabs.findIndex(e => e.path.includes(tabName));

  if (value !== tabNb && tabNb > -1) {
    setValue(tabNb);
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          className={classes.tabs}
          variant='scrollable'
          classes={{ scroller: classes.scroller }}
          TabScrollButtonProps={{ className: classes.scrollButtons }}
          TabIndicatorProps={{
            className: classes.tabsIndicator
          }}
        >
          {tabs.map(tab => (
            secureTab(tab.label, keycloak) ?
              <Tab key={tab.label} label={tab.label} className={classes.tab} classes={{ selected: classes.selectedTab }} disableRipple /> :
              undefined
          )).filter(x => !!x)}
        </Tabs>
      </Grid>
      <Grid item>
        <CustomIconButton
          variant='text'
          onClick={() => window.open(`/deeligenz/${pathname}${search}`, '_blank', '')}
          small
        >
          <OpenInNewRoundedIcon />
        </CustomIconButton>
      </Grid>
    </Grid>
  );
}

export default DisplayTabs;
