import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp'; // fr populate btn
import FilterCenterFocusRoundedIcon from '@mui/icons-material/FilterCenterFocusRounded';
import { CustomIconButton, CustomTooltipToggleButton } from '../../button/button';
import VerticalButtons from '../verticalbuttons';

interface EditActionsProps {
	isEditable?: boolean,
	editAction: () => void,
	isPopulate?: boolean | string | undefined,
	populateAction?: () => void,
	isSplitOptionAvailable?: boolean,
  setSplitAction?: Dispatch<SetStateAction<boolean>>,
  setDiffView?: Dispatch<SetStateAction<boolean>>,
	isNoSplitVisible?: boolean,
	isHorizontal?: boolean, // can be used to change aspect of toolbar width when string
	isVerticalSplitOpened?: boolean,
  isDiffView?: boolean,
  dispOnlyChanges?: boolean,
  setDispOnlyChanges?: Dispatch<SetStateAction<boolean>>
}

export default function EditActions(props: EditActionsProps): ReactElement {
  const { isEditable, editAction, isPopulate, populateAction, isSplitOptionAvailable, setSplitAction, setDiffView, isNoSplitVisible, isVerticalSplitOpened, isDiffView, dispOnlyChanges, setDispOnlyChanges } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState(!!dispOnlyChanges);

  useEffect(() => {
    setValue(!!dispOnlyChanges);
  }, [dispOnlyChanges]);

  return (
    <Grid container spacing={2} flexDirection='column'>
      <Grid item>
        <Tooltip title={isEditable ? t('btns.edit') : t('dds.tabs.reports.toolbar.notEditable')} arrow placement='left'>
          <div>
            <CustomIconButton
              aria-label='edit mode'
              variant='contained'
              onClick={editAction}
              disabled={!isEditable}
              small
            >
              <EditIcon fontSize='small' />
            </CustomIconButton>
          </div>
        </Tooltip>
      </Grid>
      {isPopulate &&
        <Grid item>
          <Tooltip title={isEditable ? t('dds.tabs.reports.toolbar.populate') : t('dds.tabs.reports.toolbar.notEditable')} arrow placement='left'>
            <div style={{ marginRight: '12px', marginTop: '8px' }}>
              <CustomIconButton
                aria-label='edit mode'
                variant='contained'
                onClick={populateAction}
                disabled={!isEditable}
                small
              >
                <GetAppIcon fontSize='small'/>
              </CustomIconButton>
            </div>
          </Tooltip>
        </Grid>
      }
      {/* {isSplitOptionAvailable &&
				<Tooltip title={'versions comparison'} arrow>
				<div style={{ marginRight: '12px', marginTop: '8px' }}>
					<CustomIconButton
						aria-label='edit mode'
						variant={'contained'}
						onClick={splitAction}
						disabled={false}
						small
					>
						<VerticalSplitIcon fontSize='small'/>
					</CustomIconButton>
				</div>
				</Tooltip>
			} */}
      {!isNoSplitVisible && setSplitAction && setDiffView &&
        <Grid item>
          <VerticalButtons
            isVerticalSplitOpened={isVerticalSplitOpened}
            isDiffView={isDiffView}
            setSplitAction={setSplitAction}
            setDiffView={setDiffView}
            disabled={!isSplitOptionAvailable}
          />
        </Grid>
      }
      {!isNoSplitVisible && isDiffView && setDispOnlyChanges &&
        <Grid item>
          <CustomTooltipToggleButton
            orientation='vertical'
            color='primary'
            label={t('dds.tabs.reports.toolbar.dispOnlyChanges')}
            value='check'
            selected={value}
            disabled={!isSplitOptionAvailable}
            style={{ transform: 'scale(0.8)', border: 'none', marginTop: '-6px' }}
            onChange={() => {
              setDispOnlyChanges(!value);
            }}
            size='small'
            icon={<FilterCenterFocusRoundedIcon />}
          />
        </Grid>
      }
    </Grid>
  );
}
