import React, { ReactElement } from 'react';
import ServiceError from '../../services/errors';
import ErrorPage from './errorpage/errorpage';

function ErrorPageManager( props: { error: ServiceError }) : ReactElement {
  if (props.error.isUnAuthenticated()) {
    window.location.reload();
  }

  return (
    <>
      {
        props.error.isAccessDenied() && <ErrorPage errTitle='403' errMessage='Forbidden' /> ||
        props.error.isNotFound() && <ErrorPage errTitle='404' errMessage='Page Not Found' /> ||
        props.error.isDataFound() && <h1>data not found</h1> ||
        props.error.isDataError() && <h1>Serveur Error</h1> ||
        <ErrorPage errTitle={props.error.httpStatus.toString()} errMessage={ServiceError.getErrorMsg(props.error)} />
      }
    </>
  );
}

export function ErrorStringManager( props: { error: ServiceError }) : ReactElement {
  if (props.error.isUnAuthenticated()) {
    window.location.reload();
  }

  return (
    <>
      {
        props.error.isAccessDenied() && <h4>403 : Forbidden</h4> ||
        props.error.isNotFound() && <h4>404 : Page Not Found</h4> ||
        props.error.isDataFound() && <h4>data not found</h4> ||
        props.error.isDataError() && <h4>Serveur Error</h4> ||
        <h4>{props.error.httpStatus.toString()} : {ServiceError.getErrorMsg(props.error)}</h4>
      }
    </>
  );
}

export default ErrorPageManager;
