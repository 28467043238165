import { Fund } from '../types/types';
import { BaseEntityService } from './data';

export class FundService extends BaseEntityService<Fund> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/funds';
  }
}

export default FundService;
