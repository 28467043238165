import React, { ReactElement } from "react";

import Input from '@mui/material/Input';
import { ValueSetter } from '../../../types/types';

function StringFieldEditor(props: {value?: string, setValue: ValueSetter, required?: boolean, customKey?: string } ) : ReactElement {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.setValue(event.target.value);
  };

  return (
    <Input key={props.customKey} error={props.required ? !props.value : false} defaultValue={props.value} onChange={event => handleChange(event)}/>
  );
}
export default StringFieldEditor;
