import React, { ReactElement } from 'react';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

function NoAum(): ReactElement {
  const { t } = useTranslation();

  return (
    <Paper elevation={0} style={{
      backgroundColor: 'white',
      borderRadius: '24px',
      height: '62px',
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '24px'
    }}>
      {t('dds.tabs.parameters.aums.noAUM')}
    </Paper>
  );
}

export default NoAum;
