import { API } from '../api/api';
import {
  Answer,
  ApiResponse,
  DdElement,
  DdEval,
  DdNewEval, DeleteDocumentReturn,
  KeyFinding,
  Nullable,
  Value
} from '../types/types';
import { BaseUploadService } from './data';

type QuestionServiceReturnType = Promise<ApiResponse<Nullable<Answer>>>;
type EvalServiceReturnType = Promise<ApiResponse<Nullable<Array<DdEval>>>>;

export default class QuestionService extends BaseUploadService {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1';
  }

  getEvals(id: string): EvalServiceReturnType {
    return API.get( `${this.getBaseUrl()}/ddelements/${id}/criteriaEvals`)
      .then(res => this.handleResponse<Nullable<Array<DdEval>>>(res));
  }

  addEval(id: string, newEval: DdNewEval): EvalServiceReturnType {
    return API.post( `${this.getBaseUrl()}/ddelements/${id}/criteriaEvals`, newEval)
      .then(res => this.handleResponse<Nullable<Array<DdEval>>>(res));
  }

  setRating(id: string, rating: Nullable<string>, evalId: string): EvalServiceReturnType {
    return API.get( `${this.getBaseUrl()}/ddelements/${id}/criteriaEvals/${evalId}/setRating?rating=${rating}`)
      .then(res => this.handleResponse<Nullable<Array<DdEval>>>(res));
  }

  setFlags(id: string, flags: Array<string>, evalId: string): EvalServiceReturnType {
    return API.get( `${this.getBaseUrl()}/ddelements/${id}/criteriaEvals/${evalId}/setFlags?flags=${flags.toString()}`)
      .then(res => this.handleResponse<Nullable<Array<DdEval>>>(res));
  }

  setKeyFinding(id: string, evalId: string, keyFinding?: KeyFinding): EvalServiceReturnType {
    return API.put( `${this.getBaseUrl()}/ddelements/${id}/criteriaEvals/${evalId}/setKeyFinding`, { keyFinding })
      .then(res => this.handleResponse<Nullable<Array<DdEval>>>(res));
  }

  setMeeting(id: string, evalId: string, meeting?: string): EvalServiceReturnType {
    return API.put( `${this.getBaseUrl()}/ddelements/${id}/criteriaEvals/${evalId}/setMeeting`, { meeting })
      .then(res => this.handleResponse<Nullable<Array<DdEval>>>(res));
  }

  setRemarks(id: string, remarks: string): Promise<ApiResponse<Nullable<DdElement>>> {
    return API.put( `${this.getBaseUrl()}/ddelements/${id}/remarks`, { remarks })
      .then(res => this.handleResponse<Nullable<DdElement>>(res));
  }

  setMeetingRemarks(id: string, remarks: string): Promise<ApiResponse<Nullable<DdElement>>> {
    return API.put( `${this.getBaseUrl()}/ddelements/${id}/meetingRemarks`, { remarks })
      .then(res => this.handleResponse<Nullable<DdElement>>(res));
  }

  saveAsAuditee(id: string, value: Value, draft: boolean, surveyId: string, answerInfo?: string[]): QuestionServiceReturnType {
    return API.put( `${this.getBaseUrl()}/surveys/${surveyId}/questions/${id}/answer`, { value, draft,  answerInfo })
      .then(res => this.handleResponse<Nullable<Answer>>(res));
  }

  saveAsAuditor(id: string, value: Value, draft: boolean, answerInfo?: string[]): QuestionServiceReturnType {
    return API.put( `${this.getBaseUrl()}/ddelements/${id}/answers/auditor`, { value, draft, answerInfo })
      .then(res => this.handleResponse<Nullable<Answer>>(res));
  }

  revertAsAuditee(id: string, surveyId: string): Promise<ApiResponse<Nullable<string>>> {
    return API.patch( `${this.getBaseUrl()}/surveys/${id}/questions/${surveyId}/answer`, { draft: true })
      .then(res => this.handleResponse<Nullable<string>>(res));
  }

  deleteAsAuditee(id: string, surveyId: string): Promise<ApiResponse<Nullable<string>>> {
    return API.delete( `${this.getBaseUrl()}/surveys/${id}/questions/${surveyId}/answer`)
      .then(res => this.handleResponse<Nullable<string>>(res));
  }

  validate(id: string): QuestionServiceReturnType {
    return API.get( `${this.getBaseUrl()}/ddelements/${id}/actions/validate`)
      .then(res => this.handleResponse<Nullable<Answer>>(res));
  }

  reject(id: string): QuestionServiceReturnType {
    return API.get( `${this.getBaseUrl()}/ddelements/${id}/actions/reject`)
      .then(res => this.handleResponse<Nullable<Answer>>(res));
  }
}

export class UploadQuestionService extends QuestionService {
  ddElementId: string;

  role?: string;

  ddId?: string;

  url: string;

  constructor(ddElementId: string, role?: string, ddId?: string) {
    super();
    this.ddElementId = ddElementId;
    this.role = role;
    this.ddId = ddId;

    switch (this.role) {
    case 'auditee':
      this.url = `${this.getBaseUrl()}/surveys/${this.ddId}/questions/${this.ddElementId}/answer/files`;
      break;
    case 'auditor':
      this.url = `${this.getBaseUrl()}/ddelements/${this.ddElementId}/answers/auditor/files`;
      break;
    default:
      this.url = '';
    }
  }

  getUploadUrl(): string {
    return this.url;
  }

  getDownloadURL(docId: string): string {
    return API.getUri({ url: `${this.getBaseUrl()}/ddelements/${this.ddElementId}/download/${docId}` });
  }

  delete(id: string): Promise<ApiResponse<Nullable<DeleteDocumentReturn>>> {
    return API.delete( `${this.url}/${id}`)
      .then(res => this.handleResponse<Nullable<DeleteDocumentReturn>>(res));
  }
}
