import React, { ReactElement, useEffect, useState } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { lighten, useTheme } from '@mui/material/styles';
import { Categories, DueDiligenceMenu } from '../../types/types';
import { FiltersStyledMenu, useFiltersStyles } from '../filters/filters';
import { SearchType } from '../search/search';
import { getTreesFromCategories } from '../../utils/dataforcontext';
import { mainColor, mainRadius } from '../../themes/theme';

interface CategoriesTreeProps extends SearchType {
  categories: Array<Categories>
}

const useStyles = makeStyles(() =>
  createStyles({
    treeItemContent: {
      borderRadius: `${mainRadius} !important`,
      padding: '0px 8px',
      transition: 'all 0.2s ease-in-out'
    },
    treeItemLabel: {
      borderRadius: `${mainRadius} !important`,
      padding: '0px 8px',
      transition: 'all 0.2s ease-in-out'
    },
    "@global": {
      ".MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label": {
        backgroundColor: 'transparent',
        transition: 'all 0.2s ease-in-out'
      },
      ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
        backgroundColor: mainColor,
        color: lighten(mainColor, 0.85),
        padding: '0px 8px',
        transition: 'all 0.2s ease-in-out'
      },
      ".MuiTreeItem-root:hover > .MuiTreeItem-content .MuiTreeItem-label": {
        color: 'black',
        transition: 'all 0.2s ease-in-out'
      },
      transition: 'all 0.2s ease-in-out'
    },
    root: {
      width: 'min-content',
      padding: '12px',
      paddingRight: '24px'
    }
  })
);

function CategoriesTree(props: CategoriesTreeProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles();
  const filtersClasses = useFiltersStyles(theme);
  const { tree, treeList } = getTreesFromCategories(props.categories);
  const [expanded, setExpanded] = useState(['root', ...treeList.map(menu => menu.path)]);
  const [category, setCategory] = useState(props.filterParamsManager.getFilter(props.filter.id)?.value ||'*/');
  const [label, setLabel] = useState(treeList.find(item => item.path === category.replace('root', '').replace('/*', '').replaceAll('/', '.'))?.label || '');
  const defaultSelect = category.replaceAll('/', '.').slice(0, -2);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const [selected, setSelected] = useState(defaultSelect);

  useEffect(() => {
    props.update && setCategory('*/');
  }, [props.update]);

  useEffect(() => {
    if (category.length > 2) {
      props.filterParamsManager.onFilterChanged(props.filter.id, category);
    } else {
      props.filterParamsManager.onFilterRemoved(props.filter.id);
    }
  }, [category]);

  const handleOnCLick = (nodes: DueDiligenceMenu, event: React.MouseEvent<HTMLLIElement>) => {
    if (!event.target.toString().includes('SVG')) {
      setCategory(`${nodes.path.replace('root', '').replaceAll('.', '/')}/*`);
      setLabel(nodes.label);
      setSelected(nodes.path);
      setAnchorEl(null);
    } else if (expanded.includes(nodes.path)) {
      setExpanded(expanded.filter(item => item !== nodes.path));
    } else {
      setExpanded([...expanded, nodes.path]);
    }
  };

  const renderTree = (nodes: DueDiligenceMenu) => (
    <TreeItem
      key={nodes.path}
      classes={{
        content: classes.treeItemContent,
        label: classes.treeItemLabel
      }}
      style={{ whiteSpace: 'nowrap' }}
      nodeId={nodes.path}
      label={nodes.label}
      onClick={event => handleOnCLick(nodes, event)}>
      {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
    </TreeItem>
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.categories &&
        <>
          <Button
            variant='outlined'
            onClick={handleClick}
            color='primary'
            className={category.length > 2 ? filtersClasses.button : filtersClasses.button}
            size='small'
            endIcon={anchorEl ? <ExpandLessRoundedIcon/> : <ExpandMoreRoundedIcon/>}
          >
            {category.length > 2 ? label : 'All categories'}
          </Button>
          <FiltersStyledMenu
            disableScrollLock
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            variant='selectedMenu'
          >
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpanded={['root', ...treeList.map(menu => menu.path)]}
              selected={selected}
              expanded={expanded}
              defaultExpandIcon={<ChevronRightIcon />}
              defaultSelected={defaultSelect.length === 0 ? 'root' : defaultSelect}
            >
              {[
                renderTree({ path: 'root', label: 'All Categories', children: tree })
              ]}
            </TreeView>
          </FiltersStyledMenu>
        </>
      }
    </>
  );
}

export default CategoriesTree;
