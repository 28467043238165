import React, { ReactElement } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
  Route,
  Navigate,
  Routes
} from 'react-router-dom';
import { TabSpecs, TabsInfo } from '../types/types';
import secureTab from '../components/authentification/securetab';

function DynamicTabsRouter({ tabsInfo, tabSpecs }: { tabsInfo: TabsInfo, tabSpecs: TabSpecs }): ReactElement {
  const { keycloak } = useKeycloak();

  return (
    <Routes>
      <Route path='/' element={<Navigate to={`${tabsInfo.tabs[0]?.id || 'summary'}`} replace />} />
      {tabsInfo.tabs.map((tabInfo) => {
        const tabSpec = tabSpecs.find(e => e.id === (tabInfo?.id || 'summary'));

        return (
          (tabSpec && secureTab(tabSpec.label, keycloak)) ?
            <Route key={tabSpec.label} path={`${tabSpec.id}/*`} element={tabSpec.component}/> :
            undefined
        );
      }).filter(x => !!x)}
    </Routes>
  );
}

export default DynamicTabsRouter;
