import { Paper, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { useTranslation } from 'react-i18next';

const colorSwitcher = (rank: number | string | undefined) => {
  switch (rank) {
  case '1': case 'A' : case '2' : case 'B' : case '3' : case 'C' :
    return '#7cb342';
  case '4' : case 'D' :
    return '#ee7f01';
  case '5' : case 'E' :
    return '#e53935'; // red
  default:
    return 'RGB(97, 97, 97)';
  }
};

const useStyles = makeStyles({
  root: {
    borderRadius: '3px',
    width: '38px',
    height: '38px',
    textAlign: 'center',
    lineHeight: '32px',
    background: 'transparent',
    borderStyle: 'solid',
    borderWidth: '2px',
    paddingTop: '1px'
  }
});

interface RatingProps {
	rank: string | number | undefined,
	displayAsLetter?: boolean,
  tooltip?: string,
	isComputed?: boolean,
  withIcon?: boolean,
  hideNA?: boolean
}

function Rating(props: RatingProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rank, displayAsLetter } = props;
  const letters = ['', 'A', 'B', 'C', 'D', 'E'];

  return (
    <>
      { (props.hideNA && (!rank || rank === '0')) ?
        <>
        </> :
        <Tooltip arrow title={props.tooltip || (props.isComputed ? t('dds.ratings.computedRating') : t('dds.ratings.currentRating'))}>
          <Paper
            className={classes.root}
            elevation={0}
            style={{
              borderColor: colorSwitcher(rank),
              color: colorSwitcher(rank),
              borderStyle: props.isComputed ? 'dashed' :  'solid'
            }}>
            {props.withIcon ?
              <>
                {props.isComputed &&
                  <SettingsRoundedIcon
                    style={{ position: 'relative', top: '11px', left: '-3px', transform: 'scale(0.5)' }} />
                }
                {rank && rank !== '0' ?
                  <span
                    style={{
                      position: 'relative',
                      fontSize: props.isComputed ? '14px' : '20px',
                      left: props.isComputed ? '-5px' : undefined,
                      top: props.isComputed ? '-7px' : undefined
                    }}
                  >
                    {displayAsLetter ? letters[Number(rank)] : rank}
                  </span> :
                  <span style={{ fontSize: props.isComputed ? '10px' : '14px' }}>{t('utils.notApplicable')}</span>
                }
              </> :
              <>
                {rank && rank !== '0' ?
                  <span style={{ fontSize: '20px' }}>{displayAsLetter && parseInt(rank as string) >= 0 && parseInt(rank as string) <= 5 ? letters[Number(rank)] : rank}</span> :
                  <span style={{ fontSize: '14px' }}>{t('utils.notApplicable')}</span>
                }
              </>
            }
          </Paper>
        </Tooltip>
      }
    </>
  );
}

export default Rating;
