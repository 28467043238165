import React, { ReactElement } from 'react';
import { fundContext } from '../../../hooks/datatabs';
import { EntityDdList } from '../../duediligences/listing/entityddlist';

function FundDdList(): ReactElement {
  const fundData = fundContext();

  return (
    <>
      {fundData && fundData._id &&
        <EntityDdList entityType='fund' entityId={fundData._id} entityTypeText='fund' />
      }
    </>
  );
}

export default FundDdList;
