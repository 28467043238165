import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

interface VerticalSplittedComparisonProps {
	previousValue?: string,
	currentValue?: string
}

export default function VerticalSplittedComparison (props: VerticalSplittedComparisonProps): ReactElement {
  const { previousValue, currentValue } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h3'>
              Previous Value :
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(marked.parse(previousValue || '')) } }  />
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h3'>
              Current Value :
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(marked.parse(currentValue || '')) } }  />
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
