import React, { ReactElement, useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import { NumberQuestionSpec, ValueSetter } from '../../../types/types';
import { addCommas, removeNonNumeric } from './amountfieldeditor/amountfieldeditor';

export default function NumberFieldEditor(props: { value: number, setValue: ValueSetter, specs?: NumberQuestionSpec, customKey?: string }) : ReactElement {
  const [value, setValue] = useState(props.value ? addCommas(props.value.toString()) : '');

  useEffect(() => {
    props.value || props.value === 0 ? setValue(addCommas(props.value.toString())) : setValue('');
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value.length < 1) {
      props.setValue('');
      setValue('');
    } else {
      const isValidValue = event.target.value.substr(event.target.value.length - 1) !== '.' && event.target.value !== '-' && !Number.isNaN(parseFloat(removeNonNumeric(event.target.value)));

      if (isValidValue) {
        props.setValue(parseFloat(removeNonNumeric(event.target.value)));
        setValue(addCommas(parseFloat(removeNonNumeric(event.target.value)).toString()));
      } else {
        setValue(addCommas(event.target.value));
      }
    }
  };

  return (
    <TextField
      key={props.customKey}
      variant='standard'
      type='text'
      value={value}
      onChange={event => handleChange(event)}
      InputProps={{
        inputProps: { type: 'text', min: props.specs?.min, max: props.specs?.max, step: 'any' },
        endAdornment: props.specs?.unit && <InputAdornment position='end'>{props.specs.unit}</InputAdornment>
      }}
      required
      error={props.specs?.required ? props.value !== 0 && !props.value : false}
    />
  );
}
