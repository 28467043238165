import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Paper, Snackbar, Tooltip, Alert, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CustomTable, { Column } from '../table/table';
import { Contact, Nullable } from '../../types/types';
import CustomButton, { CustomIconButton } from '../button/button';
import { AbstractContactsAssignmentService } from '../../services/contacts';
import EditContacts  from './editcontacts';
import AddContact from './addcontact';
import { mainRadius } from '../../themes/theme';

export const useContactListStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex'
  },
  sideContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '24px',
    marginTop: '24px',
    padding: '24px',
    minWidth: '340px',
    width: 'calc(100% - 24px)',
    borderRadius: mainRadius,
    '&:hover': {
      zIndex: '9',
      boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.2s ease-in-out'
    }
  },
  table: {
    width: '100%',
    transition: 'all 0.3s ease-out'
  },
  tableWithEditContact: {
    maxWidth: 'calc(100% - 340px)',
    overflow: 'auto',
    transition: 'all 0.3s ease-out'
  },
  editEmpty: {
    minWidth: 0,
    width: 0,
    transition: 'all 0.3s ease-out'
  },
  edit: {
    minWidth: '340px',
    width: '30%',
    transition: 'all 0.3s ease-out'
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  actions: {
    display: 'flex',
    height: '38px',
    justifyContent: 'flex-end'
  },
  close: {
    position: 'absolute',
    marginLeft: 'calc(100% - 85px)'
  },
  addRight: {
    textAlign: 'right',
    transition: 'all 0.2s ease-in-out'
  },
  addLeft: {
    textAlign: 'left',
    transition: 'all 0.2s ease-in-out'
  }
}));

export const useContactSidePanelLayoutStyle = makeStyles(() => ({
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  btn: {
    width: '100%'
  },
  title: {
    marginTop: '1.5px'
  }
}));

function ContactsTable(props: {contactsList: Array<Contact>, setUpdate: Dispatch<SetStateAction<boolean>>, service: AbstractContactsAssignmentService<Contact>}): ReactElement {
  const theme = useTheme();
  const classes = useContactListStyles(theme);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [contactToEdit, setContactToEdit] = useState<Nullable<Contact>>(null);
  const [openAddContact, setOpenAddContact] = useState<boolean>(false);

  const columns: Array<Column> = [
    { id: 'avatar', label: '', minWidth: 'min-content' },
    { id: 'name', label: 'Name', minWidth: 'min-content' },
    { id: 'email', label: 'Email', minWidth: 'min-content' },
    { id: 'phoneNumber', label: 'Phone', minWidth: 'min-content' },
    { id: 'title', label: 'Title', minWidth: 'min-content' },
    { id: 'actions', label: '', minWidth: 'min-content', align: 'right' }
  ];

  const handleRemove = (contactToRemove: Contact) => {
    props.service.remove(contactToRemove.id)
      .then(() => {
        props.setUpdate(true);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  const handleEdit = (contact: Contact) => {
    setContactToEdit(contact);
    setOpenAddContact(true);
  };

  const rows = props.contactsList.map(contact => ({
    id: contact.id,
    value: columns.map(column => (
      <>
        {column.id === 'avatar' &&
              <Avatar className={classes.avatar} alt={`${contact.firstName}.${contact.lastName}`} src=''>
                {contact.firstName.charAt(0) + contact.lastName.charAt(0) || ''}
              </Avatar>
        }
        {column.id === 'name' && `${contact.firstName} ${contact.lastName}`}
        {column.id === 'title' && contact[column.id] }
        {column.id === 'email' && contact[column.id] }
        {column.id === 'phoneNumber' && contact[column.id] }
        {column.id === 'actions' &&
            <div className={classes.actions}>
              <Tooltip title='Edit' arrow>
                <div style={{ marginRight: '12px' }}>
                  <CustomIconButton
                    variant='contained'
                    onClick={() => handleEdit(contact)}
                    disabled={openAddContact}
                    small
                  >
                    <EditRoundedIcon />
                  </CustomIconButton>
                </div>
              </Tooltip>
              <Tooltip title='Remove' arrow>
                <div>
                  <CustomIconButton variant='contained' color='secondary' small onClick={() => handleRemove(contact)}>
                    <RemoveRoundedIcon />
                  </CustomIconButton>
                </div>
              </Tooltip>
            </div>
        }
      </>
    ))
  }));

  const handleAdd = () => {
    setOpenAddContact(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={openAddContact ? classes.tableWithEditContact : classes.table}>
          {props.contactsList.length > 0 && <CustomTable columns={columns} rows={rows} size='medium' />}
          <div className={props.contactsList.length > 0 ? classes.addRight : classes.addLeft}>
            <CustomButton
              disabled={openAddContact}
              key='add'
              variant='contained'
              onClick={handleAdd}
            >
              {props.contactsList.length > 0 ? 'Add Contact' : 'Add your first contact'}
            </CustomButton>
          </div>
        </div>
        <div className={openAddContact ? classes.edit : classes.editEmpty}>
          { openAddContact &&
          <Paper elevation={0} className={classes.sideContainer}>
            <div className={classes.close}>
              <CustomIconButton
                variant='outlined'
                onClick={() => {
                  setOpenAddContact(false);
                  setContactToEdit(null);
                }}
                small
                style={{ marginLeft: 'auto' }}
              >
                <CloseRoundedIcon />
              </CustomIconButton>
            </div>
            { contactToEdit ?
              <EditContacts
                contactToEdit={contactToEdit}
                setContactToEdit={setContactToEdit}
                setUpdate={props.setUpdate}
                onClose={() => setOpenAddContact(false)}
              /> :
              <AddContact
                contacts={props.contactsList}
                contactsService={props.service}
                setUpdate={props.setUpdate}
                onClose={() => setOpenAddContact(false)}
              />
            }
          </Paper>
          }
        </div>
        <Snackbar onClose={handleCloseSnackbar} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleCloseSnackbar} style={{ marginTop: '20px' }} severity='error'>
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default ContactsTable;
