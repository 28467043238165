import React, { ReactElement, useRef, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { DialogActions, Grid, Theme, Tooltip } from '@mui/material';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import { t } from 'i18next';
import { DdService } from '../../services/dds';
import { CustomIconButton } from '../button/button';
import { PDFViewerDialogProps } from './pdfmodal';

const styles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    transition: 'all .5s'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[800],
    height: '32px',
    width: '32px',
    lineHeight: '32px',
    fontSize: '16px',
    backgroundColor: theme.palette.grey[200]
  }
}));

export interface DialogTitleProps {
  id: string,
  children: React.ReactNode,
  onClose: () => void
}

function DialogTitle(props: DialogTitleProps): ReactElement {
  const { children, onClose, ...other } = props;
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

const DialogContent = styled(MuiDialogContent)(() => ({
  '& .MuiDialogContent-root': {
    padding: useTheme().spacing(2)
  }
}));

function ImgViewerDialog(props: PDFViewerDialogProps): ReactElement {
  const [open, setOpen] = React.useState(false);
  const ddService = new DdService();
  const url = props.handleDisplay ? props.handleDisplay(props.documentId) : ddService.getDownloadURL(props.ddId, props.documentId);
  const [file] = useState(url);
  const [zoom, setZoom] = useState(false);
  const el = useRef<null | HTMLDivElement>(null);
  const defaultWidth = 300;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setZoom(false);
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <Tooltip title={t('btns.view')} arrow>
        <div>
          <CustomIconButton variant='text' onClick={handleClickOpen} small>
            <VisibilityRoundedIcon />
          </CustomIconButton>
        </div>
      </Tooltip>
      <Dialog
        onClose={handleClose} aria-labelledby='pdf-dialog' open={open}
        maxWidth={false}
      >
        <DialogTitle id='image-dialog' onClose={handleClose}>
          <Typography variant='h3'>{ props.documentTitle }</Typography>
        </DialogTitle>
        <DialogContent ref={el}>
          <img
            src={file || undefined}
            alt={props.documentTitle}
            style={{ width: zoom ? `${defaultWidth * 2.4 }px` : `${defaultWidth}px` }}
          />
        </DialogContent>
        <DialogActions>
          <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CustomIconButton variant='contained' aria-label='zoom'  small onClick={() => setZoom(!zoom)}>
              { zoom ? <ZoomOutRoundedIcon /> : <ZoomInRoundedIcon /> }
            </CustomIconButton>
            <CustomIconButton variant='contained' onClick={() => props.handleDownload(props.documentId)} small style={{ marginLeft: '16px' }}>
              <GetAppOutlinedIcon />
            </CustomIconButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImgViewerDialog;
