import React, { ReactElement, useEffect, useState } from 'react';
import { ArrowUpwardRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { CustomIconButton } from '../../button/button';

function ScrollTopBtn(props: {isVisible?: boolean, scrollToTop?: () => void}): ReactElement {
  const [onHover, setOnHover] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    setIsVisible(false);
    if (props.scrollToTop) {
      props.scrollToTop();
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
        setOnHover(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {(isVisible || props.isVisible) &&
        <>
          <Box
            style={{
              position: 'fixed',
              zIndex: 9999,
              bottom: onHover ? 0 : '-6px',
              right: onHover ? '14px' : '22px',
              cursor: 'pointer',
              width: 'max-content',
              height: 'max-content',
              transition: 'all 0.2s ease-in-out'
            }}
            onMouseLeave={() => setOnHover(false)}
            onMouseEnter={() => setOnHover(true)}
          >
            <CustomIconButton
              style={{
                width: onHover ? undefined : '30px',
                height: onHover ? undefined : '30px',
                borderBottomLeftRadius: onHover ? undefined : 0,
                borderBottomRightRadius: onHover ? undefined : 0,
                transition: 'all 0.2s ease-in-out'
              }}
              variant='contained'
              color='primary'
              onClick={scrollToTop}
            >
              <ArrowUpwardRounded style={{ cursor: 'pointer' }} fontSize={onHover ? undefined : 'small'} />
            </CustomIconButton>
          </Box>
        </>
      }
    </>
  );
}

export default ScrollTopBtn;
