import React, { ReactElement, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle, Tooltip, AlertColor } from '@mui/material';
import { ApiResponse, Nullable } from '../../../types/types';
import { ImportGroupService } from '../../../services/import';
import { ImportGroup, ImportGroupWithStats } from '../../../types/importtypes';
import { ImportGroupAllErrorsList, ImportGroupImportList } from './importlist';
import EntityLink from '../../modularcomponents/entitylink/entitylink';
import ServiceError from '../../../services/errors';
import ErrorPageManager from '../../Error/errorpagemanager';

const useStyles = makeStyles({
  importGroup: {
    marginBottom: '3px'
  },
  bars: {

  },
  bar: {
    float: 'left',
    height: '10px'
  },
  importedInfo: {
    marginLeft: '50px',
    fontSize: 'small'
  }
});

export function ImportGroupStatsBar(props: {importGroup: ImportGroupWithStats}) : ReactElement {
  const classes = useStyles({});

  const colors: {[key: string]: string} = {
    'failed': 'red',
    'created': 'grey',
    'completed': 'green',
    'started': 'blue',
    'unknown': 'yellow'
  };
  const keys = ['completed', 'started', 'created', 'failed' ];

  const stats = props.importGroup.stats;
  const totalImports = Object.values(stats).reduce((sum: number, currValue: number) => (sum + currValue), 0);

  return (
    <div className={classes.bars}>
      {
        keys.map(key => (
          <div key={key}>
            { stats[key] && stats[key] > 0 &&
                <Tooltip title={`${stats[key]} ${key}`} aria-label={`${stats[key]} ${key}`}>
                  <div className={classes.bar} style={{ 'backgroundColor': colors[key] || colors.unknown, 'width': `${stats[key] * 100 / totalImports  }%` }} />
                </Tooltip>
            }
          </div>
        ))
      }
    </div>
  );
}

export function ImportGroupSummary(props: {importGroup: ImportGroupWithStats}) : ReactElement {
  const { importGroup } = props;

  const classes = useStyles({});

  let severity: AlertColor = 'info';
  if (importGroup.stats.started > 0) {
    severity = 'info';
  } else if (importGroup.stats.created > 0) {
    severity = 'info';
  } else if (importGroup.stats.failed > 0) {
    severity = 'error';
  } else if (importGroup.stats.completed > 0) {
    severity = 'success';
  }

  const totalImports = Object.values(importGroup.stats).reduce((sum: number, currValue: number) => (sum + currValue), 0);

  function renderStatsAsText(myImportGroup: ImportGroupWithStats) : ReactElement {
    return (
      <>
        { Object.keys(myImportGroup.stats).map(key => (
          <span key={key}><span>{key}:</span> <span>{myImportGroup.stats[key]}</span>&nbsp;</span>
        ))
        }
      </>
    );
  }

  return (
    <>
      <Alert key={importGroup._id} className={classes.importGroup} severity={severity}>
        <AlertTitle><strong><EntityLink type='importGroups' id={importGroup._id}>{importGroup.filename}</EntityLink></strong>
          <span className={classes.importedInfo}>Imported
            { importGroup.importUserId &&
          <><span> by: </span><span>{importGroup.importUserId}</span></>
            }
            { importGroup.creationTime &&
          <><span> on: </span><span>{importGroup.creationTime}</span></>
            }
          </span>
        </AlertTitle>
        <div>
          <span><b>Total number of included imports:</b>&nbsp;{totalImports} </span>
          <span>( { renderStatsAsText(importGroup) })</span>
        </div>
        <div><ImportGroupStatsBar importGroup={importGroup} /></div>
      </Alert>
    </>
  );
}

export function ImportGroupTable(props: {list: Array<ImportGroupWithStats>}) : ReactElement {
  return (
    <div>
      {props.list.map((importGroup: ImportGroupWithStats) => <ImportGroupSummary key={importGroup._id} importGroup={importGroup} /> )}
    </div>
  );
}

export function ImportGroupList(): ReactElement {
  const [listResp, setListResp] = React.useState<Nullable<ApiResponse<Nullable<Array<ImportGroupWithStats>>>>>(null);
  const importGroupService = new ImportGroupService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);

  function updateData() {
    importGroupService.getAllWithStats()
      .then((apiResp) => {
        setListResp(apiResp);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
      });
  }

  useEffect(() => {
    updateData();

    const interval = setInterval(() => updateData(), 20000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {error ? <ErrorPageManager error={error} /> :
        <>
          { (listResp != null) && (listResp.data != null) &&
            <>
              { (listResp.data.length === 0) &&
                <div>No imported files...</div>
              }
              { (listResp.data.length > 0) &&
                <ImportGroupTable list={listResp.data} />
              }
            </>
          }
        </>
      }
    </>
  );
}

export function ImportGroupPage(props: {importGroup: ImportGroup}): ReactElement {
  return (
    <>
      <div>
        <h2>Import Group - {props.importGroup.filename} - {props.importGroup.creationTime} <small>(by {props.importGroup.importUserId})</small> </h2>
        <h3>Imports in the group</h3>
        <ImportGroupImportList importGroupId={props.importGroup._id} />
        <h3>All errors in the group (per import)</h3>
        <ImportGroupAllErrorsList importGroupId={props.importGroup._id} />
      </div>
    </>
  );
}
