import React, { ReactElement, useEffect, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { ButtonsSettings, QuestionDocument, ValueSetter } from '../../../types/types';
import { UploadQuestionService } from '../../../services/questions';
import UploadFiles from '../../upload/uploadfiles';
import SimpleFilesList from '../../modularcomponents/lists/simplefileslist';

function FileFieldEditor(props: {value?: QuestionDocument, setValue: ValueSetter, id: string, buttonsSettings: ButtonsSettings, required?: boolean, customKey?: string}) : ReactElement {
  const uploadService = new UploadQuestionService(props.id, props.buttonsSettings.role, props.buttonsSettings.ddId);
  const [value, setValue] = useState<QuestionDocument | undefined | null>(props.value);
  const [errorMsg, setErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const deleteDocument = (id: string) => {
    uploadService.delete(id)
      .then(() => {
        setValue(null);
        props.buttonsSettings.update && props.buttonsSettings.update();
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  const handleDownload = (id: string) => {
    window.open(
      uploadService.getDownloadURL(id),
      '_blank'
    );
  };

  const handleDisplay = (id: string) => (uploadService.getDownloadURL(id));

  return (
    <>
      <UploadFiles
        service={uploadService}
        dispUploaded
        disableAfterUpload
        hideAfterFinish
        actions={{ download: handleDownload, delete: deleteDocument }}
        disabled={!!value}
        callback={props.buttonsSettings.update}
      >
        { value ?
          <SimpleFilesList list={[value]} download={handleDownload} display={handleDisplay} delete={deleteDocument} />
          :
          undefined
        }
      </UploadFiles>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
export default FileFieldEditor;
