import AuditedEntitiesService from './auditedentities';

class AumService extends AuditedEntitiesService {

  constructor(type: string, id: string) {
    super(type, id);
  }
}

export default AumService;
