import { Grid, Paper, Theme, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { green, red } from '@mui/material/colors';

import { NavLink } from 'react-router-dom';
import { computePercentage } from '../../utils/computations';

const getColor = (title : string): string => {
  switch (title) {
  case 'white':
    return 'RGB()';
  case 'lightGreen' :
    return 'RGB(223, 235, 222)';
  case 'lightBlue' :
    return 'RGB(222, 228, 244)';
  case 'blue' :
    return 'rgba(50, 104, 203, 0.375)';
  case 'lightRed' :
    return 'RGB(239, 215, 219)';
  case 'lightGrey' : case 'lightGray' :
    return 'RGB(228, 231, 236)';
  default:
    return 'RGB(228, 231, 236)'; // same as above
  }
};
// add icons or cases if necessary ;)
const iconCompute = (title: string): ReactElement => {
  switch (title){
  case 'All right' :
    return <ThumbUpAltRoundedIcon style={{ color: green[500] }} />;
  case 'Concern' :
    return <ErrorRoundedIcon style={{ color: 'RGB(69, 122, 201)' }}/>; // blue
  case 'Red Flag' :
    return <FlagRoundedIcon style={{ color: red[500] }}/>;
  case 'Meeting' :
    return <RecordVoiceOverRoundedIcon />;
  case 'Key Finding' :
    return <VpnKeyRoundedIcon />;
  case 'Next Review' :
    return <RedoRoundedIcon />;
  case 'Validated' :
    return <CheckIcon/>;
  case 'Rejected' :
    return <CloseIcon />;
  default:
    return <></>;
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    padding: '4px 8px',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    transition: 'all 0.2s ease-in-out',
    opacity: '0.9',
    '&:hover': {
      opacity: '1',
      zIndex: '9',
      boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.2s ease-in-out'
    }
  },
  pct: {
    textAlign: 'right',
    width: '64px',
    height: '64px',
    lineHeight: '64px',
    fontSize: '32px',
    marginRight: '8px'
  }
}));

export interface AnalysisStatCardProps {
	count?: number,
  lessThan?: boolean,
	total?: number,
	showPercent?: boolean,
	color: string,
	title: string,
	link?: string,
  isAuditee?: boolean
}

function PctNeeded(props: AnalysisStatCardProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { count, total, title } = props;

  return <>
    <Typography variant='h1' component='h2' className={classes.pct}>
      {computePercentage(count || 0, total || 0 )}
    </Typography>
    <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>%</Typography>
    <Typography style={{ paddingLeft: '8px', lineHeight: '16px' }}>
      <span>{count}/{total}</span>
      <br/>
      {title}
    </Typography>
  </>;
}

function NoPctNeeded(props: AnalysisStatCardProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { count, total, title, lessThan } = props;

  return (
    <>
      <Typography variant='h1' component='h2' className={classes.pct}>
        {lessThan && count ? `<${count}` : `${count}`}
      </Typography>
      {iconCompute(title)}
      <Typography style={{ paddingLeft: '8px', lineHeight: '16px', maxWidth: '120px' }}>
        { total?
          <>
            <span>{count}/{total}</span>
            <br/>
            {title}
          </> :
          <>
            {title}
          </>
        }
      </Typography>
    </>
  );
}

function Card(props: AnalysisStatCardProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { showPercent, color } = props;

  return <Paper
    className={classes.paper}
    style={{ backgroundColor: getColor(color), display: 'flex', alignItems: 'center' }}
    elevation={0}
  >
    {showPercent ?
      <PctNeeded {...props} /> :
      <NoPctNeeded {...props} />
    }
  </Paper>;
}

export function InputStatCard(props: AnalysisStatCardProps): ReactElement {
  const { title, link, isAuditee } = props;

  return (
    <>
      <Grid key={title} item style={{ minWidth: '240px' }}>
        { link && !isAuditee ?
          <NavLink to={`../${link}`} style={{ textDecoration: 'none' }}>
            <Card {...props} />
          </NavLink> :
          <Card {...props} />
        }
      </Grid>
    </>
  );
}
