import React, { ReactElement, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, Collapse, Grow, Typography, Zoom, ToggleButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { darken, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import EntityLink from '../../modularcomponents/entitylink/entitylink';
import EntityIcon from '../../modularcomponents/entityicon/entityicon';
import SecureDisplay from '../../authentification/securedisplay';
import { mainRadius } from '../../../themes/theme';
import { API_BASE_URL } from '../../../utils/env';
import { dataSettings } from '../../../hooks/datatabs';

const drawerWidth = 270;

interface SideMenuListItemProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    isMaintenance?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    border: 0,
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    border: 0,
    overflowX: 'hidden',
    width: `calc(${theme.spacing(14)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(12)} + 0.2px)`
    }
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    height: '100vh',
    padding: 0
    // overflow: 'auto' seems to make appear a scroll bar
  },
  sidebarItems: {
    overflow: 'auto'
  },
  toggleBtn: {
    width: '85%',
    marginLeft: '21.6px',
    marginBottom: '4px',
    transition: 'all 0.2s ease-in-out'
  },
  toggleBtnClosed: {
    width: '50px',
    marginLeft: '21.6px',
    marginBottom: '4px',
    transition: 'all 0.2s ease-in-out'
  },
  link: {
    textDecoration: 'none'
  },
  subLink: {
    textDecoration: 'none'
  },
  navItem: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    padding: '2px 2px',
    textAlign: 'left',
    transition: 'all 0.2s ease-in-out'
  },
  navItemClosed: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    width: '28px',
    padding: '2px 2px',
    textAlign: 'left',
    transition: 'all 0.2s ease-in-out'
  },
  subNavItemClosed: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    transition: 'all 0.2s ease-in-out'
  },
  btnItem: {
    padding: '8px',
    borderRadius: '12px',
    border: 'solid 1.5px',
    borderColor: 'transparent',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start !important',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    transition: 'border-color 0.2s ease-in-out, color 0.05s ease-in-out, background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.primary.main,
      transition: 'border-color 0.2s ease-in-out, color 0.05s ease-in-out, background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out'
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      transition: 'border-color 0.2s ease-in-out, color 0.05s ease, background-color 0.2s ease-in-out'
    },
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      transition: 'border-color 0.2s ease-in-out, color 0.05s ease, background-color 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.dark,
        transition: 'border-color 0.2s ease-in-out, color 0.05s ease, background-color 0.2s ease-in-out'
      }
    },
    '&:after': {
      content: 'test'
    },
    '& span': {
      display: 'block !important'
    }
  },
  subItems: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px'
  },
  subItemsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  subItemsHide: {
    backgroundColor: theme.palette.primary.main,
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px'
  },
  subBtnItem: {
    width: '90%',
    padding: '8px',
    marginLeft: '5%',
    marginBottom: '12px',
    borderRadius: '12px',
    border: 'none',
    textTransform: 'none',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.grey['300'],
      // boxShadow: '0px 10px 20px rgba(0,0,0,0.1)',
      transition: 'all 0.2s ease-in-out'
    },
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
      transition: 'all 0.2s ease-in-out, border-radius 0.2s ease-in-out 0.2s',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  },
  icon: {
    minWidth: '24px',
    paddingRight: '8px',
    color: theme.palette.primary.main
  },
  iconActivate: {
    minWidth: '24px',
    paddingRight: '8px',
    color: darken(theme.palette.background.default, 0.05)
  },
  label: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'block !important',
    width: '100%',
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    transitionDelay: '0s',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis'
  },
  labelHover: {
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    overflow: 'visible',
    transition: 'all 0.3s ease-out',
    transitionDelay: '0s'
  },
  labelSelected: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    transitionDelay: '0s',
    textOverflow: 'ellipsis'
  },
  labelSelectedHover: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflow: 'visible',
    transition: 'all 0.3s ease-out',
    transitionDelay: '0s'
  },
  labelClosed: {
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '0px',
    overflow: 'hidden',
    transition: 'all 0.2s ease-in-out'
  },
  menuButton: {
    width: 'min-content',
    borderRadius: mainRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: '21.6px',
    transition: 'all 0.2s ease-in-out',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  menuButtonClosed: {
    width: 'min-content',
    borderRadius: mainRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: '21.6px',
    transition: 'all 0.2s ease-in-out',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  logoutButton: {
    width: 'min-content',
    borderRadius: mainRadius,
    marginBottom: theme.spacing(4),
    marginLeft: '21.6px',
    transition: 'all 0.2s ease-in-out',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  logoutButtonClosed: {
    width: 'min-content',
    borderRadius: mainRadius,
    marginBottom: theme.spacing(4),
    marginLeft: '21.6px',
    transition: 'all 0.2s ease-in-out 0.2s',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  rotate: {
    transform: 'rotate(180deg)'
  },
  selected: {
  },
  selectedWithChild: {
    backgroundColor: `${theme.palette.primary.main  } !important`,
    color: `${theme.palette.primary.contrastText  } !important`,
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    transitionDelay: '0.2s',
    transition: 'color 0.05s ease, background-color 0.2s ease-in-out'
  },
  bottomLogo: {
    marginTop: 'auto',
    minHeight: '52px'
  },
  logoPosition: {
    height: '32px',
    marginBottom: '-32px',
    textAlign: 'center'
    // marginLeft: '20px',
  },
  logoPositionClosed: {
    height: '32px',
    marginTop: 'auto',
    marginLeft: '34.6px'
  },
  logo: {
    maxWidth: '200px',
    maxHeight: '28px'
  },
  logoClose: {
    width: '24px'
  },
  rightBorder: {
    position: 'absolute',
    right: 0,
    width: '4px',
    height: '50px',
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  rightBorderClose: {
    position: 'absolute',
    right: 0,
    width: '4px',
    height: '50px',
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  rightBorderColor: {
    borderRadius: '50px',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main
  }
}));

function StyledToggleButton(props: {located: string, type: string, label: string, open: boolean, small?: boolean, children?: ReactElement | Array<ReactElement>}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const location = useLocation().pathname.split('/')[1];
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <div className={props.open ? classes.toggleBtn : classes.toggleBtnClosed}>
      <EntityLink type={props.type} className={classes.link} reload={props.located === location}>
        <ToggleButton
          // disableRipple
          value={props.located}
          onMouseEnter={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
          selected={location === props.located}
          classes={{ root: classes.btnItem, selected: props.children ? classes.selectedWithChild : classes.selected }}
        >
          <div className={props.open ? classes.navItem : classes.navItemClosed}>
            <EntityIcon type={props.type} />
            <Typography
              variant='h2'
              className={props.open ? location === props.located ? mouseOver ? classes.labelSelectedHover : classes.labelSelected : mouseOver ? classes.labelHover : classes.label  : classes.labelClosed}
              style={{ marginLeft: '11px' }}
            >
              {props.label}
            </Typography>
          </div>
        </ToggleButton>
      </EntityLink>
      <Collapse
        in={location === props.located}
        timeout={{ appear: 200, enter: 100, exit: 50 }}
        className={location === props.located ? classes.subItems: classes.subItemsHide}
      >
        <div className={classes.subItemsContainer}>
          {props.children}
        </div>
      </Collapse>
      <Zoom in={location === props.located}>
        <div
          style={{ marginTop: `-${props.children ?
            (props.children as Array<ReactElement>).length !== undefined ?
              (((props.children as Array<ReactElement>).length + 1) * 60) : 112
            : 50}px` }}
          className={props.open ? classes.rightBorder : classes.rightBorderClose}
        ><div className={classes.rightBorderColor} /></div>
      </Zoom>
    </div>
  );
}

function StyledSubMenuButton(props: {located: string, type: string, label: string, open: boolean, small?: boolean}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <EntityLink type={props.type} className={classes.subLink}>
      <ToggleButton
        // disableRipple
        selected={useLocation().pathname.includes(props.located)}
        classes={{ root: classes.subBtnItem, selected: classes.selected }}
        value={props.located}
      >
        <div className={props.open ? classes.navItem : classes.subNavItemClosed}>
          <EntityIcon type={props.type} />
          <Typography
            variant={props.small ? 'body1' : 'h2'}
            className={useLocation().pathname.includes(props.located) ? classes.labelSelected : classes.label}
            style={{ marginLeft: '10px' }}
          >
            {props.label}
          </Typography>
        </div>
      </ToggleButton>
    </EntityLink>
  );
}

function SideMenuListItem({ open, setOpen, isMaintenance }: SideMenuListItemProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const settings = dataSettings();
  const { t } = useTranslation();
  const [logo, setLogo] = useState(settings?.settings?.logo.find(myLogo => myLogo.type === 'appMain'));
  const [icon, setIcon] = useState(settings?.settings?.logo.find(myLogo => myLogo.type === 'appIcon'));

  useEffect(() => {
    setLogo(settings?.settings?.logo.find(myLogo => myLogo.type === 'appMain'));
    setIcon(settings?.settings?.logo.find(myLogo => myLogo.type === 'appIcon'));
  }, [settings]);

  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <List className={classes.sidebar} style={{ top: isMaintenance ? '48px' : 'inherit', height: isMaintenance ? 'calc(100vh - 48px)' : 'inherit' }}>
        <IconButton
          color='inherit'
          onClick={() => setOpen(prevState => !prevState)}
          edge='start'
          className={open ? classes.menuButton : classes.menuButtonClosed}
          size='large'>
          {open ? <ArrowBackIosRoundedIcon /> : <ArrowForwardIosRoundedIcon />}
        </IconButton>

        <div className={classes.sidebarItems}>
          <SecureDisplay permissions='/dds'>
            <StyledToggleButton located='dds' type='due diligence' label={t('dds.label')} open={open}/>
          </SecureDisplay>

          <SecureDisplay permissions='/investmgrs'>
            <StyledToggleButton located='assetmgrs' type='assetMgr' label={t('assetMgrs.label')} open={open}/>
          </SecureDisplay>

          <SecureDisplay permissions='/funds'>
            <StyledToggleButton located='funds' type='fund' label={t('funds.label')} open={open}/>
          </SecureDisplay>

          <SecureDisplay permissions='/mancos'>
            <StyledToggleButton located='mancos' type='investMgr' label={t('investMgrs.label')} open={open}/>
          </SecureDisplay>

          <SecureDisplay permissions='/mandates'>
            <StyledToggleButton located='mandates' type='mandate' label={t('mandates.label')} open={open}/>
          </SecureDisplay>

          <SecureDisplay permissions='/strategies'>
            <StyledToggleButton located='strategies' type='strategy' label={t('strategies.label')} open={open}/>
          </SecureDisplay>

          <SecureDisplay permissions='/admin'>
            <StyledToggleButton located='admin' type='admin' label={t('admin.label')} open={open}>
              <SecureDisplay key='import' permissions='/admin'>
                <StyledSubMenuButton located='/import/dd' type='import' label={t('admin.menu.importDds.label')} open={open} small/>
              </SecureDisplay>
              <SecureDisplay key='ddImportGPSL' permissions='/admin'>
                <StyledSubMenuButton located='/import/gpsl' type='importGPSL' label={t('admin.menu.importGPSL.label')} open={open} small/>
              </SecureDisplay>
              <SecureDisplay key='ddImportTemplate' permissions='/admin'>
                <StyledSubMenuButton located='/import/template' type='importTemplate' label={t('admin.menu.importTemplates.label')} open={open} small/>
              </SecureDisplay>
              <SecureDisplay key='settings' permissions='/admin'>
                <StyledSubMenuButton located='/settings' type='settings' label={t('admin.menu.settings.label')} open={open} small/>
              </SecureDisplay>
            </StyledToggleButton>
          </SecureDisplay>

          <SecureDisplay permissions='/auditee'>
            <StyledToggleButton located='auditee' type='auditee' label={t('auditee.surveys.label')} open={open}/>
          </SecureDisplay>
        </div>
        <div className={classes.bottomLogo}>
          <div className={classes.logoPosition}>
            <Grow style={{ transformOrigin: 'center, left' }} in={open} timeout={200}>
              <Box>
                { logo &&
                  <img
                    className={classes.logo}
                    alt=''
                    src={`${API_BASE_URL}/api/v1/admin/platform${logo.filePath}`}
                  />
                }
              </Box>
            </Grow>
          </div>
          <div className={classes.logoPositionClosed}>
            <Grow style={{ transformOrigin: 'center, left' }} in={!open} timeout={200}>
              <Box>
                { icon &&
                  <img
                    className={classes.logoClose}
                    alt=''
                    src={`${API_BASE_URL}/api/v1/admin/platform${icon.filePath}`}
                  />
                }
              </Box>
            </Grow>
          </div>
        </div>
      </List>
    </Drawer>
  );
}

export default SideMenuListItem;
