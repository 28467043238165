import React, { ReactElement } from "react";
import Typography from '@mui/material/Typography';

function TextFieldViewer(props: {value?: string}) : ReactElement {
  return (
    <Typography variant='body2' style={{ whiteSpace: 'pre-line' }}>
      {props.value}
    </Typography>
  );
}
export default TextFieldViewer;
