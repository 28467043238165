import React, { ReactElement, useEffect, useState } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DueDiligenceMenu } from '../../../types/types';
import { ddTemplateContext } from '../../../hooks/datatabs';
import { getTreesFromCategories } from '../../../utils/dataforcontext';

function DdTemplateCategories(): ReactElement {
  const ddTemplate = ddTemplateContext();
  const [expanded, setExpanded] = useState(['root']);

  useEffect(() => {
    ddTemplate && setExpanded(['root', ...ddTemplate.categories.map(menu => menu.path)]);
  }, []);

  if (ddTemplate) {
    const { tree } = getTreesFromCategories(ddTemplate.categories);
    const defaultSelect = '*';

    const renderTree = (nodes: DueDiligenceMenu) => (
      <TreeItem
        key={nodes.path}
        style={{ whiteSpace: 'nowrap' }}
        nodeId={nodes.path}
        label={nodes.label}
      >
        {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
      </TreeItem>
    );

    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['root', ...ddTemplate.categories.map(menu => menu.path)]}
        expanded={expanded}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultSelected={defaultSelect.length === 0 ? 'root' : defaultSelect}
      >
        {[
          renderTree({ path: 'root', label: 'All Categories', children: tree })
        ]}
      </TreeView>
    );
  }

  return (
    <></>
  );
}

export default DdTemplateCategories;
