import React, { ReactElement, useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Grid, Snackbar, Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import { useTranslation } from 'react-i18next';
import { EvalComponentsProps } from './evaluations';
import { CustomTooltipToggleButton, StyledToggleButtonGroup } from '../../button/button';

interface EvalFlagsProps extends EvalComponentsProps {
  anchorElKeyFinding: HTMLElement | null,
  handleKeyFindingSetterOpen: (event: React.MouseEvent<HTMLElement>) => void,
  anchorElMeeting: HTMLElement | null,
  handleMeetingSetterOpen: (event: React.MouseEvent<HTMLElement>) => void
}

function EvalFlags(props: EvalFlagsProps): ReactElement {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [disableAll, setDisableAll] = useState(false);
  const { flags } = props.ddEval;

  if (props.ddEval.keyFinding) {
    flags.push('keyFinding');
  }
  if (props.ddEval.flags.includes('meeting')) {
    flags.push('meeting');
  }

  const handleFlagsChange = (oldFlags: (string | undefined)[], newFlags: string[], id: string) => {
    if (_.countBy(oldFlags).keyFinding !== _.countBy(newFlags).keyFinding) {
      newFlags.splice(newFlags.indexOf('keyFinding'), 1);
    } else if (_.countBy(oldFlags).meeting !== _.countBy(newFlags).meeting) {
      newFlags.splice(newFlags.indexOf('meeting'), 1);
    } else {
      setDisableAll(true);
      props.service.setFlags(props.ddElement._id, newFlags, id)
        .then(() => {
          props.setUpdateList(true);
          setDisableAll(false);
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${err.message}`);
          setSnackbarOpen(true);
          setDisableAll(false);
        });
    }
  };

  useEffect(() => {
    setDisableAll(false);
  }, [props.ddEval]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item style={{ margin: '8px', marginBottom: '0px' }}>
          <Typography variant='h4'>{t('ddElements.rating.flags')} :</Typography>
        </Grid>
        <Grid item style={{ display: 'flex', justifyContent: 'center', marginTop: '-8px' }}>
          <StyledToggleButtonGroup
            orientation='horizontal'
            value={flags}
            onChange={(event, value) => handleFlagsChange(flags, value, props.ddEval._id)}
          >
            {!(props.readonly && !flags.includes('meeting')) &&
              <CustomTooltipToggleButton
                label={props.ddElement.evaluation?.meeting?.remarks || t('ddElements.rating.meeting')}
                value='meeting'
                icon={<RecordVoiceOverRoundedIcon />}
                onClick={props.handleMeetingSetterOpen}
                disabled={props.readonly || disableAll}
                style={{ color: 'rgba(0, 0, 0, 0.54)', opacity: disableAll ? 0.5 : 1 }}
              />
            }
            {!(props.readonly && !flags.includes('keyFinding')) &&
              <CustomTooltipToggleButton
                label={
                  props.ddElement.evaluation?.keyFinding ?
                    `${props.ddElement.evaluation.keyFinding.title}${props.ddElement.evaluation.keyFinding.value ?
                      ` : ${props.ddElement.evaluation.keyFinding.value}` : ''}`
                    : t('ddElements.rating.keyFinding')
                }
                value='keyFinding'
                icon={<VpnKeyRoundedIcon />}
                onClick={props.handleKeyFindingSetterOpen}
                disabled={props.readonly || disableAll}
                style={{ color: 'rgba(0, 0, 0, 0.54)', opacity: disableAll ? 0.5 : 1 }}
              />
            }
            {!(props.readonly && !flags.includes('nextReview')) &&
              <CustomTooltipToggleButton
                label={t('ddElements.rating.nextReview')}
                value='nextReview'
                icon={<RedoRoundedIcon />}
                disabled={props.readonly || disableAll}
                style={{ color: 'rgba(0, 0, 0, 0.54)', opacity: disableAll ? 0.5 : 1 }}
              />
            }
          </StyledToggleButtonGroup>
        </Grid>
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default EvalFlags;
