import React, { ReactElement } from 'react';
import { fundContext } from '../../../../hooks/datatabs';
import { API_BASE_URL } from '../../../../utils/env';
import { DisplayDataModal } from '../../../dev/displaydata';

function DeveloperPage(): ReactElement {
  const fund =  fundContext();

  if (fund) {
    return (
      <div>
        <h2>API links</h2>
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/funds/${fund._id}`} text='Entity JSON' />
      </div>
    );
  }

  return (<></>);
}

export default DeveloperPage;
