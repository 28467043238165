import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import SubjectIcon from '@mui/icons-material/Subject';
import DifferenceRoundedIcon from '@mui/icons-material/DifferenceRounded';
import { CustomTooltipToggleButton, StyledToggleButtonGroup } from '../button/button';

interface VerticalButtonsProps {
  orientation?: 'horizontal' | 'vertical',
  isVerticalSplitOpened?: boolean,
  isDiffView?: boolean,
	setSplitAction: Dispatch<SetStateAction<boolean>>,
  setDiffView: Dispatch<SetStateAction<boolean>>,
	disabled?: boolean
}

export default function VerticalButtons(props: VerticalButtonsProps): ReactElement {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(props.disabled);
  const [value, setValue] = useState(props.isVerticalSplitOpened ? 'split' : props.isDiffView ? 'diff' : 'plain');

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  useEffect(() => {
    setValue(props.isVerticalSplitOpened ? 'split' : props.isDiffView ? 'diff' : 'plain');
  }, [props.isVerticalSplitOpened, props.isDiffView]);

  useEffect(() => {
    switch (value) {
    case 'split':
      props.setSplitAction(true);
      props.setDiffView(false);
      break;
    case 'diff':
      props.setSplitAction(false);
      props.setDiffView(true);
      break;
    case 'plain':
      props.setSplitAction(false);
      props.setDiffView(false);
      break;
    default:
      props.setSplitAction(false);
      props.setDiffView(false);
      break;
    }
  }, [value]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    setValue(nextView);
  };

  return (
    <>
      <StyledToggleButtonGroup
        orientation={props.orientation ? props.orientation : 'vertical'}
        value={value}
        exclusive
        onChange={handleChange}
        color='primary'
        size='small'
      >
        <CustomTooltipToggleButton
          orientation={props.orientation ? props.orientation : 'vertical'}
          label={t('dds.tabs.reports.toolbar.fullText')}
          value='plain'
          disabled={disabled}
          icon={<SubjectIcon />}
        />
        <CustomTooltipToggleButton
          orientation={props.orientation ? props.orientation : 'vertical'}
          label={t('dds.tabs.reports.toolbar.splitView')}
          value='split'
          disabled={disabled}
          icon={<VerticalSplitIcon />}
        />
        <CustomTooltipToggleButton
          orientation={props.orientation ? props.orientation : 'vertical'}
          label={t('dds.tabs.reports.toolbar.diffView')}
          value='diff'
          icon={<DifferenceRoundedIcon />}
          disabled={disabled}
        />
      </StyledToggleButtonGroup>
    </>
  );
}
