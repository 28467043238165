import React, { Dispatch, ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import SurveysDdElementListItem from '../../../../../components/auditee/surveys/listitem';
import { DataSurvey, SurveyElement } from '../../../../../types/types';
import { surveyContext } from '../../../../../hooks/datatabs';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../../../components/params';
import {
  ListRendererMap,
  MultiModeList,
  QuestionListDataProvider
} from '../../../../../components/entity/list';
import CustomPagination from '../../../../../components/layout/pagination/pagination';
import SurveyFilters from './filters';
import { SurveyService } from '../../../../../services/survey';
import QuestionSkeleton from '../../../../../components/layout/loading/questionskeleton';

class SurveyDataProvider extends QuestionListDataProvider<SurveyElement> {
  constructor(ddId: string, searchParamsAccess: SearchParamsAccess, itemsPerPageMin?: number) {
    super(ddId, searchParamsAccess, new SurveyService(), itemsPerPageMin);
  }
}

interface DdElementListProps {
  surveyElements: Array<SurveyElement>,
  setUpdateList: Dispatch<React.SetStateAction<boolean>>,
  readonly?: boolean,
  setReadonly?: (value: boolean) => void
}

function QuestionsDdElementList(props: DdElementListProps): ReactElement {
  // We order the elements by displayIndex ascending before rendering them
  // =====================================================================
  if (props.surveyElements) {
    props.surveyElements.sort((a: SurveyElement, b: SurveyElement) => (a.displayIndex - b.displayIndex));
  }

  return (
    <Grid container spacing={2}>
      {props.surveyElements &&
      props.surveyElements.map((surveyElement: SurveyElement) => (
        <Grid key={surveyElement._id} item xs={12}>
          <SurveysDdElementListItem
            surveyElement={surveyElement}
            setUpdateList={props.setUpdateList}
            readonly={props.readonly}
          />
        </Grid>
      ))
      }
    </Grid>
  );
}

function SurveyMultiModeList(props: {survey: DataSurvey, readonly?: boolean, setReadonly?: (value: boolean) => void }): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new SurveyDataProvider(props.survey._id, searchParamsAccess, 50);

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '42px' }}>No questions...</div>
    </>
  );

  const renderLoading = () => (
    <QuestionSkeleton />
  );

  const renderAsList = (eltList: Array<SurveyElement>, totalCount: number, setUpdateList?: Dispatch<React.SetStateAction<boolean>>) => (
    <>
      {setUpdateList && <QuestionsDdElementList surveyElements={eltList} setUpdateList={setUpdateList} readonly={props.readonly} />}
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
    </>
  );

  const renderers: ListRendererMap<SurveyElement> = {
    'list': renderAsList
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['list' ]}
      defaultMode='list'
      renderers={renderers}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      filtersComponent={SurveyFilters}
    />
  );
}

function Questions() : ReactElement {
  const survey = surveyContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));
  const [readonly, setReadonly] = React.useState(false);

  useEffect(() => {
    if (survey) {
      survey.lifecycle?.activities.forEach((activity) => {
        if (activity.id === 'input') {
          if (activity.status === 'started' || activity.status === 'reopened') {
            setReadonly(false);
          } else {
            setReadonly(true);
          }
        }
      });
    }
  }, [survey]);

  return (
    <>
      <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
        { survey  &&
          <SurveyMultiModeList survey={survey} readonly={readonly} setReadonly={setReadonly} />
        }
      </SearchParamsContextProvider>
    </>
  );
}

export default Questions;
