import React, { ReactElement } from 'react';
import { ddElementGroupContext } from '../../../hooks/datatabs';
import JsonWrapper from '../../dev/jsonwrapper';

function DdElementGroupJson(): ReactElement {
  const ddElementGroup = ddElementGroupContext();

  return (
    <>
      { ddElementGroup &&
        <JsonWrapper>
          {JSON.stringify(ddElementGroup, null, ' ')}
        </JsonWrapper>
      }
    </>
  );
}

export default DdElementGroupJson;