import React, { ReactElement, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, DataDueDiligence, Nullable } from '../../../types/types';

import CustomTable, { Column } from '../../../components/table/table';
import EntityLink from '../../../components/modularcomponents/entitylink/entitylink';
import { Rating2, Rating1, Owners } from './datadisplay';
import { DdService } from '../../../services/dds';
import ServiceError from '../../../services/errors';
import ErrorPageManager from '../../../components/Error/errorpagemanager';
import CustomButton from '../../../components/button/button';

const useStyles = makeStyles({
  rating: {
    display: 'inline-block'
  }
});

export function EntityDdTable(props: {list: Array<DataDueDiligence>}) : ReactElement {
  const border = '2px solid white'; // TODO JNG why ?
  const classes = useStyles({ border });

  const columns: Array<Column> = [
    // { id: 'name', label: 'Name', minWidth: 'min-content' },
    // { id: 'auditedEntity', label: 'Audited Entity', minWidth: 'min-content', align: 'center' },
    { id: 'startDate', label: 'Start Date', minWidth: 'min-content', align: 'center' },
    { id: 'validationDate', label: 'Validation Date', minWidth: 'min-content', align: 'center' },
    { id: 'ddType', label: 'Dd Type', minWidth: 'min-content', align: 'center' },
    // { label: 'phase', minWidth: 'min-content', align: 'center' },
    // { label: 'Global Risk Score', minWidth: 'min-content', align: 'center' },
    { id: 'aaisRating', label: 'AAIS Global Rating', minWidth: 'min-content', align: 'center' },
    { id: 'esgRating', label: 'AAIS ESG Rating', minWidth: 'min-content', align: 'center' },
    { id: 'owners', label: 'Owners', minWidth: 'min-content', align: 'center' }
    // { label: 'Next review', minWidth: 'min-content' },

  ];

  const rows = props.list.map((dd: DataDueDiligence) => ({
    id: dd._id,
    value: columns.map(column => (
      <>
        {column.id === 'name' &&
              <Typography>
                <EntityLink type='dueDiligence' id={`${dd._id}`} name={dd.auditedEntity.name} center={column.align === 'center'} openInNewTabIcon />
              </Typography>
        }
        {column.id === 'auditedEntity' &&
              <Typography>
                <EntityLink type={dd.auditedEntity.type} id={dd.auditedEntity.id} name={dd.auditedEntity.type} center={column.align === 'center'} openInNewTabIcon />
              </Typography>
        }
        {column.id === 'ddType' &&
              <Typography>
                {dd.type}
              </Typography>
        }
        {/* {column.label === columns[3].label && <Typography>Phase</Typography>} */}
        {column.id === 'aaisRating' &&
            <div className={classes.rating} >
              <Rating1 ratings={dd.ratings} />
            </div>
        }
        {column.id === 'esgRating' &&
            <div className={classes.rating} >
              <Rating2 ratings={dd.ratings} />
            </div>
        }
        {column.id === 'owners' &&
              <div>
                <Owners participants={dd.participants} />
              </div>
        }
        {column.id === 'startDate' &&
              <Typography>
                <EntityLink type='dueDiligence' id={`${dd._id}`} name={dd.startDate} center={column.align === 'center'} openInNewTabIcon />
              </Typography>
        }
        {column.id === 'validationDate' &&
              <Typography>
                <EntityLink type='dueDiligence' id={`${dd._id}`} name={dd.expectedValidationDate ? dd.expectedValidationDate : dd.validationDate} center={column.align === 'center'} openInNewTabIcon />
              </Typography>
        }
        {/* {column.label === columns[7].label && <Typography>In one week</Typography>} */}
      </>
    ))
  }));

  return (
    <CustomTable columns={columns} rows={rows} size='small' />
  );
}

export function EntityDdList(props: {entityType: string, entityId: string, entityTypeText: string}): ReactElement {
  const [listResp, setListResp] = React.useState<Nullable<ApiResponse<Nullable<Array<DataDueDiligence>>>>>(null);
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const ddService = new DdService();
  const navigate = useNavigate();

  useEffect(() => {
    ddService.getDdsForEntity(props.entityType, props.entityId)
      .then((apiResp) => {
        setListResp(apiResp);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
      });
  }, []);

  return (
    <>
      <h3>Due Diligences on this {props.entityTypeText}</h3>
      {error ? <ErrorPageManager error={error} /> :
        <div>
          {(listResp != null) && (listResp.data != null) &&
          (<>
            {(listResp.data.length === 0) &&
              <div>No Due Diligence found...</div>
            }
            {(listResp.data.length > 0) &&
              <EntityDdTable list={listResp.data} />
            }
          </>
          )
          }
          <CustomButton variant='contained' color='primary' style={{ marginTop: '24px' }} onClick={() => {
            const params = new URLSearchParams();
            params.set('type', props.entityType);
            params.set('id', props.entityId);
            navigate({ pathname: '/dds/create', search: params.toString() });
          }
          } >Create Due Diligence</CustomButton>
        </div>
      }
    </>
  );
}
