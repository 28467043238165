import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Fade, Grid, Modal, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { mainRadius } from '../../../themes/theme';
import CustomButton from '../../button/button';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      borderRadius: mainRadius,
      width: '600px'
    },
    title: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '12px',
      width: 'fit-content',
      textAlign: 'center'
    }
  })
);

function ValidationModal(props: {action: () => void, cancel: () => void, open: boolean, msg: string}): ReactElement {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={props.open}
        onClose={props.cancel}
        closeAfterTransition
      >
        <Fade in={props.open}>
          <div
            className={classes.paper}
            style={{
              backgroundColor: theme.palette.background.paper,
              padding: theme.spacing(2, 4, 3)
            }}
          >
            <Grid container spacing={4} justifyContent='space-between'>
              <Grid item xs={12}>
                <Typography className={classes.title}  variant='h2' id='transition-modal-title'>{props.msg}</Typography>
              </Grid>
              <Grid item xs={6}>
                <CustomButton onClick={props.action} variant='contained' style={{ backgroundColor: green[500] }} fullWidth>
                  Confirm
                </CustomButton>
              </Grid>
              <Grid item xs={6}>
                <CustomButton onClick={props.cancel} variant='contained' color='secondary' fullWidth>
                  Cancel
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ValidationModal;
