import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import IdRouter from '../../../../../router/idrouter';
import ListOfMeetings from '../../../../duediligences/pages/meetings/list';
import MeetingDetails from '../../../../duediligences/pages/meetings/details';
import { surveyContext } from '../../../../../hooks/datatabs';

function SurveysMeetings() : ReactElement {
  const survey = surveyContext();
  const { id } = useParams<{ id: string }>();
  const [readonly, setReadonly] = React.useState(false);

  useEffect(() => {
    if (survey) {

      survey.lifecycle?.activities.forEach((activity) => {
        if (activity.id === 'meeting') {
          if (activity.status === 'started' || activity.status === 'reopened') {
            setReadonly(false);
          } else {
            setReadonly(true);
          }
        }
      });
    }
  }, [survey]);

  return (
    <IdRouter
      index={<ListOfMeetings ddId={id || ''} readonly={readonly} />}>
      <MeetingDetails ddId={id || ''} readonly={readonly} />
    </IdRouter>
  );
}

export default SurveysMeetings;
