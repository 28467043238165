import React, { ReactElement, useState } from "react";
import CardActions from '@mui/material/CardActions';
import CountryFieldEditor from '../../field/editors/countryfieldeditor';
import QuestionBox from "../box";
import QuestionValue from "../value";
import { QuestionEditorProps, Value, CountryQuestionSpec } from '../../../types/types';
import QuestionEditorButton from "../questioneditorbutton";
import QuestionLabel from '../label';

function CountryQuestionEditor(props: QuestionEditorProps<CountryQuestionSpec>) : ReactElement {
  const [value, setValue] = useState<Value>(props.answer?.value);

  return (

    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <CountryFieldEditor value={value as string || ''} setValue={setValue}/>
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} />
      </CardActions>
    </QuestionBox>
  );
}
export default CountryQuestionEditor;
