import React, { ReactElement, useState } from 'react';
import HeaderTitle from '../../modularcomponents/header/headertitle';
import HeaderProperty from '../../modularcomponents/header/headerproperty';
import HeaderPropertyName from '../../modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../../modularcomponents/header/headerpropertyvalue';
import HeaderContainer from '../../modularcomponents/header/headercontainer';
import HeaderItems from '../../modularcomponents/header/headeritems';
import HeaderItem from '../../modularcomponents/header/headeritem';
import { DdTemplate } from '../../../types/types';
import HeaderButtons from '../../modularcomponents/header/headerbuttons';
import { DdTemplatesService } from '../../../services/import';
import VersionsSubHeader from '../VersionsSubHeader';
import HeaderSummary from '../../modularcomponents/header/headersummary';

function DdTemplateHeader(props: {ddTemplate: DdTemplate}): ReactElement {
  const active = `${props.ddTemplate.versionManagement.active}`;
  const [expand, setExpand] = useState(false);

  const toggleExpand = () => {
    setExpand(val => !val);
  };

  return (
    <HeaderContainer expanded={expand} setExpanded={setExpand}>
      <HeaderSummary toggleExpand={toggleExpand}>
        <HeaderTitle toggleExpand={toggleExpand} type='' name={props.ddTemplate.label} />
        <HeaderButtons id={props.ddTemplate._id} service={new DdTemplatesService()} actionList={[]} />
      </HeaderSummary>
      <HeaderItems>
        <HeaderItem>
          <HeaderProperty>
            <HeaderPropertyName name='Full Ref' />
            <HeaderPropertyValue>
              {props.ddTemplate.fullRef}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Simple Id' />
            <HeaderPropertyValue>
              {props.ddTemplate.versionManagement.simpleId}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Version' />
            <HeaderPropertyValue>
              {props.ddTemplate.versionManagement.version.toString()}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Active' />
            <HeaderPropertyValue>
              {active.charAt(0).toUpperCase() + active.slice(1)}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Type' />
            <HeaderPropertyValue>
              {props.ddTemplate.type}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Label' />
            <HeaderPropertyValue>
              {props.ddTemplate.label}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Audited Entity Supported Types' />
            <HeaderPropertyValue>
              {props.ddTemplate.auditedEntity.supportedTypes.toString()}
            </HeaderPropertyValue>
          </HeaderProperty>
        </HeaderItem>
      </HeaderItems>
      <VersionsSubHeader<DdTemplate> id={props.ddTemplate._id} service={new DdTemplatesService()} />
    </HeaderContainer>
  );
}

export default DdTemplateHeader;
