import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { green } from '@mui/material/colors';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { CustomIconButton } from '../../button/button';

const useStyles = makeStyles(() => ({
  saveButton: {
    height: '38px',
    width: '38px',
    marginTop: '8px',
    textTransform: 'none',
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[300]
    }
  }
}));

interface CancelOrSaveActionsProps {
	cancelAction: () => void,
	deleteAction?: () => void,
	saveAction: (value: unknown) => void,
	isHorizontal?: boolean,
	isSaveNeeded?: boolean,
	noTags?: boolean
}

export default function CancelOrSaveActions(props: CancelOrSaveActionsProps) : ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cancelAction, deleteAction, saveAction } = props;

  return (
    <>
      <Tooltip title={t('dds.tabs.reports.toolbar.discardChanges')} arrow placement='left'>
        <div style={{ marginRight: '12px' }}>
          <CustomIconButton
            aria-label='cancel changes'
            variant='contained'
            color='secondary'
            onClick={cancelAction}
            small
          >
            <ClearIcon fontSize='small' />
          </CustomIconButton>
        </div>
      </Tooltip>
      {deleteAction && <Tooltip title={t('dds.tabs.reports.toolbar.deleteItem')} arrow placement='left'>
        <div style={{ marginRight: '12px', marginTop: '8px' }}>
          <CustomIconButton
            aria-label='delete item'
            variant='contained' // passer text pour avoir uniquement l'icon
            color='secondary'
            onClick={deleteAction}
            small
          >
            <DeleteRoundedIcon fontSize='small' />
          </CustomIconButton>
        </div>
      </Tooltip>}
      { !props.noTags &&
			<Tooltip title={t('dds.tabs.reports.toolbar.saveChangesToServer')} arrow placement='left'>
			  <div style={{ marginRight: '12px' }}>
			    <CustomIconButton
			      aria-label='save changes'
			      variant='contained' // passer text pour avoir uniquement l'icon
			      className={classes.saveButton}
			      onClick={saveAction}
			      disabled={!props.isSaveNeeded}
			      small
			    >
			      <SaveIcon fontSize='small' />
			    </CustomIconButton>
			  </div>
			</Tooltip>}
    </>
  );
}
