import { DeprecatedThemeOptions } from '@mui/material';
import { lighten } from '@mui/material/styles';

export const mainColor = 'rgb(69, 122, 201)';
export const secondaryColor = 'rgb(244, 67, 54)';
export const mainRadius = '25px';

const theme: DeprecatedThemeOptions = {
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 18,
      fontWeight: 700
    },
    h2: {
      fontSize: 16,
      fontWeight: 500
    },
    h3: {
      fontSize: 14,
      fontWeight: 600
    },
    h4: {
      fontSize: 12,
      fontWeight: 600
    },
    h5: {
      fontSize: 10,
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 500
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 500
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    }
  },
  palette: {
    primary: {
      main: mainColor,
      contrastText: lighten(mainColor, 0.8)
    }
  },
  shape: {
    borderRadius: 25
  }
};

export default theme;
