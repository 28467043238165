import React, { ReactElement } from 'react';
import { QuestionDocument } from '../../../types/types';
import SimpleFilesList from '../../modularcomponents/lists/simplefileslist';
import { UploadQuestionService } from '../../../services/questions';

export default function FileFieldViewer(props: {id: string, value?: QuestionDocument}) : ReactElement {
  const uploadService = new UploadQuestionService(props.id);

  const handleDownload = (id: string) => {
    window.open(
      uploadService.getDownloadURL(id),
      '_blank'
    );
  };

  const handleDisplay = (id: string) => (uploadService.getDownloadURL(id));

  return (
    <>
      { props.value &&
        <SimpleFilesList list={[props.value]} download={handleDownload} display={handleDisplay} />
      }
    </>
  );
}
