import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'rich-markdown-editor';
import { Grid, Snackbar, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '../toolbar/Toolbar';
import { ReportBlockFieldDesc } from '../../../types/reporttype';
import { PopulateFieldMethod } from '../blocks/reportsblock';
import ReactKeyUtils from '../../../utils/reactkeyutils';
import VerticalSplittedComparison from './VerticalSplittedComparison';
import DiffView from './diffview';
import { light } from '../customEditorTheme';

const useStyles = makeStyles(() => ({
  editor: {
    margin: 'auto'
  },
  fieldContent: {
    paddingRight: '16px',
    maxWidth: 'calc(100% - 54px)',
    flexBasis: 'calc(100% - 54px)',
    '& p': {
      margin: '0',
      padding: '0',
      fontSize: '14px'
    }
  }
}));

interface ReportBlockTextFieldProps {
	desc: ReportBlockFieldDesc,
	previousValue?: string,
	currentValue?: string,
	isEditable?: boolean,
	onSave: (value : string | undefined) => void,
	populateField?: PopulateFieldMethod,
	isEachFieldsSplitted?: boolean,
  isDiffView?: boolean
}

function ReportBlockTextField(props: ReportBlockTextFieldProps):ReactElement {
  const { t } = useTranslation();
  const isPopulate = props.desc.populate;
  const classes = useStyles();
  const { isEditable } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isVerticalSplitOpened, setIsVerticalSplitOpened] = useState<boolean>(!!props.isEachFieldsSplitted);
  const [isDiffView, setIsDiffView] = useState<boolean>(!!props.isDiffView);
  const [dispOnlyChanges, setDispOnlyChanges] = useState(false);
  const { previousValue, currentValue } = props;
  const initialValue = currentValue || previousValue;
  const [text, setText] = useState<string | undefined>(initialValue);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [myKey, setKey] = useState(ReactKeyUtils.generateRandomKey());
  const editAction = () => {
    setIsEditing(true);
    setIsVerticalSplitOpened(false);
  };

  const cancelAction = () => {
    setText(initialValue);
    setKey(ReactKeyUtils.generateRandomKey()); // mandatory for Editor
    setIsEditing(false);
  };

  const populateAction = () => {
    if (props.populateField){
      props.populateField(props.desc.id)
        .then((apiResp) => {
          const populateText = apiResp.data? apiResp.data: undefined;
          setText(populateText);
          // set new key to force a refresh of editor as editor have his own cycle
          setKey(ReactKeyUtils.generateRandomKey());
          setIsEditing(true);
        })
        .catch((err) => {
          console.log(err);
          setSnackbarOpen(true);
        });
    }
  };

  const saveAction = () => {
    if (text) {
      props.onSave( text );
    } else {
      props.onSave( undefined );
    }
    setIsEditing(false);
  };

  useEffect(() => {
    setIsVerticalSplitOpened(!!props.isEachFieldsSplitted);
    setIsDiffView(!!props.isDiffView);
  }, [props.isEachFieldsSplitted, props.isDiffView]);

  return (
    <Grid container>
      <Grid item sm={11} style={{
        maxWidth: 'calc(100% - 54px)', flexBasis: 'calc(100% - 54px)' /* borderRight: '1px solid grey' */
      }}>
        <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '60px' }} severity='error'>
            {t('dds.tabs.reports.text.nothingReceived')}
          </Alert>
        </Snackbar>
        {currentValue && (isVerticalSplitOpened || isDiffView) ?
          <>
            {isVerticalSplitOpened &&
              <VerticalSplittedComparison previousValue={previousValue} currentValue={currentValue} />
            }
            {isDiffView &&
              <DiffView previousValue={previousValue} currentValue={currentValue} dispOnlyChanges={dispOnlyChanges} />
            }
          </> :
          <Editor
            key={myKey.toString()}
            className={ classes.editor }
            defaultValue={ text || '' }
            readOnly={ !isEditing }
            onChange={setText}
            theme={light}
          />
        }
      </Grid>
      <Grid
        item sm={1}
        style={{ maxWidth: '54px', flexBasis: '54px' }}>
        <Toolbar
          isEditable={ isEditable }
          isEditing={ isEditing }
          editAction={ editAction }
          cancelAction={ cancelAction }
          saveAction={ saveAction }
          isPopulate={isPopulate}
          populateAction={populateAction}
          isSplitOptionAvailable={!!currentValue}
          setSplitAction={setIsVerticalSplitOpened}
          setDiffView={setIsDiffView}
          isVerticalSplitOpened={isVerticalSplitOpened}
          isDiffView={isDiffView}
          dispOnlyChanges={dispOnlyChanges}
          setDispOnlyChanges={setDispOnlyChanges}
          isSaveNeeded
        />
      </Grid>
    </Grid>
  );
}

export default ReportBlockTextField;
