import React, { ReactElement } from "react";
import Typography from '@mui/material/Typography';

function BooleanFieldViewer(props: {value?: string}) : ReactElement {
  return (
    <Typography variant='body1' component='p'>
      {props.value ? 'yes' : 'no'}
    </Typography>
  );
}
export default BooleanFieldViewer;
