import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';

function HeaderPropertyName(props: {name: string}): ReactElement {
  return (
    <Grid item>
      <Typography variant='body2' style={{ marginTop: '1px' }}>
        {props.name}
      </Typography>
    </Grid>
  );
}

export default HeaderPropertyName;
