import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import Contacts from '../../../../components/contacts/contacts';
import { FundsContactsService } from '../../../../services/contacts';

function FundsContacts() : ReactElement {
  const { id } = useParams<{ id: string }>();
  const service = new FundsContactsService(id || '');

  return (
    <Contacts service={service} />
  );
}

export default FundsContacts;
