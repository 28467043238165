import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import * as _ from 'lodash';
import theme from './theme';
import { light } from './light';
import { dark } from './dark';

interface NavProps {
  darkTheme?: boolean,
  children: ReactNode
}

function ThemeWrapper( props : NavProps): ReactElement {
  const [darkTheme, setDarkTheme] = useState(props.darkTheme);
  const appliedTheme = createTheme(_.merge( theme, darkTheme ? dark : light));

  useEffect(() => {
    setDarkTheme(props.darkTheme);
  }, [props.darkTheme]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appliedTheme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeWrapper;
