import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { QuestionSpec } from '../../types/types';

const useStyles = makeStyles(() => createStyles(
  {
    title: {
      whiteSpace: 'break-spaces',
      overflow: 'hidden',
      textOverflowX: 'ellipsis',
      width: 'calc(100% - 160px)',
      '&:hover': {
        overflow: 'auto',
        textOverflow: 'clip'
      }
    }
  }
));

export default function QuestionLabel(props: { spec: QuestionSpec }): ReactElement {
  const classes = useStyles();

  return (
    <Box style={{ padding: '12px', marginBottom: '12px' }}>
      <Typography
        variant='h2'
        color='textSecondary'
        gutterBottom
        className={classes.title}
      >
        {props.spec.label}
      </Typography>
    </Box>
  );
}

