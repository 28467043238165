import { Paper, Typography, IconButton, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, { ReactElement, ReactNode, useState } from 'react';
import { useDrag } from 'react-dnd';
import { CategoryItemProps } from './collapsablelist';
import { UpdateMeetingProps } from '../../../types/meetingstypes';

const useStyles = makeStyles(() => ({
  item: {
    transition: 'all 0.2s ease-in-out',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      transition: 'all 0.2s ease-in-out'
    }
  }
}));

function indentation(path: string) {
  const level = path.split('/').length;

  return (8 * level) - 8;
}

function camelCaseSeparator(label: string) {
  return label.match(/[A-Z][a-z]+/g)?.join(' ');
}

function addUpperCaseToFirstLetter(label: string) {
  return label[0].toUpperCase() + label.substring(1);
}

function getFormatedName(label: string) {
  const labels = label.split('/');
  const computedLabel = camelCaseSeparator(
    addUpperCaseToFirstLetter(
      labels[labels.length-1]
    )
  );
  if (computedLabel && computedLabel.indexOf('Esg') > -1){
    return computedLabel.replace('Esg', 'ESG');
  }
  if (computedLabel && computedLabel.indexOf('Aum') > -1){
    return computedLabel.replace('Aum', 'AUM');
  }

  return computedLabel;
}

interface CategoryItemNeededProps {
	category: CategoryItemProps,
	collapse: (id: string) => void,
	children: ReactNode,
	categoryId: string,
	meetingId: string,
	disableDrag?: boolean,
	path?: string
}

export default function CategoryItem(props: CategoryItemNeededProps): ReactElement {
  const classes = useStyles();
  const [mouseOver, setMouseOver] = useState(false);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'category',
      item: (): UpdateMeetingProps => ({
        itemId: props.categoryId,
        oldMeetingId: props.meetingId,
        path: props.path,
        isCategory: true
      }),
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    }),
    []);

  return (
    <Paper
      elevation={0}
      ref={props.disableDrag ? undefined : drag as React.Ref<HTMLDivElement>}
      style={{
        opacity: isDragging ? 0.5 : 1,
        marginLeft: `${indentation(props.category.path)  }px`,
        marginBottom: '4px',
        position: 'relative',
        backgroundColor: 'transparent',
        cursor: isDragging ? 'grabbing' : props.disableDrag ? undefined : 'grab'
      }}
      className={props.disableDrag ? undefined : classes.item}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <Box style={{ display: 'flex', justifyContent: 'space-between', verticalAlign: 'center' }}>
        <Typography
          style={{
            marginLeft: '6px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <IconButton
            aria-label='collapse'
            size='small'
            style={{
              padding: 0,
              paddingLeft: '3px',
              marginRight: '8px',
              cursor: 'pointer'
            }}
            onClick={() => props.collapse(props.category.id)}
          >
            <KeyboardArrowRightIcon style={{ transform: props.category.isCollapsed ? 'rotate(90deg)' : 'rotate(0deg)' }}/>
          </IconButton>
          {getFormatedName(props.category.label)}
        </Typography>
        <Box
          style={{
            opacity: mouseOver ? 1 : 0,
            transition: 'all 0.4s ease-in-out'
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Paper>
  );
}
