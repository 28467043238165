import React, { ReactElement } from 'react';
import { strategyContext } from '../../../../hooks/datatabs';
import { API_BASE_URL } from '../../../../utils/env';
import { DisplayDataModal } from '../../../dev/displaydata';

function StrategyDeveloperPage(): ReactElement {
  const strategy =  strategyContext();

  if (strategy) {
    return (
      <div>
        <h2>API links</h2>
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/strategies/${strategy._id}`} text='Entity JSON' />
      </div>
    );
  }

  return (<></>);
}

export default StrategyDeveloperPage;
