import React, { Dispatch, ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
  Chip,
  Divider,
  Grid,
  Snackbar,
  Typography,
  Paper,
  Tabs,
  Tab,
  Alert,
  Theme, Stack
} from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import { green, red } from '@mui/material/colors';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { TabTypeMap } from '@mui/material/Tab/Tab';
import { useTranslation } from 'react-i18next';
import { ArrowDownwardRounded, WarningRounded } from '@mui/icons-material';
import {
  Answer,
  ButtonsSettings,
  CriteriaEvals,
  DdElement,
  Nullable,
  Value
} from '../../types/types';
import DispatchQuestionViewer from '../question/viewers/dispatchquestionviewer';
import DispatchQuestionEditor from '../question/editors/dispatchquestioneditor';
import Comments from './comments/comments';
import Evaluations from './evaluations/evaluations';
import { ElementsCommentService } from '../../services/comments';
import QuestionService from '../../services/questions';
import { dataContext } from '../../hooks/datatabs';
import QuestionDisplayButtons from './questiondisplaybuttons';
import QuestionValidationButtons from './questionvalidationbuttons';
import QuestionHistory from './questionhistory';
import { mainColor, mainRadius } from '../../themes/theme';
import { useTabsStyles } from '../layout/tabs/dynamictabs';
import Remarks from './remarks';
import DdElementDev from './ddelementdev';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 'min-content'
    },
    paperBtns: {
      marginBottom: '48px',
      marginRight: '12px'
    },
    paper: {
      padding: '12px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '0.9',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out'
      }
    },
    title: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginBottom: '0px',
      height: '28px',
      '&:hover': {
        textOverflow: 'clip'
      }
    },
    tags: {
      marginLeft: '24px',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      color: theme.palette.primary.contrastText
    },
    breadcrumbs: {
      color: 'grey'
    },
    sideElements: {
      display: 'flex',
      flexDirection: 'column'
    },
    tabsIndicator: {
      height: '32px',
      zIndex: 2,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      borderRadius: mainRadius
    },
    customChipIndicator: {
      marginTop: '-3px',
      width: '24px',
      height: '24px',
      borderRadius: '24px',
      lineHeight: '24px',
      backgroundColor: '#457ac9',
      color: 'white',
      position: 'absolute',
      right: '4px',
      fontSize: '12px'
    }
  })
);

export function a11yProps(index: unknown): unknown {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`
  };
}

interface TabPanelProps {
  children?: React.ReactNode,
  index: unknown,
  value: unknown
}

export function TabPanel(props: TabPanelProps): ReactElement {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

interface DdElementListItemHeaderProps {
  _id: string,
  type: string,
  title: string,
  breadcrumbs: string,
  draft: boolean,
  visibleToExternal?: boolean,
  evals?: CriteriaEvals,
  survey?: boolean
}

export function DdElementListItemHeader(props: DdElementListItemHeaderProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const params = useLocation();
  const { t } = useTranslation();
  const [criteriaEvals, setCriteriaEvals] = useState(props.evals);

  if (!params.search) {
    params.search = '';
  }

  useEffect(() => {
    setCriteriaEvals(props.evals);
  }, [props.evals]);

  return (
    <Grid container spacing={0} style={{ paddingLeft: '8px', paddingTop: '4px', overflow: 'hidden' }}>
      <Grid item style={{ height: '30px' }}>
        <Typography className={classes.title} variant='body1' gutterBottom>
          {/*! url.includes('surveys') ?
            <EntityLink type='survey' id={props._id} params={params.search} name={props.title} />
            : */
            props.title
          }
        </Typography>
      </Grid>
      {!props.visibleToExternal && !props.survey &&
        <Grid item xs>
          <Chip label={t('ddElements.internal')} className={classes.tags} size='small' />
        </Grid>
      }
      <Grid item xs>
        {props.draft && <Chip label={t('ddElements.status.draft.label')} className={classes.tags} size='small' />}
      </Grid>
      {criteriaEvals && criteriaEvals.final &&
        <Grid item>
          <Grid container spacing={2}>
            {criteriaEvals.final.rating === 'ok' &&
              <Grid item><ThumbUpAltRoundedIcon style={{ color: green[500] }} /></Grid>
            }
            {criteriaEvals.final.rating === 'concern' &&
              <Grid item><ErrorRoundedIcon style={{ color: mainColor }} /></Grid>
            }
            {criteriaEvals.final.rating === 'redFlag' &&
              <Grid item><FlagRoundedIcon style={{ color: red[500] }} /></Grid>
            }
            {criteriaEvals.final.flags.includes('meeting') &&
              <Grid item><RecordVoiceOverRoundedIcon /></Grid>
            }
            {criteriaEvals.final.flags.includes('keyFinding') &&
              <Grid item><VpnKeyRoundedIcon /></Grid>
            }
            {criteriaEvals.final.flags.includes('nextReview') &&
              <Grid item><RedoRoundedIcon /></Grid>
            }
          </Grid>
        </Grid>
      }
      <Grid item xs={12}>
        <Typography className={classes.breadcrumbs} variant='body2'>{props.breadcrumbs}</Typography>
      </Grid>
    </Grid>
  );
}

function CustomChipIndicatorAndLabel( props: { label: string, count: number, boolean?: boolean }): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <span>
        {props.label}
        { (props.count > 0 || props.boolean) &&
          <strong className={ classes.customChipIndicator }>
            { props.boolean ? '!' : props.count }
          </strong>
        }
      </span>
    </>
  );
}

interface AnalysisDdElementListItemProps {
  ddElement: DdElement,
  setUpdateList: Dispatch<React.SetStateAction<boolean>>,
  readonly?: 'full' | 'partial'
}

/**
 * This represents a DD Element in the list of elements. It shows the title + various tabs
 * @returns
 */
export default function AnalysisDdElementListItem(props: AnalysisDdElementListItemProps): ReactElement {
  const { keycloak } = useKeycloak();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const tabsClasses = useTabsStyles();
  const [view, setView] = useState(props.ddElement.answers.auditor ? 'auditorAnswer' : 'auditeeAnswer');
  const [answer, setAnswer] = useState<Nullable<Answer>>(
    props.ddElement.answers.auditor ?
      props.ddElement.answers.auditor :
      (props.ddElement.answers.auditee && !props.ddElement.answers.auditee.draft && props.ddElement.answers.auditee) || null
  );
  const [criteriaEvals, setCriteriaEvals] = useState(props.ddElement.evaluation?.criteriaEvals);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [disabled, setDisabled] = useState(false);
  const [disableInput, setDisableInput] = useState(props.ddElement.answers.auditor ? answer?.answerInfo?.[0] : undefined);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const questionService = new QuestionService;
  const ddMenu = dataContext();
  const treeList = ddMenu?.treeList;
  const paths = props.ddElement.category.split('/');
  let location = '';
  let locationLabel = '';

  paths.forEach((path, index) => {
    location += index > 0 ? `.${  path}` : path;
    treeList?.forEach((treePath) => {
      if (treePath.path === location) {
        locationLabel += index > 0 ? ` > ${  treePath.label}` : treePath.label;
      }
    });
  });

  useEffect(() => {
    setAnswer(
      props.ddElement.answers.auditor ?
        props.ddElement.answers.auditor :
        (props.ddElement.answers.auditee && !props.ddElement.answers.auditee.draft && props.ddElement.answers.auditee) || null
    );
  }, [props.ddElement.answers]);

  useEffect(() => {
    setCriteriaEvals(props.ddElement.evaluation?.criteriaEvals);
  }, [props.ddElement.evaluation]);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const getTabsForElement = (ddElement: DdElement /* initialTabId?: string */) => {
    const tabs = [];
    if (ddElement.kind === 'question') {
      tabs.push('question');
    }
    tabs.push('evaluation');
    // tabs.push('contradictionRoom');
    tabs.push('messages');
    tabs.push('remarks');
    tabs.push('audit');
    if (keycloak.hasResourceRole('developer')) {
      tabs.push('dev');
    }

    return tabs;
  };

  const tabIds = getTabsForElement(props.ddElement);

  const getTabForId = (ddElement: DdElement, tabId: string, index: number) => {
    const messagesCount = ddElement.comments.length;
    const remarksCount = ddElement.remarks || ddElement.evaluation?.meeting?.remarks;

    switch (tabId) {
    case 'question':
      return (<Tab key={tabId} label={t('ddElements.tabs.question.label')} {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple />);
    case 'evaluation':
      return (<Tab key={tabId} label={t('ddElements.tabs.evaluation.label')} {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple />);
    case 'messages':
      return (
        <Tab
          style={{ paddingRight: messagesCount > 0 ? '36px' : '24px', marginRight: messagesCount > 0 ? '16px' : '0' }}
          key={tabId} label={<CustomChipIndicatorAndLabel label={t('ddElements.tabs.messages.label')} count={messagesCount} />}
          {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple
        />);
    case 'remarks':
      return (<Tab
        style={{ paddingRight: remarksCount ? '36px' : '24px', marginRight: remarksCount ? '16px' : '0' }}
        key={tabId} label={<CustomChipIndicatorAndLabel label={t('ddElements.tabs.remarks.label')} count={remarksCount ? 1 : 0} boolean={!!remarksCount} />}
        {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple
      />);
    case 'audit':
      return (<Tab key={tabId} label={t('ddElements.tabs.audit.label')} {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple />);
    default:
      return (<Tab key={tabId} label={tabId} {...a11yProps(index) as ExtendButtonBase<TabTypeMap>} className={tabsClasses.tab} classes={{ selected: tabsClasses.selectedTab }} disableRipple />);
    }
  };

  const getTabContentForId = (ddElement: DdElement, tabId: string) => {
    const onSaveAsDraft = (value: Value, answerInfo?: string[]) => {
      setDisabled(true);
      questionService.saveAsAuditor(ddElement._id, value, true, answerInfo)
        .then(() => {
          props.setUpdateList(true);
          setDisabled(false);
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${err.message}`);
          setSnackbarOpen(true);
          setDisabled(false);
        });
    };

    const onSubmit = (value: Value, answerInfo?: string[]) => {
      setDisabled(true);
      questionService.saveAsAuditor(ddElement._id, value, false, answerInfo)
        .then(() => {
          setView('auditorAnswer');
          props.setUpdateList(true);
          setDisabled(false);
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${err.message}`);
          setSnackbarOpen(true);
          setDisabled(false);
        });
    };

    const handleSetAnswer = (value: Nullable<Answer>) => {
      setAnswer(value);
      setView('auditorEditor');
    };

    const buttonsSettings: ButtonsSettings = {
      onSaveAsDraft,
      onSubmit,
      role: 'auditor',
      answerInfo: answer?.answerInfo,
      id: ddElement._id,
      disabled: disabled || !!props.readonly,
      draft: answer?.draft,
      update: () => props.setUpdateList(true),
      disableInput,
      setDisableInput: (val: string) => setDisableInput(val)
    };

    switch (tabId) {
    case 'question':
      return (
        <Grid container spacing={2} style={{ height: 'max-content' }}>
          <Grid item xs>
            <Paper className={classes.paper} elevation={0} variant='outlined' style={{ position: 'relative', height: '100%' }}>
              {ddElement.answers.previous && view !== 'previousAnswer' &&
                <Paper
                  elevation={0}
                  sx={{
                    p: '4px',
                    pl: '8px',
                    pr: '8px',
                    width: '100%',
                    mb: 1,
                    cursor: 'pointer',
                    backgroundColor: lighten(theme.palette.warning.light, 0.7),
                    '&:hover': {
                      textDecoration: `underline ${theme.palette.warning.main}`
                    }
                  }}
                  onClick={() => {
                    setView('previousAnswer');
                    ddElement.answers.previous && setAnswer(ddElement.answers.previous);
                  }}
                >
                  <Stack direction='row'>
                    <WarningRounded fontSize='small' color='warning' />
                    <Stack direction='row' spacing={2} sx={{ ml: 'auto', mr: '44px', width: 'fit-content' }}>
                      <Typography variant='body1' fontWeight={500} color={theme.palette.warning.main}>
                        Previous answer available
                      </Typography>
                      <ArrowDownwardRounded fontSize='small' color='warning' />
                    </Stack>
                  </Stack>
                </Paper>
              }
              <QuestionDisplayButtons
                ddElement={ddElement}
                view={view}
                setView={setView}
                setAnswer={setAnswer}
                readonly={!!props.readonly}
                auditor
              />
              {view === 'auditorEditor' ?
                <DispatchQuestionEditor
                  spec={ddElement.question.spec}
                  answer={answer}
                  buttonsSettings={buttonsSettings}
                  disableInput={disableInput}
                />
                :
                <DispatchQuestionViewer
                  spec={props.ddElement.question.spec}
                  answer={answer}
                  setAnswer={view === 'previousAnswer' ? handleSetAnswer : undefined}
                  id={ddElement._id}
                  buttonsSettings={buttonsSettings}
                  disableInput={disableInput}
                />
              }
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.paper} elevation={0} variant='outlined' style={{ position: 'relative', height: '100%', width: 'min-content' }}>
              <Grid container direction='column'>
                <Grid item style={{ minWidth: '300px', width: 'min-content', marginLeft: 'auto' }}>
                  <QuestionValidationButtons ddElement={props.ddElement} questionService={questionService} setUpdateList={props.setUpdateList} readonly={!!props.readonly} />
                </Grid>
                <Grid item style={{ marginTop: '24px' }}>
                  <Evaluations
                    ddElement={props.ddElement}
                    questionService={questionService}
                    setUpdateList={props.setUpdateList}
                    onlyAnalyst
                    readonly={props.readonly === 'full'}
                  />
                </Grid>
              </Grid>
              { props.ddElement.events.length > 0 &&
                <>
                  <Grid item style={{ marginTop: '12px' }}>
                    <Divider orientation='horizontal' />
                  </Grid>
                  <Grid item style={{ marginTop: '12px' }}>
                    <Remarks ddElement={props.ddElement} questionService={questionService} setUpdate={props.setUpdateList} readonly={props.readonly === 'full'} small />
                  </Grid>
                </>
              }
            </Paper>
          </Grid>
        </Grid>
      );
    case 'evaluation':
      return (
        <Evaluations ddElement={props.ddElement} questionService={questionService} setUpdateList={props.setUpdateList} readonly={props.readonly === 'full'} />
      );
    case 'messages':
      return (
        <Comments
          events={ddElement.comments}
          commentsService={new ElementsCommentService(ddElement._id)}
          setUpdate={props.setUpdateList}
          readonly={!!props.readonly}
        />
      );
    case 'remarks':
      return (
        <Remarks ddElement={props.ddElement} questionService={questionService} setUpdate={props.setUpdateList} readonly={props.readonly === 'full'} />
      );
    case 'audit':
      return (
        <Grid container spacing={2} style={{ height: 'max-content' }}>
          <Grid item xs>
            <Paper className={classes.paper} elevation={0} variant='outlined' style={{ position: 'relative', height: '100%' }}>
              <QuestionHistory ddElement={props.ddElement} />
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.paper} elevation={0} variant='outlined' style={{ position: 'relative', height: '100%', width: 'min-content' }}>
              <Grid container direction='column'>
                <Grid item style={{ minWidth: '300px', width: 'min-content', marginLeft: 'auto' }}>
                  <QuestionValidationButtons ddElement={props.ddElement} questionService={questionService} setUpdateList={props.setUpdateList} readonly={!!props.readonly} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      );
    case 'dev':
      return (
        <DdElementDev ddElement={ddElement} />
      );

    default:
      return (<>{t('utils.notAvailableYet')}</>);
    }
  };

  const getTabs = (ddElement: DdElement) => (
    tabIds.map((tabId, i) => getTabForId(ddElement, tabId, i))
  );

  let backgroundColor;

  switch (props.ddElement.answerStatus) {
  case 'validated' :
    backgroundColor = 'rgba(124, 179, 66, 0.175)';
    break;
  case 'rejected' :
    backgroundColor = 'rgba(229, 57, 53, 0.175)';
    break;
  case 'answered' :
    backgroundColor = 'rgba(50, 104, 203, 0.375)';
    break;
  default :
    backgroundColor = 'rgba(157, 157, 157, 0.175)';
  }

  return (
    <>
      <Paper key={props.ddElement._id} className={classes.paper} style={{ backgroundColor }} elevation={0} variant='outlined'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DdElementListItemHeader
              _id={props.ddElement._id}
              type={props.ddElement.kind}
              title={props.ddElement.title}
              breadcrumbs={locationLabel}
              visibleToExternal={props.ddElement.question.visibleToExternal}
              draft={(view === 'auditorAnswer' || view === 'auditorEditor') && props.ddElement.answers.auditor?.draft || false}
              evals={typeof criteriaEvals === typeof (criteriaEvals as CriteriaEvals) ? (criteriaEvals as CriteriaEvals) : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={selectedTabIndex}
              onChange={handleTabChange}
              indicatorColor='primary'
              className={tabsClasses.tabs}
              variant='scrollable'
              classes={{ scroller: tabsClasses.scroller }}
              TabScrollButtonProps={{ className: tabsClasses.scrollButtons }}
              style={{ marginRight: 'auto', marginTop: '0px' }}
              TabIndicatorProps={{
                className: classes.tabsIndicator
              }}
              // centered
            >
              {getTabs(props.ddElement)}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {tabIds.map((tabId, i) => {
              if (i === selectedTabIndex) {
                if (tabId === 'question' || tabId === 'audit') {
                  return (
                    <TabPanel key={`tab-${  tabId}`} value={selectedTabIndex} index={i}>
                      {getTabContentForId({ ...props.ddElement }, tabId)}
                    </TabPanel>
                  );
                }

                return (
                  <TabPanel key={`tab-${  tabId}`} value={selectedTabIndex} index={i}>
                    <Paper className={classes.paper} elevation={0} variant='outlined' style={{ position: 'relative' }}>
                      {getTabContentForId({ ...props.ddElement }, tabId)}
                    </Paper>
                  </TabPanel>
                );
              }

              return undefined;
            })}
          </Grid>
        </Grid>
      </Paper>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
