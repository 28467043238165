import React, { Dispatch, ReactElement } from 'react';
import { Checkbox } from '@mui/material';
import { Nullable } from '../../types/types';
import { FilterParamsManager } from '../entity/list';

export interface AllCheckboxProps<T> {
  list: T[],
  checked: Nullable<T[]>,
  setChecked: Dispatch<React.SetStateAction<Nullable<T[]>>>,
  paramsSort?: FilterParamsManager
}

function AllCheckbox<T>(props: AllCheckboxProps<T>): ReactElement {

  return (
    <>
      { props.checked &&
        <Checkbox
          color='primary'
          indeterminate={props.checked.length > 0 && props.checked.length !== props.list.length}
          checked={props.checked.length === props.list.length}
          onClick={() => (props.checked && props.checked.length > 0 ? props.setChecked([]) : props.setChecked(props.list))}
        />
      }
    </>
  );
}

const CheckForAll = React.memo((props: AllCheckboxProps<unknown>) => <AllCheckbox {...props} />);

export default CheckForAll;
