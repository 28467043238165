import React, { ReactElement, useEffect, useState } from 'react';
import CardActions from '@mui/material/CardActions';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import * as _ from 'lodash';
import QuestionBox from '../box';
import QuestionValue from '../value';
import MultiTypeFieldEditor from '../../field/editors/multitypefieldeditor';
import {
  BaseValueTypes,
  QuestionEditorProps,
  QuestionSpec,
  TableQuestionSpec,
  TableType
} from '../../../types/types';
import QuestionEditorButton from '../questioneditorbutton';
import QuestionLabel from '../label';
import { useStyles as tableHeadStyles } from '../../table/head';
import CustomButton, { CustomIconButton } from '../../button/button';
import ReactKeyUtils from '../../../utils/reactkeyutils';

function TableQuestionEditor(props: QuestionEditorProps<TableQuestionSpec>): ReactElement {
  const tableQuestionSpec = props.spec as TableQuestionSpec;
  const [value, setValue] = useState(props.answer?.value as Array<BaseValueTypes> || []);
  const computeIndexes = (val: Array<BaseValueTypes>) => val.map(() => (tableQuestionSpec.columns.map(() => ReactKeyUtils.generateRandomKey())));
  const [indexes, setIndexes] = useState<Array<string[]>>(value ? computeIndexes(value) : []);
  const computeCheckArray = (val: Array<BaseValueTypes>) => val.map(row => (
    tableQuestionSpec.columns.map((column) => {
      if (column.required) {
        if (row) {
          const columnValue = row[column.id as keyof typeof row];
          if (columnValue === 0 || (columnValue !== undefined && columnValue !== '')) {
            return true;
          }
        }

        return false;
      }

      return true;
    })
  )
  );
  const [checkArray, setCheckArray] = useState<Array<boolean[]>>(computeCheckArray(value) || []);
  const [notSubmittable, setNotSubmittable] = useState(false);
  const theme = useTheme();
  const classesTableHead = tableHeadStyles(theme);

  const baseInfo: BaseValueTypes = {};

  tableQuestionSpec.columns?.forEach((column) => {
    baseInfo[column.id] = '';
  });

  useEffect(() => {
    setNotSubmittable(value.length < 1 || !checkArray.every(item => item.every(Boolean)));
  }, [checkArray]);

  const reset = (myValue: Array<BaseValueTypes>) => {
    if (myValue) {
      setValue(myValue);
    } else {
      setValue([]);
    }
    setCheckArray(computeCheckArray(myValue || []) || []);
    setIndexes(myValue ? computeIndexes(myValue) : []);
  };

  const addObject = () => {
    setCheckArray([...checkArray, tableQuestionSpec.columns.map(column => !column.required)]);
    setValue([...(value as Array<BaseValueTypes>), baseInfo]);
    setIndexes([...indexes, tableQuestionSpec.columns.map(() => ReactKeyUtils.generateRandomKey())]);
  };

  const deleteObject = (index: number) => {
    if ((value as Array<BaseValueTypes>).length > 1) {
      setCheckArray(checkArray.filter((key, i) => i !== index));
      setValue((value as Array<BaseValueTypes>).filter((rowValue, rowIndex) => rowIndex !== index));
      setIndexes(indexes.filter((x, i) => i !== index));
    } else {
      setCheckArray([]);
      setValue([]);
      setIndexes([]);
    }
  };

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>

        <Table style={{ width: 'fit-content' }}>
          { value.length > 0 &&
            <TableHead>
              <TableRow>
                { tableQuestionSpec.columns?.map(column => (
                  <TableCell
                    key={column.id}
                    className={classesTableHead.tableHead}
                    align='left'
                  >
                    <Typography variant='h3'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          }
          <TableBody>
            { value && (value as Array<BaseValueTypes>).map((rowValue, rowIndex) => (
              <TableRow key={rowIndex.toString()}>
                { rowValue && tableQuestionSpec.columns.map((column, i) => (
                  <TableCell key={column.id} style={{ border: 'none' }}>
                    <MultiTypeFieldEditor
                      customKey={indexes[rowIndex][i]}
                      type={column.type}
                      index={rowIndex}
                      specs={column as QuestionSpec}
                      value={rowValue[column.id as keyof typeof rowValue]}
                      lastValue={value[rowIndex - 1]?.[column.id as keyof typeof rowValue]}
                      setValue={(v) => {
                        const myArray = _.cloneDeep(value);
                        const myCheckArray = _.cloneDeep(checkArray);

                        myCheckArray[rowIndex][i] = column.required ? v === 0 || !!v : true;
                        myArray[rowIndex] = {
                          ...(value as Array<TableType>)[rowIndex],
                          [column.id]: v as BaseValueTypes
                        };

                        setCheckArray(myCheckArray);
                        setValue(myArray);
                      }}
                    />
                  </TableCell>
                ))}
                <TableCell key='delete' style={{ border: 'none' }}>
                  <CustomIconButton variant='text' color='secondary' onClick={() => deleteObject(rowIndex)}><DeleteForeverIcon/></CustomIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{ border: 'none' }}>
                <CustomButton variant='text' onClick={addObject} small style={{ whiteSpace: 'nowrap' }}>Add New Row</CustomButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>

      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={myValue => reset(myValue as Array<BaseValueTypes>)} defaultValue={props.answer?.value as Array<BaseValueTypes> || []} buttonsSettings={{ ...props.buttonsSettings, notSubmittable }} />
      </CardActions>
    </QuestionBox>
  );
}

export default TableQuestionEditor;
