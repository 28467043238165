import React, { ReactElement, useEffect, useState } from 'react';
import {
  useParams
} from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  FundContextProvider,
  SetUpdateHookContextProvider,
  PathHook
} from '../hooks/datatabs';
import {
  Nullable,
  Fund
} from '../types/types';
import FundHeader from '../containers/funds/pageheader';
import { fundsTabs } from '../utils/tabs';
import DisplayTabs from '../components/layout/tabs/tabs';
import { FundService } from '../services/fund';
import ServiceError from '../services/errors';
import ErrorPageManager from '../components/Error/errorpagemanager';
import TabsRouter from './tabsrouter';

function FundRouter(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Nullable<Fund>>(null);
  const fundService = new FundService();
  const [update, setUpdate] = useState(true);
  const [error, setError] = useState<Nullable<ServiceError>>(null);

  useEffect(() => {
    if (update && id) {
      data && setData(null);

      fundService.getEntity(id)
        .then((apiResp) => {
          setData(apiResp.data);
          setUpdate(false);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
          setUpdate(false);
        });
    }
  }, [update, id]);

  PathHook(`${data?.name}`);

  return (
    <SetUpdateHookContextProvider value={setUpdate}>
      <Grid container spacing={2}>
        {error ? <ErrorPageManager error={error} /> :
          <FundContextProvider value={data}>
            <Grid item xs={12}>
              <FundHeader entity={data} />
            </Grid>
            <Grid item xs={12}>
              <DisplayTabs tabs={fundsTabs} />
            </Grid>
            <Grid item xs={12}>
              <TabsRouter tabs={fundsTabs} />
            </Grid>
          </FundContextProvider>
        }
      </Grid>
    </SetUpdateHookContextProvider>
  );
}

export default FundRouter;
