import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AbstractParticipantsAssignmentsService } from '../../../services/contacts';
import { AuditedEntity, Nullable, Participant } from '../../../types/types';
import ServiceError from '../../../services/errors';
import ParticipantsTable from './participantsTable';
import ErrorPageManager from '../../Error/errorpagemanager';
import { setUpdateHook } from '../../../hooks/datatabs';

function Participants(props: {service: AbstractParticipantsAssignmentsService<Participant>, list: Array<Participant>, auditedEntity: AuditedEntity}): ReactElement {
  const { t } = useTranslation();
  const [participantList, setParticipantList] = useState<Nullable<Array<Participant>>>(props.list);
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(false);
  const setHookUpdate = setUpdateHook();

  useEffect(() => {
    setTimeout(() => {
      update && setHookUpdate(true);
      update && props.service.getAll()
        .then((apiResp) => {
          setParticipantList(apiResp.data || []);
          setUpdate(false);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
          setUpdate(false);
        });
    }, 300); // TODO: remove when possible
  }, [update]);

  return (
    <>
      {
        error ? <ErrorPageManager error={error} /> :
          participantList ? <ParticipantsTable participantsList={participantList} setUpdate={setUpdate} service={props.service} auditedEntity={props.auditedEntity} /> : <>{t('utils.loading')}</>
      }
    </>
  );
}

export default Participants;
