import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { MeetingProps, UpdateMeetingProps } from '../../../types/meetingstypes';

interface ItemOptionsProps {
	isCategory: boolean,
	path?: string,
	questionId: string,
	meetingId: string,
	meetings: MeetingProps[],
	updateMeetings?: ( updateItems: UpdateMeetingProps ) => void
}

function ItemOptions(props: ItemOptionsProps): ReactElement {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const computeIcon = () => {
    if (props.isCategory){
      if (props.meetingId !== ''){
        return <RemoveCircleIcon fontSize='small'/>;
      }

      return <AddCircleIcon fontSize='small'/>;
    }
    if (props.meetingId === ''){
      return <AddCircleOutlineIcon fontSize='small'/>;
    }

    return <RemoveCircleOutlineIcon fontSize='small'/>;
  };

  return (
    <>
      <IconButton
        aria-label='actions'
        size='small'
        style={{
          padding: 0,
          marginTop: props.isCategory ? '2px' : '0px'
        }}
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        {computeIcon()}
      </IconButton>
      <Menu
        id={`question-options-${  props.questionId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { props.meetingId === '' && props.meetings.map( meeting => (
          <MenuItem
            key={`opt-${props.questionId}`}
            onClick={() => props.updateMeetings && props.updateMeetings(
              {
                action: props.isCategory ? 'MOVE CATEGORY' : 'MOVE QUESTION',
                itemId: props.questionId,
                oldMeetingId: props.meetingId,
                meetingId: meeting._id,
                path: props.path
              }
            )}
          >
            {t('dds.tabs.meetings.allocation.moveTo')} {meeting.title}
          </MenuItem>
        )
        )}
        { props.meetingId !== '' &&
					<MenuItem
					  onClick={() => props.updateMeetings && props.updateMeetings(
					    {
					      action: props.isCategory ? 'REMOVE CATEGORY' : 'REMOVE QUESTION',
					      itemId: props.questionId,
					      oldMeetingId: props.meetingId,
					      path: props.path
					    }
					  )}
					>
					  {t('dds.tabs.meetings.allocation.Remove')} {props.isCategory ? ' category' : 'question'}
					</MenuItem>
        }
      </Menu>
    </>
  );
}

export default ItemOptions;
