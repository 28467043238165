import React, { ReactElement, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Navigate, Routes, Route, useLocation, useParams } from 'react-router-dom';
import { DdService } from '../services/dds';
import { Nullable, ReportsTabsInfo } from '../types/types';
import ServiceError from '../services/errors';
import ErrorPageManager from '../components/Error/errorpagemanager';
import ReportsTabs from '../components/reports/reportstabs';
import IdRouter from './idrouter';
import ReportBlocks from '../components/reports/blocks/reportblocks';
import { DdReportsList } from '../components/reports/reportsdynamiclist';

function ReportsRouter(props: {readonly?: boolean}): ReactElement {
  const [tabsInfo, setTabsInfo] = useState<Nullable<ReportsTabsInfo>>(null);
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const { id } = useParams<{ id: string }>();
  const ddService = new DdService();
  const { pathname } = useLocation();

  const tabId = pathname.split('/').pop() || '';

  useEffect(() => {
    id && ddService.getReportsTabs(id)
      .then((apiResp) => {
        apiResp.data && setTabsInfo(apiResp.data);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
      });
  }, [id]);

  return (
    <>
      { error ? <ErrorPageManager error={error} /> :
        <Grid container spacing={2}>
          {tabsInfo && id &&
            <>
              <Grid item xs={12}>
                <ReportsTabs tabsInfo={tabsInfo} />
              </Grid>
              <Grid item xs={12}>
                <Routes>
                  <Route path='/pdfReports/*' element={
                    <DdReportsList />
                  }
                  />
                  <Route path='/*' element={
                    <IdRouter index={<Navigate to={`${tabsInfo ? tabsInfo[0].id : ''}`} replace />}>
                      <ReportBlocks
                        id={id}
                        reportBlocks={tabsInfo[tabsInfo.findIndex(t => t.id === tabId)]?.reportBlocks}
                        readonly={props.readonly}
                      />
                    </IdRouter>
                  }
                  />
                </Routes>
              </Grid>
            </>
          }
        </Grid>
      }
    </>
  );
}

export default ReportsRouter;
