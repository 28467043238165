import React, { ReactElement } from 'react';
import { investmgrContext } from '../../../hooks/datatabs';
import { EntityDdList } from '../../duediligences/listing/entityddlist';

function AssetMgrDdList(): ReactElement {
  const investMgrData = investmgrContext();

  return (
    <>
      {investMgrData && investMgrData._id &&
        <EntityDdList entityType='assetMgr' entityId={investMgrData._id} entityTypeText='asset manager' />
      }
    </>
  );
}

export default AssetMgrDdList;
