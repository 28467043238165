import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { SelectQuestionSpec } from '../../../types/types';

export default function SelectFieldViewer(props: {spec: SelectQuestionSpec, value?: string}) : ReactElement{
  const { spec } = props;

  return (
    <>
      {spec.choices && spec.choices.map((rowValue) => {
        if (rowValue.value === props.value) {
          return (
            <Typography key={rowValue.value} variant='body1' component='p'>
              {rowValue.label}
            </Typography>
          );
        }

        return undefined;
      })}
      {spec.other && (!spec.choices || !spec.choices.some(rowValue => rowValue.value === props.value)) &&
        <Typography variant='body1' component='p'>
          {props.value}
        </Typography>
      }
    </>
  );
}
