import { AxiosRequestConfig } from 'axios';
import { AnyParams, BaseEntityService } from './data';
import { ApiResponse, Filter, MeetingProps, Nullable, DataSurvey, SurveyElement, SummaryAuditeeInfo } from '../types/types';
import { API } from '../api/api';
import { SearchElementService } from './ddelements';
import { AbstractDdMeetingsService, DdMeetingServiceReturnType, DdMeetingsServiceReturnType } from './ddmeetings';

export class SurveyService extends BaseEntityService<DataSurvey> implements SearchElementService<SurveyElement> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/surveys';
  }

  getSummaryInfo(ddId: string): Promise<ApiResponse<Nullable<SummaryAuditeeInfo>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/summaryInfo`)
      .then(res => this.handleResponse<SummaryAuditeeInfo>(res));
  }

  getElementList(ddId: string, filters?: Array<Filter>, skip?: number, limit?: number) : Promise<ApiResponse<Nullable<Array<SurveyElement>>>> {
    const filtersParams: AnyParams = {};

    if (filters) {
      filters.forEach((filter) => {
        const filterValue = filter.value.split(',');
        // Now add 'f_' to the filters so the server knowns how to interpret them
        filtersParams[filter.id] = filterValue.length > 1 ? filterValue : filter.value;
      });
    }
    const body = {
      filters: filtersParams,
      skip,
      limit
    };

    const url = `/api/v1/surveys/${ddId}/questions/search`;

    return API.post(url, body)
      .then(res => this.handleResponse<Array<SurveyElement>>(res));
    // TODO: here, handle the normal errors instead of returning the Promise from AXIOS
  }
}

export class DdSurveysMeetingsService extends AbstractDdMeetingsService<MeetingProps> {
  ddId: string;

  constructor(ddId: string) {
    super();
    this.ddId = ddId;
  }

  getBaseUrl() : string {
    return `/api/v1/surveys/${this.ddId}/meetings`;
  }

  getAll(config?: AxiosRequestConfig): DdMeetingsServiceReturnType<MeetingProps> {
    return API.get(`${this.getBaseUrl()}`, config)
      .then(res => this.handleResponse<MeetingProps[]>(res));
  }

  get(meetingId: string, config?: AxiosRequestConfig): DdMeetingServiceReturnType<MeetingProps> {
    return API.get(`${this.getBaseUrl()}/${meetingId}`, config)
      .then(res => this.handleResponse<MeetingProps>(res));
  }
}
