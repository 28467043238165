import { AxiosRequestConfig } from 'axios';
import { BaseService } from './data';
import { ApiResponse, Nullable } from '../types/types';
import { BaseMeetingProps, MeetingProps } from '../types/meetingstypes';
import { API } from '../api/api';

export type DdMeetingsServiceReturnType<T> = Promise<ApiResponse<Nullable<Array<T>>>>;
export type DdMeetingServiceReturnType<T> = Promise<ApiResponse<Nullable<T>>>;

export abstract class AbstractDdMeetingsService<T> extends BaseService {
  basePath: string;

  protected constructor() {
    super ();
    this.basePath = '';
  }

  abstract getBaseUrl(): string;

  abstract getAll(config?: AxiosRequestConfig): DdMeetingsServiceReturnType<T> | DdMeetingServiceReturnType<T>;

  abstract get(meetingId: string, config?: AxiosRequestConfig): DdMeetingServiceReturnType<T>;
}

export class DdMeetingsService extends AbstractDdMeetingsService<MeetingProps> {
  ddId: string;

  constructor(ddId: string) {
    super();
    this.ddId = ddId;
  }

  getBaseUrl() : string {
    return `/api/v1/dds/${this.ddId}/meetings`;
  }

  getAll(config?: AxiosRequestConfig): DdMeetingsServiceReturnType<MeetingProps> {
    return API.get(`${this.getBaseUrl()}`, config)
      .then(res => this.handleResponse<MeetingProps[]>(res));
  }

  get(meetingId: string, config?: AxiosRequestConfig): DdMeetingServiceReturnType<MeetingProps> {
    return API.get(`${this.getBaseUrl()}/${meetingId}`, config)
      .then(res => this.handleResponse<MeetingProps>(res));
  }

  create(title: string, config?: AxiosRequestConfig): DdMeetingServiceReturnType<MeetingProps> {
    return API.post(`${this.getBaseUrl()}`, { title }, config)
      .then(res => this.handleResponse<MeetingProps>(res));
  }

  delete(meetingId: string, config?: AxiosRequestConfig): Promise<ApiResponse<Nullable<string>>> {
    return API.delete(`${this.getBaseUrl()}/${meetingId}`, config)
      .then(res => this.handleResponse<string>(res));
  }

  edit(meetingId: string, meeting: BaseMeetingProps, config?: AxiosRequestConfig): DdMeetingServiceReturnType<MeetingProps> {
    return API.put(`${this.getBaseUrl()}/${meetingId}`, meeting, config)
      .then(res => this.handleResponse<MeetingProps>(res));
  }

  allocate(meetingId: string, ddElementId?: string, category?: string, config?: AxiosRequestConfig): Promise<ApiResponse<Nullable<string>>> {
    return API.put(`${this.getBaseUrl()}/${meetingId}/allocate`, { ddElementId, category }, config)
      .then(res => this.handleResponse<string>(res));
  }

  remove(meetingId: string, ddElementId?: string, category?: string, config?: AxiosRequestConfig): Promise<ApiResponse<Nullable<string>>> {
    return API.put(`${this.getBaseUrl()}/${meetingId}/deallocate`, { ddElementId, category }, config)
      .then(res => this.handleResponse<string>(res));
  }
}
