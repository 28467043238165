import { Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomIconButton } from '../button/button';

export default function GoBackButton(): ReactElement {
  const navigate = useNavigate();

  return (
    <Tooltip title='go back to list' arrow>
      <div style={{ marginRight: '12px' }}>
        <CustomIconButton
          aria-label='edit mode'
          variant='contained'
          onClick={() => navigate(-1)}
          style={{ height: '34px', width: '34px' }}
        >
          <ArrowBackIcon fontSize='small' />
        </CustomIconButton>
      </div>
    </Tooltip>
  );
}
