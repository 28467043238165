import { AxiosResponse } from 'axios';
import { API } from '../api/api';
import {
  ApiResponse,
  DataDueDiligence,
  DdTemplate,
  DdValidationItem,
  Nullable,
  ReportsTabsInfo,
  Tag,
  TagsFamily,
  SummaryInfo,
  DdDocument,
  DdProcessingRequest,
  DdQuestionElement,
  DdReport,
  DdTemplateOption,
  DownloadTokenResult,
  TabsInfo
} from '../types/types';
import { FormattedRating, ReportBlockData } from '../containers/duediligences/pages/rating/ratingtable';
import { BaseEntityService } from './data';

// type DdElementsServiceReturnType = Promise<AxiosResponse<ApiResponse<Nullable<Array<DdElement>>>>>;
type DeleteDdReturnType = Promise<AxiosResponse<ApiResponse<Nullable<DataDueDiligence>>>>;

export class DueDiligenceDevService {
  deleteDD(ddId: string) : DeleteDdReturnType {
    const path = `/api/v1/dds/${ddId}/dev/deleteDD`;

    return API.get(path);
  }
}

interface AnyParams {
  [key: string] : unknown
}

interface PaginatedParams extends AnyParams {
  limit?: number,
  skip?: number
}

export class DdService extends BaseEntityService<DataDueDiligence> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/dds';
  }

  getDdsForEntity(type: string, id: string): Promise<ApiResponse<Nullable<Array<DataDueDiligence>>>> {
    const info = this.getListInfo();
    const params : PaginatedParams = { ...info.defaultParams };
    // if (itemsPerPage) {
    //   params.limit = itemsPerPage;
    // }
    // if (skip) {
    //   params.skip = skip;
    // }
    params.limit = 1000;
    params.skip = 0;

    params.type = type;
    params.id = id;

    return API.get(`${info.url}/byAuditedEntity`, { params })
      .then(res => this.handleResponse<Array<DataDueDiligence>>(res));
  }

  getFormattedRatingsForDd(ddId: string): Promise<ApiResponse<Nullable<Array<FormattedRating>>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/formattedRatings`)
      .then(res => this.handleResponse<Array<FormattedRating>>(res));
  }

  getReportBlockData(ddId: string, blockId: string): Promise<ApiResponse<Nullable<ReportBlockData>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/reportInfo/${blockId}`)
      .then(res => this.handleResponse<ReportBlockData>(res));
  }

  updateReportBlockField(ddId: string, blockId: string, valuePath: string, value?: unknown): Promise<ApiResponse<Nullable<unknown>>> { // TODO set the proper type
    const updateData = {
      valuePath,
      value
    };

    return API.put(`${this.getBaseUrl()}/${ddId}/reportInfo/${blockId}`, updateData)
      .then(res => this.handleResponse<unknown>(res));
  }

  getDocumentsForDd(ddId: string): Promise<ApiResponse<Nullable<Array<DdDocument>>>> {
    const params: PaginatedParams = {};
    params.limit = 1000;
    params.skip = 0;

    return API.get(`${this.getBaseUrl()}/${ddId}/docs`, { params })
      .then(res => this.handleResponse<Array<DdDocument>>(res));
  }

  deleteDocument(ddId: string, docId: string): Promise<ApiResponse<unknown>> {
    return API.delete(`${this.getBaseUrl()}/${ddId}/docs/${docId}`)
      .then(res => this.handleResponse<unknown>(res));
  }

  getReportsForDd(ddId: string): Promise<ApiResponse<Nullable<Array<DdReport>>>> {
    const params: PaginatedParams = {};
    params.limit = 1000;
    params.skip = 0;

    return API.get(`${this.getBaseUrl()}/${ddId}/reports`, { params })
      .then(res => this.handleResponse<Array<DdReport>>(res));
  }

  getDownloadToken(ddId: string): Promise<ApiResponse<Nullable<DownloadTokenResult>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/downloadToken`)
      .then(res => this.handleResponse<DownloadTokenResult>(res));
  }

  getDownloadURL(ddId: string, docId: string, downloadToken?: string): string {
    return API.getUri({ url: `${this.getBaseUrl()}/${ddId}/download/${docId}`, params: { dlToken: downloadToken } });
  }

  getTabsInfo(ddId: string): Promise<ApiResponse<Nullable<TabsInfo>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/tabsInfo`)
      .then(res => this.handleResponse<TabsInfo>(res));
  }

  getSummaryInfo(ddId: string): Promise<ApiResponse<Nullable<SummaryInfo>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/summaryInfo`)
      .then(res => this.handleResponse<SummaryInfo>(res));
  }

  getReportsTabs(ddId: string): Promise<ApiResponse<Nullable<ReportsTabsInfo>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/reports/tabs`)
      .then(res => this.handleResponse<ReportsTabsInfo>(res));
  }

  getPossibleDdTypes(entityType: string, entityId: string, useLatestVersion: boolean): Promise<ApiResponse<Nullable<Array<string>>>> {
    const params = {
      type: entityType,
      id: entityId,
      useLatestVersion
    };

    return API.get(`${this.getBaseUrl()}/create/possibleDdTypes`, { params })
      .then(res => this.handleResponse<Array<string>>(res));
  }

  getPossibleDdTemplates(entityType: string, entityId: string, ddType: string | undefined, useLatestVersion: boolean): Promise<ApiResponse<Nullable<Array<DdTemplate>>>> {
    const params = {
      type: entityType,
      id: entityId,
      ddType,
      useLatestVersion
    };

    return API.get(`${this.getBaseUrl()}/create/possibleDdTemplates`, { params })
      .then(res => this.handleResponse<Array<DdTemplate>>(res));
  }

  getPossibleDdTemplatesOptions(entityType: string, entityId: string, templateId: string, useLatestVersion: boolean): Promise<ApiResponse<Nullable<Array<DdTemplateOption>>>> {
    const params = {
      type: entityType,
      id: entityId,
      templateId,
      useLatestVersion
    };

    return API.get(`${this.getBaseUrl()}/create/possibleDdTemplatesOptions`, { params })
      .then(res => this.handleResponse<Array<DdTemplateOption>>(res));
  }

  createDD(entityType: string, entityId: string, ddTemplateId: string, creationOptions: string[], useLatestVersion: boolean): Promise<ApiResponse<Nullable<DdProcessingRequest>>> {
    const body = {
      entityType,
      entityId,
      templateId: ddTemplateId,
      params: {
        creationOptions,
        useLatestVersion
      }
    };

    return API.post(`${this.getBaseUrl()}/create`, body)
      .then(res => this.handleResponse<DdProcessingRequest>(res));
  }

  getTagsFamilies(ddId: string): Promise<ApiResponse<Nullable<TagsFamily[]>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/tagsFamilies`)
      .then(res => this.handleResponse<TagsFamily[]>(res));
  }

  /// api/v1/dds/<ddId>/reportInfo/<blockId>/populate/<fieldId>
  getPopulateText(ddId: string, blockId: string, fieldId: string): Promise<ApiResponse<Nullable<string>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/reportInfo/${blockId}/populate/${fieldId}`)
      .then(res => this.handleResponse<string>(res));
  }

  updateTags(ddId: string, fileId: string, tags: Tag[]): Promise<ApiResponse<Nullable<unknown>>> {
    return API.put(`${this.getBaseUrl()}/${ddId}/docs/${fileId}/tags`, tags)
      .then(res => this.handleResponse<unknown>(res));
  }
}

export class MeetingsService extends DdService {
  ddId: string;

  constructor(ddId: string) {
    super();
    this.ddId = ddId;
  }

  getQuestionsFlaggedAsMeeting(ddId: string): Promise<ApiResponse<Nullable<DdQuestionElement[]>>> {
    return API.get(`${this.getBaseUrl()}/${ddId}/analysis?f_flags=meeting`)
      .then((res) => {
        console.log(res);

        return this.handleResponse<DdQuestionElement[]>(res);
      });
  }

  // getAllMeetings
  // createMeeting
  // uploadMeeting
  // deleteMeeting
}

export class ManagerViewService extends DdService {
  ddId: string;

  constructor(ddId: string) {
    super();
    this.ddId = ddId;
  }

  getAllForDD(): Promise<ApiResponse<Nullable<DdValidationItem[]>>> {
    return API.get(`${this.getBaseUrl()}/${this.ddId}/validationItems?active=true`)
      .then(res => this.handleResponse<DdValidationItem[]>(res));
  }

  // Validate method:  to check)
  validate(itemToValidateId: string): Promise<ApiResponse<Nullable<DdValidationItem>>> {
    return API.get(`${this.getBaseUrl()}/${this.ddId}/validationItems/${itemToValidateId}/validate`)
      .then(res => this.handleResponse<DdValidationItem>(res));
  }

  // Reject method: to check, let this one to unkknown for test purposes
  reject(itemToRejectId: string): Promise<ApiResponse<Nullable<DdValidationItem>>> {
    return API.get(`${this.getBaseUrl()}/${this.ddId}/validationItems/${itemToRejectId}/reject`)
      .then(res => this.handleResponse<DdValidationItem>(res));
  }
}

export class DdDocumentUploadService extends DdService {
  ddId: string;

  constructor(ddId: string) {
    super();
    this.ddId = ddId;
  }

  getUploadUrl(): string {
    return `${this.getBaseUrl()}/${this.ddId}/docs/upload`;
  }
}

export class DdGraphUploadService extends DdService {
  ddId: string;

  blockId: string;

  fieldId: string;

  constructor(ddId: string, blockId: string, fieldId: string) {
    super();
    this.ddId = ddId;
    this.blockId = blockId;
    this.fieldId = fieldId;
  }

  getUploadUrl(): string {
    return `${this.getBaseUrl()}/${this.ddId}/reportInfo/${this.blockId}/fields/${this.fieldId}/graphs`;
  }

  deleteGraph(id: string): Promise<ApiResponse<Nullable<boolean>>> {
    return API.delete(`${this.getBaseUrl()}/${this.ddId}/reportInfo/${this.blockId}/fields/${this.fieldId}/graphs/${id}`)
      .then(res => this.handleResponse<boolean>(res));
  }
}
