import React, { ReactElement, useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import { useTheme, styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ValueSetter } from "../../../types/types";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 'none',
    '&:not(:first-of-type)': {
      borderRadius: `${useTheme().shape.borderRadius}px`,
      width: '46px',
      marginLeft: '6px'
    },
    '&:first-of-type': {
      borderRadius: `${useTheme().shape.borderRadius}px`,
      width: '46px'
    }
  }
}));

function BooleanFieldEditor(props: {value: boolean, setValue: ValueSetter, required?: boolean, customKey?: string}) : ReactElement {
  const [boolean, setBoolean] = React.useState<boolean | null>(props.value);

  useEffect(() => {
    setBoolean(props.value);
  }, [props.value]);

  const handleBoolean = (
    event: React.MouseEvent<HTMLElement>,
    Boolean: boolean
  ) => {
    if (Boolean !== null) {
      setBoolean(Boolean);
      props.setValue(Boolean);
    }
  };

  return (
    <StyledToggleButtonGroup
      key={props.customKey}
      value={boolean}
      exclusive
      onChange={handleBoolean}
      aria-label='text alignment'
    >
      <ToggleButton value>
        YES
      </ToggleButton>
      <ToggleButton value={false}>
            NO
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
}
export default BooleanFieldEditor;
