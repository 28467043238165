import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { lighten, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardActions, CardHeader, Grid, Theme, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { Link, useLocation } from 'react-router-dom';
import { mainRadius } from '../../themes/theme';
import { Nullable, DdReport } from '../../types/types';
import CustomButton from '../button/button';
import ServiceError from '../../services/errors';
import ErrorPageManager from '../Error/errorpagemanager';
import { ReportsGetterInterface } from '../../services/ddreports';
import { ReportHelper } from './pdfreports';

export interface PropsEntityReportList {
  service: ReportsGetterInterface,
  id: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    overflow: 'visible'
  },
  root: {
    width: 350,
    border: 'none',
    padding: '4px',
    borderRadius: mainRadius,
    overflow: 'visible',
    '&:hover': {
      boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)'
    }
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  viewBtn: {
    height: '42px',
    borderRadius: mainRadius,
    marginLeft: 'auto',
    textTransform: 'none',
    '&:hover': {
    }
  },
  downloadBtn: {
    height: '42px',
    borderRadius: mainRadius,
    marginLeft: 'auto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2)
    }
  }
}));

function EntityReportList(props: PropsEntityReportList): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [reports, setReports] = useState<Nullable<Array<DdReport>>>(null);
  const [error, setError] = useState<Nullable<ServiceError>>(null);

  function updateData() {
    if (props.id) {
      props.service.getReportList()
        .then((apiResp) => {
          setReports(apiResp.data);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
        });
    }
  }

  useEffect(() => {
    updateData();
  }, []);

  return (
    <>
      {error ? <ErrorPageManager error={error} /> :
        <Grid container spacing={2}>
          {reports && reports.map((report: DdReport) =>

          // const generationTime = report.generationTime;
          // const generationTime = new Date();
          // const subheader = (generationTime) ? `Generated on : ${generationTime?.toString().substring(0,10)}` : undefined;

            (
              <Grid key={report._id} item>
                <Card className={classes.root} variant='outlined'>
                  <CardHeader
                    avatar={
                      <DescriptionOutlinedIcon />
                    }
                    title={<Typography variant='h6'>{report.title}</Typography>}
                    // subheader={subheader}
                  />
                  <CardActions disableSpacing style={{ justifyContent: 'end' }}>
                    {!report.formats.find(obj => obj.format === 'html')?.disabled &&
                      <Link to={`${pathname}/${report._id}`} style={{ textDecoration: 'none' }}>
                        <CustomButton variant='outlined' style={{ marginLeft: 'auto', marginRight: '12px' }}>
                          <VisibilityOutlinedIcon />
                          <Typography style={{ paddingLeft: '6px' }}>{t('dds.tabs.reports.view')}</Typography>
                        </CustomButton>
                      </Link>
                    }
                    {!report.formats.find(obj => obj.format === 'pdf')?.disabled &&
                      <CustomButton
                        variant='contained'
                        onClick={() => ReportHelper.downloadPDFReportByDdReportId(report._id)}
                      >
                        <GetAppOutlinedIcon />
                        <Typography style={{ paddingLeft: '6px' }}>{t('dds.tabs.reports.downloadPDF')}</Typography>
                      </CustomButton>
                    }
                  </CardActions>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      }
    </>
  );

}

export default EntityReportList;
