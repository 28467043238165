import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { investmgrContext } from '../../../hooks/datatabs';
import { EntityDdList } from '../../duediligences/listing/entityddlist';

function InvestMgrDdList(): ReactElement {
  const investMgrData = investmgrContext();
  const { t } = useTranslation();

  return (
    <>
      {investMgrData && investMgrData._id &&
        <EntityDdList entityType='investMgr' entityId={investMgrData._id} entityTypeText={t('investMgrs.simpleLabel')} />
      }
    </>
  );
}

export default InvestMgrDdList;
