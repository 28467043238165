import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Maintenance } from '../../../types/types';

const useStyles = makeStyles(() => ({
  banner: {
    zIndex: 4000,
    backgroundColor: '#fcf4a3',
    color: 'rgba(0, 0, 0, .7)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '48px',
    lineHeight: '48px'
  }
}));

function MaintenanceBanner(props: { maintenance: Maintenance }): ReactElement {
  const classes = useStyles();

  const formatNumber = (num: number) => (
    num.toLocaleString('en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
  );

  return (
    <Grid container className={classes.banner} justifyContent='space-between'>
      <Grid item style={{ margin: '6px 12px' }}>
        <WarningRoundedIcon />
      </Grid>
      <Grid item style={{ alignItems: 'center', margin: '10px' }}>
        <Typography style={{ display: 'flex', alignItems: 'center' }}>
          <strong style={{ fontSize: '16px' }}>Warning :</strong>
          <span style={{ fontSize: '14px', fontWeight: 500, marginLeft: '8px' }}>
            {props.maintenance.start ?
              `Maintenance operation is scheduled the ${formatNumber(props.maintenance.start.getDate())}/${formatNumber(props.maintenance.start.getMonth())}/${props.maintenance.start.getFullYear()} at ${formatNumber(props.maintenance.start.getHours())}:${formatNumber(props.maintenance.start.getMinutes())}` :
              'Maintenance started'
            }
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography> </Typography>
      </Grid>
    </Grid>
  );
}

export default MaintenanceBanner;
