import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Snackbar, Typography, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import MeetingCreationModal from '../../../../components/meetings/listofmeetings/meetingscreationmodal';
import { MeetingProps } from '../../../../types/meetingstypes';
import ServiceError from '../../../../services/errors';
import { DdMeetingsService } from '../../../../services/ddmeetings';
import CustomTable, { Column } from '../../../../components/table/table';
import { CustomIconButton } from '../../../../components/button/button';
import { parseISOString } from '../../../../utils/parse';
import { DdSurveysMeetingsService } from '../../../../services/survey';

function ListOfMeetings(props: {ddId: string, isAuditor?: boolean, readonly?: boolean}): ReactElement {
  const { t } = useTranslation();
  const [meetingsList, setMeetingsList] = useState<MeetingProps[]>([]);
  const [updateMeetingList, setUpdateMeetingList] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const ddMeetingsService = props.isAuditor ?
    new DdMeetingsService(props.ddId) :
    new DdSurveysMeetingsService(props.ddId);

  useEffect(() => {
    if (updateMeetingList) {
      ddMeetingsService.getAll()
        .then((apiResp) => {
          apiResp.data && setMeetingsList(apiResp.data);
          setUpdateMeetingList(false);
        })
        .catch((err) => {
          setSnackbarMessage(ServiceError.getErrorMsg(err));
          setSnackbarOpen(true);
          setUpdateMeetingList(false);
        });
    }

    return () => {
      setUpdateMeetingList(false);
    };
  }, [updateMeetingList]);

  const deleteMeeting = (meetingId: string) => {
    (ddMeetingsService as DdMeetingsService).delete(meetingId)
      .then(() => {
        setUpdateMeetingList(true);
      })
      .catch((err) => {
        setSnackbarMessage(ServiceError.getErrorMsg(err));
      });
  };

  const createMeeting = (title: string) => {
    (ddMeetingsService as DdMeetingsService).create(title)
      .then(() => {
        setUpdateMeetingList(true);
      })
      .catch((err) => {
        setSnackbarMessage(ServiceError.getErrorMsg(err));
      });
  };

  const dataDisplayComputation = (date: Date | undefined) => {
    if (date) {
      return parseISOString(date.toString());
    }

    return null;
  };

  const columns: Array<Column> = [
    { id: 'title', label: t('utils.title'), minWidth: 'min-content' },
    { id: 'participants', label: t('contactsAndParticipants.participants.label'), minWidth: 'min-content' },
    { id: 'date', label: t('utils.date'), minWidth: 'min-content' },
    { id: 'location', label: t('utils.location'), minWidth: 'min-content' },
    { id: 'actions', label: '', minWidth: 'min-content', align: 'right' }
  ];

  const rows = meetingsList.map(meeting => ({
    id: meeting._id,
    value: columns.map(column => (
      <>
        {column.id === 'title' &&
							<Link to={`${meeting._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
							  <Typography variant='h2'>{meeting.title}</Typography>
							</Link>
        }
        {column.id === 'participants' &&
							<Grid container spacing={1}>
							  <Grid item>
							    <PeopleRoundedIcon />
							  </Grid>
							  <Grid item>
							    <Typography>{meeting.participantIds.length + meeting.extraParticipants.length}</Typography>
							  </Grid>
							</Grid>
        }
        {column.id === 'date' && meeting.date &&
							<Grid container spacing={1}>
							  <Grid item>
							    <EventRoundedIcon />
							  </Grid>
							  <Grid item>
							    <Typography>{dataDisplayComputation(meeting.date)}</Typography>
							  </Grid>
							</Grid>
        }
        {column.id === 'location' && meeting.location &&
							<Grid container spacing={1}>
							  <Grid item>
							    <LocationOnRoundedIcon />
							  </Grid>
							  <Grid item>
							    <Typography>{meeting.location}</Typography>
							  </Grid>
							</Grid>
        }
        {column.id === 'actions' && props.isAuditor && !props.readonly &&
							<CustomIconButton
							  onClick={() => deleteMeeting(meeting._id)} variant='contained' color='secondary' small
							>
							  <DeleteRoundedIcon />
							</CustomIconButton>
        }
      </>
    ))
  }));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {t('dds.tabs.meetings.scheduledMeetings')}
          </Typography>
        </Grid>
        {meetingsList.length > 0 &&
					<Grid item xs={12} style={{ marginBottom: '16px' }}>
					  <CustomTable columns={columns} rows={rows} />
					</Grid>
        }
        { props.isAuditor && !props.readonly ?
          <Grid item xs={12}>
            <MeetingCreationModal createMeeting={createMeeting}/>
          </Grid>
          :
          meetingsList.length < 1 &&
					<Grid item xs={12}>
					  <Typography>{t('dds.tabs.meetings.noMeeting')}</Typography>
					</Grid>
        }
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ListOfMeetings;
