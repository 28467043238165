import React, { ReactElement } from 'react';
import { AccordionSummary, Grid } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  accordionContent: {
    width: '100%',
    padding: 0
  }
}));

function HeaderSummary(props: {toggleExpand: () => void, children?: ReactElement | ReactElement[]}): ReactElement {
  const classes = useStyles();

  return (
    <AccordionSummary
      style={{ flexDirection: 'row-reverse' }}
      expandIcon={
        <ExpandMoreRoundedIcon
          onClick={ props.toggleExpand }
        />
      }
      classes={{
        content: classes.accordionContent
      }}
    >
      <Grid container spacing={2} style={{ width: 'calc(100% + 12px)' }} justifyContent='space-between'>
        {props.children}
      </Grid>
    </AccordionSummary>
  );
}

export default HeaderSummary;
