import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./en/translation.json";
import frTranslation from "./fr/translation.json";

export interface I18nPossibleLanguageType {
  full: string,
  global: string,
  version: string
}

export const resources = {
  en: {
    translation: enTranslation
  },
  fr: {
    translation: frTranslation
  }
} as const;

export const i18nPossibleLanguages: I18nPossibleLanguageType[] = [
  { full: "english", global: "en", version: "en-EN" },
  { full: "french", global: "fr", version: "fr-FR" }
];

i18n.use(initReactI18next).init({
  lng: i18nPossibleLanguages[0].global,
  returnNull: false,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources
}).then(() => undefined);
