import React, { ReactElement, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate, Outlet
} from 'react-router-dom';
import Home from '../containers/home';
import BreadcrumbsNav from '../components/layout/breadcrumbs/breadcrumbs';
import Layout from '../components/layout/navbar';
import IdRouter from './idrouter';
import AuditeeRouter from './auditeerouter';
import { PathContextProvider } from '../hooks/path';

import DdListingPage from '../containers/duediligences/listing/listingpage';
import DueDiligenceRouter from './duediligencerouter';

import FundListingPage from '../containers/funds/listing/listingpage';
import FundRouter from './fundrouter';

import InvestMgrListingPage from '../containers/investmentmanagers/listing/listingpage';
import InvestmgrRouter from './investmgrrouter';

import AssetMgrListingPage from '../containers/assetmanagers/listing/listingpage';
import AssetMgrRouter from './assetmgrrouter';

import MandateListingPage from '../containers/mandates/listing/listingpage';
import MandateRouter from './mandaterouter';

import StrategyListingPage from '../containers/strategies/listing/listingpage';
import StrategyRouter from './strategyrouter';

import SecureRoute from '../components/authentification/secureroute';
import AdminRouter from '../components/admin/adminrouter';
import CreateDD from '../containers/duediligences/pages/create';
import DisplayDataPage from '../containers/dev/displaydata';
import PostAuthModal from '../modals/postauthmodal';

function Main(): ReactElement {
  const [pathId, setPathId] = useState(['', '']);

  return (
    <Router basename='/deeligenz'>
      <Routes>
        <Route element={
          <Layout>
            <PostAuthModal />
            <PathContextProvider value={[pathId, setPathId]}>
              <BreadcrumbsNav pathId={pathId} />
              <Outlet />
            </PathContextProvider>
          </Layout>
        }>
          <Route path='/' element={<Home />} />
          <Route path='/dds/*' element={
            <SecureRoute permissions='/dds'>
              <Outlet />
            </SecureRoute>
          }
          >
            <Route path='*' element={
              <IdRouter index={<DdListingPage />} route='/dds'>
                <DueDiligenceRouter />
              </IdRouter>
            }
            />
            <Route path='create' element={<SecureRoute permissions='/dds'><CreateDD /></SecureRoute>} />
          </Route>
          <Route path='/assetmgrs/*' element={
            <SecureRoute permissions='/investmgrs'>
              <Outlet />
            </SecureRoute>
          }
          >
            <Route path='*' element={
              <IdRouter index={<AssetMgrListingPage />} permissions='details' route='/investmgrs'>
                <AssetMgrRouter />
              </IdRouter>
            }
            />
          </Route>
          <Route path='/funds/*' element={
            <SecureRoute permissions='/funds'>
              <Outlet />
            </SecureRoute>
          }
          >
            <Route path='*' element={
              <IdRouter index={<FundListingPage />} permissions='details' route='/funds'>
                <FundRouter />
              </IdRouter>
            }
            />
          </Route>
          <Route path='/mancos/*' element={
            <SecureRoute permissions='/mancos'>
              <Outlet />
            </SecureRoute>
          }
          >
            <Route path='*' element={
              <IdRouter index={<InvestMgrListingPage />} permissions='details' route='/mancos'>
                <InvestmgrRouter />
              </IdRouter>
            }
            />
          </Route>
          <Route path='/mandates/*' element={
            <SecureRoute permissions='/mandates'>
              <Outlet />
            </SecureRoute>
          }
          >
            <Route path='*' element={
              <IdRouter index={<MandateListingPage />} permissions='details' route='/mandates'>
                <MandateRouter />
              </IdRouter>
            }
            />
          </Route>
          <Route path='/strategies/*' element={
            <SecureRoute permissions='/strategies'>
              <Outlet />
            </SecureRoute>
          }
          >
            <Route path='*' element={
              <IdRouter index={<StrategyListingPage />} route='/strategies'>
                <StrategyRouter />
              </IdRouter>
            }
            />
          </Route>
          <Route path='/auditee/*' element={
            <SecureRoute permissions='/auditee'>
              <Outlet />
            </SecureRoute>
          }
          >
            <Route path='*' element={
              <AuditeeRouter />
            }
            />
          </Route>
          <Route path='/admin/*' element={
            <SecureRoute permissions='/admin'>
              <Outlet />
            </SecureRoute>
          }
          >
            <Route path='*' element={
              <AdminRouter />
            }
            />
          </Route>
          <Route path='/dev/*'>
            <Route path='displayData' element={<DisplayDataPage />} />
          </Route>
          <Route path='/' element={<Navigate to='/' />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default Main;
