import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function StrategiesIcon(props: SvgIconProps): ReactElement {
  return (
    <div style={{ height: '24px', width: '24px' }}>
      <SvgIcon {...props} version='1.0' xmlns='http://www.w3.org/2000/svg' width='341.3' height='341.3' viewBox='0 0 256 256'>
        <path d='M67 6l-4 5v56l10 18c7 11 8 14 8 17l-6 45c-6 45-7 48-5 50h8c2-2 14-92 14-98-1-4-2-7-10-19l-8-14-1-25V15h14v25h30V15h21l1 13v12h30V15h13l1 26v25l-9 13-9 16-1 2h-21c-17 0-21 0-23 2-2 1-3 6-1 8l24 1h21l8 51 9 53c2 4 7 6 15 6h8l3 9 3 12 1 2h-83l-83-1 4-12 3-10h45c42 0 45 0 46-2 2-2 2-5 0-7s-6-2-47-2c-48 0-48 0-52 5l-6 16c-4 13-5 15-4 18 2 6-2 6 94 6l89-1 5-4c1-4 1-5-4-19l-6-16c-3-4-7-5-14-5h-8a7378 7378 0 00-15-105c0-3 1-6 8-16l10-18 1-30c0-27 0-30-6-32-3-2-19-3-23-1-5 2-7 7-7 16v8h-8v-7c0-9-1-14-5-16-3-2-5-2-17-2s-14 0-17 2-5 7-5 16v7h-8v-8c0-7 0-9-2-12-3-4-5-5-16-5-8 0-10 0-13 2z'/>
      </SvgIcon>
    </div>
  );
}
