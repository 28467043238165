import React, { ReactElement, useEffect, useState } from 'react';
// import GlobalProgressSteps from '../../../../components/summary/GlobalProgressSteps';
import { useParams } from 'react-router-dom';
import AuditeeInputPhaseStats from '../../../../../components/summary/AuditeeInputPhaseStats';
import { SurveyService } from '../../../../../services/survey';
// import { SummaryInfo } from '../../../../../types/types';
import { Nullable, SummaryAuditeeInfo } from '../../../../../types/types';
import ServiceError from '../../../../../services/errors';

function AuditeeSummary(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const surveyService = new SurveyService();
  // const ddStatus = '' + dd?.status;
  // const ddType = '' + dd?.type;
  const [summaryInfo, setSummaryInfo] = useState<Nullable<SummaryAuditeeInfo>>(null);

  const fetchData = () => {
    if (id) {
      surveyService.getSummaryInfo(id)
        .then((apiResp) => {
          setSummaryInfo(apiResp.data);
          // console.log('summary info : ', summaryInfo)
        })
        .catch((err) => {
          console.log(ServiceError.ensureServiceError(err));
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* <GlobalProgressSteps ddType={ddType} ddStatus={ddStatus}/> */}
      { summaryInfo && summaryInfo.input &&
        <AuditeeInputPhaseStats phaseInfo={ summaryInfo.input } />
      }
    </>
  );
}

export default AuditeeSummary;
