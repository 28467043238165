import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'rich-markdown-editor';
import { useParams } from 'react-router-dom';
import { Chip, Grid, Paper, Snackbar, Typography, Alert, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  InfoRounded,
  SaveRounded
} from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { DdParticipantsService } from '../../../../services/contacts';
import { Participant, Nullable, Topic } from '../../../../types/types';
import { MeetingProps, MeetingTextData } from '../../../../types/meetingstypes';
import GoBackButton from '../../../../components/gobackbutton/gobackbutton';
import SetUpMeeting from '../../../../components/meetings/meetingdetails/setup';
import CustomButton, { CustomIconButton } from '../../../../components/button/button';
import { DdMeetingsService } from '../../../../services/ddmeetings';
import ServiceError from '../../../../services/errors';
import { mainColor, mainRadius } from '../../../../themes/theme';
import CollapsableList from '../../../../components/meetings/allocation/collapsablelist';
import { DdSurveysMeetingsService } from '../../../../services/survey';
import { light } from '../../../../components/reports/customEditorTheme';

const useStyles = makeStyles((theme: Theme) => ({
  meetingElements: {
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-in-out',
    opacity: '0.8',
    position: 'relative',
    border: 'none',
    padding: '12px',
    backgroundColor: 'rgba(157, 157, 157, 0.175)'
  },
  save: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[400]
    }
  },
  paper: {
    padding: '12px 18px 12px 18px',
    marginBottom: '12px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-in-out',
    opacity: '0.9',
    '&:hover': {
      opacity: '1',
      zIndex: '9',
      boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.2s ease-in-out'
    }
  },
  setUp: {
    width: '100%',
    transformOrigin: 'top right',
    borderRadius: '24px',
    position: 'absolute',
    top: '52px'
  },
  componentBigTitle: {
    margin: '0px 0px 0px 12px'
  },
  componentTitle: {
    margin: '12px 0px 0px 12px'
  },
  component: {
    margin: '0px 0px 12px 12px'
  }
}));

function MeetingDetails(props: {ddId: string, isAuditor?: boolean, readonly?: boolean}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [participants, setParticipants] = useState<Nullable<Participant[]>>([]);
  const [meeting, setMeeting] = useState<Nullable<MeetingProps>>(null);
  const [defaultMeeting, setDefaultMeeting] = useState<Nullable<MeetingProps>>(null);
  const [update, setUpdate] = useState(true);
  const [isInformationClose, setIsInformationClose] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editAgenda, setEditAgenda] = useState(false);
  const [editGlobal, setEditGlobal] = useState(false);
  const ddMeetingsService = props.isAuditor ?
    new DdMeetingsService(props.ddId) :
    new DdSurveysMeetingsService(props.ddId);
  const participantsService = new DdParticipantsService(props.ddId);

  useEffect(() => {
    if (update && id) {
      setUpdate(false);
      ddMeetingsService.get(id)
        .then((apiResp) => {
          apiResp.data && setDefaultMeeting(apiResp.data);
        })
        .catch((err) => {
          setDefaultMeeting(null);
          setSnackbarMessage(ServiceError.getErrorMsg(err));
          setSnackbarOpen(true);
        });
    }
  }, [id, update]);

  useEffect(() => {
    setMeeting(defaultMeeting);
    participantsService.getAll()
      .then((apiResp) => {
        apiResp.data && setParticipants(apiResp.data);
      });
  }, [defaultMeeting]);

  const handleUpdateMeeting = (myMeeting: MeetingProps) => {
    (ddMeetingsService as DdMeetingsService).edit(myMeeting._id, {
      title: myMeeting.title,
      location: myMeeting.location,
      date: myMeeting.date,
      participantIds: myMeeting.participantIds,
      extraParticipants: myMeeting.extraParticipants,
      visible: myMeeting.visible,
      meetingTextData: myMeeting.meetingTextData
    })
      .then((apiResp) => {
        apiResp.data && setDefaultMeeting(apiResp.data);
      })
      .catch((err) => {
        setSnackbarMessage(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
      });
  };

  const nbOfQuestions = (topics: Topic[]) => {
    let count = 0;

    topics.forEach(topic => topic.ddElements.forEach(() => {
      count += 1;
    }));

    return (count);
  };

  if (!meeting) {
    return (
      <>
        <Grid container style={{ position: 'relative' }}>
          <Grid
            key='noMeetingDetails'
            item
            xs={12}
            style={{ transition: 'all .4s ease-in-out' }}
          >
            <Grid container>
              <Grid item xs>
                <Typography
                  variant='h2'
                  component='h2'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '14px'
                  }}
                >
                  <GoBackButton />
                  {t('dds.tabs.meetings.details.noTitle')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography>
              {t('dds.tabs.meetings.details.noMeetingForId')}
            </Typography>
          </Grid>
        </Grid>
        <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2} justifyContent='space-between' style={{ position: 'relative' }}>
        <Grid item xs={8}>
          <Typography
            variant='h2'
            component='h2'
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <GoBackButton />
            {meeting.title}
            {meeting.type && <Chip size='small' label={meeting.type} style={{ marginLeft: '16px' }} />}
          </Typography>
        </Grid>
        <Grid item>
          <CustomButton
            onClick={() => setIsInformationClose(!isInformationClose)}
            small
            variant='contained'
            color='primary'
            style={{ paddingRight: '12px', paddingLeft: '12px' }}
          >
            <InfoRounded style={{ marginRight: '6px' }} />
            {t('utils.information')}
            {isInformationClose ?
              <ChevronLeftRounded style={{ marginLeft: '6px' }} /> :
              <ChevronRightRounded style={{ marginLeft: '6px' }} />
            }
          </CustomButton>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              key='meetingDetails'
              item
              xs={!isInformationClose ? 8 : 12}
              style={{ transition: 'all .4s ease-in-out' }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Paper elevation={0} className={classes.meetingElements}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={classes.componentTitle}>
                        <Typography variant='h2'>
                          {t('dds.tabs.meetings.details.agenda')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper elevation={0} className={classes.paper}>
                          <Grid container spacing={2}>
                            <Grid item xs>
                              <Editor
                                style={{
                                  marginTop: '8px',
                                  borderBottom: editAgenda ? `solid 2px ${mainColor}` : undefined
                                }}
                                placeholder={t('inputs.typeYourCommentHere') || ''}
                                defaultValue={meeting.meetingTextData?.agenda || undefined}
                                onChange={(value) => {
                                  const myMeetingTextData: MeetingTextData = {
                                    agenda: value(),
                                    globalComment: meeting?.meetingTextData?.globalComment || null
                                  };
                                  setMeeting(old => (old && { ...old, meetingTextData: myMeetingTextData }));
                                }}
                                readOnly={!editAgenda || props.readonly}
                                theme={light}
                              />
                            </Grid>
                            {editAgenda && props.isAuditor ?
                              <>
                                <Grid item>
                                  <CustomIconButton
                                    variant='contained'
                                    small
                                    onClick={() => {
                                      handleUpdateMeeting(meeting);
                                      setEditAgenda(false);
                                    }}
                                    disabled={props.readonly}
                                  >
                                    <SaveRounded />
                                  </CustomIconButton>
                                </Grid>
                                <Grid item>
                                  <CustomIconButton
                                    color='secondary'
                                    variant='contained'
                                    small
                                    onClick={() => {
                                      setMeeting(defaultMeeting);
                                      setEditAgenda(false);
                                    }}
                                    disabled={props.readonly}
                                  >
                                    <CloseRoundedIcon />
                                  </CustomIconButton>
                                </Grid>
                              </>
                              :
                              <>
                                { props.isAuditor &&
                                  <Grid item>
                                    <CustomIconButton
                                      color='primary'
                                      variant='contained'
                                      small
                                      onClick={() => setEditAgenda(true)}
                                      disabled={props.readonly}
                                    >
                                      <EditRoundedIcon/>
                                    </CustomIconButton>
                                  </Grid>
                                }
                              </>
                            }
                          </Grid>
                        </Paper>
                      </Grid>
                      { props.isAuditor &&
										<>
										  <Grid item xs={12} className={classes.componentBigTitle}>
										    <Typography variant='h1'>
										      {t('dds.tabs.meetings.details.questions')}
										    </Typography>
										  </Grid>
										  { meeting.ddElementByTopic && nbOfQuestions(meeting.ddElementByTopic) > 0 ?
										    meeting.ddElementByTopic.map(topic => ( topic.ddElements.length > 0 &&
													<Grid item xs={12} key={topic.topicId}>
													  <Grid container spacing={2}>
													    { (meeting.ddElementByTopic && meeting.ddElementByTopic.length > 1 || topic.topicId !== 'other') &&
																<Grid item xs={12} className={classes.componentTitle}>
																  <Typography variant='h3' color='primary'>
																    {topic.topicTitle}
																  </Typography>
																</Grid>
													    }
													    <Grid item xs={12}>
													      <CollapsableList
													        list={topic.ddElements}
													        meetings={[meeting]}
													        meetingId={meeting?._id}
													        type='details'
													        update={() => setUpdate(true)}
													        disableDrag
													        isAuditor={props.isAuditor}
													      />
													    </Grid>
													  </Grid>
													</Grid>
										    ))
										    :
										    <Typography style={{ margin: '8px 0 12px 24px' }}>{t('dds.tabs.meetings.details.noQuestion')}</Typography>
										  }
										</>
                      }
                      { props.isAuditor &&
										<>
										  <Grid item xs={12} className={classes.componentTitle}>
										    <Typography variant='h2'>
										      {t('dds.tabs.meetings.details.globalComment')}
										    </Typography>
										  </Grid>
										  <Grid item xs={12}>
										    <Paper elevation={0} className={classes.paper}>
										      <Grid container spacing={2}>
										        <Grid item xs>
										          <Editor
										            style={{
										              marginTop: '8px',
										              borderBottom: editGlobal ? `solid 2px ${mainColor}` : undefined
										            }}
										            placeholder={t('inputs.typeYourCommentHere') || ''}
										            defaultValue={meeting.meetingTextData?.globalComment || undefined}
										            onChange={(value) => {
										              const myMeetingTextData: MeetingTextData = {
										                globalComment: value(),
										                agenda: meeting?.meetingTextData?.agenda || null
										              };
										              setMeeting(old => (old && { ...old, meetingTextData: myMeetingTextData }));
										            }}
										            readOnly={!editGlobal}
										            theme={light}
										          />
										        </Grid>
										        {editGlobal ?
										          <>
										            <Grid item>
										              <CustomIconButton
										                variant='contained'
										                small
										                onClick={() => {
										                  handleUpdateMeeting(meeting);
										                  setEditGlobal(false);
										                }}
										                disabled={props.readonly}
										              >
										                <SaveRounded />
										              </CustomIconButton>
										            </Grid>
										            <Grid item>
										              <CustomIconButton
										                color='secondary'
										                variant='contained'
										                small
										                onClick={() => {
										                  setMeeting(defaultMeeting);
										                  setEditGlobal(false);
										                }}
										                disabled={props.readonly}
										              >
										                <CloseRoundedIcon />
										              </CustomIconButton>
										            </Grid>
										          </>
										          :
										          <>
										            <Grid item>
										              <CustomIconButton
										                color='primary'
										                variant='contained'
										                small
										                onClick={() => setEditGlobal(true)}
										                disabled={props.readonly}
										              >
										                <EditRoundedIcon />
										              </CustomIconButton>
										            </Grid>
										          </>
										        }
										      </Grid>
										    </Paper>
										  </Grid>
										</>
                      }
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.setUp}
              style={{
                right: !isInformationClose ? 0 : 'calc(-33.33% - 14px)',
                transition: 'all .4s ease-in-out'
              }}
            >
              <SetUpMeeting
                ddIdParticipants={participants}
                meeting={meeting}
                updateMeeting={handleUpdateMeeting}
                isAuditor={props.isAuditor}
                readonly={props.readonly}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default MeetingDetails;
