import React, { Dispatch, ReactElement, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextareaAutosize } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { CommentsService } from './comments';
import { CommentsServiceType, DdComments } from '../../../types/types';
import { mainRadius } from '../../../themes/theme';
import { CustomIconButton } from '../../button/button';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120
  },
  newMsg: {
    border: '1px solid #ccc',
    borderRadius: mainRadius,
    padding: '4px',
    '&:focus-within': {
      backgroundColor: '#fff',
      border: '1px solid #888',
      outline: 'none'
    }
  },
  newMsgTextArea: {
    width: '100%',
    border: '0px solid',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    outline: 'none',
    boxSizing: 'border-box',
    padding: '4px',
    backgroundColor: 'transparent',
    '&:focus-within': {
      outline: 'none'
    }
  }
}));

function SelectType({ type, setType }: {type: string, setType: Dispatch<SetStateAction<string>>}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  return (
    <FormControl className={classes.formControl} size='small'>
      <Select
        variant='standard'
        labelId='demo-simple-select-outlined-label'
        id='demo-simple-select-outlined'
        value={type}
        onChange={handleChange}
      >
        <MenuItem value='comment'>{t('messages.types.comment')}</MenuItem>
        <MenuItem value='idea'>{t('messages.types.idea')}</MenuItem>
        <MenuItem value='question'>{t('messages.types.question')}</MenuItem>
        <MenuItem value='answer'>{t('messages.types.answer')}</MenuItem>
        <MenuItem value='decision'>{t('messages.types.decision')}</MenuItem>
        <MenuItem value='alert'>{t('messages.types.alert')}</MenuItem>
      </Select>
    </FormControl>
  );
}

function MessageBox({ setUpdate, typeDisp, children, id, addComment }: {setUpdate: Dispatch<React.SetStateAction<boolean>>, typeDisp?: boolean, children?: ReactElement, id: string | undefined, addComment: (comment: DdComments) => void}): ReactElement {
  const classes = useStyles();
  const [type, setType] = useState('comment');
  const [value, setValue] = useState('');
  const [textAreaDisabled, setTextAreaDisabled] = useState(false);
  const commentsService = useContext(CommentsService) as CommentsServiceType;

  const handleSend = () => {
    setTextAreaDisabled(true);
    if (id === undefined) {
      commentsService.add(type, value)
        .then((res) => {
          addComment(res.data.data);
          setTextAreaDisabled(false);
          setValue('');
          setUpdate(true);
        })
        .catch(() => {
          setUpdate(true);
        });
    } else {
      commentsService.reply(id, value)
        .then((res) => {
          addComment(res.data.data);
          setTextAreaDisabled(false);
          setValue('');
          setUpdate(true);
        })
        .catch(() => {
          setUpdate(true);
        });
    }
    setType('comment');
  };

  return (
    <Grid container spacing={2} alignItems='center' className={classes.newMsg}>
      <Grid item xs style={{ padding: '4px' }}>
        <TextareaAutosize
          disabled={textAreaDisabled}
          className={classes.newMsgTextArea}
          placeholder='new message'
          value={value}
          onChange={(event => setValue(event.target.value))}
          style={{
            fontFamily: 'inherit'
          }}
        />
      </Grid>
      <Grid item style={{ width: 'fit-content', padding: '4px' }} alignSelf='flex-end'>
        <Grid container spacing={1} alignItems='center'>
          { typeDisp &&
            <Grid item>
              <SelectType type={type} setType={setType} />
            </Grid>
          }
          <Grid item>
            <CustomIconButton variant='text' color='primary' small onClick={handleSend}>
              <SendRoundedIcon />
            </CustomIconButton>
          </Grid>
          { children &&
            <Grid item>
              {children}
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MessageBox;
