import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import { Person } from '../../types/types';

interface AddListProps<T> {
  contactList: Array<T>,
  contactSelected: Array<T>,
  setContactSelected: Dispatch<React.SetStateAction<Array<T>>>,
  enableSelectAll?: true
}

const useStyles = makeStyles(() => ({
  typo: {
    marginTop: '12px',
    marginBottom: '12px'
  },
  root: {
    display: 'flex',
    maxHeight: '600px'
  },
  listLabel: {
    marginLeft: '-12px',
    width: 'calc(100% + 12px)',
    overflowX: 'auto'
  },
  formControl: {
    width: 'calc(100%)'
  },
  divider: {
    width: '100%'
  }
}));

function SelectList<T extends Person>(props: AddListProps<T> ): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (props.contactSelected.length === props.contactList?.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  });

  const handleChange = (contact: T) => {
    if (props.contactSelected.some(item => contact === item)) {
      props.setContactSelected(props.contactSelected.filter(item => item !== contact));
    } else {
      props.setContactSelected([ ...props.contactSelected, contact]);
    }
  };

  const handleSelectAll = () => {
    if (props.contactSelected === props.contactList) {
      props.setContactSelected([]);
    } else {
      props.setContactSelected(props.contactList || []);
    }
  };

  return (
    <div style={{ overflow: 'auto', maxHeight: '400px' }}>
      { props.enableSelectAll && <Typography variant='h3' className={classes.typo}>{t('contactsAndParticipants.participants.selectContactsToAdd')}</Typography>}
      <div className={classes.root}>
        <FormControl component='fieldset' className={classes.formControl}>
          <FormGroup>
            { props.enableSelectAll && props.contactList.length > 0 &&
              <>
                <FormControlLabel
                  key='all'
                  control={
                    <Checkbox color='primary' checked={allChecked} onChange={handleSelectAll} />
                  }
                  label={
                    <ListItem>
                      <ListItemText className={classes.listLabel}
                        primary={<Typography variant='body2'>{t('btns.selectAll')}</Typography>} />
                    </ListItem>
                  }
                />
                <Divider className={classes.divider} />
              </>
            }
            {props.contactList.map(contact => (
              <FormControlLabel
                key={contact.username}
                control={
                  <Checkbox color='primary' checked={props.contactSelected.some(item => contact === item)} onChange={() => handleChange(contact)} />
                }
                style={{ whiteSpace: 'nowrap' }}
                label={
                  <ListItem>
                    <ListItemText className={classes.listLabel} primary={`${contact.firstName} ${contact.lastName}`} secondary={`${contact.email}`} />
                  </ListItem>
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );
}

export default SelectList;
