import React, { ReactElement } from 'react';
import ReactDiffViewer from 'react-diff-viewer';

interface DiffViewProps {
  previousValue?: string,
  currentValue?: string,
  dispOnlyChanges?: boolean
}

function DiffView (props: DiffViewProps): ReactElement {
  const { previousValue, currentValue, dispOnlyChanges } = props;

  return (
    <ReactDiffViewer oldValue={previousValue} newValue={currentValue} splitView={false} showDiffOnly={dispOnlyChanges} />
  );
}

export default DiffView;
