import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import DateQuestionViewer  from './datequestionviewer';
import AmountQuestionViewer  from './amountquestionviewer';
import NumberQuestionViewer  from './numberquestionviewer';
import SelectQuestionViewer  from './selectquestionviewer';
import StringQuestionViewer  from './stringquestionviewer';
import StringArrayQuestionViewer  from './stringarrayquestionviewer';
import TableQuestionViewer  from './tablequestionviewer';
import TextQuestionViewer  from './textquestionviewer';
import BooleanQuestionViewer  from './booleanquestionviewer';
import CountryQuestionViewer  from './countryquestionviewer';
import CountryArrayQuestionViewer  from './countryarrayquestionviewer';
import CurrencyQuestionViewer  from './currencyquestionviewer';
import { Answer, ButtonsSettings, Nullable, QuestionSpec } from '../../../types/types';
import QuestionBox from '../box';
import QuestionValue from '../value';
import DateArrayQuestionViewer from './datearrayquestionviewer';
import QuestionLabel from '../label';
import ObjectQuestionViewer from './objectquestionviewer';
import FileQuestionViewer from './filequestionviewer';
import FileArrayQuestionViewer from './filearrayquestionviewer';
import MultiSelectQuestionViewer from './multiselectquestionviewer';
import CompanyQuestionViewer from './companyquestionviewer';
import PercentQuestionViewer from './percentquestionviewer';
import QuestionEditorButton from '../questioneditorbutton';

1;

export default function DispatchQuestionViewer(props: {spec: QuestionSpec, answer: Nullable<Answer>, setAnswer?: (answer: Nullable<Answer>) => void, id: string, meetingAuditor?: boolean, buttonsSettings?: ButtonsSettings, disableInput?: string}): ReactElement {

  const { t } = useTranslation();
  const componentMap = {
    'boolean': BooleanQuestionViewer,
    'company': CompanyQuestionViewer,
    'companies': TableQuestionViewer,
    'country': CountryQuestionViewer,
    'country[]': CountryArrayQuestionViewer,
    'currency': CurrencyQuestionViewer,
    'date': DateQuestionViewer,
    'amount': AmountQuestionViewer,
    'number': NumberQuestionViewer,
    'object': ObjectQuestionViewer,
    'percent': PercentQuestionViewer,
    'person': ObjectQuestionViewer,
    'persons': TableQuestionViewer,
    'select': SelectQuestionViewer,
    'multiselect': MultiSelectQuestionViewer,
    'string': StringQuestionViewer,
    'string[]': StringArrayQuestionViewer,
    'table': TableQuestionViewer,
    'text': TextQuestionViewer,
    'date[]': DateArrayQuestionViewer,
    'currency[]': CountryArrayQuestionViewer,
    'file': FileQuestionViewer,
    'file[]': FileArrayQuestionViewer
  };

  const type = props.spec.type as keyof typeof componentMap;
  const ReturnComponent = componentMap[type];

  const setValue = (value: Nullable<Answer['value']>) => {
    if (props.answer && props.setAnswer) {
      props.setAnswer({
        ...props.answer,
        value
      });
    }
  };

  const formatAnswerInfo = () => {
    if (props.meetingAuditor === false) {
      return (
        <Box>
          <Typography
            style={{
              padding: '8px',
              fontSize: '16px',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.54)',
              lineHeight: 1.2
            }}>
            {props.spec.label}
          </Typography>
        </Box>
      );
    }

    if (props.answer) {
      const received = props.answer.answerInfo[0];

      if (received === 'notApplicable') {
        return (
          <QuestionBox>
            <QuestionLabel spec={props.spec} />
            <QuestionValue>
              {t('ddElements.status.notApplicable')}
            </QuestionValue>
            {props.setAnswer && props.buttonsSettings &&
              <QuestionEditorButton response={props.answer?.value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} previousAnswer />
            }
          </QuestionBox>
        );
      }
      if (received.includes('notDisclosed')) {
        if (received.includes('NotTo')) {
          return (
            <QuestionBox>
              <QuestionLabel spec={props.spec} />
              <QuestionValue>
                {t('ddElements.status.notDisclosed.noReview')}
              </QuestionValue>
              {props.setAnswer && props.buttonsSettings &&
                <QuestionEditorButton response={props.answer?.value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} previousAnswer />
              }
            </QuestionBox>
          );
        }
        if (received.includes('ToReview')) {
          return (
            <QuestionBox>
              <QuestionLabel spec={props.spec} />
              <QuestionValue>
                {t('ddElements.status.notDisclosed.toReview')}
              </QuestionValue>
              {props.setAnswer && props.buttonsSettings &&
                <QuestionEditorButton response={props.answer?.value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} previousAnswer />
              }
            </QuestionBox>
          );
        }

        return (
          <QuestionBox>
            <QuestionLabel spec={props.spec} />
            <QuestionValue>
              {t('ddElements.status.notDisclosed.label')}
            </QuestionValue>
          </QuestionBox>
        );
      }

      return <ReturnComponent {...props} />;
    }

    return undefined;
  };

  if (ReturnComponent) {
    if (props.answer === null) {
      if (props.meetingAuditor === false){
        return (
          <Box>
            <Typography
              style={{
                padding: '8px',
                fontSize: '16px',
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 0.54)',
                lineHeight: 1.2
              }}>
              {props.spec.label}
            </Typography>
          </Box>
        );
      }

      return (
        <QuestionBox>
          <QuestionLabel spec={props.spec} />
          <QuestionValue>
            <div>{t('ddElements.status.noAnswer')}</div>
          </QuestionValue>
        </QuestionBox>
      );
    }
    if (props.answer.answerInfo) {
      return (
        <>
          {formatAnswerInfo()}
        </>
      );
    }

    return (
      <ReturnComponent {...props} />
    );
  }

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <div>{t('ddElements.status.notSupported')} {props.spec.type}</div>
      </QuestionValue>
    </QuestionBox>
  );
}
