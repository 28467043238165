import React, { ReactElement } from 'react';
import AuditeeSummary from './auditeesummary';

function SurveysSummary() : ReactElement {

  return (
    <>
      <AuditeeSummary />
    </>
  );
}

export default SurveysSummary;
