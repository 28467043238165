import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Dialog, DialogContent, DialogTitle, Grid, Snackbar, Typography, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import AumsTable from './aumstable';
import CustomButton, { CustomIconButton } from '../button/button';
import ManualAum from './manualaum';
import NoAum from './noaum';
import { StyledMenu, useMenuStyle } from '../layout/navbar/navbar';
import AumService from '../../services/aum';
import { Aum, DataDueDiligence, Nullable } from '../../types/types';
import ServiceError from '../../services/errors';
import checkRoles from '../authentification/checkRoles';
import { dataContext } from '../../hooks/datatabs';

function Aums(props: { name: string, aumService: AumService, aum?: Aum, update: () => void, entity?: boolean }): ReactElement {
  const theme = useTheme();
  const menuClasses = useMenuStyle(theme);
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const AUMTypes = {
    manualAUM: {
      label: t('dds.tabs.parameters.aums.manualAUM'),
      value: 'manualAUM'
    },
    filterAUM: {
      label: t('dds.tabs.parameters.aums.filterAUM'),
      value: 'filterAUM'
    },
    noAUM: {
      label: t('dds.tabs.parameters.aums.noAUM'),
      value: 'noAUM'
    }
  };
  const getAumType = (type: string) => {
    if (type === 'manual') {
      return AUMTypes.manualAUM;
    }

    return AUMTypes.filterAUM;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [aumType, setAumType] = useState(props.aum?.aumContributors && props.aum.aumContributors.length > 0 ? getAumType(props.aum.aumContributors[0].type) : AUMTypes.noAUM);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const dd: Nullable<DataDueDiligence> = !props.entity ? dataContext()?.dueDiligence || null : null;
  const participantRoles = dd?.participants.filter(participant => participant.id === keycloak.subject).flatMap(participant => participant.roles) || [];
  const canEditAUM = checkRoles(['owner', 'manager', 'admin', 'developer'], keycloak)
    || participantRoles.some(role => role === 'owner' || role === 'manager');

  const aumMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSetAUMType = (type: { label: string, value: string }) => {
    setAnchorEl(null);
    setAumType(type);
    if (type.value === AUMTypes.noAUM.value) {
      props.aumService.setAUM([])
        .then(() => {
          props.update();
        })
        .catch((err) => {
          setErrorMsg(ServiceError.getErrorMsg(err));
          setSnackbarOpen(true);
        });
    }
  };

  const validNoAumType = () => {
    handleSetAUMType(AUMTypes.noAUM);
    handleClose();
  };

  const renderMenu = (
    <StyledMenu
      disableScrollLock
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={() => setAnchorEl(null)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MenuItem className={menuClasses.menuItem} onClick={() => handleSetAUMType(AUMTypes.manualAUM)}>
            {AUMTypes.manualAUM.label}
          </MenuItem>
        </Grid>
        <Grid item xs={12}>
          <MenuItem className={menuClasses.menuItem} onClick={() => handleSetAUMType(AUMTypes.filterAUM)}>
            {AUMTypes.filterAUM.label}
          </MenuItem>
        </Grid>
        <Grid item xs={12}>
          <MenuItem className={menuClasses.menuItem} onClick={() => setOpenModal(true)}>
            {AUMTypes.noAUM.label}
          </MenuItem>
        </Grid>
      </Grid>
    </StyledMenu>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item>
              <Typography variant='subtitle1'>{t('dds.tabs.parameters.aums.label')}</Typography>
            </Grid>
            <Grid item>
              <CustomButton variant='text' onClick={aumMenuOpen} style={{ paddingLeft: '12px', paddingRight: '6px' }} small disabled={!canEditAUM}>
                {aumType.label}
                <ArrowDropDownRoundedIcon style={anchorEl ? { transform: 'rotate(180deg)' } : undefined} />
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {aumType.value === AUMTypes.filterAUM.value && (
            <AumsTable
              aumService={props.aumService}
              list={props.aum?.aumContributors || []}
              aumType={aumType.value}
              type={props.aum?.aumContributors && props.aum.aumContributors.length > 0 && props.aum?.aumContributors[0].type || ''}
              update={props.update}
              readonly={!canEditAUM}
            />
          )}
          {aumType.value === AUMTypes.manualAUM.value &&
            <ManualAum
              name={props.aum?.aumContributors && props.aum.aumContributors.length > 0 && props.aum?.aumContributors[0].label || ''}
              type={props.aum?.aumContributors && props.aum.aumContributors.length > 0 && props.aum?.aumContributors[0].type || ''}
              aum={props.aum}
              aumService={props.aumService}
              update={props.update}
              readonly={!canEditAUM}
            />
          }
          {aumType.value === AUMTypes.noAUM.value && <NoAum />}
        </Grid>
      </Grid>
      {renderMenu}
      { openModal &&
        <Dialog
          disableEnforceFocus
          fullWidth
          maxWidth='xs'
          open={openModal}
          onClose={handleClose}
          aria-labelledby='max-width-dialog-title'
        >
          <DialogTitle id='max-width-dialog-title'>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item>
                <Typography style={{ marginTop: '8px' }} variant='h1'>{t('dds.tabs.parameters.aums.noAUM')}</Typography>
              </Grid>
              <Grid item>
                <CustomIconButton
                  variant='contained'
                  color='secondary'
                  onClick={handleClose}
                  small
                >
                  <CloseRoundedIcon />
                </CustomIconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ minHeight: '140px' }}>
            <Typography style={{ marginBottom: '24px' }}>
              {t('dds.tabs.parameters.aums.confirmNotSetAUM')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomButton fullWidth style={{ backgroundColor: green[500], color: 'white' }} onClick={validNoAumType}>{t('btns.validate')}</CustomButton>
              </Grid>
              <Grid item xs={6}>
                <CustomButton fullWidth variant='contained' color='secondary' onClick={handleClose}>{t('btns.cancel')}</CustomButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      }
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Aums;
