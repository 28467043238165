import React, { ReactElement, useState } from "react";
import CardActions from '@mui/material/CardActions';
import StringFieldEditor from '../../field/editors/stringfieldeditor';
import QuestionBox from "../box";
import QuestionValue from "../value";
import { QuestionEditorProps, StringQuestionSpec, Value } from '../../../types/types';
import QuestionEditorButton from "../questioneditorbutton";
import QuestionLabel from '../label';

function StringQuestionEditor(props: QuestionEditorProps<StringQuestionSpec>) : ReactElement {
  const [value, setValue] = useState<Value>(props.answer?.value);

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <StringFieldEditor value={value as string} setValue={setValue}/>
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} />
      </CardActions>
    </QuestionBox>
  );
}
export default StringQuestionEditor;
