import { BaseEntityService, BaseService, PaginatedParams } from './data';
import { ApiResponse, DdReport, Nullable } from '../types/types';
import { API } from '../api/api';

export interface ReportsGetterInterface {
  getReportList(): Promise<ApiResponse<Nullable<Array<DdReport>>>>,
  getReport(reportId: string): Promise<ApiResponse<Nullable<DdReport>>>
}

export class DdReportService extends BaseEntityService<DdReport> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/ddreports';
  }
}

export class ReportsGetter<T> extends BaseService implements ReportsGetterInterface {
  id: string;

  baseService: BaseEntityService<T>;

  constructor(id: string, baseService: BaseEntityService<T>) {
    super();
    this.id = id;
    this.baseService = baseService;
  }

  getBaseUrl(): string {
    return this.baseService.getBaseUrl();
  }

  getReportList(): Promise<ApiResponse<Nullable<Array<DdReport>>>> {
    const params: PaginatedParams = {};
    params.limit = 1000;
    params.skip = 0;

    return API.get(`${this.getBaseUrl()}/${this.id}/reports`, { params })
      .then(res => this.handleResponse<Array<DdReport>>(res));
  }

  getReport(reportId: string): Promise<ApiResponse<Nullable<DdReport>>> {

    return API.get(`${this.getBaseUrl()}/${this.id}/reports/${reportId}`)
      .then(res => this.handleResponse<DdReport>(res));
  }
}

export default DdReportService;
