import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Box, Grid } from '@mui/material';
import DownloadRoundedIcon from '@mui/icons-material/GetAppRounded';
import CustomTable, { Column } from '../../table/table';
import { CustomIconButton } from '../../button/button';
import { QuestionDocument } from '../../../types/types';
import PdfViewerDialog from '../../pdfviewer/pdfmodal';
import ImgViewerDialog from '../../pdfviewer/imagemodal';
import { bytesToSize } from '../../../utils/computations';

function SimpleFilesList(props: {list: QuestionDocument[], download: (id: string) => void, display: (id: string) => void, delete?: (id: string) => void}) : ReactElement {
  const { id } = useParams();
  const ddId = id as string;

  const columns: Array<Column> = [
    { id: 'filename', label: 'Filename', minWidth: 'min-content', align: 'left' },
    //    { id: 'contentType', label: 'File Type', minWidth: 'min-content', align: 'center' },
    { id: 'size', label: 'Size', minWidth: 'max-content', align: 'center' },
    { id: 'actions', label: '', minWidth: 'min-content', align: 'right' }
  ];

  const isValidImg = (extension: string) => {
    const imgExtensions = ['jpg', 'jpeg', 'svg', 'bmp', 'png', 'gif'];

    return imgExtensions.includes(extension);
  };

  const rows = props.list.map((file: QuestionDocument) => ({
    id: file.fileInfo.filename,
    value: columns.map(column => (
      <>
        {column.id === 'filename' &&
          <div>{file.fileInfo.filename}</div>
        }
        {column.id === 'contentType' &&
          <div>
            {file.fileInfo.extension}
          </div>
        }
        {column.id === 'size' &&
          <div style={{ whiteSpace: 'nowrap' }}>
            {bytesToSize(file.fileInfo.size)}
          </div>
        }
        {column.id === 'actions' &&
          <Grid container spacing={1} justifyContent='flex-end' style={{ flexWrap: 'nowrap' }}>
            {isValidImg(file.fileInfo.extension) &&
              <Grid item>
                <ImgViewerDialog documentId={file._id} documentTitle={file.fileInfo.filename} ddId={ddId} handleDownload={props.download} handleDisplay={props.display}/>
              </Grid>
            }
            {file.fileInfo.extension === 'pdf' &&
              <Grid item>
                <PdfViewerDialog documentId={file._id} documentTitle={file.fileInfo.filename} ddId={ddId} handleDownload={props.download} handleDisplay={props.display}/>
              </Grid>
            }
            {props.download &&
              <Grid item>
                <CustomIconButton
                  variant='contained'
                  color='primary'
                  onClick={() => (props.download ? props.download(file._id) : undefined)}
                  small
                >
                  <DownloadRoundedIcon />
                </CustomIconButton>
              </Grid>
            }
            {props.delete &&
              <Grid item>
                <CustomIconButton
                  variant='contained'
                  color='secondary'
                  onClick={() => (props.delete ? props.delete(file._id) : undefined)}
                  small
                >
                  <DeleteRoundedIcon />
                </CustomIconButton>
              </Grid>
            }
          </Grid>
        }
      </>
    ))
  }));

  return (
    <Box style={{ width: 'calc(100% - 12px)' }}>
      <CustomTable columns={columns} rows={rows} size='medium' />
    </Box>
  );
}

export default SimpleFilesList;
