import React, { ReactElement } from 'react';
import { mandateContext } from '../../../../hooks/datatabs';
import { API_BASE_URL } from '../../../../utils/env';
import { DisplayDataModal } from '../../../dev/displaydata';

function MandateDeveloperPage(): ReactElement {
  const mandate =  mandateContext();

  if (mandate) {
    return (
      <div>
        <h2>API links</h2>
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/mandates/${mandate._id}`} text='Entity JSON' />
      </div>
    );
  }

  return (<></>);
}

export default MandateDeveloperPage;
