import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Snackbar, TextField, Typography, Alert, Theme } from '@mui/material';
import { Contact, Nullable } from '../../types/types';
import CustomButton  from '../button/button';
import {
  GlobalContactsService
} from '../../services/contacts';
import { useContactSidePanelLayoutStyle } from './contactsTable';

interface EditContactsProps {
  contactToEdit: Contact,
  setContactToEdit: Dispatch<SetStateAction<Nullable<Contact>>>,
  setUpdate: Dispatch<SetStateAction<boolean>>,
  onClose: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  contactHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px'
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  elements: {
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'column'
  },
  contactName: {
    marginLeft: '12px'
  },
  input: {
    marginTop: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));

function EditContacts(props: EditContactsProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const contactClasses = useContactSidePanelLayoutStyle();
  const [contact, setContact] = useState(props.contactToEdit);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const contactsService = new GlobalContactsService;
  const fieldsLabel = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone',
    username: 'Username',
    title: 'Title'
  };

  useEffect(() => {
    props.contactToEdit !== contact && setContact(props.contactToEdit);
  }, [props.contactToEdit]);

  const handleChange = (key: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContact({ ...contact, [key]: event.target.value });
  };

  const handleSave = () => {
    contactsService.edit(props.contactToEdit.id, contact)
      .then((apiResp) => {
        apiResp.data === contact && props.setContactToEdit(null);
        props.setUpdate(true);
        props.onClose();
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className={classes.elements}>
        <div className={classes.contactHeader}>
          <Avatar className={classes.avatar} alt={`${props.contactToEdit.firstName || 'New'}.${props.contactToEdit.lastName || 'Contact'}`} src=''>
            {props.contactToEdit.firstName.charAt(0) + props.contactToEdit.lastName.charAt(0) || 'N'}
          </Avatar>
          <Typography variant='h2' className={classes.contactName}>{`${props.contactToEdit.firstName || 'New'} ${props.contactToEdit.lastName || 'Contact'}`}</Typography>
        </div>

        { Object.entries(fieldsLabel).map(([key]) => {
          if (key === 'username' || key === 'id') {
            return (<div key={key} />);
          }

          return (
            <TextField
              key={key}
              variant='standard'
              label={fieldsLabel[key as keyof typeof fieldsLabel]}
              className={classes.input}
              maxRows={1}
              value={contact[key as keyof Contact]}
              onChange={event => handleChange(key, event)}
            />
          );
        })}
      </div>
      <div className={contactClasses.buttons}>
        <div className={contactClasses.btn}>
          <CustomButton
            variant='contained'
            style={{ width: '100%' }}
            onClick={handleSave}
          >
            Save
          </CustomButton>
        </div>
      </div>
      <Snackbar onClose={handleCloseSnackbar} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default EditContacts;
