import { orange } from '@mui/material/colors';
import { mainColor, secondaryColor } from '../themes/theme';

function getColorFromDate(date?: string): string | undefined {
  if (!date) {
    return undefined;
  }

  const daysLeft = Math.ceil((+ new Date(date) - + new Date()) / (1000 * 60 * 60 * 24));

  switch (true) {
  case daysLeft > 5:
    return mainColor;
  case daysLeft > 1:
    return orange[500];
  case daysLeft < 2:
    return secondaryColor;
  default:
    return mainColor;
  }
}

export default getColorFromDate;
