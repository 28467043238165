import React, { ReactElement, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  SelectChangeEvent,
  Snackbar,
  Tooltip,
  Typography,
  Alert, Theme
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { DdTemplate, Nullable, DdTemplateOption } from "../../../../types/types";
import { DdService } from "../../../../services/dds";
import CustomButton from '../../../../components/button/button';
import { mainRadius } from '../../../../themes/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

function CreateDD(): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [searchParams /* setSearchParams */] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));
  const entityType = searchParams.get('type') ;
  const entityId = searchParams.get('id') ;
  const [ddType, setDdType] = React.useState('');
  const [ddTemplate, setDdTemplate] = React.useState<Nullable<DdTemplate>>(null);
  const [possibleDdTypes, setPossibleDdTypes] = React.useState<Nullable<Array<string>>>(null);
  const [possibleDdTemplates, setPossibleDdTemplates] = React.useState<Nullable<Array<DdTemplate>>>(null);
  const ddService = new DdService();
  const [displayTemplate, setDisplayTemplate] = React.useState(false);
  const [displayButton, setDisplayButton] = React.useState(false);
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  // const [isOptionReceived, setIsOptionReceived] = useState(true);
  const [templateId, setTemplateId] = useState<Nullable<string>>(null);
  const [ddTemplateOptions, setDdTemplateOptions] = useState<Nullable<Array<DdTemplateOption>>>(null);
  const [checkedOptions, setCheckedOptions ] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [useLatestVersion, setUseLatestVersion] = useState(false);
  const { keycloak } = useKeycloak();

  const resetSelectedTemplate = () => {
    setTemplateId(null);
    setDdTemplateOptions(null);
    setCheckedOptions([]);
    setDisplayButton(false);
  };

  const resetDdTemplates = () => {
    setDdTemplate(null);
    setPossibleDdTemplates(null);
    resetSelectedTemplate();
  };

  const onDdTypeSelected = (type: string) => {
    setDdType(type);
    resetDdTemplates();
    setDisplayTemplate(true);
  };

  const onDdTemplateSelected = (template: DdTemplate) => {
    resetSelectedTemplate();
    setDdTemplate(template);
    setTemplateId(template._id);
  };

  const resetDdTypes = () => {
    setDdType('');
    setPossibleDdTypes(null);
    setDisplayTemplate(false);
    resetDdTemplates();
  };

  const ToggleUseLatestVersion = () => {
    setUseLatestVersion(!useLatestVersion);
    resetDdTypes();
  };

  useEffect(() => {
    if (entityId && entityType) {
      ddService.getPossibleDdTypes(entityType, entityId, useLatestVersion)
        .then((apiResp) => {
          setPossibleDdTypes(apiResp.data);
          if (apiResp.data !== null && apiResp.data.length === 1) {
            onDdTypeSelected(apiResp.data[0]);
          }
        })
        .catch((err) => {
          console.log(`Error ${err}`);
          // TODO: handle error appropriately
        });
    }
  }, [useLatestVersion]);

  useEffect(()=>{
    if (entityType && entityId && ddType) {
      ddService.getPossibleDdTemplates(entityType, entityId, ddType, useLatestVersion)
        .then((apiResp) => {
          console.log(apiResp.data);
          setPossibleDdTemplates(apiResp.data);
          // ADDED
          if (apiResp.data !== null && apiResp.data.length === 1) {
            onDdTemplateSelected(apiResp.data[0]);
          }
        })
        .catch((err) => {
          console.log(`Error ${err}`);
          // TODO: handle error appropriately
        });
    }
  }, [ddType, displayTemplate]);

  useEffect(() => {
    // ADDED FOR OPTIONS DISPLAY
    if (entityType && entityId && templateId) {
      ddService.getPossibleDdTemplatesOptions(entityType, entityId, templateId, useLatestVersion)
        .then((apiResp) => {
          console.log(apiResp.data);
          if (apiResp.data !== null){
            setDdTemplateOptions(apiResp.data);
            setDisplayButton(true);
          }
        })
        .catch((err) => {
          console.log(`Error ${  err}`);
          // TODO: handle error appropriately
        });
    }
  }, [templateId]);

  const handleChangeType = (event: SelectChangeEvent) => {
    onDdTypeSelected(event.target.value as string);
  };

  const handleChangeTemplate = (template: DdTemplate) => {
    onDdTemplateSelected(template);
  };

  const isChecked = (value: string) => checkedOptions.indexOf(value) > -1;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedOptionsNew = [...checkedOptions];

    if (isChecked(event.target.name)){
      checkedOptionsNew.splice(
        checkedOptionsNew.indexOf(event.target.name, 1)
      );
    } else {
      checkedOptionsNew.push(event.target.name);
    }
    setCheckedOptions(checkedOptionsNew);
  };

  const handleCreateDD = () => {
    setDisable(true);
    if ( entityId && entityType && ddTemplate) {
      const myTemplateId = ddTemplate._id;
      ddService.createDD(entityType, entityId, myTemplateId, checkedOptions, useLatestVersion)
        .then((apiResp) => {
          console.log(apiResp.data);

          // Redirect to the page of the DD
          function goToDdPage() {
            setDisable(false);
            const ddId = apiResp?.data?.dd?.ddId;
            if (ddId) {
              navigate(`/dds/${  ddId}`);
            }
          }
          setTimeout(goToDdPage, 200);
        })
        .catch((err) => {
          console.log(`Error ${err}`);
          setSnackbarOpen(true);
          setDisable(false);
        });
    }
  };

  return (
    <>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
                This due diligence could not be created...
        </Alert>
      </Snackbar>
      <Typography variant='h1' component='h1'>
                Create New Due Diligence
      </Typography>
      { keycloak.hasResourceRole('admin') &&
                <Tooltip title='Use the latest version (even if not active) for all templates'>
                  <FormControlLabel
                    control={<Checkbox checked={useLatestVersion} onChange={ToggleUseLatestVersion } name='gilad' />}
                    label='Use Latest Version'
                  />
                </Tooltip>
      }
      {(possibleDdTypes !== null) &&
            (
              <>
                <Typography variant='h2' style={{ margin: '16px 0 4px 0' }}>Choose the type of Due Diligence</Typography>
                <FormControl className={classes.formControl} style={{ backgroundColor: 'transparent', minWidth: '240px' }}>
                  {
                    (possibleDdTypes.length === 1) ? (
                      <Select variant='standard' value={possibleDdTypes[0]}>
                        <MenuItem value={possibleDdTypes[0]}>{possibleDdTypes[0]}</MenuItem>
                      </Select>
                    ) : (
                      <Select
                        variant='standard'
                        value={ddType}
                        onChange={handleChangeType}
                      >
                        {possibleDdTypes.map(type => (
                          <MenuItem key={type} value={type}>{type}</MenuItem>
                        )
                        )}
                      </Select>
                    )
                  }

                </FormControl>
              </>
            )}
      {
        displayTemplate  && <div>
          {(possibleDdTemplates !== null) &&
                    (<>
                      <Typography variant='h2' style={{ margin: '16px 0 4px 0' }}>Choose the template of Due Diligence</Typography>
                      <FormControl className={classes.formControl} style={{ backgroundColor: 'transparent', minWidth: '240px' }}>
                        {
                          (possibleDdTemplates.length === 1) ? (
                            <Select variant='standard' value={possibleDdTemplates[0].label}>
                              <MenuItem value={possibleDdTemplates[0].label}>{possibleDdTemplates[0].label}</MenuItem>
                            </Select>
                          ) : ( <Select
                            variant='standard'
                            value={ddTemplate?.label || ''}
                          >
                            {possibleDdTemplates.map(template => (
                              <MenuItem key={template._id} value={template.label} onClick={() => handleChangeTemplate(template)}>{template.label}</MenuItem>
                            )
                            )}
                          </Select> )
                        }

                      </FormControl>
                    </>
                    )}
        </div>
      }
      {
        displayTemplate && (ddTemplateOptions != null) &&
                <>
                  { ddTemplateOptions.length > 0 ?
                    <>
                      <Typography variant='h2' style={{ margin: '16px 0 4px 0' }}>Choose the template options</Typography>
                      <FormControl className={classes.formControl}>
                        <FormGroup>
                          { ddTemplateOptions.map( option =>
                            <FormControlLabel key={option.label}
                              control={<Checkbox checked={isChecked(option.value)} onChange={handleCheckboxChange} name={option.label} color='primary'/>}
                              label={option.value}
                            />
                          )}
                        </FormGroup>
                      </FormControl>
                    </> :
                    <Typography style={{ marginTop: '16px' }}>No options available for this template</Typography>
                  }
                </>
      }
      {
        displayButton &&
                <div style={{ marginTop: '24px' }}>
                  <CustomButton variant='contained' color='primary' onClick={handleCreateDD} disabled={disable} >
                        Create Due Diligence
                  </CustomButton>
                  {disable && <LinearProgress style={{ marginTop: '24px', borderRadius: mainRadius }} />}
                </div>
      }
    </>
  );
}

export default CreateDD;
