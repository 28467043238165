import React, { ReactElement } from 'react';
import { DdElement } from '../../types/types';
import { DisplayDataModal } from '../../containers/dev/displaydata';

function DdElementDev(props: {ddElement: DdElement}): ReactElement {
  const { ddElement } = props;

  return (
    <div>
      <h2>Developer view</h2>
      <DisplayDataModal url={`${process.env.REACT_APP_DEELIGENZ_API_BASE_URL  }/api/v1/ddelements/${ddElement._id}`} text='DdElement JSON'/>

    </div>
  );
}

export default DdElementDev;
