import React, { ReactElement, useEffect, useState } from 'react';
import CardActions from '@mui/material/CardActions';
import makeStyles from '@mui/styles/makeStyles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as _ from 'lodash';
import QuestionBox from '../box';
import QuestionValue from '../value';
import {
  BaseValueTypes,
  QuestionEditorProps,
  ObjectQuestionSpec
} from '../../../types/types';
import QuestionEditorButton from '../questioneditorbutton';
import MultiTypeFieldEditor from '../../field/editors/multitypefieldeditor';
import QuestionLabel from '../label';

const useStyles = makeStyles({
  table: {
    marginTop: '-24px'
  },
  title: {
    marginTop: '24px'
  }
});

function ObjectQuestionEditor(props: QuestionEditorProps<ObjectQuestionSpec>) : ReactElement {
  const [value, setValue] = useState(props.answer?.value as Record<string, unknown> || {});
  const objectQuestionSpecFields = props.spec.fields;
  const computeCheckArray = (val: Record<string, unknown>) => objectQuestionSpecFields?.map(spec => (spec.required ? val[spec.id] === 0 || !!val[spec.id] : true));
  const [checkArray, setCheckArray] = useState<boolean[]>(computeCheckArray(value) || []);
  const [notSubmittable, setNotSubmittable] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setNotSubmittable(!checkArray.every(Boolean));
  }, [value]);

  const reset = (myValue: Record<string, unknown>) => {
    if (myValue) {
      setValue(myValue);
    } else {
      setValue({});
    }
    setCheckArray(computeCheckArray(myValue || {}) || []);
  };

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec}/>
      <QuestionValue>
        <Table className={classes.table}>
          <TableBody>
            {objectQuestionSpecFields && (objectQuestionSpecFields).map((field, fieldIndex) => {
              let objectValue = _.cloneDeep(value);
              if (!objectValue) {
                objectValue = {};
              }
              if (field) {
                return (
                  <TableRow key={field.id} style={{ display: 'flex', flexDirection: 'column' }}>
                    <TableCell style={{ border: 'none', padding: 0 }}>
                      <Typography className={classes.title} variant='h3'>{field.label}</Typography>
                    </TableCell>
                    <TableCell style={{ border: 'none', padding: 0 }}>
                      <MultiTypeFieldEditor
                        customKey={fieldIndex.toString()}
                        type={field.type}
                        specs={field}
                        value={objectValue[field.id] as BaseValueTypes}
                        setValue={(v) => {
                          checkArray[fieldIndex] = field.required ? v === 0 || !!v : true;
                          setCheckArray(checkArray);
                          objectValue[field.id] = v as BaseValueTypes;
                          setValue(objectValue);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              }

              return undefined;
            })}
            <TableRow />
          </TableBody>
        </Table>
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={myValue => reset(myValue as Record<string, unknown>)} defaultValue={props.answer?.value} buttonsSettings={{ ...props.buttonsSettings, notSubmittable }} />
      </CardActions>
    </QuestionBox>
  );
}
export default ObjectQuestionEditor;
