import React, { ReactElement } from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { mainRadius } from '../../../themes/theme';

function TableSkeleton(props: { nbColumns: number }): ReactElement {
  const numberOfRaws = 5;

  return (
    <Grid container spacing={2} justifyContent='space-around' style={{ marginTop: '14px' }}>
      {[...Array(props.nbColumns)].map(() => (
        <Grid key={`-${Math.random() * 100}-`} item style={{ width: `${(100 / props.nbColumns) - 4}%` }}>
          <Typography variant='h3' style={{ width: '70%', maxWidth: '70px' }}>
            <Skeleton animation='wave' variant='rectangular' width='100%' height='16px' style={{ borderRadius: 5 }} />
          </Typography>
        </Grid>
      ))}
      {[...Array(numberOfRaws)].map((x, i) => (
        <Grid key={`--${Math.random() * 100}--`} item xs={12} style={{ opacity: (1 - (i / 5)), marginTop: i === 0 ? '14px' : undefined }}>
          <Skeleton animation='wave' variant='rectangular' width='100%' height='64px' style={{ borderRadius: mainRadius }} />
        </Grid>
      ))}
    </Grid>
  );
}

export default TableSkeleton;
