import React, { ReactElement } from "react";
import ReactFlagsSelect from "react-flags-select";
import { CountryManager } from '../../../utils/countries';
import { ValueSetter } from "../../../types/types";
// doc : https://github.com/ekwonye-richard/react-flags-select/blob/master/v1.md

function CountryFieldEditor(props: {value?: string, setValue: ValueSetter, required?: boolean, customKey?: string}):ReactElement {
  const onSelect = (code: string): void =>  {
    props.setValue(CountryManager.getInstance().getByCode2(code || "")?.code3);
  };
  const searchable = true;
  const searchPlaceholder = "";

  return (
    <ReactFlagsSelect
      key={props.customKey}
      selected={CountryManager.getInstance().getByCode3(props.value || "")?.code2 || ''}
      onSelect={onSelect}
      searchable={searchable}
      searchPlaceholder={searchPlaceholder}
    />
  );
}
export default CountryFieldEditor;
