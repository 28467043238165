import React, { ReactElement } from 'react';
import { Grid, Paper, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { mainRadius } from '../../../themes/theme';
import ReactKeyUtils from '../../../utils/reactkeyutils';
import { useStyles } from '../../ddelement/listitem';

function CardsSkeleton(props: { nbCards: number }): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container spacing={3} justifyContent='space-around'>
      {[...Array(props.nbCards)].map(() => (
        <Grid
          key={ReactKeyUtils.generateRandomKey()}
          item
          xs
          style={{
            minWidth: '370px',
            maxWidth: '750px'
          }}
        >
          <Paper elevation={0} className={classes.paper} style={{ width: '100%', height: '370px', opacity: 0.7, backgroundColor: 'rgba(200, 200, 200, 0.2)' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Skeleton animation='wave' variant='circular' width='32px' height='32px' />
                  </Grid>
                  <Grid item xs>
                    <Skeleton animation='wave' variant='rectangular' width='100%' height='16px' style={{ borderRadius: 5, marginTop: '8px' }} />
                  </Grid>
                  <Grid item>
                    <Skeleton animation='wave' variant='rectangular' width='40px' height='40px' style={{ borderRadius: 3 }}/>
                  </Grid>
                  <Grid item>
                    <Skeleton animation='wave' variant='circular' width='40px' height='40px' />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {[...Array(3)].map(() => (
                    <Grid key={ReactKeyUtils.generateRandomKey()} item>
                      <Skeleton animation='wave' variant='rectangular' width='60px' height='24px' style={{ borderRadius: mainRadius }} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation='wave' variant='rectangular' width='100%' height='48px' style={{ borderRadius: mainRadius }} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation='wave' variant='rectangular' width='100%' height='72px' style={{ borderRadius: mainRadius }} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation='wave' variant='rectangular' width='100%' height='40px' style={{ borderRadius: mainRadius }} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation='wave' variant='rectangular' width='100%' height='40px' style={{ borderRadius: mainRadius }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default CardsSkeleton;
