import React, { ReactElement, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Select, SelectChangeEvent, Typography } from '@mui/material';
import { ValueSetter } from "../../../types/types";

// data : https://gist.github.com/joseluisq/59adf057a8e77f625e44e8328767a2a5
export const currencies = [
  { label: 'ㅤ', value: 'ㅤ' },
  { label: 'EUR', value: 'Euro' },
  { label: 'USD', value: 'United States Dollar' },
  { label: 'GBP', value: 'British Pound Sterling' },
  { label: 'JPY', value: 'Japanese Yen' },
  { label: 'CHF', value: 'Swiss Franc' },
  { label: 'AOA', value: 'Angolan Kwanza' },
  { label: 'ARS', value: 'Argentine Peso' },
  { label: 'AUD', value: 'Australian Dollar' },
  { label: 'AWG', value: 'Aruban Florin' },
  { label: 'AZN', value: 'Azerbaijani Manat' },
  { label: 'BAM', value: 'Bosnia-Herzegovina Convertible Mark' },
  { label: 'BBD', value: 'Barbadian Dollar' },
  { label: 'BDT', value: 'Bangladeshi Taka' },
  { label: 'BGN', value: 'Bulgarian Lev' },
  { label: 'BHD', value: 'Bahraini Dinar' },
  { label: 'BIF', value: 'Burundian Franc' },
  { label: 'BMD', value: 'Bermudan Dollar' },
  { label: 'BND', value: 'Brunei Dollar' },
  { label: 'BOB', value: 'Bolivian Boliviano' },
  { label: 'BRL', value: 'Brazilian Real' },
  { label: 'BSD', value: 'Bahamian Dollar' },
  { label: 'BTC', value: 'Bitcoin' },
  { label: 'BTN', value: 'Bhutanese Ngultrum' },
  { label: 'BWP', value: 'Botswanan Pula' },
  { label: 'BYN', value: 'Belarusian Ruble' },
  { label: 'BZD', value: 'Belize Dollar' },
  { label: 'CAD', value: 'Canadian Dollar' },
  { label: 'CDF', value: 'Congolese Franc' },
  { label: 'CLF', value: 'Chilean Unit of Account (UF)' },
  { label: 'CLP', value: 'Chilean Peso' },
  { label: 'CNH', value: 'Chinese Yuan (Offshore)' },
  { label: 'CNY', value: 'Chinese Yuan' },
  { label: 'COP', value: 'Colombian Peso' },
  { label: 'CRC', value: 'Costa Rican Colón' },
  { label: 'CUC', value: 'Cuban Convertible Peso' },
  { label: 'CUP', value: 'Cuban Peso' },
  { label: 'CVE', value: 'Cape Verdean Escudo' },
  { label: 'CZK', value: 'Czech Republic Koruna' },
  { label: 'DJF', value: 'Djiboutian Franc' },
  { label: 'DKK', value: 'Danish Krone' },
  { label: 'DOP', value: 'Dominican Peso' },
  { label: 'DZD', value: 'Algerian Dinar' },
  { label: 'EGP', value: 'Egyptian Pound' },
  { label: 'ERN', value: 'Eritrean Nakfa' },
  { label: 'ETB', value: 'Ethiopian Birr' },
  { label: 'FJD', value: 'Fijian Dollar' },
  { label: 'FKP', value: 'Falkland Islands Pound' },
  { label: 'GEL', value: 'Georgian Lari' },
  { label: 'GGP', value: 'Guernsey Pound' },
  { label: 'GHS', value: 'Ghanaian Cedi' },
  { label: 'GIP', value: 'Gibraltar Pound' },
  { label: 'GMD', value: 'Gambian Dalasi' },
  { label: 'GNF', value: 'Guinean Franc' },
  { label: 'GTQ', value: 'Guatemalan Quetzal' },
  { label: 'GYD', value: 'Guyanaese Dollar' },
  { label: 'HKD', value: 'Hong Kong Dollar' },
  { label: 'HNL', value: 'Honduran Lempira' },
  { label: 'HRK', value: 'Croatian Kuna' },
  { label: 'HTG', value: 'Haitian Gourde' },
  { label: 'HUF', value: 'Hungarian Forint' },
  { label: 'IDR', value: 'Indonesian Rupiah' },
  { label: 'ILS', value: 'Israeli New Sheqel' },
  { label: 'IMP', value: 'Manx pound' },
  { label: 'INR', value: 'Indian Rupee' },
  { label: 'IQD', value: 'Iraqi Dinar' },
  { label: 'IRR', value: 'Iranian Rial' },
  { label: 'ISK', value: 'Icelandic Króna' },
  { label: 'JEP', value: 'Jersey Pound' },
  { label: 'JMD', value: 'Jamaican Dollar' },
  { label: 'JOD', value: 'Jordanian Dinar' },
  { label: 'KES', value: 'Kenyan Shilling' },
  { label: 'KGS', value: 'Kyrgystani Som' },
  { label: 'KHR', value: 'Cambodian Riel' },
  { label: 'KMF', value: 'Comorian Franc' },
  { label: 'KPW', value: 'North Korean Won' },
  { label: 'KRW', value: 'South Korean Won' },
  { label: 'KWD', value: 'Kuwaiti Dinar' },
  { label: 'KYD', value: 'Cayman Islands Dollar' },
  { label: 'KZT', value: 'Kazakhstani Tenge' },
  { label: 'LAK', value: 'Laotian Kip' },
  { label: 'LBP', value: 'Lebanese Pound' },
  { label: 'LKR', value: 'Sri Lankan Rupee' },
  { label: 'LRD', value: 'Liberian Dollar' },
  { label: 'LSL', value: 'Lesotho Loti' },
  { label: 'LYD', value: 'Libyan Dinar' },
  { label: 'MAD', value: 'Moroccan Dirham' },
  { label: 'MDL', value: 'Moldovan Leu' },
  { label: 'MGA', value: 'Malagasy Ariary' },
  { label: 'MKD', value: 'Macedonian Denar' },
  { label: 'MMK', value: 'Myanma Kyat' },
  { label: 'MNT', value: 'Mongolian Tugrik' },
  { label: 'MOP', value: 'Macanese Pataca' },
  { label: 'MRO', value: 'Mauritanian Ouguiya (pre-2018)' },
  { label: 'MRU', value: 'Mauritanian Ouguiya' },
  { label: 'MUR', value: 'Mauritian Rupee' },
  { label: 'MVR', value: 'Maldivian Rufiyaa' },
  { label: 'MWK', value: 'Malawian Kwacha' },
  { label: 'MXN', value: 'Mexican Peso' },
  { label: 'MYR', value: 'Malaysian Ringgit' },
  { label: 'MZN', value: 'Mozambican Metical' },
  { label: 'NAD', value: 'Namibian Dollar' },
  { label: 'NGN', value: 'Nigerian Naira' },
  { label: 'NIO', value: 'Nicaraguan Córdoba' },
  { label: 'NOK', value: 'Norwegian Krone' },
  { label: 'NPR', value: 'Nepalese Rupee' },
  { label: 'NZD', value: 'New Zealand Dollar' },
  { label: 'OMR', value: 'Omani Rial' },
  { label: 'PAB', value: 'Panamanian Balboa' },
  { label: 'PEN', value: 'Peruvian Nuevo Sol' },
  { label: 'PGK', value: 'Papua New Guinean Kina' },
  { label: 'PHP', value: 'Philippine Peso' },
  { label: 'PKR', value: 'Pakistani Rupee' },
  { label: 'PLN', value: 'Polish Zloty' },
  { label: 'PYG', value: 'Paraguayan Guarani' },
  { label: 'QAR', value: 'Qatari Rial' },
  { label: 'RON', value: 'Romanian Leu' },
  { label: 'RSD', value: 'Serbian Dinar' },
  { label: 'RUB', value: 'Russian Ruble' },
  { label: 'RWF', value: 'Rwandan Franc' },
  { label: 'SAR', value: 'Saudi Riyal' },
  { label: 'SBD', value: 'Solomon Islands Dollar' },
  { label: 'SCR', value: 'Seychellois Rupee' },
  { label: 'SDG', value: 'Sudanese Pound' },
  { label: 'SEK', value: 'Swedish Krona' },
  { label: 'SGD', value: 'Singapore Dollar' },
  { label: 'SHP', value: 'Saint Helena Pound' },
  { label: 'SLL', value: 'Sierra Leonean Leone' },
  { label: 'SOS', value: 'Somali Shilling' },
  { label: 'SRD', value: 'Surinamese Dollar' },
  { label: 'SSP', value: 'South Sudanese Pound' },
  { label: 'STD', value: 'São Tomé and Príncipe Dobra (pre-2018)' },
  { label: 'STN', value: 'São Tomé and Príncipe Dobra' },
  { label: 'SVC', value: 'Salvadoran Colón' },
  { label: 'SYP', value: 'Syrian Pound' },
  { label: 'SZL', value: 'Swazi Lilangeni' },
  { label: 'THB', value: 'Thai Baht' },
  { label: 'TJS', value: 'Tajikistani Somoni' },
  { label: 'TMT', value: 'Turkmenistani Manat' },
  { label: 'TND', value: 'Tunisian Dinar' },
  { label: 'TOP', value: 'Tongan Paanga' },
  { label: 'TRY', value: 'Turkish Lira' },
  { label: 'TTD', value: 'Trinidad and Tobago Dollar' },
  { label: 'TWD', value: 'New Taiwan Dollar' },
  { label: 'TZS', value: 'Tanzanian Shilling' },
  { label: 'UAH', value: 'Ukrainian Hryvnia' },
  { label: 'UGX', value: 'Ugandan Shilling' },
  { label: 'USD', value: 'United States Dollar' },
  { label: 'UYU', value: 'Uruguayan Peso' },
  { label: 'UZS', value: 'Uzbekistan Som' },
  { label: 'VEF', value: 'Venezuelan Bolívar Fuerte' },
  { label: 'VND', value: 'Vietnamese Dong' },
  { label: 'VUV', value: 'Vanuatu Vatu' },
  { label: 'WST', value: 'Samoan Tala' },
  { label: 'XAF', value: 'CFA Franc BEAC' },
  { label: 'XAG', value: 'Silver Ounce' },
  { label: 'XAU', value: 'Gold Ounce' },
  { label: 'XCD', value: 'East Caribbean Dollar' },
  { label: 'XDR', value: 'Special Drawing Rights' },
  { label: 'XOF', value: 'CFA Franc BCEAO' },
  { label: 'XPD', value: 'Palladium Ounce' },
  { label: 'XPF', value: 'CFP Franc' },
  { label: 'XPT', value: 'Platinum Ounce' },
  { label: 'YER', value: 'Yemeni Rial' },
  { label: 'ZAR', value: 'South African Rand' },
  { label: 'ZMW', value: 'Zambian Kwacha' },
  { label: 'ZWL', value: 'Zimbabwean Dollar' }
];

export default function CurrencyFieldEditor(props: {value?: string, setValue: ValueSetter, required?: boolean, customKey?: string, dispLabel?: boolean}):ReactElement {
  const [currency, setCurrency] = React.useState(props.value || 'ㅤ');
  const [moreOpen, setMoreOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.target.value && setOpen(false);
    setCurrency(event.target.value as string);
    event.target.value === 'ㅤ' ? props.setValue(undefined) : props.setValue(event.target.value as string);
  };

  const handleMoreOpen = () => {
    setOpen(true);
    setMoreOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMoreOpen(false);
  };

  useEffect(() => {
    setCurrency(props.value || 'ㅤ');
  }, [props.value]);

  return (
    <>
      {props.dispLabel && <Typography variant='body2' style={{ opacity: 0.8 }}>Currency</Typography>}
      <Select
        variant='standard'
        error={props.required ? currency === 'ㅤ' : false}
        key={props.customKey}
        id='standard-select-currency'
        value={currency}
        open={open}
        onClose={handleClose}
        onOpen={() => setOpen(true)}
        onChange={handleChange}
        style={{ minWidth: '108px' }}
      >
        {currencies.map((option, index) => {
          if (index < 6 || option.label === currency) {
            return (
              <MenuItem key={option.value} value={option.label}>
                {option.label}
              </MenuItem>
            );
          }
          if (moreOpen) {
            return (
              <MenuItem key={option.value} value={option.label}>
                {option.label}
              </MenuItem>
            );
          }

          return undefined;
        })}
        {!moreOpen &&
          <MenuItem style={{ marginTop: '12px' }} onClick={handleMoreOpen}>More ...</MenuItem>
        }
      </Select>
    </>
  );
}
