import { DeprecatedThemeOptions } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { mainColor, secondaryColor } from './theme';

export const light: DeprecatedThemeOptions = {
  typography: {
    h3: {
      color: 'grey'
    },
    h4: {
      color: 'grey'
    }
  },
  palette: {
    mode: 'light',
    background: {
      default: lighten(mainColor, 0.93),
      paper: lighten(mainColor, 1)
    },
    text: {
      primary: lighten('#000000', 0.4)
    },
    primary: {
      main: mainColor,
      dark: 'rgba(222, 229, 236, 0.7)',
      contrastText: lighten(mainColor, 0.85)
    },
    secondary: {
      main: secondaryColor
    }
  }
};

export default light;
