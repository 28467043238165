import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { InputInfo } from '../../types/types';
import { InputStatCard } from './InputStatCard';

interface AuditeeInputPhaseStatsProps {
	phaseInfo: InputInfo
}

export default function AuditeeInputPhaseStats(props: AuditeeInputPhaseStatsProps): ReactElement {
  const { phaseInfo } = props;

  return (
    <Grid container style={{ backgroundColor: 'white', padding: '16px', borderRadius: '24px', marginBottom: '16px' }}>
      <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <InputStatCard count={phaseInfo.total} total={phaseInfo.total} showPercent={false} title='Total Questions' color='white' />
        <InputStatCard count={phaseInfo.answered} total={phaseInfo.total} showPercent title='answered' color='blue' link='questions?f_answerStatus=answered' />
        <InputStatCard count={phaseInfo.notAnswered} total={phaseInfo.total} showPercent title='not answered' color='lightGrey' link='questions?f_answerStatus=notAnswered' />
        <InputStatCard count={phaseInfo.validated} total={phaseInfo.total} showPercent title='Validated' color='lightGreen' link='questions?f_answerStatus=validated' />
        <InputStatCard count={phaseInfo.rejected} total={phaseInfo.total} showPercent title='Rejected' color='lightRed' link='questions?f_answerStatus=rejected' />
      </Grid>
    </Grid>
  );
}
