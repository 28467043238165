import React, { ReactElement } from 'react';
import { ddReportBlockContext } from '../../../hooks/datatabs';
import JsonWrapper from '../../dev/jsonwrapper';

function DdReportBlockJson(): ReactElement {
  const ddReportBlock = ddReportBlockContext();

  return (
    <>
      { ddReportBlock &&
        <JsonWrapper>
          {JSON.stringify(ddReportBlock, null, ' ')}
        </JsonWrapper>
      }
    </>
  );
}

export default DdReportBlockJson;