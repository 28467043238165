import React, { Dispatch, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Participant } from '../../../types/types';

export const defaultExternalRoles = ['survey-editor'];
export const defaultInternalRoles = ['owner', 'validator', 'contributor', 'manager'];

interface ParticipantsRolesProps {
  participantType: string,
  external: boolean,
  rolesSelected: Array<string>,
  setRolesSelected: Dispatch<React.SetStateAction<Array<string>>>
}

interface ParticipantsRolesInListProps {
  participant: Participant,
  editionMode: boolean,
  rolesSelected: Array<string>,
  setRolesSelected: Dispatch<React.SetStateAction<Array<string>>>
}

const useStyles = makeStyles(() => ({
  roles: {
    width: '100%',
    marginTop: '12px',
    marginBottom: '24px'
  },
  rolesTitle: {
    margin: '12px 0px'
  },
  rolesLabels: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  rolesLabel: {
    marginRight: '6px',
    marginTop: '3px',
    marginBottom: '3px'
  }
}));

export function ParticipantsRolesInList(props: ParticipantsRolesInListProps): ReactElement {
  const classes = useStyles();

  const handleDelete = (role: string) => {
    props.setRolesSelected(props.rolesSelected.filter(item => item !== role));
  };

  const handleClick = (role: string) => {
    !props.rolesSelected.includes(role) && props.setRolesSelected([...props.rolesSelected, role]);
  };

  return (
    <>
      { (props.participant.external ? defaultExternalRoles : defaultInternalRoles).map((role) => {
        if (props.participant.roles.includes(role) || props.editionMode) {
          return (
            <Chip
              key={role}
              size='small'
              label={role}
              className={classes.rolesLabel}
              color={
                !props.editionMode && props.participant.roles.includes(role) || props.rolesSelected.includes(role) ? 'primary' : undefined
              }
              onClick={props.editionMode ? () => handleClick(role) : undefined}
              onDelete={
                props.editionMode && props.rolesSelected.includes(role) && props.rolesSelected.length > 1 ?
                  () => handleDelete(role) : undefined}
            />
          );
        }

        return undefined;
      })}
    </>
  );
}

function ParticipantsRoles(props: ParticipantsRolesProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    props.setRolesSelected(props.external ? ['survey-editor'] : ['contributor']);
  }, [props.external]);

  const handleDelete = (role: string) => {
    props.setRolesSelected(props.rolesSelected.filter(item => item !== role));
  };

  const handleClick = (role: string) => {
    !props.rolesSelected.includes(role) && props.setRolesSelected([...props.rolesSelected, role]);
  };

  return (
    <div className={classes.roles}>
      <Typography variant='h3' className={classes.rolesTitle}>{t('contactsAndParticipants.participants.chooseRoles')}</Typography>
      <div className={classes.rolesLabel}>
        {(props.external ? defaultExternalRoles : defaultInternalRoles).map(role => (
          <Chip
            key={role}
            label={role}
            color={props.rolesSelected.includes(role) ? 'primary' : undefined}
            className={classes.rolesLabel}
            onClick={() => handleClick(role)}
            onDelete={props.rolesSelected.includes(role) && props.rolesSelected.length > 1 ? () => handleDelete(role) : undefined}
          />
        ))}
      </div>
    </div>
  );
}

export default ParticipantsRoles;
