export function computePercentage(count: number, total: number): number {
  const result = Math.round((count / total) * 100);
  // isNan is here to avoid problems when dividing by 0;

  return Number.isNaN(result) ? 0 : result;
}

export function roundValue(val: number): string {
  return Math.round(val).toLocaleString('en-US');
}

export function bytesToSize(bytes: number): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes  } ${  sizes[i]}`;

  return `${(bytes / 1024**i).toFixed(1)  } ${  sizes[i]}`;
}

export default computePercentage;
