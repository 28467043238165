import { Mandate } from '../types/types';
import { BaseEntityService } from './data';

export class MandateService extends BaseEntityService<Mandate> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/mandates';
  }
}

export default MandateService;
