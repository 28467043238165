import React, { ReactElement } from 'react';
import { Nullable, InvestMgr } from '../../../../types/types';
import { investmgrContext } from '../../../../hooks/datatabs';
import { DisplayDataModal } from '../../../dev/displaydata';
import { API_BASE_URL } from '../../../../utils/env';

function DeveloperPage(): ReactElement {
  const investMgr: Nullable<InvestMgr> =  investmgrContext();

  if (investMgr) {
    return (
      <div>
        <h2>API links</h2>
        <DisplayDataModal url={`${API_BASE_URL}/api/v1/assetMgrs/${investMgr._id}`} text='Entity JSON' />
      </div>
    );
  }

  return (<></>);
}

export default DeveloperPage;
