import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import { LifeCycle } from '../../../types/types';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';
import { mainColor } from '../../../themes/theme';

function HeaderPhase(props: { lifeCycle: LifeCycle }): ReactElement {

  return (
    <Grid item xs display='flex' alignItems='baseline'>
      {props.lifeCycle.phases.map((phase) => {
        const daysLeft = Math.ceil((+ new Date(phase.expectedEndTime) - + new Date()) / (1000 * 60 * 60 * 24));

        return (
          props.lifeCycle.currentPhaseId === phase.id &&
          <Typography key={phase.id} variant='h3' style={{ textAlign: 'center', color: daysLeft > 1 && daysLeft < 6 ? orange[500] : daysLeft > 5 ? mainColor : undefined, marginTop: '1px' }} color={daysLeft < 2 ? 'secondary' : undefined}>
            {capitalizeFirstLetter(phase.label)} ({daysLeft < 0 ? - daysLeft : daysLeft} {daysLeft > 1 || daysLeft < -1 ? 'days' : 'day'} {daysLeft < 0 ? 'late' : 'left'})
          </Typography>
        );
      })}
    </Grid>
  );
}

export default HeaderPhase;
