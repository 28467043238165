import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { HighlightRounded } from '@mui/icons-material';
import ReportBlockRating from '../fields/ReportBlockRating';
import { ReportBlockFieldDesc } from '../../../types/reporttype';
import ReportBlockTextField from '../fields/ReportBlockTextField';
import ReportBlockStringField from '../fields/ReportBlockStringField';
import { mainRadius } from '../../../themes/theme';
import ReportBlockGraphField, {
  GraphFieldData,
  MultiGraphFieldData
} from '../fields/reportblockgraphfields/ReportBlockGraphField';
import { PopulateFieldMethod } from './reportsblock';
import { DdGraphUploadService } from '../../../services/dds';
import ReportBlockMultiGraphField from '../fields/reportblockgraphfields/ReportBlockMultiGraphField';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: '16px',
    marginTop: '16px',
    // padding:' 12px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-in-out',
    opacity: '0.9',
    '&:hover': {
      opacity: '1',
      zIndex: '9',
      boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.2s ease-in-out'
    }
  },
  title: {
    borderRadius: '16px 0 0 16px',
    color: 'white'
  },
  label: {
    fontSize: '16px'
  },
  rating: {
    borderRadius: '0 16px 16px 0',
    paddingLeft: '16px'
  },
  blockEnder: {
    borderRadius: '0 16px 16px 0',
    paddingLeft: '16px'
  }
}));

interface BlockReportFieldProps {
    desc: ReportBlockFieldDesc,
    previousValue: unknown,
    currentValue: unknown,
    displayAsLetter?: boolean
}

interface EditableReportFieldProps extends BlockReportFieldProps {
  blockId: string,
  hash?: string,
  hasEditRights?: boolean,
  saveAction: (valuePath: string, value: unknown | undefined) => void,
  update: () => void,
  populateField?: PopulateFieldMethod,
  uploadServiceFactory: (fieldId: string) => DdGraphUploadService | undefined,
  isEachFieldsSplitted?: boolean,
  isDiffView?: boolean
}

function StringItem(props: EditableReportFieldProps): ReactElement {
  const { desc, previousValue, currentValue, hasEditRights, saveAction, isEachFieldsSplitted, isDiffView } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid id={`${props.blockId}.${props.desc.id}`} item xs={10} className={classes.blockEnder}>
      <Grid container>
        <ReportBlockStringField
          desc={desc}
          previousValue={previousValue as string}
          currentValue={currentValue as string}
          isEditable={hasEditRights && desc.editable}
          onSave={ (value: string | undefined) => saveAction(desc.id, value) }
          isEachFieldsSplitted={isEachFieldsSplitted}
          isDiffView={isDiffView}
        />
      </Grid>
    </Grid>
  );
}

function TextItem(props: EditableReportFieldProps): ReactElement {
  const { desc, previousValue, currentValue, hasEditRights, saveAction, populateField, isEachFieldsSplitted, isDiffView } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid id={`${props.blockId}.${props.desc.id}`} item xs={10}>
      <Grid container className={classes.blockEnder}>
        <ReportBlockTextField
          onSave={ (value: string | undefined) => saveAction(desc.id, value) }
          desc={desc}
          previousValue={previousValue as string}
          currentValue={currentValue as string}
          isEditable={hasEditRights && desc.editable}
          populateField={populateField}
          isEachFieldsSplitted={isEachFieldsSplitted}
          isDiffView={isDiffView}
        />
      </Grid>
    </Grid>
  );
}

function RatingItem(props: EditableReportFieldProps): ReactElement {
  const { desc, previousValue, currentValue, hasEditRights, saveAction, isEachFieldsSplitted, isDiffView } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const ratingPreviousValue = previousValue as {[key: string]: unknown};
  const ratingCurrentValue  = currentValue as {[key: string]: unknown};
  const opinionPreviousValue = ratingPreviousValue?.opinion as string;// Faire test plutôt que 'as string' ici (cf. ligne 63)
  const opinionCurrentValue = ratingCurrentValue?.opinion as string;
  const scorePreviousValue = ratingPreviousValue?.score as (number | string | undefined);
  const scoreCurrentValue = ratingCurrentValue?.score as (number | string | undefined);
  const scoreComputedValue = ratingCurrentValue?.computedScore as (number | string | undefined);
  const scoreDisplayAsLetter = props.desc.displayAsLetter ? props.desc.displayAsLetter : false ;
  let backgroundColor: string | undefined;

  switch (desc.validationStatus) {
  case 'validated' :
    backgroundColor = 'rgba(124, 179, 66, 0.175)';
    break;
  case 'rejected' :
    backgroundColor = 'rgba(229, 57, 53, 0.175)';
    break;
  default:
    backgroundColor = undefined;
  }

  return (
    <>
      <Grid id={`${props.blockId}.${props.desc.id}`} item sm={7} lg={8}>
        <Grid container style={{ padding: '16px' }}>
          <ReportBlockTextField
            onSave={ (value: string | undefined) => saveAction(`${desc.id  }.opinion`, value) }
            desc={desc}
            previousValue={opinionPreviousValue}
            currentValue={opinionCurrentValue}
            isEditable={hasEditRights && desc.editable}
            isEachFieldsSplitted={isEachFieldsSplitted}
            isDiffView={isDiffView}
          />
        </Grid>
      </Grid>
      <Grid item sm={3} lg={2} className={classes.rating} style={backgroundColor ?  { backgroundColor } : undefined}>
        <Grid container style={{ justifyContent: 'center' }}>
          <ReportBlockRating
            previousValue={scorePreviousValue}
            currentValue={scoreCurrentValue}
            computedValue={scoreComputedValue}
            type='final'
            isEditable={hasEditRights && desc.editable}
            displayAsLetter={scoreDisplayAsLetter}
            onSave={ (value: number | undefined) => saveAction(`${desc.id  }.score`, value) }
          />
        </Grid>
      </Grid>
    </>
  );
}

function GraphItem(props: EditableReportFieldProps): ReactElement {
  const { desc, previousValue, currentValue, hasEditRights, saveAction, isEachFieldsSplitted, isDiffView } = props;

  const graphPreviousValue = previousValue as GraphFieldData|undefined;
  const graphCurrentValue  = currentValue as GraphFieldData|undefined;

  const onSave = (value: GraphFieldData | undefined) => {
    if (value) {
      const partialData = {
        tags: value.tags
        // No text, will be provided separately
      };
      saveAction(desc.id, partialData);
    } else {
      throw new Error('See what to do !');
    }
  };

  return (
    <Grid id={`${props.blockId}.${props.desc.id}`} item sm={10}>
      <ReportBlockGraphField
        onSave={onSave}
        desc={desc}
        currentValue={currentValue as GraphFieldData || previousValue as GraphFieldData}
        isEditable={hasEditRights && desc.editable}
        update={props.update}
        uploadServiceFactory={props.uploadServiceFactory}
      />
      <Grid container style={{ borderTop: '1px solid rgba(0, 0, 0, .2)', paddingTop: '8px' }}>
        <ReportBlockTextField
          onSave={ (value: string | undefined) => saveAction(`${desc.id  }.text`, value) }
          desc={desc}
          previousValue={graphPreviousValue?.text}
          currentValue={graphCurrentValue?.text}
          isEditable={hasEditRights && desc.editable}
          isEachFieldsSplitted={isEachFieldsSplitted}
          isDiffView={isDiffView}
        />
      </Grid>
    </Grid>
  );
}

function MultiGraphItem(props: EditableReportFieldProps): ReactElement {
  const { desc, previousValue, currentValue, hasEditRights, saveAction, isEachFieldsSplitted, isDiffView } = props;

  const onSave = (value: GraphFieldData | undefined) => {
    if (value) {
      const partialData = {
        tags: value.tags
        // No text, will be provided separately
      };
      saveAction(desc.id, partialData);
    } else {
      throw new Error('See what to do !');
    }
  };

  const values = currentValue as MultiGraphFieldData || previousValue as MultiGraphFieldData;

  return (
    <Grid id={`${props.blockId}.${props.desc.id}`} item sm={10}>
      <Grid container spacing={2}>
        <ReportBlockMultiGraphField
          onSave={onSave}
          desc={desc}
          currentValue={values}
          isEditable={hasEditRights && desc.editable}
          update={props.update}
          uploadServiceFactory={props.uploadServiceFactory}
        />
        <Grid item xs={12}>
          <Grid container style={{ borderTop: '1px solid rgba(0, 0, 0, .2)', paddingTop: '8px' }}>
            <ReportBlockTextField
              onSave={(value: string | undefined) => saveAction(`${desc.id  }.text`, value)}
              desc={desc}
              currentValue={values?.text || undefined}
              isEditable={hasEditRights && desc.editable}
              isEachFieldsSplitted={isEachFieldsSplitted}
              isDiffView={isDiffView}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function UndefinedItem(props: EditableReportFieldProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <Grid id={`${props.blockId}.${props.desc.id}`} item xs={10} className={classes.blockEnder}>
      <Grid container>
        <Typography>{t('dds.tabs.reports.blocks.noSuchTypeForField')}</Typography>
      </Grid>
    </Grid>
  );
}

function ReportBlockDispatch(props: EditableReportFieldProps): ReactElement {
  const { desc } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const selected = `#${props.blockId}.${props.desc.id}` === props.hash;

  const handleBlockDispatch = (): ReactElement => {
    const type = props.desc.type;

    switch (type) {
    case 'string' :
      return (
        <StringItem {...props} />
      );
    case 'graph' :
      return (
        <GraphItem {...props} />
      );
    case 'multigraph' :
      return (
        <MultiGraphItem {...props} />
      );
    case 'text' :
      return (
        <TextItem {...props} />
      );
    case 'rating' :
      return (
        <RatingItem {...props} />
      );
    default :
      return (
        <UndefinedItem {...props} />
      );
    }
  };

  return (
    <Paper className={classes.paper} elevation={0} style={{ border: desc.highlight || selected ? '4px solid yellow' : 'none' }}>
      <Grid container spacing={4}>
        <Grid item xs={2} className={classes.title}>
          <Typography component='h2' variant='h3' color='primary' style={{ display: 'flex', alignItems: 'center' }} gutterBottom className={classes.label}>
            {desc.label}
            {desc.highlight && <Tooltip title='highlight'><HighlightRounded style={{ marginLeft: '8px' }}/></Tooltip>}
          </Typography>
        </Grid>
        {handleBlockDispatch()}
      </Grid>
    </Paper>
  );
}

export default ReportBlockDispatch;
