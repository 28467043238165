import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import Contacts from '../../../../components/contacts/contacts';
import { InvestMgrsContactsService } from '../../../../services/contacts';

function InvestMgrsContacts() : ReactElement {
  const { id } = useParams<{ id: string }>();
  const service = new InvestMgrsContactsService(id || '');

  return (
    <Contacts service={service} />
  );
}

export default InvestMgrsContacts;
