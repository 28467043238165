import React, { ReactElement } from 'react';
import {
  dataContext,
  fundContext,
  investmgrContext,
  mandateContext,
  strategyContext
} from '../../hooks/datatabs';
import { DdService } from '../../services/dds';
import EntityReportList from './entityreportlist';
import { DataDueDiligence, Fund, InvestMgr, Mandate, Strategy } from '../../types/types';
import DdSummaryReport from './reportssummary';
import IdRouter from '../../router/idrouter';
import { ReportsGetter } from '../../services/ddreports';
import { FundService } from '../../services/fund';
import { InvestMgrService } from '../../services/investmgr';
import { MandateService } from '../../services/mandate';
import { StrategyService } from '../../services/strategy';

export function DdReportsList(): ReactElement {
  const ddMenu = dataContext();
  const ddId = ddMenu?.dueDiligence?._id || null;
  const ddService = new DdService();
  const reportsService = ddId ? new ReportsGetter<DataDueDiligence>(ddId, ddService) : null;

  return (
    <>
      {reportsService && ddId &&
      <IdRouter index={<EntityReportList service={reportsService} id={ddId} />}>
        <DdSummaryReport />
      </IdRouter>
      }
    </>
  );
}

export function FundReportsList(): ReactElement {
  const fund = fundContext();
  const fundId = fund?._id;
  const fundService = new FundService();
  const reportsService = fundId ? new ReportsGetter<Fund>(fundId, fundService) : null;

  return (
    <>
      {reportsService && fundId &&
      <IdRouter index={<EntityReportList service={reportsService} id={fundId} />}>
        <DdSummaryReport />
      </IdRouter>
      }
    </>
  );
}

export function InvestMgrReportsList(): ReactElement {
  const investMgr = investmgrContext();
  const investMgrId = investMgr?._id;
  const investMgrService = new InvestMgrService();
  const reportsService = investMgrId ? new ReportsGetter<InvestMgr>(investMgrId, investMgrService) : null;

  return (
    <>
      {reportsService && investMgrId &&
      <IdRouter index={<EntityReportList service={reportsService} id={investMgrId} />}>
        <DdSummaryReport />
      </IdRouter>
      }
    </>
  );
}

export function MandatesReportsList(): ReactElement {
  const mandate = mandateContext();
  const mandateId = mandate?._id;
  const mandateService = new MandateService();
  const reportsService = mandateId ? new ReportsGetter<Mandate>(mandateId, mandateService) : null;

  return (
    <>
      {reportsService && mandateId &&
      <IdRouter index={<EntityReportList service={reportsService} id={mandateId} />}>
        <DdSummaryReport />
      </IdRouter>
      }
    </>
  );
}

export function StrategiesReportsList(): ReactElement {
  const strategy = strategyContext();
  const strategyId = strategy?._id;
  const strategyService = new StrategyService();
  const reportsService = strategyId ? new ReportsGetter<Strategy>(strategyId, strategyService) : null;

  return (
    <>
      {reportsService && strategyId &&
      <IdRouter index={<EntityReportList service={reportsService} id={strategyId} />}>
        <DdSummaryReport />
      </IdRouter>
      }
    </>
  );
}
