import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './i18n/config';
import { DndProvider } from 'react-dnd';
import  { HTML5Backend } from 'react-dnd-html5-backend';
import Authenticate from './main/components/authentification/authentification';
import App from './main/index';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <Authenticate>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </Authenticate>
  );
}
