import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, ListItemText } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { green, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { parseISOString } from '../../utils/parse';
import { DdElement } from '../../types/types';

const useStyles = makeStyles(() =>
  createStyles({
    event: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start'
    },
    text: {
      margin: '0px 0px 0px 12px'
    },
    title: {
      width: 'min-content',
      marginLeft: '12px',
      marginRight: 'auto',
      marginTop: '12px',
      marginBottom: '12px'
    }
  })
);

function QuestionHistory(props: {ddElement: DdElement, onlyLast?: boolean}): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const events = !props.onlyLast ? props.ddElement.events : [props.ddElement.events[props.ddElement.events.length - 1]];

  return (
    <>
      <Grid container spacing={1} style={{ padding: '12px' }}>
        {events.map((event) => {
          const elementInfo = `${t('utils.on')} ${parseISOString(event.timestamp)} ${t('utils.by')} ${event.by.userId} (${event.by.type})`;

          return (
            <Grid item xs={12} key={event.timestamp}>
              {event.kind === 'answer.entered' &&
              <div className={classes.event}>
                <AccessTimeRoundedIcon color='primary' />
                <ListItemText
                  className={classes.text}
                  primaryTypographyProps={{ color: 'primary' }}
                  secondaryTypographyProps={{ color: 'primary' }}
                  primary={t('ddElements.status.answered')}
                  secondary={elementInfo} />
              </div>
              }
              {event.kind === 'answer.validated' &&
              <div className={classes.event} style={{ color: green[500] }}>
                <CheckRoundedIcon style={{ color: green[500] }} />
                <ListItemText
                  className={classes.text}
                  primaryTypographyProps={{ style: { color: green[500] } }}
                  secondaryTypographyProps={{ style: { color: green[500] } }}
                  primary={t('ddElements.status.validated')}
                  secondary={elementInfo} />
              </div>
              }
              {event.kind === 'answer.rejected' &&
              <div className={classes.event}>
                <ClearRoundedIcon style={{ color: red[500] }} />
                <ListItemText
                  className={classes.text}
                  primaryTypographyProps={{ style: { color: red[500] } }}
                  secondaryTypographyProps={{ style: { color: red[500] } }}
                  primary={t('ddElements.status.rejected')}
                  secondary={elementInfo} />
              </div>
              }
            </Grid>
          );
        })
        }
      </Grid>
    </>
  );
}

export default QuestionHistory;
