import React, { ReactElement, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { green, grey, orange, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import { Grid, Stepper, Step, StepLabel, StepConnector, StepIconProps, stepConnectorClasses } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { LifeCycle } from '../../../types/types';
import { mainColor } from '../../../themes/theme';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';

const useStyles = makeStyles({
  label: {
    fontSize: '10px'
  }
});

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 44,
    height: 44,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '-12px'
  },
  rootSmall: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '-12px'
  },
  active: {
    backgroundColor: mainColor,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.15)'
  },
  completed: {
    backgroundColor: green[500]
  }
});

interface ColorlibStepIconProps extends StepIconProps {
  currentColor: string,
  small?: boolean
}

function ColorlibStepIcon(myProps: ColorlibStepIconProps): ReactElement {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = myProps;

  const icons: { [index: string]: React.ReactElement } = {
    1: <EditRoundedIcon fontSize='small' style={myProps.small ? { width: '18px' } : undefined} />,
    2: <EqualizerRoundedIcon fontSize='small' style={myProps.small ? { width: '18px' }  : undefined} />,
    3: <AssignmentRoundedIcon fontSize='small' style={myProps.small ? { width: '18px' }  : undefined} />,
    4: <DoneAllRoundedIcon fontSize='small' style={myProps.small ? { width: '18px' }  : undefined} />
  };

  return (
    <div
      className={clsx(myProps.small ? classes.rootSmall : classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
      style={active ? { backgroundColor: myProps.currentColor } : undefined}
    >
      {icons[String(myProps.icon)]}
    </div>
  );
}

function HeaderGlobalProgress(props: {lifecycle: LifeCycle, small?: boolean}): ReactElement {
  const componentClasses = useStyles();
  const { t } = useTranslation();
  const myRef = useRef<HTMLDivElement>(null);
  const allPhases = [t('dds.phases.input'), t('dds.phases.analysis'), t('dds.phases.report'), t('dds.phases.validation')];
  const [reversedPhases, setReversedPhases] = useState([...props.lifecycle.phases].reverse());
  const [currentColor, setCurrentColor] = useState<string>(mainColor);

  const scrollToRight = () => {
    myRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'end', block: 'end' });
  };

  useEffect(() => {
    scrollToRight();
  }, [myRef]);

  const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: props.small ? 14 : 20,
      left: props.small ? 'calc(-50% + 12px)' : undefined,
      right: props.small ? 'calc(50% + 12px)' : undefined
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: `linear-gradient(45deg, ${green[500]}, ${currentColor})`
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: green[500]
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1
    }
  }));

  useEffect(() => {
    setReversedPhases([...props.lifecycle.phases].reverse());
  }, [props.lifecycle.phases]);

  useEffect(() => {
    const daysLeft = Math.ceil((+ new Date(reversedPhases[0]?.expectedEndTime || '') - + new Date()) / (1000 * 60 * 60 * 24));

    if (reversedPhases[0]?.status === 'completed') {
      setCurrentColor(green[500]);
    } else if (reversedPhases[0]?.status === 'notStarted') {
      setCurrentColor(grey[300]);
    } else if (reversedPhases[0]?.status === 'aborted') {
      setCurrentColor(grey[400]);
    } else if (reversedPhases[0]?.status === 'started') {
      if (daysLeft < 2) {
        setCurrentColor(red[500]);
      } else if (daysLeft < 6) {
        setCurrentColor(orange[500]);
      } else {
        setCurrentColor(mainColor);
      }
    } else {
      setCurrentColor(mainColor);
    }
  }, [reversedPhases]);

  const MyColorLibStepIcon = (myProps: StepIconProps) => ColorlibStepIcon( { currentColor, small: props.small, ...myProps });

  return (
    <Grid item xs={12}>
      <Stepper
        style={{ backgroundColor: 'transparent', padding: '0px' }}
        alternativeLabel
        activeStep={allPhases.indexOf(reversedPhases[0]?.type.toLowerCase().includes('validation') ? 'validation' : reversedPhases[0]?.type)}
        connector={<ColorlibConnector />}
      >
        {allPhases.map(phase => (
          <Step key={phase}>
            <StepLabel
              StepIconComponent={myProps => MyColorLibStepIcon(myProps)}
              classes={props.small ? {
                label: componentClasses.label
              } : undefined}
            >
              {capitalizeFirstLetter(phase)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}

export default HeaderGlobalProgress;
