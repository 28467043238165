import React, { Dispatch, ReactElement } from 'react';
import KeyboardRoundedIcon from '@mui/icons-material/KeyboardRounded';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import { useTranslation } from 'react-i18next';
import { Answer, DdElement, Nullable } from '../../types/types';
import { CustomTooltipToggleButton, StyledToggleButtonGroup } from '../button/button';

interface QuestionDisplayButtonsProps {
  ddElement: DdElement,
  view: string,
  setView: Dispatch<React.SetStateAction<string>>,
  setAnswer: Dispatch<React.SetStateAction<Nullable<Answer>>>,
  readonly?: boolean,
  auditor?: boolean
}

function QuestionDisplayButtons(props: QuestionDisplayButtonsProps): ReactElement {
  const { t } = useTranslation();

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== null) {
      props.setView(nextView);
    }
    if (nextView === 'auditeeAnswer') {
      props.setAnswer(props.ddElement.answers.auditee && !props.ddElement.answers.auditee.draft && props.ddElement.answers.auditee || null);
    } else if (nextView === 'auditorAnswer' && props.auditor) {
      props.setAnswer(props.ddElement.answers.auditor);
    } else if (nextView === 'previousAnswer' && props.ddElement.answers.previous) {
      props.setAnswer(props.ddElement.answers.previous);
    } else if (nextView === 'auditorEditor' && props.auditor) {
      props.setAnswer(props.ddElement.answers.auditor);
    }
  };

  return (
    <div
      key={`${props.ddElement._id  } btns`}
      style={{
        width: 'calc(100% - 24px)',
        textAlign: 'right',
        position: 'absolute'
      }}
    >
      <StyledToggleButtonGroup
        orientation='horizontal'
        value={props.view}
        exclusive
        onChange={handleChange}
        size='small'
      >
        <CustomTooltipToggleButton
          label={t('ddElements.tabs.question.modes.auditeeAnswer')}
          value='auditeeAnswer'
          icon={<KeyboardRoundedIcon />}
        />
        {props.auditor &&
          <CustomTooltipToggleButton
            label={t('ddElements.tabs.question.modes.auditorAnswer')}
            value='auditorAnswer'
            disabled={props.ddElement.answers.auditor === null}
            icon={<EqualizerRoundedIcon />}
          />
        }
        <CustomTooltipToggleButton
          label={t('ddElements.tabs.question.modes.previousAnswer')}
          value='previousAnswer'
          disabled={props.ddElement.answers.previous === null || props.ddElement.answers.previous === undefined}
          icon={<HistoryRoundedIcon />}
        />
        {props.auditor &&
          <CustomTooltipToggleButton
            label={t('ddElements.tabs.question.modes.auditorEditor')}
            value='auditorEditor'
            icon={<CreateRoundedIcon />}
            disabled={props.readonly}
          />
        }
      </StyledToggleButtonGroup>
    </div>
  );
}

export default QuestionDisplayButtons;
