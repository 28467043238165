import React, { ReactElement } from 'react';
import { ddTemplateContext } from '../../../hooks/datatabs';
import JsonWrapper from '../../dev/jsonwrapper';

function DdTemplateJson(): ReactElement {
  const ddTemplate = ddTemplateContext();

  return (
    <>
      { ddTemplate &&
        <JsonWrapper>
          {JSON.stringify(ddTemplate, null, ' ')}
        </JsonWrapper>
      }
    </>
  );
}

export default DdTemplateJson;