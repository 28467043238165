import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { Snackbar, TextField, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GlobalContactsService } from '../../services/contacts';
import { Contact } from '../../types/types';
import { useContactSidePanelLayoutStyle } from './contactsTable';
import CustomButton from '../button/button';

interface CreateContactProps {
  handleAdd: () => void,
  contactList: Array<Contact>,
  setContactList: Dispatch<React.SetStateAction<Array<Contact>>>,
  contactsService: GlobalContactsService
}

const useStyle = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    marginTop: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));

function CreateContact(props: CreateContactProps): ReactElement {
  const contactClasses = useContactSidePanelLayoutStyle();
  const classes = useStyle();
  const [step, setStep] = useState('email');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [contact, setContact] = useState<Contact>({
    firstName: '',
    lastName: '',
    email: '',
    id: '',
    phoneNumber: '',
    username: '',
    title: ''
  });

  useEffect(() => {
    setEmailError(false);
  }, [contact.email]);

  useEffect(() => {
    if (props.contactList.length > 0 && props.contactList[0].username === contact.username) {
      props.handleAdd();
    }
  }, [props.contactList]);

  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  }

  const checkEmail = () => {
    if (validateEmail(contact.email)) {
      props.contactsService.findByEmail(contact.email)
        .then(() => {
          setEmailError(true);
          setEmailErrorMsg('Email already used');
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            setStep('names');
          } else {
            setErrorMsg(`${err.response?.status}: ${err.message}`);
            setSnackbarOpen(true);
          }
        });
    } else {
      setEmailError(true);
      setEmailErrorMsg('Email invalid');
    }
  };

  const validatePhoneNumber = (phoneNumber?: string) => {
    if (phoneNumber) {
      return true;
    }

    return true;
  };

  const makeUsername = () => {
    if (contact.firstName.length > 1 && contact.lastName.length > 1) {
      props.contactsService.computeUsername(contact.firstName, contact.lastName)
        .then((apiResp) => {
          if (apiResp.data) {
            setContact({ ...contact, username: apiResp.data.possibleUsername });
            setStep('others');
          }
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${err.message}`);
          setSnackbarOpen(true);
        });
    }
  };

  const createContact = () => {
    if (validatePhoneNumber(contact.phoneNumber)) {
      props.contactsService.add(contact)
        .then((apiResp) => {
          apiResp.data && props.setContactList([{ ...contact, id: apiResp.data.id }]);
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${err.message}`);
          setSnackbarOpen(true);
        });
    }
  };

  const handleSubmit = () => {
    switch (step) {
    case 'email' :
      checkEmail();
      break;
    case 'names' :
      makeUsername();
      break;
    case 'others' :
      createContact();
      break;
    default:
      break;
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (key: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContact({ ...contact, [key as keyof typeof contact]: event.target.value });
  };

  return (
    <>
      { step === 'email' &&
          <>
            <TextField
              key={step}
              variant='standard'
              error={emailError}
              label='Email'
              className={classes.input}
              maxRows={1}
              value={contact?.email}
              helperText={emailError && emailErrorMsg}
              onChange={event => handleChange(step, event)}
              onKeyPress={(event) => {
                !emailError && contact.email.length > 1 && handleKeyPress(event);
              }}
            />
            <div className={contactClasses.buttons} style={{ marginTop: '32px' }}>
              <div className={contactClasses.btn}>
                <CustomButton
                  variant='contained'
                  style={{ width: '100%' }}
                  onClick={handleSubmit}
                  disabled={emailError || contact.email.length < 2}
                >
                  Next
                </CustomButton>
              </div>
            </div>
          </>
      }
      { step === 'names' &&
        <>
          <TextField
            key={`${step  }first`}
            variant='standard'
            label='First Name'
            className={classes.input}
            maxRows={1}
            value={contact?.firstName}
            onChange={event => handleChange('firstName', event)}
          />
          <TextField
            key={`${step  }last`}
            variant='standard'
            label='Last Name'
            className={classes.input}
            maxRows={1}
            value={contact?.lastName}
            onChange={event => handleChange('lastName', event)}
            onKeyPress={(event) => {
              contact.firstName.length > 1 && contact.lastName.length > 1 && handleKeyPress(event);
            }}
          />
          <div className={contactClasses.buttons} style={{ marginTop: '32px' }}>
            <div className={contactClasses.btn}>
              <CustomButton
                variant='contained'
                style={{ width: '100%' }}
                onClick={handleSubmit}
                disabled={contact.firstName.length < 2 || contact.lastName.length < 2}
              >
                Next
              </CustomButton>
            </div>
          </div>
        </>
      }
      { step === 'others' &&
          <>
            <TextField
              key={`${step  }phone`}
              variant='standard'
              label='Phone Number (optional)'
              className={classes.input}
              maxRows={1}
              value={contact?.phoneNumber}
              onChange={event => handleChange('phoneNumber', event)}
            />
            <TextField
              key={`${step  }title`}
              variant='standard'
              label='Title (optional)'
              className={classes.input}
              maxRows={1}
              value={contact?.title}
              onChange={event => handleChange('title', event)}
              onKeyPress={handleKeyPress}
            />
            <div className={contactClasses.buttons} style={{ marginTop: '32px' }}>
              <div className={contactClasses.btn}>
                <CustomButton
                  variant='contained'
                  style={{ width: '100%' }}
                  onClick={handleSubmit}
                >
                  Create and Add
                </CustomButton>
              </div>
            </div>
          </>
      }
      <Snackbar onClose={handleCloseSnackbar} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateContact;
