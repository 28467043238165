import React, { Dispatch, ReactElement, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import { parseInt } from 'lodash';

const units = [
  {
    value: 1,
    label: 'ㅤ' // Invisible character copy paste 'ㅤ'
  },
  {
    value: 1000,
    label: 'K'
  },
  {
    value: 1000000,
    label: 'M'
  },
  {
    value: 1000000000,
    label: 'B'
  },
  {
    value: 1000000000000,
    label: 'T'
  }
];

export default function UnitFieldEditor(props: {defaultUnit?: number, setUnit: Dispatch<React.SetStateAction<number>>}) : ReactElement {
  const [unit, setUnit] = React.useState(props.defaultUnit ? props.defaultUnit : 1);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUnit(parseInt(event.target.value));
    props.setUnit(parseInt(event.target.value));
  };

  useEffect(() => {
    props.defaultUnit ? setUnit(props.defaultUnit) : setUnit(1);
  }, [props.defaultUnit]);

  return (
    <>
      <TextField
        key={unit}
        variant='standard'
        id='standard-select-unit'
        select
        label='Unit'
        value={unit}
        onChange={handleChange}
        style={{ minWidth: '54px' }}
      >
        {units.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
