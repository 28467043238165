import React, { ReactElement } from "react";
import Typography from '@mui/material/Typography';
import { Table, TableBody, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Answer,
  BaseValueTypes,
  ObjectQuestionSpec
  , Nullable } from '../../../types/types';
import MultiTypeFieldViewer from './multitypefieldviewer';

const useStyles = makeStyles({
  table: {
    marginTop: '-24px'
  },
  title: {
    marginTop: '24px'
  }
});

function ObjectFieldViewer(props: { spec: ObjectQuestionSpec, answer: Nullable<Answer> }) : ReactElement {
  const objectQuestionSpecFields = props.spec.fields;
  const value = props.answer?.value as Record<string, unknown>;
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableBody>
        { objectQuestionSpecFields && (objectQuestionSpecFields).map(field => (
          <TableRow key={field.id}>
            { field &&
            <td>
              <Typography className={classes.title} variant='h3'>{field.label}</Typography>
              {value && <MultiTypeFieldViewer type={field.type} value={value[field.id] as BaseValueTypes} specs={field} />}
            </td>
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
export default ObjectFieldViewer;
