import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../components/params';
import { Mandate } from '../../../types/types';
import { MandateTable } from './table';
import { EntityListDataProvider, ListRendererMap, MultiModeList } from '../../../components/entity/list';
import { MandateService } from '../../../services/mandate';
import MandateFilters from './filters';
import CustomPagination from '../../../components/layout/pagination/pagination';
import TableSkeleton from '../../../components/layout/loading/tableskeleton';

class MandateListDataProvider extends EntityListDataProvider<Mandate> {
  constructor(searchParamsAccess: SearchParamsAccess) {
    super(searchParamsAccess, new MandateService());
  }
}

export function MandateMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new MandateListDataProvider(searchParamsAccess);

  function renderEmpty() {
    return (
      <div style={{ marginTop: '40px' }}>No mandate...</div>
    );
  }

  function renderLoading() {
    return (
      <TableSkeleton nbColumns={7} />
    );
  }

  function renderAsTable(eltList: Array<Mandate>, totalCount: number) {
    return (
      <>
        <MandateTable list={eltList} paramsSort={listDataProvider.sortParamsManager} />
        <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
      </>
    );
  }

  function renderAsCards(eltList: Array<Mandate>, totalCount: number) {  // eslint-disable-line @typescript-eslint/no-unused-vars
    return (
      <>
        <div>Cards List here</div>
        <div>Pagination goes here...</div>
      </>
    );
  }

  const renderers: ListRendererMap<Mandate> = {
    'table': renderAsTable,
    'cards': renderAsCards
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table' /* 'cards' */]}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={() => renderEmpty()}
      renderLoading={() => renderLoading()}
      filtersComponent={MandateFilters}
    />
  );
}

function MandateListingPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <MandateMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default MandateListingPage;

// La partie pagination prend un PaginationListener en props pour indiquer quand il faut changer de page
// Le composant qui gère la liste paginée passe un PaginationListener qui sait modifier l'état lorsqu'il y a des changements

// Avoir un composant qui sait modifier les URLSearchParams pour cette liste (sait quel 'prefix' mettre devant ces params)
// => ça implique une MAJ des params

/*
organisation générale

- pour chaque Entity:
  - une page de liste (ex: /dds)
  - une page de détails (ex: /dds/<id>)
    - avoir un header (potentiellement un SelectionHeader qui permet de choisir le bon header)
    - des tabs
      - avec une condition sur l'affichage
      - avec des droits d'accès
      - chaque onglet est un composant qui prend l'entity en props
        - passé directement lorsque c'est chargé
*/
