import React, { ReactElement } from 'react';
import { mandateContext } from '../../../hooks/datatabs';
import { EntityDdList } from '../../duediligences/listing/entityddlist';

function MandateDdList(): ReactElement {
  const mandateData = mandateContext();

  return (
    <>
      {mandateData && mandateData._id &&
        <EntityDdList entityType='mandate' entityId={mandateData._id} entityTypeText='mandate' />
      }
    </>
  );
}

export default MandateDdList;
