import React, { ReactElement } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import makeStyles from '@mui/styles/makeStyles';
import { mainColor, mainRadius } from '../../themes/theme';
import { DropZoneProps } from './uploadfiles';

const useStyle = makeStyles({
  drag: {
    borderRadius: mainRadius,
    border: `solid 2px ${  mainColor}`,
    padding: '12px'
  }
});

interface DropZoneWrapperProps extends DropZoneProps {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void,
  children: ReactElement
}

function DropzoneWrapper(props: DropZoneWrapperProps): ReactElement {
  const classes = useStyle();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: props.onDrop, noClick: !props.clickable, multiple: props.multiple, accept: props.fileTypes, maxFiles: props.maxFiles, noDrag: props.noDrag, useFsAccessApi: false });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      { isDragActive ?
        <div className={classes.drag}>{props.children}</div> :
        props.children
      }
    </div>
  );
}

export default DropzoneWrapper;
