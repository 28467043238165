import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { orange, red } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { DataDueDiligence } from '../../../types/types';
import CustomTable, { Column } from '../../../components/table/table';
import EntityLink from '../../../components/modularcomponents/entitylink/entitylink';
import { Rating2, Rating1, Owners } from './datadisplay';
import { FilterParamsManager } from '../../../components/entity/list';

const useStyles = makeStyles({
  rating: {
    display: 'inline-block'
  }
});

export function DdTable(props: {list: Array<DataDueDiligence>, paramsSort?: FilterParamsManager}) : ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const getColor = (date: Date) => {
    const now = new Date();

    switch (true) {
    case date.getTime() < now.getTime():
      return red[500];
    case date.getTime() >= now.getTime() && date.getTime() < (new Date(now.setMonth(now.getMonth() + 3))).getTime():
      return orange[500];
    default:
      return 'inherit';
    }
  };

  const columns: Array<Column> = [
    { id: 'name', label: t('dds.cardsAndTable.columns.name'), minWidth: 'min-content' },
    { id: 'auditedEntity', label: t('dds.cardsAndTable.columns.auditedEntity'), minWidth: 'min-content', align: 'center' },
    { id: 'type', label: t('dds.cardsAndTable.columns.type'), minWidth: 'min-content', align: 'center' },
    { id: 'status', label: t('dds.cardsAndTable.columns.status'), minWidth: 'min-content', align: 'center' },
    { id: 'currentPhase', label: t('dds.cardsAndTable.columns.currentPhase'), minWidth: 'min-content', align: 'center' },
    // { label: 'Global Risk Score', minWidth: 'min-content', align: 'center' },
    { id: 'rating1', label: t('dds.ratings.rating1'), minWidth: 'min-content', align: 'center' },
    { id: 'rating2', label: t('dds.ratings.rating2'), minWidth: 'min-content', align: 'center' },
    { id: 'owners', label: t('dds.cardsAndTable.columns.owners'), minWidth: 'min-content', align: 'left' },
    { id: 'startDate', label: t('dds.dates.startDate'), minWidth: 'min-content', align: 'center' },
    { id: 'validationDate', label: t('dds.dates.validateDate'), minWidth: 'min-content', align: 'center' },
    { id: 'nextReviewDate', label: t('dds.dates.nextReviewDate'), minWidth: 'min-content', align: 'center' }
  ];

  const rows = props.list.map((dd: DataDueDiligence) => ({
    id: dd._id,
    value: columns.map(column => (
      <>
        {column.id === 'name' &&
              <Typography>
                <EntityLink type='dueDiligence' id={`${dd._id}`} name={dd.auditedEntity.name} center={column.align === 'center'} openInNewTabIcon />
              </Typography>
        }
        {column.id === 'auditedEntity' &&
              <Typography>
                <EntityLink type={dd.auditedEntity.type} id={dd.auditedEntity.id} name={dd.auditedEntity.type} center={column.align === 'center'} openInNewTabIcon />
              </Typography>
        }
        {column.id === 'type' &&
            <Typography>
              {dd.type}
            </Typography>
        }
        {column.id === 'status' &&
            <Typography>
              {dd.status}
            </Typography>
        }
        {column.id === 'currentPhase' &&
            <Typography>
              {dd.lifecycle && dd.lifecycle.currentPhaseId && dd.lifecycle.phases.find(p => p.id === dd.lifecycle?.currentPhaseId)?.label}
            </Typography>
        }
        {/* {column.label === columns[3].label && <Typography>Phase</Typography>} */}
        {/* {column.label === columns[4].label && <Rating type={'final'} rank={'5'} />} */}
        {column.id === 'rating1' &&
            <div className={classes.rating} >
              <Rating1 ratings={dd.ratings} />
            </div>
        }
        {column.id === 'rating2' &&
            <div className={classes.rating} >
              <Rating2 ratings={dd.ratings} />
            </div>
        }
        {column.id === 'owners' &&
              <div>
                <Owners participants={dd.participants} />
              </div>
        }
        {column.id === 'startDate' && <Typography>{dd.startDate}</Typography>}
        {column.id === 'validationDate' && <Typography>{dd.expectedValidationDate || dd.validationDate}</Typography>}
        {column.id === 'nextReviewDate' && <Typography style={{ color: getColor(new Date(dd.auditedEntity.nextReviewDate)) }}>{dd.auditedEntity.nextReviewDate}</Typography>}
      </>
    ))
  }));

  return (
    <CustomTable columns={columns} rows={rows} size='small' paramsSort={props.paramsSort} />
  );
}

export default DdTable;
