import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Snackbar, Typography, Alert } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CollapsableList from '../../../../components/meetings/allocation/collapsablelist';
import Meeting from '../../../../components/meetings/allocation/meeting';
import { DdElementsService } from '../../../../services/ddelements';
import { DdElement } from '../../../../types/types';
import { MeetingProps, UpdateMeetingProps, Topic } from '../../../../types/meetingstypes';
import MeetingDropWrapper from './meetingdropwrapper';
import { DdMeetingsService } from '../../../../services/ddmeetings';
import ServiceError from '../../../../services/errors';

function Allocation(props: {ddId: string, readonly?: boolean}): ReactElement {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [questionsList, setQuestionsList] = useState<DdElement[]>([]);
  const [meetingsList, setMeetingsList] = useState<MeetingProps[]>([]);
  const [update, setUpdate] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const ddElementsService = new DdElementsService<DdElement>();
  const ddMeetingsService = new DdMeetingsService(props.ddId);

  useEffect(() => {
    if (update) {
      setUpdate(false);
      ddElementsService.getElementList(props.ddId, [{ id: 'flags', value: 'meeting' }], 0, 500)
        .then((apiResp) => {
          apiResp.data && setQuestionsList(apiResp.data);
        })
        .catch((err) => {
          setSnackbarMessage(ServiceError.getErrorMsg(err));
          setSnackbarOpen(true);
        });
      ddMeetingsService.getAll()
        .then((apiResp) => {
          apiResp.data && setMeetingsList(apiResp.data);
        })
        .catch((err) => {
          setSnackbarMessage(ServiceError.getErrorMsg(err));
          setSnackbarOpen(true);
        });
    }

    return () => {
      setUpdate(false);
    };
  }, [update]);

  useEffect(() => {
    if (props.readonly) {
      navigate(pathname.replace('/allocation', ''));
    }
  }, [props.readonly]);

  const updateMeetings = ( updateItems: UpdateMeetingProps) => {
    switch (updateItems.action) {
    case 'MOVE QUESTION':
      if (updateItems.meetingId) {
        ddMeetingsService.allocate(updateItems.meetingId, updateItems.itemId)
          .then(() => {
            setUpdate(true);
          })
          .catch((err) => {
            setSnackbarMessage(ServiceError.getErrorMsg(err));
            setSnackbarOpen(true);
          });
      }
      break;
    case 'REMOVE QUESTION':
      if (updateItems.oldMeetingId) {
        ddMeetingsService.remove(updateItems.oldMeetingId, updateItems.itemId)
          .then(() => {
            setUpdate(true);
          })
          .catch((err) => {
            setSnackbarMessage(ServiceError.getErrorMsg(err));
            setSnackbarOpen(true);
          });
      }
      break;
    case 'MOVE CATEGORY':
      if (updateItems.meetingId) {
        ddMeetingsService.allocate(updateItems.meetingId, undefined, updateItems.itemId)
          .then(() => {
            setUpdate(true);
          })
          .catch((err) => {
            setSnackbarMessage(ServiceError.getErrorMsg(err));
            setSnackbarOpen(true);
          });
      }
      break;
    case 'REMOVE CATEGORY':
      if (updateItems.oldMeetingId) {
        ddMeetingsService.remove(updateItems.oldMeetingId, undefined, updateItems.itemId)
          .then(() => {
            setUpdate(true);
          })
          .catch((err) => {
            setSnackbarMessage(ServiceError.getErrorMsg(err));
            setSnackbarOpen(true);
          });
      }
      break;
    default:
      // no default comportment
      break;
    }
  };

  const getQuestionsWithoutMeeting = () => questionsList.filter(question => (
    !meetingsList.some(meeting => (
      meeting.ddElementByTopic?.some(topic =>(
        topic.ddElements.some(ddElement => (
          ddElement._id === question._id
        ))
      ))
    ))
  ));

  const nbOfQuestions = (topics: Topic[]) => {
    let count = 0;

    topics.forEach(topic => topic.ddElements.forEach(() => {
      count += 1;
    }));

    return (count);
  };

  return (
    <>
      <Grid container style={{ padding: '8px' }}>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='h2'
                component='h2'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px'
                }}
              >
                <EventNoteIcon style={{ marginRight: '6px' }} />
                {t('dds.tabs.meetings.allocation.questionsToSchedule')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{
                  overflowX: 'visible',
                  overflowY: 'auto',
                  height: '700px',
                  padding: '24px',
                  margin: '-32px'
                }}
              >
                <Grid item xs={12}>
                  <MeetingDropWrapper meetingId='' updateMeetings={updateMeetings}>
                    <Grid item xs={12}>
                      {getQuestionsWithoutMeeting().length > 0 ?
                        <CollapsableList
                          list={getQuestionsWithoutMeeting()}
                          meetings={meetingsList}
                          meetingId=''
                          update={() => undefined}
                          updateMeetings={updateMeetings}
                          type='allocation'
                        />
                        :
                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: '24px auto' }}>
                          <PlaylistAddCheckIcon style={{ display: 'block', margin: '0 auto', height: '64px', transform: 'scale(3)', color: 'rgb(76, 175, 80)' }} />
                          <Typography style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            {t('dds.tabs.meetings.allocation.noQuestion')}
                          </Typography>
                        </Box>
                      }
                    </Grid>
                  </MeetingDropWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='h2'
                component='h2'
                style={{ display: 'flex', alignItems: 'center', marginBottom: '14px' }}>
                <ScheduleIcon style={{ marginRight: '6px' }} />
                {t('dds.tabs.meetings.yours')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={4}
                alignContent='flex-start'
                style={{
                  backgroundColor: 'transparent',
                  overflowX: 'visible',
                  overflowY: 'auto',
                  height: '700px',
                  padding: '12px',
                  margin: '-24px'
                }}
              >
                { meetingsList.map(meeting => (
                  <Grid item xs={12} key={meeting._id}>
                    <Meeting
                      id={meeting._id}
                      updateMeetings={updateMeetings}
                      actions={{ editTitle: () => undefined, deleteMeeting: () => undefined }}
                      title={meeting.title}
                      type={meeting.type}
                    >
                      { meeting.ddElementByTopic && nbOfQuestions(meeting.ddElementByTopic) > 0 ?
                        meeting.ddElementByTopic.map(topic => (
                          topic.ddElements.length > 0 &&
												<Grid container spacing={2} key={topic.topicId}>
												  {(meeting.ddElementByTopic && meeting.ddElementByTopic.length > 1 || topic.topicId !== 'other') &&
														<Grid item xs={12}>
														  <Typography variant='h3' color='primary'>
														    {topic.topicId}
														  </Typography>
														</Grid>
												  }
												  <Grid item xs={12}>
												    <CollapsableList
												      list={topic.ddElements}
												      meetings={meetingsList}
												      meetingId={meeting._id}
												      update={() => undefined}
												      updateMeetings={updateMeetings}
												      type='allocation'
												    />
												  </Grid>
												</Grid>
                        ))
                        :
                        <Typography style={{ margin: '8px 0 0 8px' }}>{t('dds.tabs.meetings.noQuestion')}</Typography>
                      }
                    </Meeting>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Allocation;
