import React, { ReactElement } from 'react';
import Flag from 'react-world-flags';
import { CountryManager } from '../../../utils/countries';

export default function Countryfieldviewer(props: {value?: string}) : ReactElement {
  const country = CountryManager.getInstance().getByCode3(props.value || "");

  return (
    <div style={{ zIndex: 999 }}>
      <Flag code={country?.code3} height='16' /> {country?.label}
    </div>
  );
}

