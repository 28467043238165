import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';

export default function QuestionValue(props: {children?: React.ReactNode}): ReactElement {
  const { children } = props;

  return (
    <Box style={{ overflow: 'auto' }}>
      {children}
    </Box>
  );
}
