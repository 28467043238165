import React, { ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import QuestionItem from './questionItem';
import CategoryItem from './categoryitem';
import ItemOptions from './itemoptions';
import { DdElement, SurveyElement } from '../../../types/types';
import { MeetingProps, UpdateMeetingProps } from '../../../types/meetingstypes';
import DisplayQuestion from '../meetingdetails/displayquestion';

interface MeetingDataProps {
	list: (DdElement | SurveyElement)[],
	meetings: MeetingProps[], // needed for menus
	meetingId: string, // point de départ
	updateMeetings?: ( updateItems: UpdateMeetingProps ) => void,
	type: string, // allocation | edition
	update: () => void,
	disableDrag?: boolean,
	isAuditor?: boolean
}

export interface CategoryItemProps {
	id: string,
	label: string,
	path: string,
	parentGroup: string,
	isCollapsed: boolean,
	questions: (DdElement | SurveyElement)[]
}

// Evaluate needed categories
function getPossiblePaths(path: string) {
  // give a path and calculate
  const splitPath = path.split('/');
  let pathBase = '';
  const possiblePaths: string[] = [];

  splitPath.forEach((value, i) => {
    if (i > 0 && i < splitPath.length) {
      pathBase += (`/${  value}`);
    } else {
      pathBase += value;
    }
    possiblePaths.push(pathBase);
  });

  return possiblePaths;
}

function CollapsableList( props: MeetingDataProps ): ReactElement {
  const compare = (a: DdElement | SurveyElement, b: DdElement | SurveyElement) => {
    const pathA = a.category;
    const pathB = b.category;
    let comparison = 0;

    if (pathA > pathB) {
      comparison = 1;
    } else if (pathA < pathB) {
      comparison = -1;
    }

    return comparison;
  };

  const isCategoryAdded = (list: CategoryItemProps[], id: string) => list.some(item => item.id === id);

  const newSortedListComputation = () => {
    const newSortedList: CategoryItemProps[] = [];
    const list = props.list ? props.list.sort(compare) : [];

    list.forEach((item) => {
      // check if each category is in the newSortedList
      const possiblePaths = getPossiblePaths(item.category);
      let currentParentGroup = '';

      possiblePaths.forEach((subPath) => {
        if (!isCategoryAdded(newSortedList, subPath)) {
          newSortedList.push(
            {
              id: subPath,
              label: subPath,
              path: subPath,
              parentGroup: currentParentGroup,
              isCollapsed: props.type !== 'details', // true for test
              questions: []
            }
          );
        }
        // add question into the questions category if her path is equal to current subpath
        if (item.category === subPath) {
          newSortedList.find( category =>
            category.id === item.category &&
						category.questions.push(item)
          );
        }
        currentParentGroup = subPath;
      });
    });

    return newSortedList;
  };

  const [sortedList, setSortedList] = useState<CategoryItemProps[]>(newSortedListComputation);

  const collapseAction = (id: string) => {
    const newSortedList: CategoryItemProps[] = [];
    const targetIndex = sortedList.findIndex( category => category.id === id);
    const { isCollapsed } = sortedList[targetIndex];
    const newValueForIsCollapsed = !isCollapsed;

    sortedList.forEach((category) => {
      if (category.id === id || category.id.includes(id) && category.questions.length < 1) {
        newSortedList.push({ ...category, isCollapsed: newValueForIsCollapsed });
      } else if (!newValueForIsCollapsed && category.id.indexOf(id) > -1) {
        newSortedList.push({ ...category, isCollapsed: false });
      } else {
        newSortedList.push(category);
      }
    });
    setSortedList(newSortedList);
  };

  const isParentGroupCollapsed = (parentGroup: string) => {
    if (parentGroup === '') {
      return true;
    }
    const founded = sortedList.find( category => category.id === parentGroup );

    return founded?.isCollapsed;
  };

  const indentation = (path: string) => {
    const level = path.split('/').length;

    return (8 * level) - 8;
  };

  useEffect(() => {
    if (props.type !== 'details') {
      setSortedList(newSortedListComputation);
    }
  }, [props.list]);

  return (
    <Box>
      { sortedList.map(category => (
        <div key={category.id}>
          {isParentGroupCollapsed(category.parentGroup) &&
						<Box key={`${category.id  }-group`}>
						  <CategoryItem
						    key={`${category.id  }category`}
						    category={category}
						    collapse={collapseAction}
						    categoryId={category.id}
						    meetingId={props.meetingId}
						    path={category.path}
						    disableDrag={props.disableDrag}
						  >
						    <>
						      {props.type !== 'details' && !props.disableDrag &&
										<ItemOptions
										  isCategory
										  path={category.path}
										  questionId={category.id}
										  meetingId={props.meetingId}
										  meetings={props.meetings}
										  updateMeetings={props.updateMeetings}
										/>
						      }
						    </>
						  </CategoryItem>
						  {category.questions.length > 0 &&
								category.questions.map(question => (
								  <div key={question._id}>
								    {isParentGroupCollapsed(question.category) &&
											<>
											  {props.type === 'allocation' &&
													<QuestionItem
													  key={`${question._id  }item`}
													  isCategory={false}
													  questionId={question._id}
													  meetingId={props.meetingId}
													  item={question} // contient id, title, path, meeting (à chnager pour meeting id);
													>
													  <>
													    {!props.disableDrag &&
																<ItemOptions
																  isCategory={false}
																  questionId={question._id}
																  meetingId={props.meetingId}
																  meetings={props.meetings}
																  updateMeetings={props.updateMeetings}
																/>
													    }
													  </>
													</QuestionItem>
											  }
											  {props.type === 'details' &&
													<Box
													  key={`${question._id  }item`}
													  style={{
													    marginLeft: `${indentation(question.category) + 8}px`
													  }}
													>
													  <DisplayQuestion ddElement={question} update={props.update} isAuditor={props.isAuditor} />
													</Box>
											  }
											</>
								    }
								  </div>
								))
						  }
						</Box>
          }
        </div>
      ))}
    </Box>
  );
}

export default CollapsableList;
