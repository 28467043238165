import React, { ReactElement } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Nullable } from '@deecision/deeligenz-interfaces';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { mainRadius } from '../../themes/theme';
import CustomButton from '../button/button';
import ThemeWrapper from '../../themes/themewrapper';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#F1F5FB'
  },
  cog: {
    display: 'block',
    width: '64px',
    height: '64px',
    margin: '0 auto 24px auto',
    opacity: '30%',
    animation: `$spin infinite 4s linear`
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(359deg)' }
  },
  logo: {
    width: '300px',
    display: 'block',
    margin: '0 auto 16px auto'
  },
  title: {
    fontWeight: 'bold',
    color: '#757575',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto'
  },
  paper: {
    width: 'min-content',
    padding: '18px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-in-out',
    opacity: '1',
    '&:hover': {
      opacity: '1',
      zIndex: '9',
      boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.2s ease-in-out'
    }
  }
}));

function Code503(): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container className={classes.container} justifyContent='center' alignContent='center'>
      <Grid item style={{ position: 'relative', top: '-40px' }}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12}>
            <SettingsRoundedIcon className={classes.cog} style={{ transform: 'rotate(359deg)' }}/>
          </Grid>
          <Grid item xs={12}>
            <img
              src={`${process.env.PUBLIC_URL}/asset/logo/deecision.svg`}
              alt='Deecision-Logo'
              className={classes.logo}
              style={{ position: 'relative', left: '16px' }}
            />
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={2} alignItems='center'>
              <Grid item >
                <Grid container >
                  <Grid item >
                    <ErrorRoundedIcon/>
                  </Grid>
                  <Grid item >
                    <Typography >
                      <span style={{ fontWeight: '500', fontSize: '16px', verticalAlign: 'baseline', marginLeft: '6px', marginTop: '-6px' }}>
                        Sorry, we are currently down for maintenance
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item >
                <Typography >
                  <span style={{ fontSize: '16px', verticalAlign: 'baseline', marginLeft: '6px' }}>
                    We apologise for the inconvenience and will be back up shortly.
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} style={{ margin: '24px auto 0 auto' }}>
              <CustomButton
                variant='contained'
                fullWidth
                onClick={() => window.location.assign(`${window.location.origin}/deeligenz`)}
              >
                Try Again
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function CodeUnknown(): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container className={classes.container} justifyContent='center' alignContent='center'>
      <Grid item style={{ position: 'relative', top: '-40px' }}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12}>
            <SettingsRoundedIcon className={classes.cog} style={{ transform: 'rotate(359deg)' }}/>
          </Grid>
          <Grid item xs={12}>
            <img
              src={`${process.env.PUBLIC_URL}/asset/logo/deecision.svg`}
              alt='Deecision-Logo'
              className={classes.logo}
              style={{ position: 'relative', left: '16px' }}
            />
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={2} alignItems='center'>
              <Grid item >
                <Grid container >
                  <Grid item >
                    <ErrorRoundedIcon/>
                  </Grid>
                  <Grid item >
                    <Typography >
                      <span style={{ fontWeight: '500', fontSize: '16px', verticalAlign: 'baseline', marginLeft: '6px', marginTop: '-6px' }}>
                        Sorry, we are currently not available
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item >
                <Typography >
                  <span style={{ fontSize: '16px', verticalAlign: 'baseline', marginLeft: '6px' }}>
                    We apologise for the inconvenience and will be back up shortly.
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} style={{ margin: '24px auto 0 auto' }}>
              <CustomButton
                variant='contained'
                fullWidth
                onClick={() => window.location.assign(`${window.location.origin}/deeligenz`)}
              >
                Try Again
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function CodePageParsed(props: {code: Nullable<number>}) {
  switch (props.code) {
  case 503 :
    return <Code503 />;
  default :
    return <CodeUnknown />;
  }
}

function CodePage(props: {code: Nullable<number>}): ReactElement {

  return (
    <ThemeWrapper>
      <CodePageParsed code={props.code} />
    </ThemeWrapper>
  );
}

export default CodePage;
