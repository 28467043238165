import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface RatingScoreTendancyProps {
	previousValue: number | string | undefined,
	currentValue: number | string | undefined
}

function RatingScoreTendancy( props: RatingScoreTendancyProps ): ReactElement {
  const { t } = useTranslation();

  let scoreTendancy : string;
  if (!props.previousValue) {
    if (!props.currentValue) {
      scoreTendancy = 'none';
    } else {
      scoreTendancy = 'new';
    }
  } else if (!props.currentValue) {
    scoreTendancy = 'same'; // No current so using the same value
  } else if (props.currentValue === props.previousValue) {
    scoreTendancy = 'same';
  } else if (props.currentValue > props.previousValue) {
    scoreTendancy = 'up';
  } else {
    scoreTendancy = 'down';
  }

  const marginSize =  '0';
  switch (scoreTendancy) {
  case 'new' :
    return (
      <Tooltip title={t('inputs.newValue')} arrow>
        <NewReleasesIcon
          fontSize='small'
          style={{ margin: marginSize }}
        />
      </Tooltip>
    );
  case 'down' :
    return (
      <Tooltip title={`${t('inputs.previousValue')} : ${props.previousValue}`} arrow>
        <CallMadeIcon
          fontSize='small'
          style={{ margin: marginSize, color: '#7cb342' }}
        />
      </Tooltip>
    );
  case 'up' :
    return (
      <Tooltip title={`${t('inputs.previousValue')} : ${props.previousValue}`} arrow>
        <CallReceivedIcon
          fontSize='small'
          style={{ margin: marginSize, transform: 'rotate(-90deg)', color: '#e53935' }}
        />
      </Tooltip>
    );
  case 'same' :
    return (
      <Tooltip title={`${t('inputs.previousValue')} : ${props.previousValue}`} arrow>
        <TrendingFlatIcon
          fontSize='small'
          style={{ margin: marginSize }}
        />
      </Tooltip>
    );
  default :
    return (
      <Tooltip title={t('inputs.noPreviousValue')} arrow>
        <HelpOutlineIcon
          fontSize='small'
          style={{ margin: marginSize }}
        />
      </Tooltip>
    );
  }
}

export default RatingScoreTendancy;
