import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { createContext } from '../../hooks/context';
import { Filter } from '../../types/types';

// Note: disable to allow use of 'navigate' as no type exposed ========================
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// ===================================================================================
export class SearchParamsAccess {
  searchParams: URLSearchParams;

  setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>;

  navigate: NavigateFunction;

  constructor(searchParams: URLSearchParams, setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>, navigate: NavigateFunction) {
    this.searchParams = searchParams;
    this.setSearchParams = setSearchParams;
    this.navigate = navigate;
  }

  getParam(name: string): string | null {
    return this.searchParams.get(name);
  }

  getFilters(): Array<Filter> {
    const filters: Array<Filter> = [];

    this.searchParams.forEach((value, key) => {
      if (key.includes('f_')) {
        filters.push({ id: key, value });
      }
    });

    return (filters);
  }

  getSortFilter(): Filter | undefined {
    let sort;

    this.searchParams.forEach((value, key) => {
      if (key.includes('s_')) {
        sort = { id: key, value };
      }
    });

    return sort;
  }

  getIntParam(name: string, defaultValue: number) : number {
    const value = this.getParam(name);
    if ((value === undefined) || (value === null)) {
      return defaultValue;
    }

    return Number.parseInt(value);
  }

  updateParam(name: string, value?: string) : void {
    if (value && value !== 'false') {
      this.searchParams.set(name, value);
    } else {
      this.searchParams.delete(name);
    }
    this.updateSearchParamsState(this.searchParams);
  }

  updateParams(params: Array<Array<string>>) : void {
    params.forEach((paramPair) => {
      this.searchParams.set(paramPair[0], paramPair[1]);
    });
    this.updateSearchParamsState(this.searchParams);
  }

  updateSearchParamsState(params: URLSearchParams) : void {
    this.navigate({ search: this.searchParams.toString() });

    // Note: a new copy of the object is needed (modifying the same one does not cause a refresh)
    this.setSearchParams(new URLSearchParams(params.toString()));
  }
}

export const [getSearchParamsContext, SearchParamsContextProvider] = createContext<SearchParamsAccess>();
