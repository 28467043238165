export async function getUserFirstAndLastNameLetter(userId: string): Promise<string> {
  const splitUserName = userId.split('.');
  let userName = '';

  await splitUserName.forEach((namePart) => {
    userName = `${userName}${namePart.charAt(0).toUpperCase()}`;
  });

  return userName;
}

async function getUserFirstAndLastName(userId: string): Promise<string> {
  const splitUserName = userId.split('.');
  let userName = '';

  await splitUserName.forEach((namePart) => {
    if (userName.length > 0) {
      userName = `${userName} ${namePart.charAt(0).toUpperCase()}${namePart.slice(1)}`;
    } else {
      userName = `${userName}${namePart.charAt(0).toUpperCase()}${namePart.slice(1)}`;
    }
  });

  return userName;
}

export default getUserFirstAndLastName;
