import React, { ReactElement } from 'react';
import {
  Route,
  useLocation,
  Navigate,
  Routes
} from 'react-router-dom';
import SecureDisplay from '../components/authentification/securedisplay';
import SecureRoute from '../components/authentification/secureroute';

interface PropsRouter {
  index: ReactElement,
  permissions?: string,
  route?: string,
  children: ReactElement
}

function IdRouter({ index, children, permissions, route }: PropsRouter): ReactElement {
  const { pathname } = useLocation();

  return (
    <SecureRoute permissions={route}>
      <Routes>
        {pathname.slice(-1) === '/' &&
          <Route path='/:url*(/+)' element={<Navigate to={pathname.slice(0, -1)} />} />
        }
        <Route path='/' element={index} />
        <Route path=':id/*' element={
          <SecureDisplay permissions={permissions}>
            {children}
          </SecureDisplay>
        }
        />
      </Routes>
    </SecureRoute>
  );
}

export default IdRouter;
