import React, { Dispatch, ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Snackbar, TextField, Typography, Alert } from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { StyledMenu } from '../../layout/navbar/navbar';
import CustomButton, { CustomIconButton } from '../../button/button';
import { EvalComponentsProps } from './evaluations';

interface MeetingEditorProps extends EvalComponentsProps {
  anchorEl: HTMLElement | null,
  setAnchorEl: Dispatch<React.SetStateAction<HTMLElement | null>>
}

function MeetingEditor(props: MeetingEditorProps): ReactElement {
  const { t } = useTranslation();
  const isMenuOpen = Boolean(props.anchorEl);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [comment, setComment] = useState<string>(props.ddElement.evaluation?.meeting?.remarks || '');
  const [disabled, setDisabled] = useState(false);
  const valueInputRef = useRef<HTMLInputElement>();

  const handleMenuCancel = () => {
    setComment(props.ddElement.evaluation?.meeting?.remarks || '');
    props.setAnchorEl(null);
  };

  const handleSave = () => {
    setDisabled(true);
    props.service.setMeeting(props.ddElement._id, props.ddEval._id, comment)
      .then(() => {
        props.setUpdateList(true);
        setDisabled(false);
        props.setAnchorEl(null);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
        setDisabled(false);
      });
  };

  const handleDelete = () => {
    setDisabled(true);
    props.service.setMeeting(props.ddElement._id, props.ddEval._id)
      .then(() => {
        props.setUpdateList(true);
        setDisabled(false);
        props.setAnchorEl(null);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
        setDisabled(false);
      });
  };

  const handleKeyPressOnComment = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.key === 'Tab') {
      event.preventDefault();
      valueInputRef.current?.focus();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  return (
    <>
      <StyledMenu
        anchorEl={props.anchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuCancel}
      >
        <Box style={{ padding: '4px 14px', width: '350px' }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <Typography variant='h3'>{t('ddElements.rating.meeting')}</Typography>
            </Grid>
            <Grid item>
              <CustomIconButton
                variant='text'
                color='secondary'
                small
                onClick={handleDelete}
                disabled={props.ddElement.evaluation?.meeting?.remarks !== '' && !(props.ddElement.evaluation?.meeting?.remarks) || disabled}
              >
                <DeleteRoundedIcon />
              </CustomIconButton>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label='Comment'
                variant='outlined'
                onChange={event => setComment(event.target.value)}
                value={comment}
                size='small'
                onKeyDown={handleKeyPressOnComment}
                multiline
                minRows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <CustomButton
                    startIcon={<SaveRoundedIcon />}
                    variant='text'
                    small
                    fullWidth
                    onClick={handleSave}
                    disabled={disabled}
                    onKeyDown={handleKeyPress}
                  >
                    Save
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomIconButton
                    color='secondary'
                    variant='text'
                    small
                    onClick={handleMenuCancel}
                    disabled={disabled}
                  >
                    <ClearRoundedIcon />
                  </CustomIconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledMenu>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default MeetingEditor;
