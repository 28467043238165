import React, { ReactElement, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { SelectQuestionSpec, ValueSetter } from '../../../types/types';

export default function SelectFieldEditor(props: {specs: SelectQuestionSpec, value?: string, setValue: ValueSetter, customKey?: string}) : ReactElement {
  const [selectValue, setSelectValue] = useState(props.value || '');
  const [value, setValue] = useState(props.value || '');
  const [otherSelected, setOtherSelected] = useState(false);
  const checkValue = (myValue: string) => {
    if (props.specs.choices && props.specs.choices.some(choice => choice.value === myValue) || myValue === 'test') {
      return myValue;
    }
    setOtherSelected(true);

    return 'other';
  };

  useEffect(() => {
    setValue(props.value || '');
    setSelectValue(props.value ? checkValue(props.value) : otherSelected ? 'other' : '');
  }, [props.value]);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value as string !== 'other') {
      setValue(event.target.value as string);
      setOtherSelected(false);
      props.setValue(event.target.value as string);
    } else {
      setValue('');
      setOtherSelected(true);
      setSelectValue('other');
    }
  };

  const handleOtherChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.setValue(event.target.value);
  };

  return (
    <Grid container spacing={2} alignItems='flex-end'>
      <Grid item>
        <FormControl component='fieldset' style={{ minWidth: '80px' }}>
          <Select key={props.customKey} variant='standard' aria-label='gender' name='gender1' value={selectValue} onChange={handleChange} error={props.specs.required ? !props.value : false}>
            {props.specs.choices && props.specs.choices.map(rowValue => (
              <MenuItem key={rowValue.label} value={rowValue.value}>{rowValue.label}</MenuItem>
            ))}
            {props.specs.other &&
                <MenuItem key='other' value='other'>Other (enter a value)</MenuItem>
            }
          </Select>
        </FormControl>
      </Grid>
      {otherSelected &&
          <Grid item>
            <TextField label='Other' defaultValue={props.value} value={value} onChange={handleOtherChange} />
          </Grid>
      }
    </Grid>
  );
}
