import { API } from "../api/api";
import { ApiResponse, Nullable, Settings } from "../types/types";
import { BaseService, BaseUploadService } from './data';

export class PlatformLogoUploadService extends BaseUploadService {
  logoType: string;

  constructor(logoType : string) {
    super();
    this.logoType = logoType;
  }

  getBaseUrl() : string {
    return '/api/v1/admin/platform/settings';
  }

  getUploadUrl(): string {
    return `${this.getBaseUrl()}/uploadLogo?logoType=${this.logoType}`;
  }
}

export class PlatformSettingsService extends BaseService {

  getBaseUrl(): string {
    return '/api/v1/admin/platform/settings';
  }

  getSettings(): Promise<ApiResponse<Nullable<Settings>>> {
    return API.get(`${this.getBaseUrl()}`)
      .then(res =>
        this.handleResponse<Nullable<Settings>>(res)
      );
  }
}
