import React, { ReactElement, useEffect, useState } from 'react';
import ContactsTable from './contactsTable';
import { Contact, Nullable } from '../../types/types';
import ServiceError from '../../services/errors';
import ErrorPageManager from '../Error/errorpagemanager';
import { AbstractContactsAssignmentService } from '../../services/contacts';

function Contacts(props: {service: AbstractContactsAssignmentService<Contact>}): ReactElement {
  const [contactList, setContactList] = useState<Nullable<Array<Contact>>>(null);
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      update && props.service.getAll()
        .then((apiResp) => {
          setContactList(apiResp.data || []);
          setUpdate(false);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
          setUpdate(false);
        });
    }, 300); // TODO: remove when possible
  }, [update]);

  return (
    <>
      {
        error ? <ErrorPageManager error={error} /> :
          contactList ? <ContactsTable contactsList={contactList} setUpdate={setUpdate} service={props.service} /> : <>Loading...</>
      }
    </>
  );
}

export default Contacts;
