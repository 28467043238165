import React, { ReactElement } from 'react';
import { strategyContext } from '../../../hooks/datatabs';
import { EntityDdList } from '../../duediligences/listing/entityddlist';

function StrategyDdList(): ReactElement {
  const strategyData = strategyContext();

  return (
    <>
      {strategyData && strategyData._id &&
        <EntityDdList entityType='strategy' entityId={strategyData._id} entityTypeText='strategy' />
      }
    </>
  );
}

export default StrategyDdList;
