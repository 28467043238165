import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDrop } from 'react-dnd';
import { green } from '@mui/material/colors';
import { mainColor, mainRadius } from '../../../../themes/theme';
import { UpdateMeetingProps } from '../../../../types/meetingstypes';

interface MeetingDropWrapperProps {
  meetingId: string,
  updateMeetings: ( updateItems: UpdateMeetingProps ) => void,
  disabled?: boolean,
  children?: ReactNode
}

const useStyles = makeStyles(() => ({
  wrapper: {
    borderRadius: mainRadius,
    transition: 'all 0.2s ease-in-out',
    opacity: '0.8',
    position: 'relative',
    border: 'none',
    padding: '8px',
    backgroundColor: 'white',
    '&:hover': {
      opacity: '1',
      zIndex: '9',
      boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.2s ease-in-out'
    }
  }
}));

function MeetingDropWrapper(props: MeetingDropWrapperProps): ReactElement {
  const classes = useStyles();
  const [dropped, setDropped] = useState(false);
  const [{ isHovered }, drop] = useDrop<UpdateMeetingProps, UpdateMeetingProps, { isHovered: boolean }>(() => ({
    accept: ['question', 'category'],
    drop: ((item) => {
      let action = '';

      if (item.isCategory) {
        action = props.meetingId !== '' ? 'MOVE CATEGORY' : 'REMOVE CATEGORY';
      } else {
        action = props.meetingId !== '' ? 'MOVE QUESTION' : 'REMOVE QUESTION';
      }
      setDropped(!!props.meetingId);
      props.updateMeetings(
        {
          action,
          itemId: item.itemId,
          oldMeetingId: item.oldMeetingId,
          meetingId: props.meetingId,
          path: item.path
        }
      );

      return undefined;
    }),
    collect: (monitor => ({
      isHovered: monitor.isOver() && monitor.canDrop()
    })),
    canDrop: (item => (
      item.oldMeetingId !== props.meetingId &&
      (!!item.oldMeetingId && !props.meetingId ||
      !item.oldMeetingId && !!props.meetingId)
    ))
  }));

  useEffect(() => {
    if (dropped) {
      setTimeout(() => {
        setDropped(false);
      }, 1500);
    }
  }, [dropped]);

  return (
    <Grid
      container
      spacing={2}
      ref={!props.disabled ? drop : null}
      className={classes.wrapper}
      style={{
        border: `2px solid ${
          isHovered ? mainColor :
            dropped ? green[500] :
              'rgba(0,0,0,0)'
        }`
      }}
    >
      {props.children}
    </Grid>
  );
}

export default MeetingDropWrapper;
