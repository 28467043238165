import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { AuditedEntity, Participant, Ratings, RatingValues } from '../../../types/types';
import EntityLinkWithIcon from '../../../components/modularcomponents/entitylinkwithicon/entitylinkwithicon';
import Rating from '../../../components/ratings/rating';

export function Owners(props: {participants: Participant[]}): ReactElement {
  if (props.participants) {
    const userName: string[] = [];

    props.participants.forEach((person) => {
      if (person.roles.includes('owner')) {
        userName.push(` ${  person.firstName  } ${  person.lastName}`);
      }
    });

    return (
      <Typography variant='body2' component='p'>{userName.toString()}</Typography>
    );
  }

  return (
    <Typography variant='body2' component='p'>N/A</Typography>
  );
}

export function ComputedOrFinalRating(props: {rating?: RatingValues, displayAsLetter?: boolean, hideNA?: boolean, withIcon?: boolean}): ReactElement {
  if (props.rating) {
    if (props.rating.final) {
      return (
        <Rating rank={props.rating.final.toString()} displayAsLetter={props.displayAsLetter} withIcon={props.withIcon} hideNA={props.hideNA} />
      );
    }
    if (props.rating.temporary) {
      return (
        <Rating rank={props.rating.temporary.toString()} isComputed displayAsLetter={props.displayAsLetter} withIcon={props.withIcon} hideNA={props.hideNA} />
      );
    }
    if (props.rating.computed) {
      return (
        <Rating rank={props.rating.computed.toString()} isComputed displayAsLetter={props.displayAsLetter} withIcon={props.withIcon} hideNA={props.hideNA} />
      );
    }
  }

  return (<></>);
}

export function Rating1(props: {ratings?: Ratings, hideNA?: boolean, withIcon?: boolean}): ReactElement {
  return (
    <ComputedOrFinalRating rating={props.ratings?.rating1 || props.ratings?.aais} hideNA={props.hideNA} withIcon={props.withIcon} />
  );
}

export function Rating2(props: {ratings?: Ratings, hideNA?: boolean, withIcon?: boolean}): ReactElement {
  return (
    <ComputedOrFinalRating rating={props.ratings?.rating2 || props.ratings?.aaisEsg} displayAsLetter hideNA={props.hideNA} withIcon={props.withIcon} />
  );
}

function FundDdOnBlock(props: {auditedEntity: AuditedEntity, survey?: boolean}): ReactElement {
  return (
    <EntityLinkWithIcon type={props.auditedEntity.type} id={props.auditedEntity.id} name={props.auditedEntity.name} survey={props.survey} />
  );
}

function InvestMgrDdOnBlock(props: {auditedEntity: AuditedEntity, survey?: boolean}): ReactElement {
  return (
    <EntityLinkWithIcon type={props.auditedEntity.type} id={props.auditedEntity.id} name={props.auditedEntity.name} survey={props.survey} />
  );
}

function MandateDdOnBlock(props: {auditedEntity: AuditedEntity, survey?: boolean}): ReactElement {
  return (
    <EntityLinkWithIcon type={props.auditedEntity.type} id={props.auditedEntity.id} name={props.auditedEntity.name} survey={props.survey} />
  );
}

export function DispachDdOnBlock(props: {auditedEntity: AuditedEntity, survey?: boolean}): ReactElement {
  switch (props.auditedEntity.type) {
  case 'fund':
    return (<FundDdOnBlock auditedEntity={props.auditedEntity} survey={props.survey} />);
  case 'investMgr':
    return (<InvestMgrDdOnBlock auditedEntity={props.auditedEntity} survey={props.survey} />);
  case 'mandate':
    return (<MandateDdOnBlock auditedEntity={props.auditedEntity} survey={props.survey} />);
  default:
    return (<EntityLinkWithIcon type={props.auditedEntity.type} id={props.auditedEntity.id} name={props.auditedEntity.name} survey={props.survey} />);
  }
}
