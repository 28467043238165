import React, { ReactElement } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom';
import { TabsType } from '../types/types';
import secureTab from '../components/authentification/securetab';

function TabsRouter({ tabs }: { tabs: TabsType }): ReactElement {
  const { keycloak } = useKeycloak();

  return (
    <Routes>
      <Route element={
        <Outlet />
      }>
        <Route path='/' element={<Navigate to={`${tabs[0].path}`} replace />} />
        {tabs.map(tab => (
          secureTab(tab.label, keycloak) ?
            <Route key={tab.path} path={tab.path} element={tab.component} /> :
            undefined
        ))}
      </Route>
    </Routes>
  );
}

export default TabsRouter;
