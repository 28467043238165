import React, { JSXElementConstructor, ReactElement, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Permissions from './permissions.json';

function SecureRoute(props: {permissions?: string, children: Array<ReactElement> | ReactElement | JSXElementConstructor<ReactElement> | undefined}): ReactElement {
  const { keycloak } = useKeycloak();
  const [access, setAccess] = useState(false);

  !access && Permissions.forEach((permission) => {
    if (permission.label === props.permissions) {
      permission.roles.map(role => keycloak.hasResourceRole(role)).some(item => item) && setAccess(true);
    }
  });

  if (!props.permissions){
    return <>{props.children}</>;
  }

  return (
    <>
      {access ?
        props.children :
        <p>Access denied</p>
      }
    </>
  );
}

export default SecureRoute;
