import React, { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardActions } from '@mui/material';
import BooleanQuestionEditor  from './booleanquestioneditor';
import CountryQuestionEditor  from './countryquestioneditor';
import CountryArrayQuestionEditor  from './countryarrayquestioneditor';
import CurrencyQuestionEditor  from './currencyquestioneditor';
import DateQuestionEditor  from './datequestioneditor';
import AmountQuestionEditor  from './amountquestioneditor';
import NumberQuestionEditor  from './numberquestioneditor';
import SelectQuestionEditor  from './selectquestioneditor';
import StringQuestionEditor  from './stringquestioneditor';
import StringArrayQuestionEditor  from './stringarrayquestioneditor';
import TableQuestionEditor  from './tablequestioneditor';
import TextQuestionEditor  from './textquestioneditor';
import DateArrayQuestionEditor from './datearrayquestioneditor';
import CurrencyArrayQuestionEditor from './currencyarrayquestioneditor';
import ObjectQuestionEditor from './objectquestioneditor';
import { QuestionDispatchProps } from '../../../types/types';
import QuestionBox from '../box';
import QuestionValue from '../value';
import QuestionLabel from '../label';
import PercentQuestionEditor from './percentquestioneditor';
import FileArrayQuestionEditor from './filearrayquestioneditor';
import FileQuestionEditor from './filequestioneditor';
import MultiSelectQuestionEditor from './multiselectquestioneditor';
import CompanyQuestionViewer from '../viewers/companyquestionviewer';
import QuestionEditorButton from '../questioneditorbutton';

export default function DispatchQuestionEditor(props: QuestionDispatchProps): ReactElement {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(props.disableInput);

  useEffect(() => {
    setDisabled(props.disableInput);
  }, [props.disableInput]);

  const componentMap = {
    'object': ObjectQuestionEditor,
    'boolean': BooleanQuestionEditor,
    'company': CompanyQuestionViewer,
    'companies': TableQuestionEditor,
    'country': CountryQuestionEditor,
    'country[]': CountryArrayQuestionEditor,
    'currency': CurrencyQuestionEditor,
    'date': DateQuestionEditor,
    'amount': AmountQuestionEditor,
    'number': NumberQuestionEditor,
    'percent': PercentQuestionEditor,
    'person': ObjectQuestionEditor,
    'persons': TableQuestionEditor,
    'select': SelectQuestionEditor,
    'multiselect': MultiSelectQuestionEditor,
    'string': StringQuestionEditor,
    'string[]': StringArrayQuestionEditor,
    'table': TableQuestionEditor,
    'text': TextQuestionEditor,
    'date[]': DateArrayQuestionEditor,
    'currency[]': CurrencyArrayQuestionEditor,
    'file': FileQuestionEditor,
    'file[]': FileArrayQuestionEditor
  };

  const type = props.spec.type as keyof typeof componentMap;

  const ReturnComponent: JSXElementConstructor<QuestionDispatchProps> = componentMap[type];

  if (disabled) {
    return (
      <QuestionBox>
        <QuestionLabel spec={props.spec} />
        <CardActions>
          <QuestionEditorButton response={props.answer?.value || null} setResponse={() => undefined} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} />
        </CardActions>
      </QuestionBox>
    );
  }
  if (ReturnComponent) {
    return (
      <ReturnComponent {...props} />
    );
  }

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <div>{t('ddElements.status.notSupported')}</div>
      </QuestionValue>
    </QuestionBox>
  );
}
