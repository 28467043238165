import React, { ReactElement, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import HeaderTitle from '../../../../components/modularcomponents/header/headertitle';
import { Nullable, PossibleAction, DataSurvey } from '../../../../types/types';
import HeaderContainer from '../../../../components/modularcomponents/header/headercontainer';
import HeaderSummary from '../../../../components/modularcomponents/header/headersummary';
import HeaderItems from '../../../../components/modularcomponents/header/headeritems';
import HeaderItem from '../../../../components/modularcomponents/header/headeritem';
import HeaderProperty from '../../../../components/modularcomponents/header/headerproperty';
import HeaderPropertyName from '../../../../components/modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../../../../components/modularcomponents/header/headerpropertyvalue';
import HeaderActivities from '../../../../components/modularcomponents/header/headeractivities';
import { DispachDdOnBlock } from '../../../duediligences/listing/datadisplay';
import getColorFromDate from '../../../../utils/datemanagement';
import HeaderButtons from '../../../../components/modularcomponents/header/headerbuttons';
import { SurveyService } from '../../../../services/survey';

function SurveysHeader(props: {entity: Nullable<DataSurvey>}): ReactElement {
  const title = `${  props.entity?.auditedEntity.name  } - ${  props.entity?.type  } - ${  props.entity?.startDate}`;
  const [expand, setExpand] = useState(false);
  const actionList: PossibleAction[] = [];

  const toggleExpand = () => {
    setExpand(val => !val);
  };

  return (
    <>
      { props.entity &&
        <HeaderContainer expanded={expand} setExpanded={setExpand}>
          <HeaderSummary toggleExpand={toggleExpand}>
            <HeaderTitle toggleExpand={toggleExpand} type='dueDiligence' name={title} />
            <HeaderButtons id={props.entity._id} service={new SurveyService()} actionList={actionList} />

          </HeaderSummary>
          <HeaderItems>
            <HeaderItem>
              <Grid item xs={12}>
                <Typography variant='body2' style={{ width: 'min-content', whiteSpace: 'nowrap', paddingBottom: '4px' }}>Due Diligence on</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '-16px' }}>
                <DispachDdOnBlock auditedEntity={props.entity.auditedEntity} survey />
              </Grid>
              <HeaderProperty>
                <HeaderPropertyName name='Type ' />
                <HeaderPropertyValue>{props.entity.type}</HeaderPropertyValue>
              </HeaderProperty>

              <HeaderProperty>
                <HeaderPropertyName name='Start Date ' />
                <HeaderPropertyValue>{props.entity.startDate}</HeaderPropertyValue>
              </HeaderProperty>

              <HeaderProperty>
                <HeaderPropertyName name='End Date ' />
                <HeaderPropertyValue color={getColorFromDate(props.entity.endDate || props.entity.expectedEndDate)}>
                  {props.entity.endDate || props.entity.expectedEndDate}
                </HeaderPropertyValue>
              </HeaderProperty>
            </HeaderItem>
            <HeaderItem>
              {props.entity.lifecycle ?
                <>
                  <HeaderProperty>
                    <HeaderPropertyName name='Current Activities  ' />
                    <HeaderActivities lifeCycle={props.entity.lifecycle} />
                  </HeaderProperty>
                </> :
                <></>
              }
            </HeaderItem>
          </HeaderItems>
        </HeaderContainer>
      }
    </>
  );
}

export default SurveysHeader;
