import React, { MouseEvent, ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DueDiligenceDevService } from '../../../../services/dds';
import CustomButton from '../../../../components/button/button';

const useStyles = makeStyles(() => ({
  dangerousPart: {
    width: '50%',
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

export function DeleteDdSection(props: {ddId: string}): ReactElement {
  const classes = useStyles();
  const navigate = useNavigate();

  const deleteDD = (event: MouseEvent) => {
    event.preventDefault();

    if (props.ddId) {
      if (window.confirm('Are you sure you want to DELETE this Due Diligence PERMANENTLY ?')) {
        const devService = new DueDiligenceDevService();
        devService.deleteDD(props.ddId);

        navigate('/dds');
      }
    }
  };

  return (
    <>
      <Typography className={classes.dangerousPart}>
        Delete this Due Diligence and all related elements (elements, data blocks, tree, ...)
        <CustomButton onClick={deleteDD} variant='contained' color='secondary' small style={{ width: '140px' }}>
          Delete DD
        </CustomButton>
      </Typography>

    </>
  );
}

export default DeleteDdSection;
