import { DeprecatedThemeOptions } from '@mui/material';

export const dark: DeprecatedThemeOptions = {
  typography: {
    h3: {
      color: 'lightgrey'
    }
  },
  palette: {
    mode: 'dark',
    background: {
      default: "#424242"
    },
    text: {
      primary: 'black'
    }
  }
};

export default dark;
