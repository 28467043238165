import React, { ReactElement } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function JsonWrapper(props: { children: string }): ReactElement {
  return (
    <SyntaxHighlighter language='json' style={docco}>
      {props.children}
    </SyntaxHighlighter>
  );
}

export default JsonWrapper;