import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import UploadFiles from '../../../upload/uploadfiles';
import { DdGraphUploadService } from '../../../../services/dds';
import DisplayGraphLogic, { BaseGraphFieldProps } from './displaygraphlogic';
import { Tag, TagsFamily } from '../../../../types/types';

const tagsForFunds = [
  {
    family: 'funds',
    label: 'Alger Capital Appreciation',
    value: 'algerCapitalAppreciation'
  },
  {
    family: 'funds',
    label: 'Aberdeen Standard SICAV I',
    value: 'aberdeenStandardSICAV'
  },
  {
    family: 'funds',
    label: 'BlackRock Strategic Funds - Fixed Income Strategies Fund',
    value: 'blackRockStrategicFunds-FixedIncomeStrategiesFund'
  },
  {
    family: 'funds',
    label: 'Fidelity Funds - Global Technology Fund',
    value: 'fidelityFunds-GlobalTechnologyFund'
  },
  {
    family: 'funds',
    label: 'Pzena Focused Value',
    value: 'PzenaFocusedValue'
  }
];

const tagsForManagers = [
  {
    family: 'managers',
    label: 'BlackRock (Luxembourg) SA',
    value: 'blackRock(Luxembourg)SA'
  },
  {
    family: 'managers',
    label: 'Comgest Asset Management Intl Ltd',
    value: 'comgestAssetManagementIntlLtd'
  },
  {
    family: 'managers',
    label: 'Fred Alger Management, LLC',
    value: 'Fred Alger Management, LLC'
  },
  {
    family: 'managers',
    label: 'FundRock Management Company S.A.',
    value: 'FundRockManagementCompanyS.A.'
  },
  {
    family: 'managers',
    label: 'Sands Capital Management, LLC',
    value: 'SandsCapitalManagementLLC'
  }
];

const tagsFamilies = [
  {
    'family': 'funds',
    'color': 'yellow',
    'tags': tagsForFunds
  },
  {
    'family': 'managers',
    'color': 'orange',
    'tags': tagsForManagers
  }
];

// SELECTION CHIPS LIST FOR MY LIST
const tagsList: TagsFamily[] = [
  {
    'family': 'funds',
    'color': 'yellow',
    'tags': [
      {
        family: 'funds',
        label: 'Alger Capital Appreciation',
        value: 'Alger Capital Appreciation'
      },
      {
        family: 'funds',
        label: 'Aberdeen Standard SICAV I',
        value: 'Aberdeen Standard SICAV I'
      },
      {
        family: 'funds',
        label: 'BlackRock Strategic Funds - Fixed Income Strategies Fund',
        value: 'BlackRock Strategic Funds - Fixed Income Strategies Fund'
      },
      {
        family: 'funds',
        label: 'Fidelity Funds - Global Technology Fund',
        value: 'Fidelity Funds - Global Technology Fund'
      },
      {
        family: 'funds',
        label: 'Pzena Focused Value',
        value: 'Pzena Focused Value'
      }
    ]
  },
  {
    'family': 'managers',
    'color': 'orange',
    'tags': [
      {
        family: 'managers',
        label: 'BlackRock (Luxembourg) SA',
        value: 'BlackRock (Luxembourg) SA'
      },
      {
        family: 'managers',
        label: 'Comgest Asset Management Intl Ltd',
        value: 'Comgest Asset Management Intl Ltd'
      },
      {
        family: 'managers',
        label: 'Fred Alger Management, LLC',
        value: 'Fred Alger Management, LLC'
      },
      {
        family: 'managers',
        label: 'FundRock Management Company S.A.',
        value: 'FundRock Management Company S.A.'
      },
      {
        family: 'managers',
        label: 'Sands Capital Management, LLC',
        value: 'Sands Capital Management, LLC'
      }
    ]
  }
];

export interface GraphInfo {
	type: string,
	path: string,
	docId: string
}

export interface MultiGraphFieldData {
	multigraph: GraphFieldData[],
	text?: string
}

export interface GraphFieldData {
	graph?: GraphInfo,
	tags?: Array<Tag>,
	text?: string
}

export interface ReportBlockGraphFieldProps extends BaseGraphFieldProps {
	uploadServiceFactory: (fieldId: string) => DdGraphUploadService | undefined,
	previousValue?: GraphFieldData, // .text not used
	currentValue?: GraphFieldData // .text not used
}

function ReportBlockGraphField(props: ReportBlockGraphFieldProps): ReactElement {
  const initialTags = props.currentValue?.tags ? props.currentValue?.tags : [];
  const graphInfo: GraphInfo | undefined = props.currentValue?.graph;
  const elementId = props.currentValue?.graph?.docId || '';
  const uploadService : DdGraphUploadService | undefined = props.uploadServiceFactory(props.desc.id);

  /* const changeDisplayMode = () => {
		setDisplayTagsMode( displayTagsMode === 1 ? 2 : 1)
	} */

  return (
    <>
      <Grid container style={{ padding: '12px' }}>
        <Grid item xs={12}>
          { uploadService &&
						(graphInfo && elementId !== '' ?
						  <DisplayGraphLogic
						    tagsList={tagsList}
						    tagsFamilies={tagsFamilies}
						    initialTags={initialTags}
						    uploadService={uploadService}
						    graphInfo={graphInfo}
						    desc={props.desc}
						    update={props.update}
						    onSave={props.onSave}
						    isEditable={props.isEditable}
						    graphId={elementId}
						  />
						  :
						  <UploadFiles
						    service={uploadService}
						    small
						    uploadButtonLabel='Upload graph'
						    callback={props.update}
						    hideAfterFinish
						    dispUploaded
						    disabled={!props.isEditable}
						  />
						)
          }
        </Grid>

      </Grid>
    </>
  );
}

export default ReportBlockGraphField;
