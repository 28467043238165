import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderTitle from '../../../components/modularcomponents/header/headertitle';
import { Fund, Nullable, PossibleAction } from '../../../types/types';
import HeaderProperty from '../../../components/modularcomponents/header/headerproperty';
import HeaderPropertyName from '../../../components/modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../../../components/modularcomponents/header/headerpropertyvalue';
import EntityLink from '../../../components/modularcomponents/entitylink/entitylink';
import HeaderRating from '../../../components/headerdata/headerrating';
import HeaderContainer from '../../../components/modularcomponents/header/headercontainer';
import HeaderItems from '../../../components/modularcomponents/header/headeritems';
import HeaderItem from '../../../components/modularcomponents/header/headeritem';
import HeaderSummary from '../../../components/modularcomponents/header/headersummary';
import HeaderAUM from '../../../components/modularcomponents/header/headeraum';
import HeaderButtons from '../../../components/modularcomponents/header/headerbuttons';
import { FundService } from '../../../services/fund';

function FundHeader(props: { entity: Nullable<Fund> }): ReactElement {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);
  const actionList: PossibleAction[] = [];

  const toggleExpand = () => {
    setExpand(val => !val);
  };

  return (
    <>
      { props.entity &&
      <>
        <HeaderContainer expanded={expand} setExpanded={setExpand}>
          <HeaderSummary toggleExpand={toggleExpand}>
            <HeaderTitle toggleExpand={toggleExpand} type='fund' name={props.entity.name} tags={props.entity.tags} />
            <HeaderButtons id={props.entity._id} service={new FundService()} actionList={actionList} />
          </HeaderSummary>
          <HeaderItems>
            <HeaderItem>
              <HeaderProperty>
                <HeaderAUM aum={props.entity.aum} />
              </HeaderProperty>

              <>
                { props.entity.assetMgr &&
                  <HeaderProperty>
                    <HeaderPropertyName name='Asset Manager ' />
                    <HeaderPropertyValue link>
                      <EntityLink type='assetMgr' id={props.entity.assetMgr._id} name={props.entity.assetMgr.name} openInNewTabIcon />
                    </HeaderPropertyValue>
                  </HeaderProperty>
                }
              </>
              <>
                {props.entity.investMgr &&
                  <HeaderProperty>
                    <HeaderPropertyName name={`${t('investMgrs.UpperCaseSimpleLabel')} `} />
                    <HeaderPropertyValue link>
                      <EntityLink type='investMgr' id={props.entity.investMgr._id} name={props.entity.investMgr.name} openInNewTabIcon />
                    </HeaderPropertyValue>
                  </HeaderProperty>
                }
              </>

              <>
                { props.entity.strategy &&
                  <HeaderProperty>
                    <HeaderPropertyName name='Strategy ' />
                    <HeaderPropertyValue link>
                      <EntityLink type='strategy' id={props.entity.strategy._id} name={props.entity.strategy.name} openInNewTabIcon />
                    </HeaderPropertyValue>
                  </HeaderProperty>
                }
              </>

              <>
                { props.entity.mandate &&
                  <HeaderProperty>
                    <HeaderPropertyName name='Mandate ' />
                    <HeaderPropertyValue link>
                      <EntityLink type='mandate' id={props.entity.mandate._id} name={props.entity.mandate.name} openInNewTabIcon />
                    </HeaderPropertyValue>
                  </HeaderProperty>
                }
              </>
            </HeaderItem>
            <HeaderItem>
              <HeaderProperty>
                <HeaderPropertyName name='Asset Class ' />
                <HeaderPropertyValue>{props.entity.assetClass}</HeaderPropertyValue>
              </HeaderProperty>

              <HeaderProperty>
                <HeaderPropertyName name='Geography ' />
                <HeaderPropertyValue>{props.entity.geography}</HeaderPropertyValue>
              </HeaderProperty>

              <HeaderProperty>
                <HeaderPropertyName name='Sub Asset Class ' />
                <HeaderPropertyValue>{props.entity.subAssetClass}</HeaderPropertyValue>
              </HeaderProperty>
            </HeaderItem>
            {/* <HeaderProperty>
                  <HeaderPropertyName name='Internal code' />
                  <HeaderPropertyValue>internal code</HeaderPropertyValue>
                </HeaderProperty>

                <HeaderProperty>
                  <HeaderPropertyName name='Open-ended fund' />
                  <HeaderPropertyValue>Open-ended fund</HeaderPropertyValue>
                </HeaderProperty>

                <HeaderProperty>
                  <HeaderPropertyName name='ISIN Code' />
                  <HeaderPropertyValue>LU0851650381</HeaderPropertyValue>
                </HeaderProperty> */}
            <HeaderItem>
              <HeaderRating rating={props.entity.ratings} nextReviewDate={props.entity.nextReviewDate} entityType={props.entity.latestDdInfo && !props.entity.latestDdInfo.validationDate ? props.entity.latestDdInfo.type : undefined} />
            </HeaderItem>
          </HeaderItems>
        </HeaderContainer>
      </>
      }
    </>
  );
}

export default FundHeader;
