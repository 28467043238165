import React, { ReactElement } from 'react';
import { Typography, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { DataSurvey } from '../../../../types/types';
import CustomTable, { Column } from '../../../../components/table/table';
import EntityLink from '../../../../components/modularcomponents/entitylink/entitylink';
import EntityIcon from '../../../../components/modularcomponents/entityicon/entityicon';
import { FilterParamsManager } from '../../../../components/entity/list';
import getColorFromDate from '../../../../utils/datemanagement';
// import { ProgressBarCompact } from '../../../../components/progressbar/progressbar';

function SurveyTable(props: {list: Array<DataSurvey>, paramsSort?: FilterParamsManager}): ReactElement {
  const columns: Array<Column> = [
    { id: 'name', label: 'Name', minWidth: 'min-content' },
    { id: 'type', label: 'Type', minWidth: 'min-content', align: 'center' },
    { id: 'startDate', label: 'Start Date', minWidth: 'min-content' },
    { id: 'endDate', label: 'End Date', minWidth: 'min-content' }
    // { id: 'progress', label: 'Progress', minWidth: 'min-content' },
  ];

  const rows = props.list.map(survey => ({
    id: survey._id,
    value: columns.map(column => (
      <>
        {column.id === 'name' &&
          <EntityLink type='survey' id={survey._id} center={column.align === 'center'} openInNewTabIcon>
            <ListItem style={{ paddingLeft: '0px', width: 'min-content' }}>
              <ListItemIcon style={{ minWidth: '40px' }}>
                <EntityIcon type={survey.auditedEntity.type} />
              </ListItemIcon>
              <ListItemText primary={survey.auditedEntity.name} style={{ width: 'min-content', whiteSpace: 'nowrap' }} />
            </ListItem>
          </EntityLink>
        }
        {column.id === 'type' && <Typography>{survey.type}</Typography>}
        {column.id === 'startDate' && <Typography>{survey.startDate}</Typography>}
        {column.id === 'endDate' &&
          <Typography style={{ color: getColorFromDate(survey.endDate || survey.expectedEndDate) }}>{survey.endDate || survey.expectedEndDate}</Typography>
        }
        {/* column.label === columns[4].label && <ProgressBarCompact value={800} endValue={900}/> */}
      </>
    ))
  }));

  return (
    <CustomTable columns={columns} rows={rows} paramsSort={props.paramsSort} />
  );
}

export default SurveyTable;
