import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../components/params';
import { Strategy } from '../../../types/types';
import { StrategyTable } from './table';
import { EntityListDataProvider, ListRendererMap, MultiModeList } from '../../../components/entity/list';
import { StrategyService } from '../../../services/strategy';
import StrategyFilters from './filters';
import CustomPagination from '../../../components/layout/pagination/pagination';
import TableSkeleton from '../../../components/layout/loading/tableskeleton';

class StrategyListDataProvider extends EntityListDataProvider<Strategy> {
  constructor(searchParamsAccess: SearchParamsAccess) {
    super(searchParamsAccess, new StrategyService());
  }
}

export function StrategyMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new StrategyListDataProvider(searchParamsAccess);

  function renderEmpty() {
    return (
      <div style={{ marginTop: '40px' }}>No strategy...</div>
    );
  }

  function renderLoading() {
    return (
      <TableSkeleton nbColumns={7} />
    );
  }

  function renderAsTable(eltList: Array<Strategy>, totalCount: number) {
    return (
      <>
        <StrategyTable list={eltList} paramsSort={listDataProvider.sortParamsManager} />
        <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
      </>
    );
  }

  function renderAsCards(eltList: Array<Strategy>, totalCount: number) {  // eslint-disable-line @typescript-eslint/no-unused-vars
    return (
      <>
        <div>Cards List here</div>
        <div>Pagination goes here...</div>
      </>
    );
  }

  const renderers: ListRendererMap<Strategy> = {
    'table': renderAsTable,
    'cards': renderAsCards
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table' /* 'cards' */]}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={() => renderEmpty()}
      renderLoading={() => renderLoading()}
      filtersComponent={StrategyFilters}
    />
  );
}

function StrategyListingPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <StrategyMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default StrategyListingPage;
