import Keycloak from 'keycloak-js';
import Permissions from './permissions.json';

export default function secureTab(permissions: string, keycloak: Keycloak): boolean {
  let i = 0;

  if (Permissions.map((permission) => {
    if (permission.label === permissions) {
      i += 1;
      if (permission.roles.map(role => keycloak.hasResourceRole(role)).some(item => item)) {
        return true;
      }
    }

    return false;
  }).some(item => item)) {
    return true;
  }

  return i === 0;
}
