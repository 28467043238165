import React, { ReactElement, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuProps, Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from "@mui/material/Container";
import ClearIcon from '@mui/icons-material/Clear';
import { NavLink } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { lighten, useTheme, styled } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';
import Logout from '../../authentification/logout';
import SideMenuListItem from '../sidemenu/sidemenulistitem';
import AccountConsole from '../../authentification/accountconsole';
import { mainRadius } from '../../../themes/theme';
import { PLATFORM, API_BASE_URL } from '../../../utils/env';
import { dataSettings, SidebarStatusProvider } from '../../../hooks/datatabs';
import MaintenanceBanner from '../banner/maintenancebanner';
import { useSidebarStatus } from '../../../hooks/localstorage';

const drawerWidth = 270;
const appBarHeight = '56px';

export const useMenuStyle = makeStyles((theme: Theme) => ({
  menuSectionSpliter: {
    marginLeft: '24px',
    marginRight: '24px',
    paddingTop: '0px',
    borderBottom: 'solid 1px #aaaaaa'
  },
  menuItem: {
    backgroundColor: 'transparent !important',
    padding: '10px',
    paddingLeft: '16px',
    marginLeft: '8px',
    marginRight: '8px',
    borderRadius: mainRadius,
    color: lighten(theme.palette.text.primary, 0.3),
    '&:hover': {
      color: `${lighten(theme.palette.text.primary, 0)  } !important`,
      backgroundColor: 'transparent !important'
    }
  }
}));

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'fixed',
    top: 0,
    zIndex: 98,
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    width: '100%',
    display: 'flex',
    boxShadow: 'none',
    height: appBarHeight
  },
  preprodChip: {
    padding: '0px 4px',
    backgroundColor: theme.palette.warning.dark,
    textTransform: 'uppercase'
  },
  defaultChip: {
    padding: '0px 4px',
    backgroundColor: lighten(theme.palette.primary.light, 0.2),
    textTransform: 'uppercase'
  },
  stagingChip: {
    padding: '0px 4px',
    backgroundColor: theme.palette.success.light,
    textTransform: 'uppercase'
  },
  grow: {
    flexGrow: 1
  },
  account: {
    position: 'fixed',
    zIndex: 99,
    right: theme.spacing(4),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    padding: '0px',
    marginTop: '12px',
    marginBottom: '12px',
    cursor: 'pointer',
    '&:hover': {
      color: lighten(theme.palette.primary.main, 0.2)
    }
  },
  accountBtn: {
    padding: '0px'
  },
  avatar: {
    width: '32px',
    height: '32px',
    fontSize: '16px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  title: {
    height: '32px',
    marginTop: '12px',
    marginBottom: '12px',
    padding: '4px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    overflowX: 'visible',
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `calc(${theme.spacing(12)} + 1px)`
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    whiteSpace: 'nowrap',
    display: 'flex'
  }
}));

function CustomMenu(props: MenuProps): ReactElement {
  return (
    <Menu
      elevation={0}
      anchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  );
}

export const StyledMenu = styled(CustomMenu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: mainRadius,
    backgroundColor: 'white',
    boxShadow: '0 1.3px 5.3px rgba(0, 0, 0, 0.048), 0 4.5px 17.9px rgba(0, 0, 0, 0.082), 0 20px 80px rgba(0, 0, 0, 0.14)',
    padding: 0,
    paddingTop: '0px',
    marginTop: '12px',
    minWidth: '220px',
    width: 'min-content'
  }
}));

interface LayoutProps {
    appTitle: string,
    darkTheme: boolean,
    onThemeChanged: () => void,
    children?: React.ReactNode
}

function Navbar( props : LayoutProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const settings = dataSettings();
  const menuClasses = useMenuStyle(theme);
  const { t } = useTranslation();
  const [status, setStatus] = useSidebarStatus();
  const [open, setOpen] = useState(status === 'open');
  const { keycloak } = useKeycloak();
  const [anchorEl, setAnchorEl] = useState<null | HTMLLIElement>(null);
  const [logo, setLogo] = useState(settings?.settings?.logo.find(myLogo => myLogo.type === 'appMain'));
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';
  const env = PLATFORM || '';

  useEffect(() => {
    setLogo(settings?.settings?.logo.find(myLogo => myLogo.type === 'appMain'));
  }, [settings]);

  useEffect(() => {
    if (open) {
      setStatus('open');
    } else {
      setStatus('close');
    }
  }, [open]);

  const handleChipColor = (myEnv: string) => {
    switch (myEnv){
    case 'preprod' :
      return 'preprodChip';
    case 'staging' :
      return 'stagingChip';
    default :
      return 'defaultChip';
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    Logout(keycloak);
  };
  const handleAccountAccess = () => {
    AccountConsole(keycloak);
  };

  const renderMenu = (
    <StyledMenu
      disableScrollLock
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MenuItem className={menuClasses.menuItem} onClick={handleAccountAccess} >
            {t('navbar.account.settings')}
            <ArrowForwardIosRoundedIcon style={{ height: '14px', marginLeft: 'auto' }} />
          </MenuItem>
        </Grid>
        <Grid item xs={12}>
          <MenuItem className={menuClasses.menuItem} onClick={handleLogout} >
            {t('navbar.account.signOut')}
            <ExitToAppIcon style={{ height: '20px', marginLeft: 'auto' }} />
          </MenuItem>
        </Grid>
      </Grid>
    </StyledMenu>
  );

  return (
    <SidebarStatusProvider value={open}>
      <Box className={classes.grow}>
        {settings?.maintenance?.programmed &&
          <MaintenanceBanner maintenance={settings.maintenance} />
        }
        <SideMenuListItem open={open} setOpen={setOpen} isMaintenance={settings?.maintenance?.programmed}/>
        <Box className={clsx(classes.content, { [classes.contentShift]: open })}>
          <Box className={classes.appBar} color='inherit' style={{ top: settings?.maintenance?.programmed ? '48px' : '' }}>
            <Grid container className={classes.title}>
              <Grid item>
                <NavLink to='/' className={classes.link}>
                  { logo &&
                    <>
                      <img
                        alt=''
                        src={`${API_BASE_URL}/api/v1/admin/platform${logo.filePath}`}
                        height='28'
                        style={{ paddingRight: '10px' }}
                      />
                      <ClearIcon color='inherit' />
                    </>
                  }
                  <img alt='' src={`${process.env.PUBLIC_URL  }/asset/logo/deecision.svg`} height='20' style={{ padding: '0px 12px', marginTop: '2px' }}/>
                </NavLink>
              </Grid>
              { env !== 'prod' &&
                <Grid item style={{ marginLeft: '8px' }}>
                  <Chip
                    label={
                      <Typography variant='h4' style={{ color: 'rgb(82, 82, 82)' }}>{env}</Typography>
                    }
                    size='small'
                    className={classes[handleChipColor(env)]}
                  />
                </Grid>
              }
            </Grid>
            <List className={classes.account} style={{ top: settings?.maintenance?.programmed ? '48px' : '0px' }}>
              <ListItem className={classes.accountBtn} onClick={handleProfileMenuOpen}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar} alt={`${keycloak.tokenParsed?.given_name}.${keycloak.tokenParsed?.family_name}`} src=''>
                    {`${keycloak.tokenParsed?.given_name?.charAt(0)}${keycloak.tokenParsed?.family_name?.charAt(0)}`}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ whiteSpace: 'nowrap', marginLeft: '-16px' }} primary={`${keycloak.tokenParsed?.given_name} ${keycloak.tokenParsed?.family_name}`} />
              </ListItem>
            </List>
            {renderMenu}
          </Box>
          <Container
            style={
              settings?.maintenance?.programmed ? {
                marginTop: `calc(${appBarHeight} + 48px + 12px)`,
                height: `calc(100% - ${appBarHeight} - 94px)`
              } :
                {
                  marginTop: `calc(${appBarHeight} + 12px)`,
                  height: `calc(100% - ${appBarHeight} - 48px)`,
                  overflowY: 'visible'
                }
            }
            maxWidth={false}
          >
            {props.children || <></> }
          </Container>
        </Box>
      </Box>
    </SidebarStatusProvider>
  );
}

export default Navbar;

