import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormGroup, List, ListItem, ListItemText, Theme } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Tag } from '../../../types/types';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    padding: '0 14px'
  },
  paper: {
    borderRadius: '25px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1.3px 5.3px rgba(0, 0, 0, 0.028), 0 4.5px 17.9px rgba(0, 0, 0, 0.042), 0 20px 80px rgba(0, 0, 0, 0.07)',
    padding: 0,
    paddingTop: '0px',
    marginTop: '12px',
    position: 'absolute',
    bottom: '40px',
    right: '8px',
    width: '300px',
    overflow: 'hidden',
    zIndex: 1401
  },
  overflowable: {
    width: '300px',
    height: '300px',
    overflowX: 'hidden',
    overflowY: 'scroll'
  },
  list: {
    position: 'relative',
    width: 'min-content',
    display: 'inline-block'
  },
  opened: {
    '&::before': {
      content: '"some content"',
      position: 'fixed',
      display: 'block',
      backgroundColor: 'transparent',
      height: '100vh',
      width: '100vw',
      top: 0,
      left: 0,
      zIndex: 1400
    }
  },
  button: {
    height: '30px',
    minWidth: 'min-content',
    borderRadius: '25px',
    color: lighten(theme.palette.text.primary, 0.3),
    borderColor: lighten(theme.palette.text.primary, 0.3),
    marginRight: '12px',
    padding: '2px 6px 2px 10px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: lighten(theme.palette.text.primary, 0),
      borderColor: lighten(theme.palette.text.primary, 0)
    },
    '&:focus': {
      borderColor: lighten(theme.palette.text.primary, 0.3)
    }
  }
}));

interface SelectCategoryProps {
	family: string,
	tags: string[],
	selectedTags: Tag[] | undefined,
	addTag: (tag: Tag) => void
	// handleAddTagFromList: (choosenTag: ChoosenTag) => void
}

function SelectCategory(props: SelectCategoryProps) : ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { family, tags, selectedTags, addTag } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [filteredList, setFilteredList] = useState(tags);

  const handleTagsToDisplay = (): void => {
    const newFilteredList = [...tags];
    selectedTags?.forEach( tag =>
      newFilteredList.splice(newFilteredList.indexOf(tag.label), 1)
    );
    setFilteredList(newFilteredList);
  };

  const handleListAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const choosenLabel = (e.currentTarget as HTMLElement).id;
    const choosenTag = {
      family,
      label: choosenLabel,
      value: choosenLabel
    };
    addTag(choosenTag);
    setIsOpen(false);
  };

  useEffect(
    handleTagsToDisplay, [selectedTags]
  );

  return (
    <Box
      id='outside'
      className={`${classes.list} ${isOpen ? classes.opened : ''}`}
      onClick={(e : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = (e.target as HTMLElement).id;
        if (isOpen && target === 'outside') {
          setIsOpen(false);
        }
      }}
    >
      <Button
        onClick={() => setIsOpen(true)}
        color='primary'
        variant='outlined'
        className={classes.button}
        style={{ paddingRight: '10px' }}
        size='small'
      >
        {family}
      </Button>
      { isOpen ?
        <FormGroup className={classes.paper}>
          { filteredList.length > 0 ?
            <List className={filteredList.length > 5 ? classes.overflowable : ''}>
              { filteredList?.map(tag => (
                <ListItem button key={tag} id={tag} onClick={e => handleListAction(e)}>
                  <ListItemText primary={tag} />
                </ListItem>
              ))}
            </List> :
            <ListItem>
              <ListItemText primary={t('dds.tabs.reports.graphs.noMoreTagsInFamily')}/>
            </ListItem>
          }
        </FormGroup>
        : null
      }
    </Box>
  );
}

export default SelectCategory;
