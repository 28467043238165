import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Snackbar, Alert } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { green, red } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import QuestionService from '../../services/questions';
import CustomButton from '../button/button';
import { DdElement } from '../../types/types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      right: '0px',
      padding: '0px',
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
);

function QuestionValidationButtons(props: {ddElement: DdElement, questionService: QuestionService, setUpdateList: Dispatch<React.SetStateAction<boolean>>, readonly?: boolean}): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [validateDisabled, setValidateDisabled] = useState(props.ddElement.answerStatus === 'validated' || props.ddElement.answerStatus === 'notAnswered' || props.readonly);
  const [rejectDisabled, setRejectDisabled] = useState(props.ddElement.answerStatus === 'rejected' || props.ddElement.answerStatus === 'notAnswered' || props.readonly);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setValidateDisabled(props.ddElement.answerStatus === 'validated' || props.ddElement.answerStatus === 'notAnswered' || props.readonly);
    setRejectDisabled(props.ddElement.answerStatus === 'rejected' || props.ddElement.answerStatus === 'notAnswered' || props.readonly);
  }, [props.ddElement]);

  const handleValidate = () => {
    setValidateDisabled(true);
    props.questionService.validate(props.ddElement._id)
      .then(() => {
        props.setUpdateList(true);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  const handleReject = () => {
    setRejectDisabled(true);
    props.questionService.reject(props.ddElement._id)
      .then(() => {
        props.setUpdateList(true);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <div key={`${props.ddElement._id  } validation`} className={classes.container}>
        <CustomButton
          variant='contained'
          style={{
            backgroundColor: validateDisabled ? green[50] : green[500],
            width: 'calc(50% - 6px)'
          }}
          startIcon={<CheckRoundedIcon />}
          onClick={handleValidate}
          disabled={validateDisabled}
        >
          {t('btns.validate')}
        </CustomButton>
        <CustomButton
          variant='contained'
          style={{
            backgroundColor: rejectDisabled ? red[50] : red[500],
            width: 'calc(50% - 6px)'
          }}
          startIcon={<ClearRoundedIcon />}
          onClick={handleReject}
          disabled={rejectDisabled }
        >
          {t('btns.reject')}
        </CustomButton>
      </div>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default QuestionValidationButtons;
