import React, { ReactElement } from 'react';
import { Answer, ObjectQuestionSpec, QuestionSpec, Nullable, ButtonsSettings } from '../../../types/types';
import QuestionBox from "../box";
import QuestionValue from "../value";
import QuestionLabel from '../label';
import ObjectFieldViewer from '../../field/viewers/objectfieldviewer';
import QuestionEditorButton from '../questioneditorbutton';

function ObjectQuestionViewer(props: {spec: QuestionSpec, answer: Nullable<Answer>, setAnswer?: (answer: Nullable<Answer>) => void, buttonsSettings?: ButtonsSettings, disableInput?: string}): ReactElement {
  const objectQuestionSpec = props.spec as ObjectQuestionSpec;
  const setValue = (value: Nullable<Answer['value']>) => {
    if (props.answer && props.setAnswer) {
      props.setAnswer({
        ...props.answer,
        value
      });
    }
  };
  
  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <ObjectFieldViewer spec={objectQuestionSpec} answer={props.answer} />
      </QuestionValue>
      {props.setAnswer && props.buttonsSettings &&
        <QuestionEditorButton response={props.answer?.value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} previousAnswer />
      }
    </QuestionBox>
  );
}

export default ObjectQuestionViewer;
