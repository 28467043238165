import React, { ReactElement, useState } from 'react';
import { Table, TableBody, TableContainer, TableRow } from '@mui/material';
import MultiTypeFieldViewer from './multitypefieldviewer';
import { Answer, BaseValueTypes, Nullable, QuestionSpec } from '../../../types/types';

function Datearrayfieldviewer(props: { spec: QuestionSpec, answer: Nullable<Answer> }): ReactElement {
  const answerValue = props.answer?.value as Array<BaseValueTypes>;
  const [value] = useState<Array<BaseValueTypes>>(answerValue);

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {value && value.map(rowValue => (
            <TableRow key={JSON.stringify(rowValue)}>
              <MultiTypeFieldViewer type='date' value={rowValue} />
            </TableRow>
          ))}
          <TableRow />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Datearrayfieldviewer;

