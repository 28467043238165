import { SearchParamsAccess } from '../params';

export interface PaginationListener {
  onPageChanged(newPage: number): void,
  onPageSizeChanged(newSize: number): void
}

/**
 * This class is in charge of reading and updating the params via the SearchParamsAccess. It knows what params to use
 * for the page and pageSize.
 */
export class ParamsPager implements PaginationListener {
  searchParamsAccess: SearchParamsAccess;

  pageParam: string;

  pageSizeParam: string;

  itemsPerPageMin?: number;

  itemsPerPageMax?: number;

  constructor(searchParamsAccess: SearchParamsAccess, itemsPerPageMin?: number, itemsPerPageMax?: number, pageParam = 'page', pageSizeParam = 'pageSize') {
    this.searchParamsAccess = searchParamsAccess;
    this.pageParam = pageParam;
    this.pageSizeParam = pageSizeParam;
    this.itemsPerPageMin = itemsPerPageMin;
    this.itemsPerPageMax = itemsPerPageMax;
  }

  getDefaultPage(): number {
    return 0;
  }

  getPage(): number {
    return this.searchParamsAccess.getIntParam(this.pageParam, this.getDefaultPage());
  }

  getDefaultPageSize(): number {
    return this.itemsPerPageMin || 20;
  }

  getPageSizeOptions(): Array<number> {
    const itemsPerPageMin = this.getDefaultPageSize();

    const optionList = [
      itemsPerPageMin,
      itemsPerPageMin * 2,
      itemsPerPageMin * 3,
      itemsPerPageMin * 4,
      itemsPerPageMin * 5,
      itemsPerPageMin * 10
    ];

    return this.itemsPerPageMax ? optionList.filter(nbItems => nbItems <= (this.itemsPerPageMax || (nbItems - 1))) : optionList;
  }

  getPageSize(): number {
    return this.searchParamsAccess.getIntParam(this.pageSizeParam, this.getDefaultPageSize());
  }

  onPageChanged(newPage: number) : void {
    this.searchParamsAccess.updateParam(this.pageParam, `${newPage}`);
  }

  onPageSizeChanged(newSize: number) : void {
    this.searchParamsAccess.updateParams([
      [this.pageParam, "0"], // TODO: compute the right page to display the first item
      [this.pageSizeParam, `${newSize}`]
    ]);
  }
}

/* ????????????????? */
// export interface Pageable {
//   getPage(): number;
//   getItemsPerPage(): number;
// }
