import React, { ReactElement } from 'react';
import {
  AmountType,
  BaseValueTypes,
  NumberQuestionSpec, PercentQuestionSpec,
  QuestionSpec,
  SelectQuestionSpec,
  ValueSetter
} from '../../../types/types';
import StringFieldEditor from './stringfieldeditor';
import CurrencyFieldEditor from './currencyfieldeditor';
import DateFieldEditor from './datefieldeditor';
import TextFieldEditor from './textfieldeditor';
import NumberFieldEditor from './numberfieldeditor';
import CountryFieldEditor from './countryfieldeditor';
import BooleanFieldEditor from './booleanfieldeditor';
import SelectFieldEditor from './selectfieldeditor';
import AmountFieldEditor from './amountfieldeditor/amountfieldeditor';
import PercentFieldEditor from './percentfieldeditor';
import MultiSelectFieldEditor from './mulltiselectfieldeditor';
import YearFieldEditor from './yearfieldeditor';

export default function MultiTypeFieldEditor(props: { type: string, index?: number, specs?: QuestionSpec, value?: BaseValueTypes, setValue: ValueSetter, customKey?: string, lastValue?: BaseValueTypes }): ReactElement {
  switch (props.type) {
  case 'string':
    return (
      <StringFieldEditor value={props.value as string} setValue={props.setValue} required={props.specs?.required} customKey={props.customKey}/>
    );
  case 'amount':
    return (
      <AmountFieldEditor value={props.value as AmountType} setValue={props.setValue} required={props.specs?.required} customKey={props.customKey}/>
    );
  case 'number':
    return (
      <NumberFieldEditor specs={props.specs as NumberQuestionSpec} value={props.value as number} setValue={props.setValue} customKey={props.customKey}/>
    );
  case 'percent':
    return (
      <PercentFieldEditor specs={props.specs as PercentQuestionSpec} value={props.value as number} setValue={props.setValue} customKey={props.customKey}/>
    );
  case 'currency':
    return (
      <CurrencyFieldEditor value={props.value as string} setValue={props.setValue} required={props.specs?.required} customKey={props.customKey}/>
    );
  case 'date':
    return (
      <DateFieldEditor value={props.value as string} setValue={props.setValue} required={props.specs?.required} customKey={props.customKey}/>
    );
  case 'text':
    return (
      <TextFieldEditor value={props.value as string} setValue={props.setValue} required={props.specs?.required} customKey={props.customKey}/>
    );
  case 'country':
    return (
      <CountryFieldEditor value={props.value as string} setValue={props.setValue} required={props.specs?.required} customKey={props.customKey}/>
    );
  case 'boolean':
    return (
      <BooleanFieldEditor value={props.value as boolean} setValue={props.setValue} required={props.specs?.required} customKey={props.customKey}/>
    );
  case 'yearCountDown':
    return (
      <>
        { props.index !== undefined && <YearFieldEditor index={props.index} value={props.value as number} setValue={props.setValue} lastValue={props.lastValue as number}/>}
      </>
    );
  case 'select':
    return (
      <>
        { props.specs && <SelectFieldEditor specs={props.specs as SelectQuestionSpec} value={props.value as string} setValue={props.setValue} customKey={props.customKey}/>}
      </>
    );
  case 'multiselect':
    return (
      <>
        { props.specs && <MultiSelectFieldEditor specs={props.specs as SelectQuestionSpec} value={props.value as string[]} setValue={props.setValue} customKey={props.customKey}/>}
      </>
    );
  default:
    return (<></>);
  }
}
