import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import Aums from '../../../../components/aum/aums';
import DdParticipants from './participants/ddParticipants';
import { dataContext, setUpdateHook } from '../../../../hooks/datatabs';
import AumService from '../../../../services/aum';

function Parameters(): ReactElement {
  const dd = dataContext()?.dueDiligence;
  const ddUpdate = setUpdateHook();
  const aumService = dd ? new AumService(dd.auditedEntity.type || '', dd.auditedEntity.id) : undefined;

  return (
    <Grid container spacing={2}>
      {dd && aumService &&
        <Grid item xs={12}>
          <Aums name={dd.auditedEntity.name} aumService={aumService} aum={dd.auditedEntity.aum} update={() => ddUpdate(true)} />
        </Grid>
      }
      <Grid item xs={12}>
        <DdParticipants />
      </Grid>
    </Grid>
  );
}

export default Parameters;
