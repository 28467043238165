import React, { ReactElement } from "react";
import Typography from '@mui/material/Typography';

function PercentFieldViewer(props: {value?: string}) : ReactElement {
  return (
    <Typography variant='body1' component='p'>
      {props.value && (parseFloat(props.value) * 100).toFixed(2)}%
    </Typography>
  );
}
export default PercentFieldViewer;
