import React, { ReactElement } from 'react';
import Keycloak  from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientTokens } from '@react-keycloak/core/lib';
import { API } from '../../api/api';

function Authenticate(props: {children: Array<ReactElement> | ReactElement}): ReactElement {
  const keycloak = new Keycloak('/deeligenz/keycloak.json');

  const onTokens = (tokens: AuthClientTokens) => {
    API.defaults.headers.common.Authorization = `bearer ${tokens.token}`;
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
      onTokens={onTokens}
    >
      {props.children}
    </ReactKeycloakProvider>
  );
}

export default Authenticate;
