import { useState, useEffect, SetStateAction, Dispatch } from 'react';

export interface LocalStorage {
  localStorageV1: {
    terms?: {
      accepted?: boolean
    },
    settings?: {
      theme?: {
        sidebar?: {
          status?: 'open' | 'close'
        }
      }
    }
  }
}

const localStorageDefaultValue: LocalStorage = {
  localStorageV1: {
    terms: {
      accepted: false
    },
    settings: {
      theme: {
        sidebar: {
          status: 'close'
        }
      }
    }
  }
};

function getStorageValue(key: string, defaultValue: LocalStorage) {
  const saved = localStorage.getItem(key);

  return saved ? JSON.parse(saved) : defaultValue;
}

const useLocalStorage: () => [value: LocalStorage, setValue: Dispatch<SetStateAction<LocalStorage>>] = () => {
  const [value, setValue] = useState<LocalStorage>(() => getStorageValue('deeligenz', localStorageDefaultValue));

  useEffect(() => {
    localStorage.setItem('deeligenz', JSON.stringify(value));
  }, [value]);

  return [value, setValue];
};

export const useTermsStatus: () => [accepted: boolean, setAccepted: Dispatch<SetStateAction<boolean>>] = () => {
  const [value, setValue] = useLocalStorage();
  const [accepted, setAccepted] = useState(value.localStorageV1.terms?.accepted || false);

  useEffect(() => {
    value.localStorageV1 = {
      ...value.localStorageV1,
      terms: {
        accepted
      }
    };
    setValue({ ...value });
  }, [accepted]);

  return [accepted, setAccepted];
};

export const removeTerms: () => void = () => {
  const value = getStorageValue('deeligenz', localStorageDefaultValue);

  value.localStorageV1 = {
    ...value.localStorageV1,
    terms: {
      accepted: false
    }
  };

  localStorage.setItem('deeligenz', JSON.stringify(value));
};

export const useSidebarStatus: () => [status: 'open' | 'close', setStatus: Dispatch<SetStateAction<'open' | 'close'>>] = () => {
  const [value, setValue] = useLocalStorage();
  const [status, setStatus] = useState(value.localStorageV1.settings?.theme?.sidebar?.status || 'open');

  useEffect(() => {
    value.localStorageV1 = {
      ...value.localStorageV1,
      settings: {
        theme: {
          sidebar: {
            status
          }
        }
      }
    };
    setValue({ ...value });
  }, [status]);

  return [status, setStatus];
};

export default useLocalStorage;
