import React, { Dispatch, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Typography } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import makeStyles from '@mui/styles/makeStyles';
import * as _ from 'lodash';
import { StyledMenu } from '../layout/navbar/navbar';
import { Tag, TagsFamily } from '../../types/types';

interface TagsProps {
  tagsFamilies: TagsFamily[],
  tags: Tag[],
  setTags: Dispatch<React.SetStateAction<Tag[]>>,
  editMode: boolean,
  tagsDirection?: 'vertical' | 'horizontal'
}

const useStyles = makeStyles({
  accordionTitle: {
    margin: '0px !important'
  }
});

function Tags(props: TagsProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tags, setTags] = useState(props.tags);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState(['']);
  const myRef = useRef(null);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    setTags(props.tags);
  }, [props.tags]);

  useEffect(() => {
    setTags(props.tags);
  }, [props.editMode]);

  const handleAdd = (tag: Tag) => {
    props.setTags(oldTags => [...oldTags, tag]);
  };

  const handleRemove = (tagValue: string) => {
    props.setTags(tags.filter(item => item.value !== tagValue));
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <StyledMenu
      disableScrollLock
      anchorEl={anchorEl ? myRef.current : null}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Grid container spacing={0}>
        { props.tagsFamilies.map(family => (
          <Grid key={family.family} item xs={12}>
            <Accordion
              key={expanded.toString()}
              elevation={0}
              expanded={expanded.includes(family.family)}
              style={{ backgroundColor: 'transparent' }}
              onChange={() => (expanded.includes(family.family) ?
                setExpanded(expanded.filter(x => x !== family.family)) :
                setExpanded(old => [...old, family.family]))
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />} style={{ minHeight: '24px', height: '40px' }} classes={{ content: classes.accordionTitle }}>
                <Typography variant='h3'>
                  {family.family}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  { family.tags.map((familyTag) => {
                    if (!_.some(tags, familyTag)) {
                      return (
                        <Grid key={familyTag.value} item xs={12}>
                          <Chip
                            style={family.color ? { backgroundColor: family.color } : undefined}
                            label={familyTag.label}
                            size='small'
                            onClick={() => handleAdd(familyTag)}
                            onDelete={() => handleAdd(familyTag)}
                            deleteIcon={<AddCircleRoundedIcon />}
                          />
                        </Grid>
                      );
                    }

                    return undefined;
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
        }
      </Grid>
    </StyledMenu>
  );

  return (
    <Grid container spacing={1}>
      {props.tagsFamilies.map((family) => {
        const familyTags = family.tags.map((tag) => {
          if (props.tags.filter(item => item === tag)) {
            return (tag);
          }

          return undefined;
        });

        if (familyTags.length > 0) {
          const theseFamilyTags: Tag[] = tags.filter(tag => (tag && tag.family === family.family));
          if (theseFamilyTags.length > 0) {
            return (
              <Grid key={family.family} item xs={props.tagsDirection === 'vertical' ? 12 : undefined} justifyContent='flex-start'>
                <Grid container spacing={1}>
                  {theseFamilyTags.map(tag => (
                    <Grid key={tag.value} item>
                      <Chip
                        key={tag.value}
                        style={family.color ? { backgroundColor: family.color } : undefined}
                        label={tag.label}
                        size='small'
                        onDelete={props.editMode ? () => handleRemove(tag.value) : undefined}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            );
          }
        }

        return undefined;
      })}
      { props.editMode &&
        <Grid item xs={props.tagsDirection === 'vertical' ? 12 : true}>
          <Chip
            ref={myRef}
            variant='outlined'
            label={t('tags.add')}
            onClick={handleMenuOpen}
            onDelete={handleMenuOpen}
            deleteIcon={<AddCircleRoundedIcon />}
            size='small'
          />
          {renderMenu}
        </Grid>
      }
    </Grid>
  );
}

export default Tags;
