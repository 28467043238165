import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { green, red } from '@mui/material/colors';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CustomButton from '../../../../components/button/button';
import { DdValidationItem } from '../../../../types/types';
import { useStyles } from '../../../../components/ddelement/listitem';
import Rating from '../../../../components/ratings/rating';
import RatingScoreTendancy from '../../../../components/reports/fields/RatingScoreTendancy';

const useValidationStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    textOverflowX: 'ellipsis',
    width: 'calc(100% - 120px)',
    '&:hover': {
      overflow: 'auto',
      textOverflow: 'clip'
    }
  },
  container: {
    right: '0px',
    padding: '0px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  buttonLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  root: {
    backgroundColor: 'white',
    borderRadius: '24px',
    padding: '16px',
    marginBottom: '16px'
  },
  'validate-button': {
    textTransform: 'capitalize',
    backgroundColor: green[500],
    height: '50px',
    width: 'calc(50% - 6px)',
    '&:hover': {
      backgroundColor: green[400]
    }
  },
  'reject-button': {
    textTransform: 'capitalize',
    backgroundColor: red[500],
    height: '50px',
    width: 'calc(50% - 6px)',
    '&:hover': {
      backgroundColor: red[400]
    }
  }
}));

export interface DdValidationItemRowProps {
	validationItem: DdValidationItem,
	statusAction: (viewId: string, status: string) => void
}

export default function DdValidationItemRow(props: DdValidationItemRowProps): ReactElement {
  const classes = useValidationStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const paperClasses = useStyles(theme);
  const [validationDisabled, setValidationDisabled] = useState(true);
  const [rejectDisabled, setRejectDisabled] = useState(true);
  const { validationItem, statusAction } = props;

  let backgroundColor;

  switch (validationItem.status) {
  case 'validated' :
    backgroundColor = 'rgba(124, 179, 66, 0.175)';
    break;
  case 'rejected' :
    backgroundColor = 'rgba(229, 57, 53, 0.175)';
    break;
  default :
    backgroundColor = 'rgba(157, 157, 157, 0.175)';
  }

  useEffect(() => {
    if (validationItem.status === 'validated') {
      setRejectDisabled(false);
    } else if (validationItem.status === 'rejected') {
      setValidationDisabled(false);
    } else {
      setValidationDisabled(false);
      setRejectDisabled(false);
    }
  }, [validationItem.status]);

  const handleSubmit = (viewId: string, status: string) => {
    setValidationDisabled(true);
    setRejectDisabled(true);
    statusAction(viewId, status);
  };

  return (
    <Grid item xs={12} id={validationItem._id}>
      <Paper elevation={0} className={paperClasses.paper} style={{ backgroundColor }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={0} style={{ paddingLeft: '8px', paddingTop: '4px', overflow: 'hidden' }}>
              <Grid item xs>
                {validationItem.url ?
                  <Link to={validationItem.url?.split('/deeligenz')[1]} className={classes.link} style={{ display: 'flex' }}>
                    <Typography className={paperClasses.title} variant='body1' component='h6' gutterBottom style={{ marginTop: '-6px' }}>
                      {`${validationItem.details.ratingFieldLabel}`}
                    </Typography>
                  </Link> :
                  <Typography variant='h6' component='h6' gutterBottom>{validationItem.title}</Typography>
                }
              </Grid>
              <Grid item style={{ marginRight: '12px', marginTop: '8px' }}>
                <RatingScoreTendancy previousValue={validationItem.details.previousValue} currentValue={validationItem.details.newValue} />
              </Grid>
              <Grid item>
                <Rating rank={validationItem.details.newValue?.toString()} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={paperClasses.breadcrumbs} style={{ marginTop: '-16px' }} variant='body2'>
                  {`${validationItem.details.ratingBlockLabel}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} style={{ height: 'max-content' }}>
              <Grid item xs>
                <Paper elevation={0} className={paperClasses.paper} style={{ position: 'relative', height: '100%' }}>
                  <Box style={{ overflow: 'auto' }}>
                    <Box style={{ padding: '12px', marginBottom: '12px' }}>
                      <Typography
                        variant='h2'
                        color='textSecondary'
                        gutterBottom
                        className={classes.title}
                      >
                        {validationItem.title}
                      </Typography>
                    </Box>
                    <Box style={{ paddingLeft: '12px', paddingRight: '12px', paddingBottom: '12px' }}>
                      <Typography variant='body2' style={{ whiteSpace: 'pre-line' }}>
                        {validationItem.description}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={0} className={paperClasses.paper} style={{ position: 'relative', height: '100%', width: 'min-content' }}>
                  <Grid container direction='column'>
                    <Grid item style={{ minWidth: '300px', width: 'min-content', marginLeft: 'auto' }}>
                      <div className={classes.container}>
                        <CustomButton
                          variant='contained'
                          className={classes['validate-button']}
                          startIcon={<CheckRoundedIcon />}
                          onClick={() => handleSubmit(validationItem._id, 'validated')}
                          disabled={validationDisabled}
                        >
                          {t('btns.validate')}
                        </CustomButton>
                        <CustomButton
                          variant='contained'
                          className={classes['reject-button']}
                          startIcon={<ClearRoundedIcon />}
                          onClick={() => handleSubmit(validationItem._id, 'rejected')}
                          disabled={rejectDisabled}
                        >
                          {t('btns.reject')}
                        </CustomButton>
                      </div>
                    </Grid>
                    {validationItem.url &&
                      <Grid item style={{ minWidth: '300px', width: 'min-content', marginLeft: 'auto', marginTop: '16px' }}>
                        <Link to={validationItem.url?.split('/deeligenz')[1]} className={classes.buttonLink}>
                          <CustomButton
                            variant='outlined'
                            startIcon={<VisibilityRoundedIcon />}
                            color='primary'
                            fullWidth
                          >
                            {t('btns.view')}
                          </CustomButton>
                        </Link>
                      </Grid>
                    }
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
