import React, { ReactElement, useState } from 'react';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import QuestionBox from '../box';
import QuestionLabel from '../label';
import QuestionValue from '../value';
import { BaseValueTypes, QuestionEditorProps, Value, CurrencyArrayQuestionSpec } from '../../../types/types';
import QuestionEditorButton from '../questioneditorbutton';
import MultiTypeFieldEditor from '../../field/editors/multitypefieldeditor';

function CurrencyArrayQuestionEditor(props: QuestionEditorProps<CurrencyArrayQuestionSpec>) : ReactElement {
  const [value, setValue] = useState<Value>(props.answer?.value || []);

  const baseInfo = '';

  const addObject = () => setValue([...(value as Array<BaseValueTypes>), baseInfo]);

  const deleteObject = (index: number) => {
    if ((value as Array<BaseValueTypes>).length > 1) {
      setValue((value as Array<BaseValueTypes>).filter((rowValue, rowIndex) => rowIndex !== index));
    } else {
      setValue([]);
    }
  };

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <Table>
          <TableBody>
            {value && (value as Array<BaseValueTypes>).map((rowValue, rowIndex) => (
              <TableRow key={rowIndex.toString()} style={{ display: 'flex', flexDirection: 'column' }}>
                <TableCell style={{ border: 'none', padding: 0 }}>
                  <MultiTypeFieldEditor
                    type='currency'
                    value={rowValue}
                    setValue={(v) => {
                      const currencyArray = (value as Array<BaseValueTypes>).slice();
                      currencyArray[rowIndex] = v as BaseValueTypes;
                      setValue(currencyArray);
                    }} />
                </TableCell>
                <TableCell style={{ border: 'none', padding: 0 }}>
                  <Button onClick={() => deleteObject(rowIndex)}><DeleteForeverIcon/></Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell style={{ border: 'none' }}>
                <Button onClick={addObject}>Add Row</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value || []} buttonsSettings={props.buttonsSettings} />
      </CardActions>
    </QuestionBox>
  );
}
export default CurrencyArrayQuestionEditor;
