import React, { ReactElement, useState } from 'react';
import { Grid, Snackbar, Typography, Alert } from '@mui/material';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import { useTranslation } from 'react-i18next';
import AuditedEntitiesService from '../../services/auditedentities';
import ServiceError from '../../services/errors';
import CustomButton from '../button/button';

function NextReviewDate(props: {nextReviewDate?: string, service: AuditedEntitiesService, update: () => void}): ReactElement {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const recalculate = () => {
    setDisabled(true);

    props.service.recalculateNextReviewDate()
      .then((apiResp) => {
        if (apiResp) {
          props.update();
        }
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
        setDisabled(false);
      });
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>{t('dds.tabs.parameters.nextReviewDate.label')}</Typography>
        </Grid>
        {props.nextReviewDate &&
          <>
            <Grid item>
              <EventRoundedIcon fontSize='small' />
            </Grid>
            <Grid item style={{ marginLeft: '-6px' }}>
              <Typography variant='body1'>{props.nextReviewDate}</Typography>
            </Grid>
          </>
        }
        <Grid item>
          <CustomButton
            variant='contained'
            onClick={recalculate}
            disabled={disabled}
            small
          >
            {t('dds.tabs.parameters.nextReviewDate.recalculate')}
          </CustomButton>
        </Grid>
      </Grid>

      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default NextReviewDate;
