import React, { ReactElement, useState } from 'react';
import CardActions from '@mui/material/CardActions';
import AmountFieldEditor from '../../field/editors/amountfieldeditor/amountfieldeditor';
import QuestionBox from '../box';
import QuestionValue from '../value';
import { QuestionEditorProps, Value, AmountQuestionSpec } from '../../../types/types';
import QuestionEditorButton from "../questioneditorbutton";
import QuestionLabel from '../label';

function AmountQuestionEditor(props: QuestionEditorProps<AmountQuestionSpec>): ReactElement {
  const [value, setValue] = useState<Value>(props.answer?.value);

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec} />
      <QuestionValue>
        <AmountFieldEditor
          value={value}
          setValue={setValue}
        />
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} />
      </CardActions>
    </QuestionBox>
  );
}

export default AmountQuestionEditor;
