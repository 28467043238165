import React, { ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Editor from 'rich-markdown-editor';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper, Snackbar, Typography, Alert, Theme } from '@mui/material';
import { SaveRounded } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { CustomIconButton } from '../../button/button';
import ServiceError from '../../../services/errors';
import QuestionService from '../../../services/questions';
import { mainColor, mainRadius } from '../../../themes/theme';
import DispatchQuestionViewer from '../../question/viewers/dispatchquestionviewer';
import { DdElement, SurveyElement } from '../../../types/types';
import { light } from '../../reports/customEditorTheme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '12px',
      marginBottom: '12px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '0.9',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out'
      }
    },
    paper2: {
      padding: '8px',
      margin: '12px 12px 0px 12px',
      width: '100%',
      backgroundColor: 'rgba(167, 167, 167, 0.15)',
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '1'
    },
    paper3: {
      padding: '8px',
      margin: '12px 12px 0px 12px',
      width: '100%',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '1',
      border: 'solid 1px',
      borderColor: 'rgba(167, 167, 167, 0.59)'
    }
  }));

function DisplayQuestion(props: {ddElement: DdElement | SurveyElement, update: () => void, isAuditor?: boolean}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [meetingRemarks, setMeetingRemarks] = useState((props.ddElement as DdElement).meeting?.remarks);
  const [editRemarks, setEditRemarks] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const ddQuestionService = new QuestionService();
  let answer;

  if (props.isAuditor) {
    const answersTemp = (props.ddElement as DdElement).answers;
    answer = (props.ddElement as DdElement).answers.auditor ?
      answersTemp.auditor :
      (answersTemp.auditee && !answersTemp.auditee.draft && answersTemp.auditee) || null;
  } else {
    answer = (props.ddElement as SurveyElement).answer || null;
  }

  useEffect(() => {
    setMeetingRemarks((props.ddElement as DdElement).meeting?.remarks);
  }, [props.ddElement]);

  const handleComment = () => {
    ddQuestionService.setMeetingRemarks(props.ddElement._id, meetingRemarks || '')
      .then(() => {
        props.update();
      })
      .catch((err) => {
        setSnackbarMessage(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={2}>
          <Paper elevation={0} className={classes.paper2} style={{ marginBottom: props.isAuditor ? '0' : '12px' }}>
            <Grid item xs={12}>
              <DispatchQuestionViewer
                spec={props.ddElement.question.spec}
                answer={answer}
                id={props.ddElement._id}
                meetingAuditor={props.isAuditor}
              />
            </Grid>
          </Paper>
          { (props.ddElement as DdElement).evaluation?.meeting?.remarks && props.isAuditor &&
            <Paper elevation={0} className={classes.paper2}>
              <Grid item xs={12} style={{ padding: '18px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='body1'>{t('dds.tabs.meetings.details.evaluationRemarks')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {(props.ddElement as DdElement).evaluation?.meeting?.remarks}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          }
          { props.isAuditor &&
          <Paper elevation={0} className={classes.paper3} style={{ marginBottom: '12px' }}>
            <Grid item xs={12} style={{ padding: '18px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body1'>{t('dds.tabs.meetings.details.meetingRemarks')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Editor
                        style={{
                          marginTop: '8px',
                          borderBottom: editRemarks ? `solid 2px ${mainColor}` : undefined
                        }}
                        defaultValue={meetingRemarks}
                        onChange={(value) => {
                          const mayValue = value();
                          setMeetingRemarks(mayValue);
                        }}
                        theme={light}
                        readOnly={!editRemarks}
                      />
                    </Grid>
                    {editRemarks ?
                      <>
                        <Grid item>
                          <CustomIconButton
                            variant='contained'
                            small
                            onClick={() => {
                              handleComment();
                              setEditRemarks(false);
                            }}
                          >
                            <SaveRounded />
                          </CustomIconButton>
                        </Grid>
                        <Grid item>
                          <CustomIconButton
                            color='secondary'
                            variant='contained'
                            small
                            onClick={() => {
                              setMeetingRemarks((props.ddElement as DdElement).meeting?.remarks);
                              setEditRemarks(false);
                            }}
                          >
                            <CloseRoundedIcon />
                          </CustomIconButton>
                        </Grid>
                      </>
                      :
                      <>
                        <Grid item>
                          <CustomIconButton color='primary' variant='contained' small onClick={() => setEditRemarks(true)}>
                            <EditRoundedIcon />
                          </CustomIconButton>
                        </Grid>
                      </>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          }
        </Grid>
      </Paper>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DisplayQuestion;
