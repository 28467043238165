import axios from 'axios';
import { DdReport } from '../../types/types';
import { API_BASE_URL } from '../../utils/env';
import AccessTokenService from '../../services/accesstoken';

export class ReportHelper {
  static getHTMLReportURL(ddId: string, report: DdReport, token: string, type?: string): string {
    const baseURL = API_BASE_URL?.replace('/api/v1', '');

    return axios.getUri({ url: `${baseURL  }/reports/generate`, params: { ddId, template: report.templateId, token, type: type || 'dd' } });
  }

  static getPDFReportURL(params: { [key: string]: string }): string {
    const baseURL = API_BASE_URL?.replace('/api/v1', '');

    return axios.getUri({ url: `${baseURL  }/reports/downloadPDF`, params });
  }

  static downloadPDFReportByDdReportId(id: string, type?: string): void {
    const accessTokenService = new AccessTokenService();

    accessTokenService.get(id, type)
      .then((res) => {
        window.open(
          this.getPDFReportURL({ ddReportId: id, token: res.data?.token || '' }),
          '_blank'
        );
      })
      .catch(e => console.log(e));
  }

  static downloadPDFReportByDocId(id: string, type?: string): void {
    const accessTokenService = new AccessTokenService();

    accessTokenService.get(id, type)
      .then((res) => {
        window.open(
          this.getPDFReportURL({ docId: id, token: res.data?.token || '' }),
          '_blank'
        );
      })
      .catch(e => console.log(e));
  }
}

export default ReportHelper;
