import { AxiosError } from 'axios';

class ServiceError extends Error {
  httpStatus: number;

  name: string;

  extra?: unknown;

  constructor(httpStatus: number, message: string, extra?: unknown) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.httpStatus = httpStatus;
    this.name = this.constructor.name;
    this.extra = extra;
  }

  static ensureServiceError(err: AxiosError | ServiceError): ServiceError {
    if (err instanceof ServiceError) {
      return (err);
    }

    return (new ServiceError(err.response?.status || 999, (err.response?.data as { data: string })?.data || (err.response?.data as { error: string })?.error || err.message));
  }

  static getErrorMsg(err: AxiosError | ServiceError): string {
    if (err instanceof ServiceError) {
      return (`${err.httpStatus} : ${err.message}`);
    }

    return (this.getErrorMsg(this.ensureServiceError(err)));
  }

  isAccessDenied(): boolean {
    return this.httpStatus === 403;
  }

  isUnAuthenticated(): boolean {
    return this.httpStatus === 401;
  }

  isNotFound(): boolean {
    return this.httpStatus === 404;
  }

  isDataFound(): boolean {
    return this.httpStatus === 200 && !this.extra;
  }

  isDataError(): boolean {
    return this.httpStatus === 200 && !!this.extra;
  }
}

export default ServiceError;
