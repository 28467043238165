import React, { ReactElement } from 'react';
import { FilterParamsManager } from '../../../components/entity/list';
import GenericFilters from '../../../components/filters/filters';
import { ApiFilter, ParamFilter } from '../../../types/types';
import { DdService } from '../../../services/dds';

function DdFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const filters: Array<ParamFilter> = props.apiFilters ?
    props.apiFilters.map(filter => (
      { id: filter.value, type: 'multiSelect', label: filter.label, values: filter.elements }
    ))
    :
    [
      { id: 'auditedEntity.type', type: 'multiSelect', label: 'Audited Entity', values: [{ label: 'strategy' }] },
      { id: 'type', type: 'multiSelect', label: 'Type', values: [{ label: 'mdd' }, { label: 'imported-mdd' }, { label: 'mdd-review' }] },
      { id: 'status', type: 'multiSelect', label: 'Status', values: [{ label: 'creating' }, { label: 'created' }, { label: 'imported' }, { label: 'validated' }, { label: 'started' }] }
    ];

  filters.push({ id: 'latest', type: 'boolean', label: 'Latest Completed' });
  if (!filters.find(filter => filter.id === 'name')) {
    filters.push({
      id: 'name',
      type: 'search',
      label: 'Search',
      autoComplete: query => new DdService().getSearchList(query)
    });
  }

  return (<GenericFilters filters={filters} filterParamsManager={props.filterParamsManager} />);
}

export default DdFilters;
