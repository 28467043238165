import Keycloak from 'keycloak-js';
import { Participant } from '../../types/types';

function checkRoles(rolesToCheck: string[], keycloak: Keycloak): boolean {

  return rolesToCheck.map(role => keycloak.hasResourceRole(role)).some(item => item);
}

export function checkAllRoles(rolesToCheck: string[], keycloak: Keycloak): boolean {

  return rolesToCheck.map(role => keycloak.hasResourceRole(role)).every(item => item);
}

export function checkAccessToDd(rolesToCheck: string[], ddParticipants: Participant[], participantId: string): boolean {

  const participantRoles = ddParticipants.filter(participant => participant.id === participantId).flatMap(participant => participant.roles);

  return participantRoles.some(role => rolesToCheck.includes(role));
}

export default checkRoles;
