import React, { ReactElement, useEffect } from 'react';
import { dataContext } from '../../../../hooks/datatabs';
import ReportsRouter from '../../../../router/reportsrouter';

function DdReports(): ReactElement {
  const dd = dataContext();
  const [readonly, setReadonly] = React.useState(false);

  useEffect(() => {
    if (dd && dd.dueDiligence) {

      dd.dueDiligence.lifecycle?.activities.forEach((activity) => {
        if (activity.id === 'report') {
          if (activity.status === 'started' || activity.status === 'reopened') {
            setReadonly(false);
          } else {
            setReadonly(true);
          }
        }
      });
    }
  }, [dd]);

  return (
    <ReportsRouter readonly={readonly} />
  );
}

export default DdReports;
