import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';

export default function StringFieldViewer(props: {value?: string}): ReactElement {
  return (
    <div>
      <Typography variant='body1' component='p'>
        {props.value}
      </Typography>
    </div>
  );
}
