import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import UploadFiles from '../../../upload/uploadfiles';
import { DdGraphUploadService } from '../../../../services/dds';
import DisplayGraphLogic, { BaseGraphFieldProps } from './displaygraphlogic';
import { TagsFamily } from '../../../../types/types';
import { MultiGraphFieldData } from './ReportBlockGraphField';

const tagsForFunds = [
  {
    family: 'funds',
    label: 'Alger Capital Appreciation',
    value: 'algerCapitalAppreciation'
  },
  {
    family: 'funds',
    label: 'Aberdeen Standard SICAV I',
    value: 'aberdeenStandardSICAV'
  },
  {
    family: 'funds',
    label: 'BlackRock Strategic Funds - Fixed Income Strategies Fund',
    value: 'blackRockStrategicFunds-FixedIncomeStrategiesFund'
  },
  {
    family: 'funds',
    label: 'Fidelity Funds - Global Technology Fund',
    value: 'fidelityFunds-GlobalTechnologyFund'
  },
  {
    family: 'funds',
    label: 'Pzena Focused Value',
    value: 'PzenaFocusedValue'
  }
];

const tagsForManagers = [
  {
    family: 'managers',
    label: 'BlackRock (Luxembourg) SA',
    value: 'blackRock(Luxembourg)SA'
  },
  {
    family: 'managers',
    label: 'Comgest Asset Management Intl Ltd',
    value: 'comgestAssetManagementIntlLtd'
  },
  {
    family: 'managers',
    label: 'Fred Alger Management, LLC',
    value: 'Fred Alger Management, LLC'
  },
  {
    family: 'managers',
    label: 'FundRock Management Company S.A.',
    value: 'FundRockManagementCompanyS.A.'
  },
  {
    family: 'managers',
    label: 'Sands Capital Management, LLC',
    value: 'SandsCapitalManagementLLC'
  }
];

const tagsFamilies = [
  {
    'family': 'funds',
    'color': 'yellow',
    'tags': tagsForFunds
  },
  {
    'family': 'managers',
    'color': 'orange',
    'tags': tagsForManagers
  }
];

// SELECTION CHIPS LIST FOR MY LIST
const tagsList: TagsFamily[] = [
  {
    'family': 'funds',
    'color': 'yellow',
    'tags': [
      {
        family: 'funds',
        label: 'Alger Capital Appreciation',
        value: 'Alger Capital Appreciation'
      },
      {
        family: 'funds',
        label: 'Aberdeen Standard SICAV I',
        value: 'Aberdeen Standard SICAV I'
      },
      {
        family: 'funds',
        label: 'BlackRock Strategic Funds - Fixed Income Strategies Fund',
        value: 'BlackRock Strategic Funds - Fixed Income Strategies Fund'
      },
      {
        family: 'funds',
        label: 'Fidelity Funds - Global Technology Fund',
        value: 'Fidelity Funds - Global Technology Fund'
      },
      {
        family: 'funds',
        label: 'Pzena Focused Value',
        value: 'Pzena Focused Value'
      }
    ]
  },
  {
    'family': 'managers',
    'color': 'orange',
    'tags': [
      {
        family: 'managers',
        label: 'BlackRock (Luxembourg) SA',
        value: 'BlackRock (Luxembourg) SA'
      },
      {
        family: 'managers',
        label: 'Comgest Asset Management Intl Ltd',
        value: 'Comgest Asset Management Intl Ltd'
      },
      {
        family: 'managers',
        label: 'Fred Alger Management, LLC',
        value: 'Fred Alger Management, LLC'
      },
      {
        family: 'managers',
        label: 'FundRock Management Company S.A.',
        value: 'FundRock Management Company S.A.'
      },
      {
        family: 'managers',
        label: 'Sands Capital Management, LLC',
        value: 'Sands Capital Management, LLC'
      }
    ]
  }
];

export interface ReportBlockMultiGraphFieldProps extends BaseGraphFieldProps {
	uploadServiceFactory: (fieldId: string) => DdGraphUploadService | undefined,
	currentValue?: MultiGraphFieldData // .text not used
}

function ReportBlockMultiGraphField(props: ReportBlockMultiGraphFieldProps): ReactElement {
  const { t } = useTranslation();
  const uploadService : DdGraphUploadService | undefined = props.uploadServiceFactory(props.desc.id);

  /* const changeDisplayMode = () => {
		setDisplayTagsMode( displayTagsMode === 1 ? 2 : 1)
	} */

  return (
    <Grid
      item
      style={
        props.currentValue?.multigraph && props.currentValue?.multigraph.length > 0 ?
          { padding: '12px 24px 12px 12px', maxWidth: '100%', flexBasis: '100%' } :
          { padding: '12px', maxWidth: '100%', flexBasis: '100%' }
      }
    >
      { uploadService &&
				<UploadFiles
				  service={uploadService}
				  small
				  multiple
				  uploadButtonLabel={t('dds.tabs.reports.graphs.uploadLabel') || undefined}
				  callback={props.update}
				  dispUploaded
				  hideAfterFinish
				  disabled={!props.isEditable}
				>
				  { props.currentValue?.multigraph && props.currentValue?.multigraph.length > 0 ?
				    <Grid container spacing={2}>
				      {props.currentValue?.multigraph.map((graph) => {
				        if (graph.graph) {
				          return (
				            <Grid key={graph.graph.docId} item xs={12}>
				              <DisplayGraphLogic
				                tagsList={tagsList}
				                tagsFamilies={tagsFamilies}
				                initialTags={graph.tags || []}
				                uploadService={uploadService}
				                graphInfo={graph.graph || undefined}
				                desc={props.desc}
				                update={props.update}
				                onSave={props.onSave}
				                isEditable={props.isEditable}
				                graphId={graph.graph.docId || ''}
				                noTags
				              />
				            </Grid>
				          );
				        }

				        return undefined;
				      })}
				    </Grid>
				    :
				    undefined
				  }
				</UploadFiles>
      }
    </Grid>
  );
}

export default ReportBlockMultiGraphField;
