import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import CustomTable, { Column } from '../../../../components/table/table';

function TemplatesHierarchy(props: {templatesHierarchy: string[]}): ReactElement {
  const columns: Array<Column> = [
    { id: 'fullRef', label: 'Templates Hierarchy', minWidth: 'min-content' }
  ];

  const rows = props.templatesHierarchy.map(template => ({
    id: template,
    value: columns.map(column => (
      <>
        {column.id === 'fullRef' &&
            <Typography>
              {template}
            </Typography>
        }
      </>
    ))
  }));

  return (
    <CustomTable columns={columns} rows={rows} size='small' />
  );
}

export default TemplatesHierarchy;