import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar, Tooltip, Typography, Alert } from '@mui/material';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import CustomTable, { Column } from '../table/table';
import { CustomIconButton } from '../button/button';
import { AumContributor } from '../../types/types';
import AumDataModal from './dialog';
import { roundValue } from '../../utils/computations';
import AumService from '../../services/aum';
import ServiceError from '../../services/errors';

function AumsTable(props: {aumService: AumService, list: AumContributor[], aumType: string, type: string, update: () => void, readonly?: boolean}): ReactElement {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleRemoveAum = (myAum: AumContributor) => {
    props.aumService.setAUM(props.list.filter(contributor => contributor.identifier !== myAum.identifier))
      .then(() => {
        props.update();
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
      });
  };

  const columns: Array<Column> = [
    { id: 'label', label: t('utils.label'), minWidth: 'min-content' },
    { id: 'type', label: t('utils.type'), minWidth: 'min-content' },
    // { id: 'gpsl', label: 'GPSL', minWidth: 'min-content', align: 'center' },
    { id: 'value', label: t('utils.value'), minWidth: 'min-content' },
    { id: 'actions', label: '', minWidth: 'min-content', align: 'right' }
  ];

  const rows = props.list.map( (aum: AumContributor) => ({
    id: aum.identifier,
    value: columns.map(column => (
      <>
        {column.id === 'label' && `${aum.label} ${aum.label}`}
        {column.id === 'type' && aum[column.id] }
        {/* {column.id === 'gpsl' &&
					<TagIcon val={aum.tags.GPSL}/>
        } */}
        {column.id === 'value' && aum.amount &&
					<strong>{roundValue(aum.amount.amount)} {aum.amount.currency}</strong>
        }
        {column.id === 'actions' && !props.readonly &&
          <div>
            <Tooltip title='Remove' arrow>
              <div>
                <CustomIconButton
                  variant='contained'
                  color='secondary'
                  small
                  onClick={() => handleRemoveAum(aum)}
                >
                  <RemoveRoundedIcon />
                </CustomIconButton>
              </div>
            </Tooltip>
          </div>
        }
      </>
    ))
  }));

  return (
    <>
      {props.type !== 'manual' && props.list.length > 0 ?
        <CustomTable columns={columns} rows={rows} /> :
        <Typography>{t('dds.tabs.parameters.aums.noAUMContributor')}</Typography>
      }
      {!props.readonly && <AumDataModal list={props.list} aumService={props.aumService} aumType={props.aumType} update={props.update} />}
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AumsTable;
