import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { investmgrContext } from '../../../hooks/datatabs';
import { InvestMgrService } from '../../../services/investmgr';
import FundAndMandateList from '../../strategies/pages/fundsandmandates/fundandmandatelist';

function InvestMgrFundAndMandateList(): ReactElement {
  const investMgrData = investmgrContext();
  const { t } = useTranslation();
  const dataProvider = new InvestMgrService();

  return (
    <>
      {investMgrData && investMgrData._id &&
        <FundAndMandateList dataProvider={dataProvider} entityId={investMgrData._id} entityTypeText={t('investMgrs.simpleLabel')} />
      }
    </>
  );
}

export default InvestMgrFundAndMandateList;
