import React, { ReactElement } from "react";
import Typography from '@mui/material/Typography';

function YearFieldViewer(props: { value?: number }) : ReactElement {
  return (
    <Typography variant='body2' style={{ whiteSpace: 'pre-line' }}>
      {props.value}
    </Typography>
  );
}
export default YearFieldViewer;
