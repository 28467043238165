import { BaseEntityService, FundAndMandateProvider } from './data';
import { ApiResponse, InvestMgr, Fund, Mandate, Nullable } from '../types/types';
import { API } from '../api/api';

export class AssetMgrService extends BaseEntityService<InvestMgr> implements FundAndMandateProvider {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/assetmgrs';
  }

  getFunds(investMgrId: string): Promise<ApiResponse<Nullable<Array<Fund>>>> {
    return API.get(`${this.getBaseUrl()}/${investMgrId}/funds`, { params: { limit: 1000 } })
      .then(res => this.handleResponse<Array<Fund>>(res));
  }

  getMandates(investMgrId: string): Promise<ApiResponse<Nullable<Array<Mandate>>>> {
    return API.get(`${this.getBaseUrl()}/${investMgrId}/mandates`, { params: { limit: 1000 } })
      .then(res => this.handleResponse<Array<Mandate>>(res));
  }
}

export default AssetMgrService;
