import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { CriteriaEvals } from '@deecision/deeligenz-interfaces';
import { Divider, Grid, Snackbar, Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import QuestionService from '../../../services/questions';
import { DdElement, DdEval, DdNewEval } from '../../../types/types';
import EvalRatings from './ratings';
import EvalFlags from './flags';
import KeyFindingEditor from './keyfindingeditor';
import Scores from './scores';
import MeetingEditor from './meetingeditor';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';

export interface EvalComponentsProps {
  ddElement: DdElement,
  ddEval: DdEval,
  service: QuestionService,
  setUpdateList: Dispatch<React.SetStateAction<boolean>>,
  readonly?: boolean
}

export default function Evaluations(props: {ddElement: DdElement, questionService: QuestionService, setUpdateList: Dispatch<React.SetStateAction<boolean>>, onlyAnalyst?: boolean, readonly?: boolean}) : ReactElement {
  const { t } = useTranslation();
  const [ddElement, setDdElement] = useState(props.ddElement);
  const [evals, setEvals] = useState<Array<DdEval>>([]);
  const [update, setUpdate] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [anchorElKeyFinding, setAnchorElKeyFinding] = useState<null | HTMLElement>(null);
  const [anchorElMeeting, setAnchorElMeeting] = useState<null | HTMLElement>(null);

  const addEval = (newEval?: DdNewEval) => {
    const customNewEval: DdNewEval = newEval ||
      {
        evaluatedBy: {
          type: 'analyst'
        },
        rating: undefined,
        flags: []
      };

    props.questionService.addEval(ddElement._id, customNewEval)
      .then(() => {
        setUpdate(true);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    setDdElement(props.ddElement);
  }, [props.ddElement]);

  useEffect(() => {
    let criteriaEvalsArray: DdEval[] = [];

    if (ddElement.evaluation?.criteriaEvals && !Array.isArray(props.ddElement.evaluation?.criteriaEvals)) {
      if ((ddElement.evaluation.criteriaEvals as CriteriaEvals).automatic) {
        criteriaEvalsArray.push((ddElement.evaluation.criteriaEvals as CriteriaEvals).automatic as DdEval);
      }
      if ((ddElement.evaluation.criteriaEvals as CriteriaEvals).analysts && (ddElement.evaluation.criteriaEvals as CriteriaEvals).analysts.length > 0) {
        criteriaEvalsArray.push((ddElement.evaluation.criteriaEvals as CriteriaEvals).analysts[0]);
      }
      if ((ddElement.evaluation.criteriaEvals as CriteriaEvals).final) {
        criteriaEvalsArray.push((ddElement.evaluation.criteriaEvals as CriteriaEvals).final as DdEval);
      }
    } else if (ddElement.criteriaEvals) {
      criteriaEvalsArray = ddElement.criteriaEvals as DdEval[];
    }
    setEvals(criteriaEvalsArray);
  }, [ddElement]);

  useEffect(() => {
    update && props.questionService.getEvals(ddElement._id)
      .then((apiResp) => {
        setUpdate(false);
        if (apiResp.data && apiResp.data.length > 0) {
          setEvals(apiResp.data);
        } else {
          addEval();
        }
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
        setUpdate(false);
      });

    return () => setUpdate(false);
  }, [update]);

  const handleKeyFindingSetterOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElKeyFinding(event.currentTarget);
  };

  const handleMeetingSetterOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMeeting(event.currentTarget);
  };

  return (
    <>
      { evals.length < 1 ? <Typography variant='h4'>{t('ddElements.tabs.evaluation.noEvaluation')}</Typography>
        :
        <Grid container spacing={2}>
          { evals.map((ddEval, index) => (
            <Grid
              key={ddEval._id}
              item
              xs={props.onlyAnalyst && ddEval.type === 'analyst'}
              style={{
                width: props.onlyAnalyst ? undefined : '30%',
                display: 'flex'
              }}
            >
              {index > 0 && !props.onlyAnalyst &&
                <Divider orientation='vertical' style={{ marginRight: '8px', marginLeft: '-8px' }} />
              }
              {(props.onlyAnalyst && ddEval.type === 'analyst' || !props.onlyAnalyst) &&
                <Grid container spacing={2}>
                  <Grid item style={props.onlyAnalyst ? { width: 'min-content' } : undefined}>
                    <Grid container spacing={props.onlyAnalyst ? 1 : 3}>
                      {!props.onlyAnalyst &&
                        <Grid item xs={12} style={{ marginTop: '8px', marginLeft: '8px', whiteSpace: 'nowrap' }}>
                          <Typography variant='h3'>
                            {capitalizeFirstLetter(ddEval.type)}
                          </Typography>
                        </Grid>
                      }
                      {((ddEval.rating && ddEval.rating !== 'null') || ddEval.type === 'analyst') &&
                        <Grid item xs={12} style={{ width: 'max-content', textAlign: 'center' }}>
                          <EvalRatings
                            ddElement={ddElement}
                            ddEval={ddEval}
                            setUpdateList={props.setUpdateList}
                            service={props.questionService}
                            readonly={props.readonly || ddEval.type === 'automatic' || ddEval.type === 'final'}
                          />
                        </Grid>
                      }
                      {(ddEval.flags.length > 0 || ddEval.type === 'analyst') &&
                        <Grid item xs={12} style={{ width: 'max-content' }}>
                          <EvalFlags
                            ddElement={ddElement}
                            ddEval={ddEval}
                            setUpdateList={props.setUpdateList}
                            service={props.questionService}
                            anchorElKeyFinding={anchorElKeyFinding}
                            handleKeyFindingSetterOpen={handleKeyFindingSetterOpen}
                            anchorElMeeting={anchorElMeeting}
                            handleMeetingSetterOpen={handleMeetingSetterOpen}
                            readonly={props.readonly || ddEval.type === 'automatic' || ddEval.type === 'final'}
                          />
                        </Grid>
                      }
                      {ddEval.scores.length > 0 &&
                        <Grid item xs={12}>
                          <Scores
                            ddElement={ddElement}
                            ddEval={ddEval}
                            setUpdateList={props.setUpdateList}
                            service={props.questionService}
                            readonly={props.readonly || ddEval.type === 'automatic' || ddEval.type === 'final'}
                          />
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              }
              {ddEval.type === 'analyst' &&
                <>
                  <KeyFindingEditor
                    ddElement={ddElement}
                    ddEval={ddEval}
                    setUpdateList={props.setUpdateList}
                    service={props.questionService}
                    anchorEl={anchorElKeyFinding}
                    setAnchorEl={setAnchorElKeyFinding}
                  />
                  <MeetingEditor
                    ddElement={ddElement}
                    ddEval={ddEval}
                    setUpdateList={props.setUpdateList}
                    service={props.questionService}
                    anchorEl={anchorElMeeting}
                    setAnchorEl={setAnchorElMeeting}
                  />
                </>
              }
            </Grid>
          ))}
        </Grid>
      }
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
