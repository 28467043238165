import { Tooltip } from '@mui/material';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { grey, red, green } from '@mui/material/colors';
import React, { ReactElement } from 'react';

function TagIcon(props: { val: boolean | null }): ReactElement {

  if (props.val === null){
    return (
      <Tooltip title='null'>
        <ErrorIcon style={{ fill: grey[500] }}/>
      </Tooltip>
    );
  }

  if (!props.val){
    return <CancelIcon style={{ fill: red[500] }}/>;
  }

  return <CheckCircleIcon style={{ fill: green[500] }}/>;
}

export default TagIcon;
