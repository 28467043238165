import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, darken, Paper, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import { PersonRounded } from '@mui/icons-material';
import { green, grey } from '@mui/material/colors';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { useTranslation } from 'react-i18next';
import { Owners, Rating1, Rating2 } from './datadisplay';
import EntityLink from '../../../components/modularcomponents/entitylink/entitylink';
import EntityIcon from '../../../components/modularcomponents/entityicon/entityicon';
import { CustomIconButton } from '../../../components/button/button';
import { DataDueDiligence } from '../../../types/types';
import { useStyles } from '../../../components/ddelement/listitem';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';
import { mainColor } from '../../../themes/theme';
import HeaderActivities from '../../../components/modularcomponents/header/headeractivities';
import HeaderGlobalProgress from '../../../components/modularcomponents/header/headerglobalprogress';
import CustomCards from '../../../components/cards/cards';

export const useMyStyles = makeStyles(() =>
  createStyles({
    link: {
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    summaryContent: {
      margin: '6px 0px 4px 0px'
    }
  }));

function DdsCards(props: {ddList: Array<DataDueDiligence> }): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const myClasses = useMyStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const getStatusColor = (ddStatus?: string) => {
    switch (ddStatus) {
    case 'completed':
      return green[500];
    case 'started':
      return mainColor;
    case 'created':
      return grey[500];
    case 'imported':
      return grey[500];
    default:
      return grey[500];
    }
  };

  const headers = props.ddList.map(dd => ({
    id: dd._id,
    value:
      <EntityLink type='dueDiligence' id={dd._id} noUnderline>
        <Grid container spacing={1}>
          <Grid item style={{ paddingTop: '6px', paddingLeft: '6px' }}>
            <EntityIcon type={dd.auditedEntity.type} />
          </Grid>
          <Grid item xs style={{ paddingTop: '6px' }}>
            <Grid container spacing={0}>
              <Grid item>
                <Typography variant='body1' className={myClasses.link}>
                  {dd.auditedEntity.name}
                </Typography>
              </Grid>
              <Grid item xs>
                <Tooltip title={t('utils.openInNewTab')} arrow>
                  <OpenInNewRoundedIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();

                      window.open(`/deeligenz/dds/${dd._id || ''}`, '_blank', '');
                    }}
                    style={{
                      marginTop: '-1px',
                      marginLeft: '2px',
                      transform: 'scale(0.6)',
                      cursor: 'pointer',
                      color: darken('#fff', 0.4)
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Rating1 ratings={dd.ratings} hideNA />
          </Grid>
          <Grid item>
            <Rating2 ratings={dd.ratings} hideNA />
          </Grid>
          <Grid item style={{ justifySelf: 'flex-end' }}>
            <Tooltip arrow title={capitalizeFirstLetter(dd.status)}>
              <Box>
                <CustomIconButton variant='contained' style={{ cursor: 'inherit', backgroundColor: getStatusColor(dd.status), color: 'white' }} disableRipple small>
                  {dd.status === 'started' &&
                    <PlayArrowRoundedIcon />
                  }
                  {dd.status === 'completed' &&
                    <DoneRoundedIcon />
                  }
                  {(dd.status === 'creating' || dd.status === 'created') &&
                    <NewReleasesRoundedIcon />
                  }
                  {dd.status === 'imported' &&
                    <ImportExportRoundedIcon />
                  }
                </CustomIconButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </EntityLink>
  }));

  const elements = props.ddList.map(dd => ({
    id: dd._id,
    values: [
      <Grid container spacing={1}>
        <Grid item>
          <Chip label={dd.auditedEntity.type} size='small' />
        </Grid>
        <Grid item>
          <Chip label={dd.type} size='small' />
        </Grid>
        {dd.tags && dd.tags?.length > 0 && dd.tags?.map(tag => (
          tag &&
            <Grid item key={tag.value}>
              <Tooltip arrow title={tag.family}>
                <Chip label={capitalizeFirstLetter(tag.value)} size='small' />
              </Tooltip>
            </Grid>
        ))}
      </Grid>,
      <Paper elevation={0} className={classes.paper} style={{ backgroundColor: theme.palette?.background?.default, marginTop: '-4px' }}>
        <Grid container justifyContent='space-around'>
          <Grid item>
            <Grid container>
              <Grid item style={{ margin: '4px 4px 0px 0px' }}>
                <DateRangeRoundedIcon fontSize='small' />
              </Grid>
              <Grid item>
                <Typography variant='h5'>{t('dds.dates.startDate')}</Typography>
                <Typography variant='h4'>{dd.startDate || '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ArrowForwardIosRoundedIcon fontSize='small' style={{ marginTop: '8px', height: '14px' }} />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item style={{ margin: '4px 4px 0px 0px' }}>
                <EventAvailableRoundedIcon fontSize='small' />
              </Grid>
              <Grid item>
                <Typography variant='h5'>{t('dds.dates.validateDate')}</Typography>
                <Typography variant='h4'>{dd.validationDate || '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ArrowForwardIosRoundedIcon fontSize='small' style={{ marginTop: '8px', height: '14px' }} />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item style={{ margin: '4px 4px 0px 0px' }}>
                <EventRoundedIcon fontSize='small' />
              </Grid>
              <Grid item>
                <Typography variant='h5'>{t('dds.dates.nextReviewDate')}</Typography>
                <Typography variant='h4'>{dd.auditedEntity.nextReviewDate || '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>,
      <>
        {dd.lifecycle &&
          <Paper elevation={0} className={classes.paper} style={{ backgroundColor: theme.palette?.background?.default, marginTop: '-4px' }}>
            <Grid container alignItems='center'>
              <Grid item>
                <Grid container spacing={1} style={{ height: '100%', marginTop: 0 }} alignItems='center'>
                  <Grid item style={{ padding: 6, display: 'flex', alignItems: 'center' }}>
                    <FormatListBulletedRoundedIcon style={{ width: '18px', height: '18px' }} />
                  </Grid>
                  <Grid item xs style={{ padding: 6, display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h4'>{t('dds.phases.label')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container alignItems='center'>
                  <HeaderGlobalProgress lifecycle={dd.lifecycle} small />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        }
      </>,
      <>
        {dd.lifecycle?.activities && dd.lifecycle.activities.length > 0 &&
          <Accordion
            elevation={0}
            className={classes.paper}
            style={{
              backgroundColor: theme.palette?.background?.default,
              padding: 0,
              marginTop: '-4px'
            }}
            expanded={expanded.includes(dd._id)}
            onChange={(event, isExpanded) => setExpanded(old => (isExpanded ? [...old, dd._id] : old.filter(e => e !== dd._id)))}
          >
            <AccordionSummary
              classes={{
                content: myClasses.summaryContent
              }}
              expandIcon={<ExpandMoreRoundedIcon fontSize='small' />}
            >
              <Grid container spacing={1} alignItems='center' style={{ marginTop: 0 }}>
                <Grid item style={{ padding: 6, display: 'flex', alignItems: 'center' }}>
                  <AssignmentRoundedIcon style={{ width: '18px', height: '18px' }} />
                </Grid>
                <Grid item xs style={{ padding: 6, display: 'flex', alignItems: 'center' }}>
                  <Typography variant='h4'>{t('dds.activities.label')}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {expanded.includes(dd._id) &&
                <Grid container spacing={1} style={{ marginTop: '-12px' }}>
                  <HeaderActivities lifeCycle={dd.lifecycle} />
                </Grid>
              }
            </AccordionDetails>
          </Accordion>
        }
      </>,
      <>
        { dd.participants.length > 0 ?
          <Paper elevation={0} className={classes.paper} style={{ backgroundColor: theme.palette?.background?.default, padding: '6px 12px', marginTop: '-4px' }}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item style={{ padding: 6, display: 'flex', alignItems: 'center' }}>
                <PersonRounded style={{ marginTop: '4px' }} />
              </Grid>
              <Grid item style={{ height: 'min-content', marginTop: 'auto', marginBottom: 'auto', marginRight: '6px' }}>
                <Owners participants={dd.participants} />
              </Grid>
            </Grid>
          </Paper> :
          <Typography variant='h4' style={{ margin: 'auto', marginTop: '-4px', marginBottom: '6px', width: 'min-content', whiteSpace: 'nowrap' }}>{t('dds.cardsAndTable.participants.noParticipant')}</Typography>
        }
      </>
    ]
  }));

  return (
    <CustomCards headers={headers} elements={elements} />
  );
}

export default DdsCards;
