import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  InvestmgrContextProvider,
  SetUpdateHookContextProvider,
  PathHook
} from '../hooks/datatabs';
import { InvestMgr, Nullable } from '../types/types';
import { assetmgrsTabs } from '../utils/tabs';
import AssetMgrHeader from '../containers/assetmanagers/pageheader';
import DisplayTabs from '../components/layout/tabs/tabs';
import { AssetMgrService } from '../services/assetmgr';
import ServiceError from '../services/errors';
import ErrorPageManager from '../components/Error/errorpagemanager';
import TabsRouter from './tabsrouter';

function AssetMgrRouter(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const [update, setUpdate] = useState(true);
  const [data, setData] = useState<Nullable<InvestMgr>>(null);
  const investmgrService = new AssetMgrService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);

  useEffect(() => {
    if (update && id) {
      data && setData(null);
      setUpdate(false);

      investmgrService.getEntity(id)
        .then((apiResp) => {
          setData(apiResp.data);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
        });
    }
  }, [update, id]);

  PathHook(`${data?.name}`);

  return (
    <SetUpdateHookContextProvider value={setUpdate}>
      <Grid container spacing={2}>
        { error ? <ErrorPageManager error={error} /> :
          <InvestmgrContextProvider value={data}>
            <Grid item xs={12}>
              <AssetMgrHeader entity={data}/>
            </Grid>
            <Grid item xs={12}>
              <DisplayTabs tabs={assetmgrsTabs} />
            </Grid>
            <Grid item xs={12}>
              <TabsRouter tabs={assetmgrsTabs} />
            </Grid>
          </InvestmgrContextProvider>
        }
      </Grid>
    </SetUpdateHookContextProvider>
  );
}

export default AssetMgrRouter;
