import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, Snackbar, Tooltip, Typography, Alert, Theme } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as _ from 'lodash';
import SelectCategory from '../SelectCategory';
import Toolbar from '../../toolbar/Toolbar';
import { GraphFieldData, GraphInfo } from './ReportBlockGraphField';
import { DdGraphUploadService } from '../../../../services/dds';
import { ReportBlockFieldDesc } from '../../../../types/reporttype';
import { Tag, TagsFamily } from '../../../../types/types';
import { API_BASE_URL } from '../../../../utils/env';

const useStyles = makeStyles((theme: Theme) => ({
  editor: {
    margin: 'auto',
    backgroundColor: 'transparent !important'
  },
  fieldContent: {
    paddingRight: '16px',
    maxWidth: 'calc(100% - 54px)',
    flexBasis: 'calc(100% - 54px)',
    '& p': {
      margin: '0',
      padding: '0',
      fontSize: '14px'
    }
  },
  imagePart: {
    flexDirection: 'row'
  },
  chip: {
    marginRight: '8px',
    marginBottom: '8px',
    maxWidth: 'calc(50% - 8px)',
    wordWrap: 'break-word'
    // backgroundColor: theme.palette.primary.light
  },
  button: {
    height: '30px',
    minWidth: 'min-content',
    borderRadius: '25px',
    color: lighten(theme.palette.text.primary, 0.3),
    borderColor: lighten(theme.palette.text.primary, 0.3),
    marginRight: '12px',
    padding: '2px 6px 2px 10px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: lighten(theme.palette.text.primary, 0),
      borderColor: lighten(theme.palette.text.primary, 0)
    },
    '&:focus': {
      borderColor: lighten(theme.palette.text.primary, 0.3)
    }
  }
}));

export interface BaseGraphFieldProps {
  desc: ReportBlockFieldDesc,
  update: () => void,
  onSave: (value : GraphFieldData | undefined) => void,
  isEditable?: boolean
}

interface DisplayGraphLogicProps extends BaseGraphFieldProps {
  tagsList: TagsFamily[],
  tagsFamilies: TagsFamily[],
  initialTags: Tag[],
  uploadService : DdGraphUploadService | undefined,
  graphInfo: GraphInfo,
  graphId: string,
  noTags?: boolean
}

function DisplayGraphLogic(props: DisplayGraphLogicProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [chosenTags, setChosenTags] = useState<Array<Tag>>(props.initialTags);
  const [isSaveNeeded, setIsSaveNeeded] = useState(false);

  useEffect(() => {
    setIsSaveNeeded(!_.isEqual(chosenTags, props.initialTags));
  }, [chosenTags]);

  const editAction = () => {
    setIsEditing(true);
  };

  const cancelAction = () => {
    setChosenTags(props.initialTags);
    setIsEditing(false);
  };

  const saveAction = () => {
    if (chosenTags) {
      const partialGraphData: GraphFieldData = {
        tags: chosenTags
      };
      props.onSave(partialGraphData);
    } else {
      const partialGraphData: GraphFieldData = {
        graph: undefined, // TODO: fix this
        tags: []
      };
      props.onSave(partialGraphData);
    }
    setIsEditing(false);
  };

  const handleDeleteTag = (tag: Tag) => {
    setChosenTags(myChosenTags => myChosenTags?.filter(
      tagSearched => tagSearched.label !== tag.label
    ));
  };

  const handleDeleteItem = () => {
    props.uploadService && props.graphId && props.uploadService.deleteGraph(props.graphId)
      .then(() => {
        setIsEditing(false);
        props.update();
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  const addTag = (tag: Tag) => {
    const sortedTags = [...chosenTags, tag].sort(
      (a, b) => {
        if (a.family < b.family) return -1;

        return a.family > b.family ? 1 : 0;
      });

    setChosenTags(sortedTags);
  };

  const colorSwitch = (tag: Tag) : string => {
    const found = props.tagsFamilies.find(
      chipsData => chipsData.family === tag.family
    );

    switch (found?.color){
    case 'red' :
      return 'RGB(239, 154, 154)';
    case 'pink' :
      return 'RGB(244, 143, 177)';
    case 'green' :
      return 'RGB(165, 214, 167)';
    case 'yellow' :
      return 'RGB(255, 245, 157)';
    case 'orange' :
      return 'RGB(255, 204, 128)';
    default :
      return 'RGB(230, 230, 230)';
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <img src={API_BASE_URL + props.graphInfo.path}
            alt={props.graphInfo.type}
            style={{ borderRadius: '16px', maxWidth: '100%' }}
            draggable={false}
          />
        </Grid>
        <Grid item style={{ maxWidth: 'calc(35% - 54px)', flexBasis: 'calc(35% - 54px)' /* borderRight: '1px solid grey' */ }}>
          { chosenTags.length > 0 && chosenTags.map( tag => (
            <Tooltip key={tag.family} title={`${  tag.label}`}>
              <Chip
                label={tag.label}
                className={classes.chip}
                style={{ backgroundColor: colorSwitch(tag) }}
                onDelete={isEditing ? () => handleDeleteTag(tag) : undefined}
              />
            </Tooltip>
          ))}
          { isEditing && !props.noTags &&
            <>
              <Typography style={{ marginBottom: '8px' }}>{t('dds.tabs.reports.graphs.addTags')}</Typography>
              { props.tagsList.map( tag => (
                <SelectCategory
                  key={`${tag.family  }_button`}
                  family={tag.family}
                  tags={tag.tags.map(myTag => myTag.label)}
                  selectedTags={chosenTags?.filter(choosenTag => choosenTag.label === tag.family)}
                  addTag={addTag}
                />
              ))}
            </>
          }
        </Grid>
        <Grid item style={{ maxWidth: '54px', flexBasis: '54px' }}>
          <Toolbar
            isEditable={props.isEditable}
            isEditing={isEditing}
            editAction={editAction}
            cancelAction={cancelAction}
            deleteAction={handleDeleteItem}
            saveAction={saveAction}
            isSplitOptionAvailable={false}
            isNoSplitVisible
            isSaveNeeded={isSaveNeeded}
            noTags={props.noTags}
          />
        </Grid>
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DisplayGraphLogic;
