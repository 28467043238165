import React, { ReactElement, useEffect, useState } from 'react';
import CardActions from '@mui/material/CardActions';
import { QuestionEditorProps, FileArrayQuestionSpec, Value, QuestionDocument } from '../../../types/types';
import QuestionBox from "../box";
import QuestionLabel from "../label";
import QuestionValue from "../value";
import QuestionEditorButton from '../questioneditorbutton';
import FileArrayFieldEditor from '../../field/editors/filearrayfieldeditor';

function FileArrayQuestionEditor(props: QuestionEditorProps<FileArrayQuestionSpec>) : ReactElement {
  const [value, setValue] = useState<Value>(props.answer?.value as QuestionDocument[]);

  useEffect(() => {
    setValue(props.answer?.value as QuestionDocument[]);
  }, [props.answer]);

  return (
    <QuestionBox>
      <QuestionLabel spec={props.spec}/>
      <QuestionValue>
        <FileArrayFieldEditor value={value as QuestionDocument[] || undefined} setValue={setValue} id={props.buttonsSettings.id} buttonsSettings={props.buttonsSettings} />
      </QuestionValue>
      <CardActions>
        <QuestionEditorButton response={value} setResponse={setValue} defaultValue={props.answer?.value} buttonsSettings={props.buttonsSettings} hideDraft />
      </CardActions>
    </QuestionBox>
  );
}

export default FileArrayQuestionEditor;
