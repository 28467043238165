import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Accordion, Box, Theme } from '@mui/material';
import { mainRadius } from '../../../themes/theme';
import { sidebarStatus } from '../../../hooks/datatabs';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: mainRadius
  },
  fullPaper: {
    position: 'fixed',
    top: '56px',
    right: '0px',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: '0px !important',
    width: 'auto',
    opacity: 1,
    zIndex: 999,
    transition: 'backgroundColor 200ms ease-in-out'
  }
}));

function HeaderContainer(props: {expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>, children: Array<ReactElement> | ReactElement}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const containerRef = useRef<HTMLDivElement>(null);
  const sidebarStatusGetter = sidebarStatus();
  const [fixed, setFixed] = useState(false);
  const { hash } = useLocation();

  const scrollHandler = () => {
    const height = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    if (window.scrollY > 55 && height > (window.innerHeight + (containerRef.current?.clientHeight || 360))) {
      setFixed(true);
    } else if (window.scrollY < 56) {
      setFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);

    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);

  useEffect(() => {
    if ((fixed && !hash)) {
      props.setExpanded(false);
    } else {
      props.setExpanded(true);
    }
  }, [fixed]);

  return (
    <>
      <Accordion
        ref={containerRef}
        style={ (fixed && !hash) ?
          {
            left: sidebarStatusGetter ? '270px' :'96px',
            transition: 'left 200ms ease-in'
          } :
          {
            transition: 'left 200ms ease-out'
          }
        }
        expanded={props.expanded}
        className={(fixed && !hash) ? classes.fullPaper : classes.paper}
        elevation={0} variant='outlined'
      >
        {props.children}
      </Accordion>
      {(fixed && !hash) &&
        <Box
          style={
            { width: '100%', height: (fixed && !hash) ? '64px' : 0 }
          }
        />
      }
    </>
  );
}

export default HeaderContainer;
