import React, { ReactElement } from 'react';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { green } from '@mui/material/colors';
import { CustomIconButton } from '../../button/button';
import { UpdateMeetingProps } from '../../../types/meetingstypes';

const useStyles = makeStyles(() => ({
  saveButton: {
    height: '38px',
    width: '38px',
    textTransform: 'none',
    backgroundColor: green[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: green[300]
    }
  }
}));

interface EditionToolsProps {
	updateMeetings?: ( updateItems: UpdateMeetingProps ) => void,
	edit: () => void,
	cancel?: () => void,
	isEditing: boolean,
	isEditable?: boolean,
	isSaveNeeded?: boolean,
  readonly?: boolean
}

export default function EditionTools(props: EditionToolsProps) : ReactElement {
  const classes = useStyles();

  const edit = () => {
    props.edit();
  };

  const cancel = () => {
    if (props.cancel){
      props.cancel();
      props.edit();
    }
  };

  const save = () => {
    props.edit();
  };

  return (
    <>
      { !props.isEditing &&
			<Tooltip title={props.isEditable ? 'Edit' : 'Not editable'} arrow>
			  <div>
			    <CustomIconButton
			      aria-label='edit mode'
			      variant='contained'
			      onClick={edit}
			      disabled={!props.isEditable || props.readonly}
			      small
			    >
			      <EditIcon fontSize='small' />
			    </CustomIconButton>
			  </div>
			</Tooltip>
      }
      { props.isEditing && props.isEditable &&
				<>
				  <Tooltip title='Discard changes' arrow>
				    <div style={{ marginRight: props.isSaveNeeded ? '12px' : undefined }}>
				      <CustomIconButton
				        aria-label='cancel changes'
				        variant='contained'
				        color='secondary'
				        onClick={cancel}
				        small
				        disabled={props.readonly}
				      >
				        <ClearIcon fontSize='small' />
				      </CustomIconButton>
				    </div>
				  </Tooltip>
				  { props.isSaveNeeded &&
						<Tooltip title='Save changes to server' arrow>
						  <div>
						    <CustomIconButton
						      aria-label='save changes'
						      variant='contained'
						      className={classes.saveButton}
						      onClick={save}
						      small
						      disabled={props.readonly}
						    >
						      <SaveIcon fontSize='small' />
						    </CustomIconButton>
						  </div>
						</Tooltip>
				  }
				</>
      }
    </>
  );
}
