import React, { Dispatch, FormEvent, ReactElement } from 'react';
import { ClickAwayListener, AutocompleteRenderInputParams, Theme } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { darken, lighten, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { CustomIconButton } from '../button/button';
import { mainRadius } from '../../themes/theme';

interface SearchBarProps {
  focused: boolean,
  setFocused: Dispatch<React.SetStateAction<boolean>>,
  handleSubmit: (query: string) => void,
  value: string,
  setValue: Dispatch<React.SetStateAction<string>>,
  setAnchorEl?: Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  params?: AutocompleteRenderInputParams,
  children?: ReactElement
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: '12px',
      padding: '6px',
      opacity: '0.8',
      display: 'flex',
      alignItems: 'center',
      height: '30px',
      borderRadius: mainRadius,
      backgroundColor: darken(theme.palette.background.default, 0.07),
      transition: 'all 0.2s ease-in-out'
    },
    rootFocused: {
      marginRight: '12px',
      borderRadius: mainRadius,
      border: '1px solid',
      borderColor: lighten(theme.palette.text.primary, 0.6),
      padding: '6px',
      opacity: '1',
      display: 'flex',
      alignItems: 'center',
      height: '30px',
      transition: 'all 0.2s ease-in-out'
    },
    input: {
      width: '100%',
      marginLeft: theme.spacing(1),
      flex: 1
    }
  })
);

function SearchBar(props: SearchBarProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const handleFocus = (event?: React.MouseEvent<HTMLDivElement>) => {
    props.setFocused(true);
    event && props.setAnchorEl && props.setAnchorEl(event.currentTarget);
  };

  const handleUnFocus = () => {
    props.setFocused(false);
  };

  return (
    <ClickAwayListener onClickAway={handleUnFocus}>
      <Paper
        id='search-container'
        component='form'
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          handleUnFocus();
          props.handleSubmit(props.value);
        }}
        className={props.focused || props.value ? classes.rootFocused : classes.root}
        style={{ minWidth: props.children ? '360px' : '100%' }}
        elevation={0}
      >
        <InputBase
          ref={props.params?.InputProps.ref}
          className={classes.input}
          placeholder={t('filters.searchBar.placeHolder') || undefined}
          id={props.params?.id}
          disabled={props.params?.disabled}
          inputProps={props.params ? props.params.inputProps : { 'aria-label': 'search' }}
          onClick={handleFocus}
          onChange={(event) => {
            props.setValue(event.target.value);
            handleFocus();
          }}
          value={props.value}
        />
        { (props.focused || props.value) &&
        <CustomIconButton
          color='secondary'
          variant='text'
          disabled={!props.focused && !props.value}
          small
          onClick={() => {
            props.setValue('');
            props.handleSubmit('');
          }}
        >
          <CloseRoundedIcon />
        </CustomIconButton>
        }
        <CustomIconButton type='submit' variant='text' disabled={!props.focused && !props.value} small aria-label='search'>
          <SearchRoundedIcon />
        </CustomIconButton>
        {props.children}
      </Paper>
    </ClickAwayListener>
  );
}

export default SearchBar;
