import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { SelectQuestionSpec } from '../../../types/types';

export default function MultiSelectFieldViewer(props: {spec: SelectQuestionSpec, value?: string[]}) : ReactElement{
  const { spec } = props;

  return (
    <>
      { spec.choices && props.value &&
        <ul style={{ paddingLeft: '16px' }}>
          { spec.choices.map(rowValue => (
            props.value?.map(((value) => {
              if (rowValue.value === value) {
                return (
                  <li key={rowValue.value}>
                    <Typography variant='body1'>
                      {rowValue.label}
                    </Typography>
                  </li>
                );
              }

              return undefined;
            }))
          ))}
        </ul>
      }
    </>
  );
}
