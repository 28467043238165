import React, { ReactElement } from "react";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ValueSetter } from "../../../types/types";
import { mainRadius } from '../../../themes/theme';

function TextFieldEditor(props: {value?: string, setValue: ValueSetter, required?: boolean, customKey?: string, small?: true } ) : ReactElement {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setValue(event.target.value);
  };

  return (
    <TextareaAutosize
      key={props.customKey}
      minRows={1}
      placeholder='Enter text here'
      value={props.value}
      style={{
        maxWidth: '100%',
        width: '100%',
        fontSize: '16px',
        fontFamily: 'inherit',
        padding: props.small ? '8px' : '12px',
        borderRadius: mainRadius,
        borderColor: props.required && !props.value ? 'red' : ''
      }}
      onChange={event => handleChange(event)}
    />
  );
}

export default TextFieldEditor;
