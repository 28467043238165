import React, { ReactElement, useEffect, useState } from 'react';
import TextField from "@mui/material/TextField";
import { Grid } from '@mui/material';
import { AmountType, Value, ValueSetter } from '../../../../types/types';
import UnitFieldEditor from "./unitfieldeditor";
import CurrencyFieldEditor from '../currencyfieldeditor';

export const removeNonNumeric = (num: { toString: () => string }): string => num.toString().replace(/[^0-9-.]/g, "");

export const addCommas = (num: { toString: () => string }): string => {
  if (num.toString().split('.').length > 1) {
    return `${num.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')  }.${
      num.toString().split('.')[1].length > 0 ? num.toString().split('.')[1] : ''}`;
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

function AmountFieldEditor(props: {value: Value, setValue: ValueSetter, required?: boolean, customKey?: string}) : ReactElement {
  const [editing, setEditing] = useState(false);
  const defaultValue = (props.value as AmountType)?.amount ? parseFloat(removeNonNumeric((props.value as AmountType).amount)) : parseFloat(removeNonNumeric('ㅤ'));
  const getUnit = (value: number, force?: boolean) => {
    if (!editing || (editing && force)) {
      if (value % 1000000000000 === 0) {
        return ('T');
      }
      if (value % 1000000000 === 0) {
        return ('B');
      }
      if (value % 1000000 === 0) {
        return ('M');
      }
      if (value % 1000 === 0) {
        return ('K');
      }
    }

    return undefined;
  };
  const getUnitNumber = (value: number, force?: boolean) => {
    switch (getUnit(value, force)) {
    case 'T':
      return 1000000000000;
    case 'B':
      return 1000000000;
    case 'M':
      return 1000000;
    case 'K':
      return 1000;
    default:
      return 1;
    }
  };
  const getNumber = (value: number, force?: boolean) => {
    switch (getUnit(value, force)) {
    case 'T':
      return (value / 1000000000000);
    case 'B':
      return (value / 1000000000);
    case 'M':
      return (value / 1000000);
    case 'K':
      return (value / 1000);
    default:
      return value;
    }
  };

  const [value, setValue] = useState(defaultValue && addCommas(getNumber(defaultValue).toString()) || undefined);
  const [unit, setUnit] = useState(defaultValue && getUnitNumber(defaultValue) || 1);
  const [currency, setCurrency] = useState(props.value && (props.value as AmountType).currency || undefined);

  useEffect(() => {
    const updateValue = (props.value as AmountType)?.amount ? parseFloat(removeNonNumeric((props.value as AmountType).amount)) : undefined;

    if (!props.value && value) {
      setValue('');
      setUnit(1);
      setCurrency(undefined);
    } else if (updateValue && ((value && parseFloat(removeNonNumeric(value)) * unit) !== updateValue || currency !== (props.value as AmountType).currency)) {
      setValue(addCommas(getNumber(updateValue, true).toString()));
      setUnit(getUnitNumber(updateValue, true) || 1);
      setCurrency((props.value as AmountType).currency);
    }
  }, [props.value]);

  useEffect( () => {
    if (value && currency) {
      props.setValue({ amount: (parseFloat(removeNonNumeric(value)) * unit), currency });
    } else {
      props.setValue(undefined);
    }
  }, [unit, currency]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEditing(true);
    setValue(addCommas(removeNonNumeric(event.target.value)));
    if (event.target.value && event.target.value.split('').pop() !== '.' && removeNonNumeric(event.target.value) && currency) {
      props.setValue({
        amount: (parseFloat(removeNonNumeric(event.target.value)) * unit),
        currency
      });
    } else if (event.target.value && event.target.value.split('').pop() !== '.' || !event.target.value) {
      props.setValue(undefined);
    }
  };

  return (
    <Grid container justifyContent='flex-start' spacing={1}>
      <Grid item xs='auto'>
        <TextField
          variant='standard'
          error={props.required ? !value : false}
          key={props.customKey}
          inputProps={{ step: "0" }}
          onChange={event => handleChange(event)}
          type='text'
          label='ㅤ'
          defaultValue={value}
          value={(value)}
        />
      </Grid>
      <Grid item xs='auto'>
        <UnitFieldEditor defaultUnit={unit} setUnit={setUnit}/>
      </Grid>
      <Grid item xs='auto'>
        <CurrencyFieldEditor value={currency} setValue={myValue => setCurrency(myValue as string)} required={props.required} dispLabel/>
      </Grid>
    </Grid>
  );
}

export default AmountFieldEditor;
