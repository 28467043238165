import React,  { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Snackbar, Typography, Alert } from '@mui/material';
import DdValidationItemRow from './DdValidationItemRow';
import { DdValidationItem } from '../../../../types/types';
import { ManagerViewService } from '../../../../services/dds';
import { dataContext } from '../../../../hooks/datatabs';
import ServiceError from '../../../../services/errors';

export default function DdValidationItemList(): ReactElement {
  const ddMenu = dataContext();
  const { t } = useTranslation();
  const ddId = ddMenu?.dueDiligence?._id;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [itemsToDisplay, setItemToDisplay] = useState<DdValidationItem[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const managerService = ddId ? new ManagerViewService(ddId) : undefined;

  const statusAction = (itemId: string, newStatus: string) => {
    switch (newStatus){
    case 'validated' :
      managerService?.validate(itemId)
        .then(() => setIsUpdate(true))
        .catch((err) => {
          setErrorMsg(ServiceError.getErrorMsg(err));
          setSnackbarOpen(true);
        });
      break;
    case 'rejected' :
      managerService?.reject(itemId)
        .then(() => setIsUpdate(true))
        .catch((err) => {
          setErrorMsg(ServiceError.getErrorMsg(err));
          setSnackbarOpen(true);
        });
      break;
    default :
    }
  };

  const parseItems = (data: DdValidationItem[]) => {
    const myValidatedItems: DdValidationItem[] = [];
    const myRejectedItems: DdValidationItem[] = [];
    const myToValidateItems: DdValidationItem[] = [];

    data.forEach((item) => {
      if (item.status === 'validated') {
        myValidatedItems.push(item);
      } else if (item.status === 'rejected') {
        myRejectedItems.push(item);
      } else {
        myToValidateItems.push(item);
      }
    });

    setItemToDisplay([...myToValidateItems, ...myRejectedItems, ...myValidatedItems]);
  };

  useEffect(() => {
    setIsUpdate(false);

    if (ddId && managerService) {
      managerService.getAllForDD()
        .then((res) => {
          if (res.data !== null) {
            parseItems(res.data);
          }
        })
        .catch((err) => {
          setSnackbarOpen(true);
          setErrorMsg(ServiceError.getErrorMsg(err));
        });
    }
  }, [isUpdate]);

  return (
    <>
      {itemsToDisplay.length > 0 ?
        <Grid container spacing={2}>
          <Typography variant='h1' component='h2' style={{ fontWeight: 700, marginBottom: '16px' }}>{t('dds.tabs.managerView.itemsRequiringValidation')}</Typography>
          { itemsToDisplay.map(item => (
            <DdValidationItemRow
              key={item._id}
              validationItem={item}
              statusAction={statusAction}
            />
          ))}
        </Grid> :
        <Typography>{t('dds.tabs.managerView.noItem')}</Typography>
      }
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '60px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
