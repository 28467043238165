import React, { ReactElement } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Grid } from '@mui/material';
import { dataContext } from '../../../../hooks/datatabs';
import { DataDueDiligence, Nullable } from '../../../../types/types';
import { DeleteDdSection } from '../components/deleteddsection';
import TemplatesHierarchy from './templatesHierarchy';
import DdElementGroupsList from './ddelementgroupslist';

function DdAdminPage(): ReactElement {
  const { keycloak } = useKeycloak();
  const ddMenu = dataContext();
  const dd: Nullable<DataDueDiligence> = ddMenu ? ddMenu.dueDiligence : null;

  if (dd) {
    return (
      <Grid container spacing={2}>
        {dd.templatesHierarchy &&
          <Grid item xs={12}>
            <TemplatesHierarchy templatesHierarchy={dd.templatesHierarchy} />
          </Grid>
        }
        {dd.resolvedVersions?.groups &&
          <Grid item xs={12}>
            <DdElementGroupsList ddElementGroups={dd.resolvedVersions.groups} />
          </Grid>
        }
        {keycloak.hasResourceRole('admin') &&
          <Grid item xs={12}>
            <h2>!!! Dangerous section !!!</h2>
            <DeleteDdSection ddId={dd._id} />
          </Grid>
        }
      </Grid>
    );
  }

  return (
    <></>
  );
}

export default DdAdminPage;
