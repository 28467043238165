import React, { ReactElement } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mainRadius } from '../../../themes/theme';
import Branding from './branding';

export const useSettingsStyles = makeStyles((theme: Theme) =>
  createStyles({
    hideBorder: {
      '&.MuiAccordion-root:before': {
        display: 'none',
        boxShadow: 'none'
      }
    },
    paper: {
      padding: '16px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '1',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out'
      }
    },
    imageDisplay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '16px'
    }
  })
);

function SettingsPage(): ReactElement {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          Settings
        </Typography>
      </Grid>
      <Branding />
    </Grid>
  );
}

export default SettingsPage;
