import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, Snackbar, Alert } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import ReportsBlockFactory from './reportsblock';
import { DdParticipantsService } from '../../../services/contacts';
import { Participant } from '../../../types/types';

function ReportBlocks(props: {id: string, reportBlocks?: string[], readonly?: boolean}): ReactElement {
  const { keycloak } = useKeycloak();
  const [reportBlocks, setReportBlocks] = useState(props.reportBlocks);
  const [isParticipant, setIsParticipant] = useState(false);
  const service = new DdParticipantsService(`${props.id}`);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const checkRolesForDD = (apiRespData: Participant[] | null) => {
    const results = apiRespData?.find(
      participant => participant.email === keycloak.tokenParsed?.email
    );

    if (results && results.roles.indexOf('contributor') > -1 && !isParticipant) {
      setIsParticipant(true);
    }
  };

  useEffect(() => {
    service.getAll()
      .then((apiResp) => {
        checkRolesForDD(apiResp.data);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  }, []);

  useEffect(() => {
    setReportBlocks(props.reportBlocks);
  }, [props.reportBlocks]);

  return (
    <>
      {reportBlocks && reportBlocks.length > 0 &&
        <Grid container spacing={2}>
          {reportBlocks.map(reportBlock => (
            <Grid key={reportBlock} item xs={12}>
              <ReportsBlockFactory blockId={reportBlock} isParticipant={isParticipant} readonly={props.readonly} />
            </Grid>
          ))}
        </Grid>
      }
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ReportBlocks;
